import React from "react";
import { Col, Progress, Row, Table } from "reactstrap";
import ValueFormat from "../../../util/ValueFormat";
import { Tooltip as ReactTooltip } from "react-tooltip";

export default function ProgressBarUD({ UDMainDashboardSafetyTableData }) {
  const TableStyle = {
    table: {
      width: "100%",
      display: "table",
      borderSpacing: 0,
      margin: "0px",
    },
  };

  const progData = UDMainDashboardSafetyTableData.map((item) => ({
    title: item.title,
    type: item.type,
    data: item.dataListKeys.map((key, index) => ({
      key: key,
      value: item.dataListValues[index],
      color:
        key === "Safe Driver"
          ? "ca"
          : key === "Sportive Driver"
          ? "cb"
          : key === "Quite Safe Driver"
          ? "cc"
          : key === "Reckless Driver"
          ? "cd"
          : key === "Quite Dangerous Driver"
          ? "ce"
          : "cf",
      category:
        key === "Safe Driver"
          ? "C1"
          : key === "Sportive Driver"
          ? "C2"
          : key === "Quite Safe Driver"
          ? "C3"
          : key === "Reckless Driver"
          ? "C4"
          : key === "Quite Dangerous Driver"
          ? "C5"
          : "C6",
      categoryColors:
        key === "Safe Driver"
          ? "#fff"
          : key === "Sportive Driver"
          ? "#000"
          : key === "Quite Safe Driver"
          ? "#000"
          : key === "Reckless Driver"
          ? "#000"
          : key === "Quite Dangerous Driver"
          ? "#fff"
          : "#fff",
    })),
    postFix: item.postFix,
    statusColor: item.statusColor,
  }));

  let distanceData = {};
  let durationData = {};
  let alertData = {};
  let otherAlertsData = {};

  progData.map((i) => {
    if (i.type === "DISTANCE") {
      distanceData = {
        type: i.type,
        data: i.data,
      };
    } else if (i.type === "DURATION") {
      durationData = {
        type: i.type,
        data: i.data,
      };
    } else if (i.type === "ALERT") {
      alertData = {
        type: i.type,
        data: i.data,
      };
    } else if (i.type === "OTHER ALERT") {
      otherAlertsData = {
        type: i.type,
        data: i.data,
      };
    }
  });

  let distanceTotal = 0;
  let durationTotal = 0;
  let alertTotal = 0;
  let otherAlertsTotal = 0;

  progData.forEach((item) => {
    item.data.forEach((dataItem) => {
      // Check the type of the data item and add its value to the corresponding total
      if (item.type === "DISTANCE") {
        distanceTotal += parseFloat(dataItem.value);
      } else if (item.type === "DURATION") {
        durationTotal += parseFloat(dataItem.value);
      } else if (item.type === "ALERT") {
        alertTotal += parseFloat(dataItem.value);
      } else if (item.type === "OTHER ALERT") {
        otherAlertsTotal += parseFloat(dataItem.value);
      }
    });
  });

  return (
    <div className="card p-2 mb-4 cardHeader">
      <Table bordered style={TableStyle.table}>
        <thead>
          <tr style={{border:"0px"}}>
            <th></th>
            <th></th>
            <th style={{ display: "flex", justifyContent:"space-between", alignItems:"center" }}>
              <div style={{display:"flex", alignItems:"center",fontSize: "14px", fontWeight: 600, textAlign: "center"}}>
                <button
                  className="btn me-1"
                  style={{ backgroundColor: "#19543E", marginTop: '1px' }}
                />
                Safe Driver(C1)
              </div>
              <div style={{display:"flex", alignItems:"center",fontSize: "14px", fontWeight: 600, textAlign: "center"}}>
                <button
                  className="btn me-1"
                  style={{ backgroundColor: "#3CB371", marginTop: '1px' }}
                />
                Sportive Driver(C2)
              </div>
              <div style={{display:"flex", alignItems:"center",fontSize: "14px", fontWeight: 600, textAlign: "center"}}>
                <button
                  className="btn me-1"
                  style={{ backgroundColor: "#9EC462", marginTop: '1px' }}
                />
                Quite Safe Driver(C3)
              </div>
              <div style={{display:"flex", alignItems:"center",fontSize: "14px", fontWeight: 600, textAlign: "center"}}>
                <button
                  className="btn me-1"
                  style={{ backgroundColor: "#FFBF00", marginTop: '1px' }}
                />
                Reckless Driver(C4)
              </div>
              <div style={{display:"flex", alignItems:"center",fontSize: "14px", fontWeight: 600, textAlign: "center"}}>
                <button
                  className="btn me-1"
                  style={{ backgroundColor: "#CD5C5C", marginTop: '1px' }}
                />
                Quite Dangerous Driver(C5)
              </div>
              <div style={{display:"flex", alignItems:"center",fontSize: "14px", fontWeight: 600, textAlign: "center"}}>
                <button
                  className="btn me-1"
                  style={{ backgroundColor: "#FF0000", marginTop: '1px' }}
                />
                Dangerous Driver(C6)
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td >
              <h6 className="body_text mt-2">
                {ValueFormat.capitalizeFirstLetter(distanceData?.type)} (kms)
              </h6>
            </td>
            <td>
              <h6 className="body_text mt-2">{distanceTotal.toFixed(2)} </h6>
            </td>
            <td>
              <Progress
                multi
                style={{ height: "30px", width: "100%", borderRadius: 20 }}
              >
                {distanceData &&
                  distanceData.data.map((item) => (
                    <Progress
                      key={item.key}
                      bar
                      value={(item.value / distanceTotal) * 100 || 0}
                      color={item.color}
                      data-tooltip-id={item.key + 1}
                      style={{
                        color: item.value > 0 ? item.categoryColors : "black",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      {item.value === 0
                        ? "0%"
                        : `${((item.value / distanceTotal) * 100).toFixed(
                            2
                          )}% (${item.value})`}
                      <ReactTooltip
                        id={item.key + 1}
                        place="top"
                        content={
                          item.value === 0
                            ? item.key + " - " + "Distance(kms) - 0% (0)"
                            : item.key +
                              " - " +
                              "Distance(kms) - " +
                              ((item.value / distanceTotal) * 100).toFixed(2) +
                              "% (" +
                              item.value +
                              ")"
                        }
                        style={{ backgroundColor: "black", zIndex: 100 }}
                      />
                    </Progress>
                  ))}
              </Progress>
            </td>
          </tr>
          <tr>
            <td>
              <h6 className="body_text mt-2">
                {ValueFormat.capitalizeFirstLetter(durationData?.type)} (hrs)
              </h6>
            </td>
            <td>
              <h6 className="body_text mt-2">{durationTotal.toFixed(2)} </h6>
            </td>
            <td>
              <Progress
                multi
                style={{ height: "30px", width: "100%", borderRadius: 20 }}
              >
                {durationData &&
                  durationData.data.map((item) => (
                    <Progress
                      key={item.key}
                      bar
                      data-tooltip-id={item.key + 2}
                      value={(item.value / durationTotal) * 100 || 0}
                      color={item.color}
                      style={{
                        color: item.categoryColors,
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      {item.value === 0
                        ? "0%"
                        : `${((item.value / durationTotal) * 100).toFixed(
                            2
                          )}% (${item.value})`}
                      <ReactTooltip
                        id={item.key + 2}
                        content={
                          item.value === 0
                            ? item.key + " - " + "Duration(hrs) - 0% (0)"
                            : item.key +
                              " - " +
                              "Duration(hrs) - " +
                              ((item.value / durationTotal) * 100).toFixed(2) +
                              "% (" +
                              item.value +
                              ")"
                        }
                        place="top"
                        style={{ backgroundColor: "black", zIndex: 100 }}
                      />
                    </Progress>
                  ))}
              </Progress>
            </td>
          </tr>

          <tr>
            <td>
              <h6 className="body_text mt-2 text-nowrap" >
                {/* {ValueFormat.capitalizeFirstLetter(alertData?.type)} */}
                Road Attributes<br/> Alert
              </h6>
            </td>
            <td>
              <h6 className="body_text mt-3">{alertTotal.toFixed(2)} </h6>
            </td>
            <td>
              <div style={{ marginTop: '12px' }}>
              <Progress
                multi
                style={{ height: "30px", width: "100%", borderRadius: 20 }}
              >
                {alertData &&
                  alertData.data.map((item) => (
                    <Progress
                      key={item.key}
                      bar
                      data-tooltip-id={item.key + 3}
                      value={(item.value / alertTotal) * 100 || 0}
                      color={item.color}
                      style={{
                        color: item.categoryColors,
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      {item.value === 0
                        ? "0%"
                        : `${((item.value / alertTotal) * 100).toFixed(2)}% (${
                            item.value
                          })`}
                      <ReactTooltip
                        id={item.key + 3}
                        content={
                          item.value === 0
                            ? item.key + " - Alert 0% (0)"
                            : item.key +
                              " - " +
                              "Alert - " +
                              ((item.value / alertTotal) * 100).toFixed(2) +
                              "% (" +
                              item.value +
                              ")"
                        }
                        place="top"
                        style={{ backgroundColor: "black", zIndex: 100 }}
                      />
                    </Progress>
                  ))}
              </Progress>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <h6 className="body_text mt-2 text-nowrap">
                {/* {ValueFormat.capitalizeFirstLetter(otherAlertsData?.type)} */}
                Overspeed / <br/>Mobile Call / Screen
              </h6>
            </td>
            <td>
              <h6 className="body_text mt-3">{otherAlertsTotal.toFixed(2)} </h6>
            </td>
            <td >
              <div style={{ marginTop: '12px' }}>
              <Progress
                multi
                style={{ height: "30px", width: "100%", borderRadius: 20 }}
              >
                {otherAlertsData &&
                  otherAlertsData.data.map((item) => (
                    <Progress
                      key={item.key}
                      bar
                      data-tooltip-id={item.key + 4}
                      value={(item.value / otherAlertsTotal) * 100 || 0}
                      color={item.color}
                      style={{
                        color: item.categoryColors,
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      {item.value === 0
                        ? "0%"
                        : `${((item.value / otherAlertsTotal) * 100).toFixed(
                            2
                          )}% (${item.value})`}
                      <ReactTooltip
                        id={item.key + 4}
                        content={
                          item.value === 0
                            ? item.key + " - Other Alert 0% (0)"
                            : item.key +
                              " - " +
                              "Other Alert - " +
                              ((item.value / otherAlertsTotal) * 100).toFixed(
                                2
                              ) +
                              "% (" +
                              item.value +
                              ")"
                        }
                        place="top"
                        style={{ backgroundColor: "black", zIndex: 100 }}
                      />
                    </Progress>
                  ))}
              </Progress>
              </div>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}
