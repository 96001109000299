import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getMarketoverview } from "../../../store/actions";
import LineColumnArea from "./charactersChart";
import AdminDashboardController from "../../../controller/Dashboard/AdminDasahboardController.js";
import ValueFormat from "../../../util/ValueFormat";
import { Tooltip as ReactTooltip } from "react-tooltip";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Col,
  Row,
  CardHeader,
  CardBody,
  Table,
} from "reactstrap";
import * as echarts from "echarts";

import Bubble from "../../Admin/Charts/bubblechart";
import Loader from "../../../components/Common/loader";
import {
  SC_LOGIN_USER_FIRST_NAME,
  SC_USERNAME,
  SC_USER_ID,
  SC_USER_USERNAME,
} from "../../../constant/StorageConstant";
import { displayText } from "../../../constant/MessageConstant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import DateTimePickerPopup from "../../ModelPopup/DateTimePopup.js";
import Converter from "../../../components/Common/EncodeDecode.js";

const C1Chart = ({ loaderCount, filter, handleSafetyTableData }) => {
  const [activeTab, setActiveTab] = useState("1");
  const [count, setCount] = useState(0);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const data = [[[0, 0, 15, "NoData"]]];

  const getOption = {
    tooltip: {
      trigger: "axis",
      show: false,
    },
    toolbox: {
      show: false,
    },
    grid: {
      zlevel: 0,
      bottom: "15%",
      borderWidth: 0,
      backgroundColor: "rgba(0,0,0,0)",
      borderColor: "rgba(0,0,0,0)",
    },
    legend: {
      right: 10,
      show: true,
    },
    xAxis: {
      min: 0,
      max: 100,
      name: "Anticipatory Driving",
      nameLocation: "bottom",
      nameGap: 0,
      nameTextStyle: {
        align: "center",
        verticalAlign: "top",
        padding: [38, 0, 0, 0],
        color: "#74788d",
        fontFamily: "Arial, sans-serif",
      },

      splitLine: {
        lineStyle: {
          type: "dashed",
        },
      },
      axisLine: {
        lineStyle: {
          color: "#74788d",
        },
      },
    },
    yAxis: {
      name: "Self Confidence",
      nameLocation: "middle",
      nameGap: 35,
      min: 0,
      max: 100,
      splitLine: {
        lineStyle: {
          type: "dashed",
        },
      },
      scale: true,
      axisLine: {
        lineStyle: {
          color: "#74788d",
        },
      },
      nameTextStyle: {
        color: "#74788d", // Set font color
        fontFamily: "Arial, sans-serif", // Set font family
      },
    },
    series: [
      {
        name: "C1",
        data: data[0],
        type: "scatter",
        symbolSize: 8,
        label: {
          emphasis: {
            show: true,
            formatter: function (param) {
              return param.data[3];
            },
            position: "right",
          },
        },
        itemStyle: {
          normal: {
            shadowBlur: 0,
            shadowColor: "rgba(94, 148, 60, 0.7)",
            shadowOffsetY: 0,
            color: new echarts.graphic.RadialGradient(0.4, 0.3, 1, [
              {
                offset: 0,
                color: "#0AA700",
              },

              {
                offset: 1,
                color: "#0AA700",
              },
            ]),
          },
        },
      },
    ],
  };

  const [safetyDataList, setSafetyDataList] = useState(getOption);

  const dispatch = useDispatch();

  const [state, setState] = useState("ALL");

  const { Marketoverview } = useSelector((state) => ({
    Marketoverview: state.dashboard.Marketoverview,
  }));

  const onChangeHandle = (data) => {
    setState(data);
    dispatch(getMarketoverview(data));
  };

  useEffect(() => {
    dispatch(getMarketoverview(state));
  }, [state]);

  useEffect(() => {
    dispatch(getMarketoverview("All"));
  }, [dispatch]);

  const series = [
    {
      name: "Driving Score",
      type: "line",
      data: [0],
    },
  ];

  const tableData = [
    {
      name: "",
      totalRides: 0,
      percentage: 0,
      rideName: [],
    },
  ];

  const [drivingData, setDrivingData] = useState(series);
  // const [filter, setFilter] = useState("CURRENT_YEAR");
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [xAxisData, setXAxisData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [safeDriverData, setSafeDriverData] = useState(tableData);
  const [current, setCurrent] = useState(1);
  const pageSize = 5;
  // const [indexStart, setIndexStart] = useState((current - 1) * pageSize);
  const [indexStart, setIndexStart] = useState(0);
  const [dataLength, setDataLength] = useState([]);
  const [prevShow, setPrevShow] = useState(false);
  const [nextShow, setNextShow] = useState(false);
  // const [modalIsOpen, setIsOpen] = useState(false);
  // const [startDateTime, setStartDateTime] = useState("");
  // const [endDateTime, setEndDateTime] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [display, setDisplay] = useState(false);
  const driverCategory = "C1";

  const getUserBoardData = async (indexStartOfSet) => {
    setDisplay(null);
    loaderCount((current) => current + 1);
    // setShowLoader(true)
    var result = await AdminDashboardController.AdminSafetyCloudPoint(
      filter.durationFilter,
      indexStartOfSet,
      pageSize,
      filter.startDateTime,
      filter.endDateTime,
      driverCategory
    );
    loaderCount((current) => current - 1);
    // setShowLoader(false)
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    if (status == "SUCCESS") {
      var resultJSONData = resultJSON.data;
      console.log("resultJSONData22", resultJSONData)
      if (resultJSONData.length > 0) {
        setDisplay(true);

        let goodDriverCount = 0;
        let sportiveDriverCount = 0;
        let qSafeDriverCount = 0;
        let recklessDriverCount = 0;
        let qDangerousDriverCount = 0;
        let dangerousDriverCount = 0;
        var totalRides = 0;
        var safetyTotalRides = 0;
        var safetyDataTemp = [];
        // console.log("getSafetyCategoryList: " + JSON.stringify(resultJSONData));
        var firstName = "";
        var username = "";
        var rideId = "";
        var displayTemp = 0;
        // setDataLength(resultJSONData.length)
        // setPrevShow()

        resultJSONData.map((safeData) => {
          safetyTotalRides = 0;
          var safeDriverDataTemp = [];
          var sportiveDriverDataTemp = [];
          var quietSafeDriverDataTemp = [];
          var recklessDriverDataTemp = [];
          var quietDangerousDriverDataTemp = [];
          var dangerousDriverDataTemp = [];

          var safeDriverRideList = [];
          var sportiveDriverRideList = [];
          var quietSafeDriverRideList = [];
          var recklessDriverRideList = [];
          var quietDangerousDriverRideList = [];
          var dangerousDriverRideList = [];

          var rideList = [];

          var divisionId = ValueFormat.parseAsString(safeData.divisionId);
          var userId = ValueFormat.parseAsString(safeData.userId);

          var rideBoxList = ValueFormat.parseAsArray(safeData.rideBoxList);
          var firstName = ValueFormat.parseAsString(ValueFormat.capitalizeAllLetter(safeData.firstName));
          var username = ValueFormat.parseAsString(safeData.username);
          rideBoxList.map((rideData) => {
            var rideBox = ValueFormat.parseAsInt(rideData.rideBox);
            var symbolSize = 10;
            var anticipateDriveValue = ValueFormat.parseAsFloat(
              rideData.anticipateDriveValue,
              2
            );
            var selfConfidenceValue = ValueFormat.parseAsFloat(
              rideData.selfConfidenceValue,
              2
            );
            var rideName = ValueFormat.parseAsString(rideData.name);
            var tooltip = firstName + " (" + rideName + ") ";
            rideId = ValueFormat.parseAsString(rideData.deviceId);
            var totalCounts = ValueFormat.parseAsInt(safeData.totalCount);
            setTotalPageCount(Math.ceil(totalCounts / pageSize));
            setNextShow(totalCounts - indexStartOfSet > pageSize);
            var rideListResult = {
              rideName: rideName,
              rideId: rideId,
              divisionId: divisionId,
              userId: userId,
              firstName: firstName,
              username: username,
              anticipateDriveValue: anticipateDriveValue,
              selfConfidenceValue: selfConfidenceValue,
            };

            if (rideBox == 1) {
              displayTemp = displayTemp + 1;
              safetyTotalRides = safetyTotalRides + 1;
              rideList.push(rideListResult);
              safeDriverRideList.push(rideName);
              goodDriverCount = goodDriverCount + 1;
              safeDriverDataTemp = [];
              safeDriverDataTemp.push(
                anticipateDriveValue,
                selfConfidenceValue,
                symbolSize,
                tooltip,
                divisionId,
                userId,
                rideId,
                firstName,
                username
              );
            } else if (rideBox == 2) {
              sportiveDriverRideList.push(rideName);
              sportiveDriverCount = sportiveDriverCount + 1;
              sportiveDriverDataTemp = [];
              sportiveDriverDataTemp.push(
                anticipateDriveValue,
                selfConfidenceValue,
                symbolSize,
                tooltip,
                divisionId,
                userId,
                rideId,
                firstName,
                username
              );
            } else if (rideBox == 3) {
              quietSafeDriverRideList.push(rideName);
              qSafeDriverCount = qSafeDriverCount + 1;
              quietSafeDriverDataTemp = [];
              quietSafeDriverDataTemp.push(
                anticipateDriveValue,
                selfConfidenceValue,
                symbolSize,
                tooltip,
                divisionId,
                userId,
                rideId,
                firstName,
                username
              );
            } else if (rideBox == 4) {
              recklessDriverRideList.push(rideName);
              recklessDriverCount = recklessDriverCount + 1;
              recklessDriverDataTemp = [];
              recklessDriverDataTemp.push(
                anticipateDriveValue,
                selfConfidenceValue,
                symbolSize,
                tooltip,
                divisionId,
                userId,
                rideId,
                firstName,
                username
              );
            } else if (rideBox == 5) {
              quietDangerousDriverRideList.push(rideName);
              qDangerousDriverCount = qDangerousDriverCount + 1;
              quietDangerousDriverDataTemp = [];
              quietDangerousDriverDataTemp.push(
                anticipateDriveValue,
                selfConfidenceValue,
                symbolSize,
                tooltip,
                divisionId,
                userId,
                rideId,
                firstName,
                username
              );
            } else if (rideBox == 6) {
              dangerousDriverRideList.push(rideName);
              dangerousDriverCount = dangerousDriverCount + 1;
              dangerousDriverDataTemp = [];
              dangerousDriverDataTemp.push(
                anticipateDriveValue,
                selfConfidenceValue,
                symbolSize,
                tooltip,
                divisionId,
                userId,
                rideId,
                firstName,
                username
              );
            }

            safeDriverData.push(safeDriverDataTemp);
          });
          totalRides =
            goodDriverCount +
            sportiveDriverCount +
            qSafeDriverCount +
            recklessDriverCount +
            qDangerousDriverCount +
            dangerousDriverCount;

          var percentage = parseFloat(
            (safetyTotalRides / totalRides) * 100
          ).toFixed(2);
          var safeDriverResult = {
            name: firstName,
            username: username,
            totalRides: safetyTotalRides,
            percentage: percentage,
            userId: userId,
            rideName: rideList,
          };

          safetyDataTemp.push(safeDriverResult);
          // console.log("safetyData", safeDriverDataTemp);

          var goodDriverPer = parseFloat(
            (goodDriverCount / totalRides) * 100
          ).toFixed(2);

          // alert(firstName)
        });
        setDisplay(displayTemp > 0 ? true : false);

        // alert('indexStart: ' + indexStart);

        if (indexStartOfSet == 0) {
          setPrevShow(false);
        } else if (indexStartOfSet > 0) {
          setPrevShow(true);
        }

        // if (resultJSONData.length == 5) {
        //   setNextShow(true);
        // } else {
        //   setNextShow(false);
        // }

        setSafetyDataList((updateData) => {
          const updatedParentObject = {
            ...updateData,
            series: updateData.series.map((item) =>
              item.name === "C1" ? { ...item, data: safeDriverData } : item
            ),
          };

          return {
            ...updateData,
            ...updatedParentObject,
          };
        });
        // handleSafetyTableData1({
        //   data: safetyDataTemp,
        //   filter: filter.durationFilter,
        // });
        setSafeDriverData(safetyDataTemp);
        // console.log("safeData1", safeDriverData);
      } else {
        setDisplay(false);
        setSafetyDataList((updateData) => {
          const updatedParentObject = {
            ...updateData,
            series: updateData.series.map((item) =>
              item.name === "C1" ? { ...item, data: data[0] } : item
            ),
          };

          return {
            ...updateData,
            ...updatedParentObject,
          };
        });
      }
    } else {
      const code = resultJSON.error.code;
      if (code == "E007") {
        setDisplay("failed");
      } else {
        setDisplay(false);
      }
      setSafetyDataList((updateData) => {
        const updatedParentObject = {
          ...updateData,
          series: updateData.series.map((item) =>
            item.name === "C1" ? { ...item, data: data[0] } : item
          ),
        };

        return {
          ...updateData,
          ...updatedParentObject,
        };
      });
    }
  };

  const getUserBoardData1 = async () => {
    setDisplay(null);
    loaderCount((current) => current + 1);
    // setShowLoader(true)
    var result = await AdminDashboardController.AdminSafetyCloudPoint1(
      filter.durationFilter,
      filter.startDateTime,
      filter.endDateTime,
      driverCategory
    );
    loaderCount((current) => current - 1);
    // setShowLoader(false)
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    if (status == "SUCCESS") {
      var resultJSONData = resultJSON.data;
      // console.log("resultJSONData22", resultJSONData)
      if (resultJSONData.length > 0) {
        setDisplay(true);

        let goodDriverCount = 0;
        let sportiveDriverCount = 0;
        let qSafeDriverCount = 0;
        let recklessDriverCount = 0;
        let qDangerousDriverCount = 0;
        let dangerousDriverCount = 0;
        var totalRides = 0;
        var safetyTotalRides = 0;
        var safetyDataTemp = [];
        // console.log("getSafetyCategoryList: " + JSON.stringify(resultJSONData));
        var firstName = "";
        var username = "";
        var rideId = "";
        var displayTemp = 0;
        // setDataLength(resultJSONData.length)
        // setPrevShow()

        resultJSONData.map((safeData) => {
          safetyTotalRides = 0;
          var safeDriverDataTemp = [];
          var sportiveDriverDataTemp = [];
          var quietSafeDriverDataTemp = [];
          var recklessDriverDataTemp = [];
          var quietDangerousDriverDataTemp = [];
          var dangerousDriverDataTemp = [];

          var safeDriverRideList = [];
          var sportiveDriverRideList = [];
          var quietSafeDriverRideList = [];
          var recklessDriverRideList = [];
          var quietDangerousDriverRideList = [];
          var dangerousDriverRideList = [];

          var rideList = [];

          var divisionId = ValueFormat.parseAsString(safeData.divisionId);
          var userId = ValueFormat.parseAsString(safeData.userId);

          var rideBoxList = ValueFormat.parseAsArray(safeData.rideBoxList);
          var firstName = ValueFormat.parseAsString(ValueFormat.capitalizeAllLetter(safeData.firstName));
          var username = ValueFormat.parseAsString(safeData.username);
          rideBoxList.map((rideData) => {
            var rideBox = ValueFormat.parseAsInt(rideData.rideBox);
            var symbolSize = 10;
            var anticipateDriveValue = ValueFormat.parseAsFloat(
              rideData.anticipateDriveValue,
              2
            );
            var selfConfidenceValue = ValueFormat.parseAsFloat(
              rideData.selfConfidenceValue,
              2
            );
            var rideName = ValueFormat.parseAsString(rideData.name);
            var tooltip = firstName + " (" + rideName + ") ";
            rideId = ValueFormat.parseAsString(rideData.deviceId);
            var rideListResult = {
              rideName: rideName,
              rideId: rideId,
              divisionId: divisionId,
              userId: userId,
              firstName: firstName,
              username: username,
              anticipateDriveValue: anticipateDriveValue,
              selfConfidenceValue: selfConfidenceValue,
            };

            if (rideBox == 1) {
              displayTemp = displayTemp + 1;
              safetyTotalRides = safetyTotalRides + 1;
              rideList.push(rideListResult);
              safeDriverRideList.push(rideName);
              goodDriverCount = goodDriverCount + 1;
              safeDriverDataTemp = [];
              safeDriverDataTemp.push(
                anticipateDriveValue,
                selfConfidenceValue,
                symbolSize,
                tooltip,
                divisionId,
                userId,
                rideId,
                firstName,
                username
              );
            } else if (rideBox == 2) {
              sportiveDriverRideList.push(rideName);
              sportiveDriverCount = sportiveDriverCount + 1;
              sportiveDriverDataTemp = [];
              sportiveDriverDataTemp.push(
                anticipateDriveValue,
                selfConfidenceValue,
                symbolSize,
                tooltip,
                divisionId,
                userId,
                rideId,
                firstName,
                username
              );
            } else if (rideBox == 3) {
              quietSafeDriverRideList.push(rideName);
              qSafeDriverCount = qSafeDriverCount + 1;
              quietSafeDriverDataTemp = [];
              quietSafeDriverDataTemp.push(
                anticipateDriveValue,
                selfConfidenceValue,
                symbolSize,
                tooltip,
                divisionId,
                userId,
                rideId,
                firstName,
                username
              );
            } else if (rideBox == 4) {
              recklessDriverRideList.push(rideName);
              recklessDriverCount = recklessDriverCount + 1;
              recklessDriverDataTemp = [];
              recklessDriverDataTemp.push(
                anticipateDriveValue,
                selfConfidenceValue,
                symbolSize,
                tooltip,
                divisionId,
                userId,
                rideId,
                firstName,
                username
              );
            } else if (rideBox == 5) {
              quietDangerousDriverRideList.push(rideName);
              qDangerousDriverCount = qDangerousDriverCount + 1;
              quietDangerousDriverDataTemp = [];
              quietDangerousDriverDataTemp.push(
                anticipateDriveValue,
                selfConfidenceValue,
                symbolSize,
                tooltip,
                divisionId,
                userId,
                rideId,
                firstName,
                username
              );
            } else if (rideBox == 6) {
              dangerousDriverRideList.push(rideName);
              dangerousDriverCount = dangerousDriverCount + 1;
              dangerousDriverDataTemp = [];
              dangerousDriverDataTemp.push(
                anticipateDriveValue,
                selfConfidenceValue,
                symbolSize,
                tooltip,
                divisionId,
                userId,
                rideId,
                firstName,
                username
              );
            }
            // safeDriverData1.push(safeDriverDataTemp);
          });
          totalRides =
            goodDriverCount +
            sportiveDriverCount +
            qSafeDriverCount +
            recklessDriverCount +
            qDangerousDriverCount +
            dangerousDriverCount;

          var percentage = parseFloat(
            (safetyTotalRides / totalRides) * 100
          ).toFixed(2);
          var safeDriverResult = {
            name: firstName,
            username: username,
            totalRides: safetyTotalRides,
            percentage: percentage,
            userId: userId,
            rideName: rideList,
          };

          safetyDataTemp.push(safeDriverResult);
          // console.log("safetyData", safeDriverDataTemp);

          var goodDriverPer = parseFloat(
            (goodDriverCount / totalRides) * 100
          ).toFixed(2);

          // alert(firstName)
        });
        setDisplay(displayTemp > 0 ? true : false);

        handleSafetyTableData(safetyDataTemp);
      } else {
        setDisplay(false);
        handleSafetyTableData([]);
      }
    } else {
      const code = resultJSON.error.code;
      if (code == "E007") {
        setDisplay("failed");
      } else {
        setDisplay(false);
      }
    }
  };

  const previous = () => {
    setIndexStart((prev) => prev - pageSize);
    setPageNumber((current) => current - 1);
  };
  const next = () => {
    setIndexStart((prev) => prev + pageSize);
    setPageNumber((current) => current + 1);
  };

  useEffect(() => {
    setPageNumber(1);
    if (indexStart == 0) setCount((prev) => prev + 1);
    else setIndexStart(0);
  }, [filter.durationFilter, filter.startDateTime, filter.endDateTime]);

  useEffect(() => {
    if (count > 0) getUserBoardData(indexStart);
    getUserBoardData1();
  }, [indexStart, count]);

  return (
    <React.Fragment>
      {/* {showLoader &&<Loader /> } */}
      <Row>
        <Col xl={5}>
          <div className="col-xl-12">
            <Card style={{ height: 560 }}>
              <CardHeader className="cardHeader">
                <div className="d-flex flex-wrap align-items-center">
                  <h5
                    className="sub_heading  me-0 mt-1"
                    style={{ marginLeft: "-9px" }}
                  >
                    {" "}
                    Safe Driver (C1){" "}
                  </h5>
                  {/* <div className="ms-auto">
                    <div>
                      <button
                        type="button"
                        data-tooltip-id="cd"
                        className={
                          filter == "CURRENT_DAY"
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilter("CURRENT_DAY")}
                      >
                        CD
                        <ReactTooltip
                          id="cd"
                          place="top"
                          content="Current Day"
                          style={{ backgroundColor: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        data-tooltip-id="cw"
                        className={
                          filter == "CURRENT_WEEK"
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilter("CURRENT_WEEK")}
                      >
                        CW
                        <ReactTooltip
                          id="cw"
                          place="top"
                          content="Current Week"
                          style={{ backgroundColor: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        data-tooltip-id="cm"
                        className={
                          filter == "CURRENT_MONTH"
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilter("CURRENT_MONTH")}
                      >
                        CM
                        <ReactTooltip
                          id="cm"
                          place="top"
                          content="Current Month"
                          style={{ backgroundColor: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        data-tooltip-id="cy"
                        className={
                          filter == "CURRENT_YEAR"
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilter("CURRENT_YEAR")}
                      >
                        CY
                        <ReactTooltip
                          id="cy"
                          place="top"
                          content="Current Year"
                          style={{ backgroundColor: "black" }}
                        />
                      </button>{" "}
                      <button
                          type="button"
                          className={
                            filter == "CUSTOM_DURATION"
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                          }
                          onClick={() =>{setIsOpen(true);}}
                          data-tooltip-id="dfdf"
                        >
                          <FontAwesomeIcon icon={faClock}/>
                          <ReactTooltip
                            id="dfdf"
                            place="top"
                            content="Custom Date Selection"
                            style={{ background: "black" }}
                          />
                        </button>{" "}
                    </div>
                  </div> */}
                </div>
              </CardHeader>

              <CardBody className="px-0" style={{ maxHeight: "440px" }}>
                <div className="table-responsive" style={{ height: "390px" }}>
                  <Table className="table mb-0">
                    <thead>
                      <tr>
                        <th style={{ fontSize: "14px", fontWeight: 600, width: '50%' }}>
                          Username
                        </th>
                        <th style={{ fontSize: "14px", fontWeight: 600, width: '20%'  }}>
                          Rides
                        </th>
                        {/* <th style={{ fontSize: "13px", fontWeight: 600}}>%</th> */}
                        <th style={{ fontSize: "14px", fontWeight: 600, width: '30%'  }}>
                          Ride name
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {display === false ? (
                        <p
                          className="data_not_available d-flex justify-content-center align-items-center"
                          style={{
                            height: "400px",
                            position: "absolute",
                            width: "100%",
                          }}
                        >
                          {displayText.DATA_NOT_AVAILABLE}
                        </p>
                      ) : display === "failed" ? (
                        <p
                          className="data_not_available d-flex justify-content-center align-items-center"
                          style={{
                            height: "400px",
                            position: "absolute",
                            width: "100%",
                          }}
                        >
                          {displayText.ER_007}
                        </p>
                      ) : display === null ? (
                        <p
                          className="fetch_data d-flex justify-content-center align-items-center"
                          style={{
                            height: "400px",
                            position: "absolute",
                            width: "100%",
                          }}
                        >
                          {displayText.FETCHING_DATA}
                        </p>
                      ) : (
                        (safeDriverData || []).map((safetyData) => (
                          <tr key={safetyData.name}>
                            <th
                              scope="row"
                              style={{
                                fontSize: "14px",
                                fontWeight: 400,
                                width: "235px",
                                verticalAlign: "center",
                                height: "48px",
                              }}
                              onMouseEnter={() => [
                                localStorage.setItem(
                                  SC_USER_ID,
                                  safetyData.userId
                                ),
                                localStorage.setItem(
                                  SC_LOGIN_USER_FIRST_NAME,
                                  safetyData.name
                                ),
                                localStorage.setItem(
                                  SC_USER_USERNAME,
                                  safetyData.username
                                ),
                              ]}
                            >
                              <Link to={"/user-dashboard"}>
                                <span style={{ fontSize: "14px" }}>
                                  {safetyData.name} <br />{" "}
                                  {"(" + safetyData.username + ")"}
                                </span>
                              </Link>
                            </th>

                            <td
                              style={{
                                fontSize: "14px",
                                width: "155px",
                                verticalAlign: "center",
                                height: "48px",
                              }}
                            >
                              {safetyData.totalRides}
                            </td>
                            {/* <td style={{ fontSize: "13px" }}>
                            {safetyData.percentage}%
                          </td> */}
                            <td>
                              {safetyData.rideName.length ? (
                                <div
                                  style={{
                                    overflowY: "auto",
                                    height: "48px",
                                    width: "95px",
                                    marginTop: "-6px",
                                    fontSize: "13px",
                                  }}
                                >
                                  {safetyData.rideName.map((rideData) => (
                                    <Link
                                    to={`/ridedetails?userId=${encodeURIComponent(Converter.encrypt(rideData.userId))}&divisionId=${encodeURIComponent(Converter.encrypt(rideData.divisionId))}&rideId=${encodeURIComponent(Converter.encrypt(rideData.rideId))}
                                    `}
                                      onClick={() =>{ localStorage.setItem( SC_LOGIN_USER_FIRST_NAME, rideData.firstName ),
                                        localStorage.setItem( SC_USER_USERNAME, safetyData.username )} }
                                      key={rideData.rideId}
                                      state={{ userId: rideData.userId, divisionId: rideData.divisionId, rideId: rideData.rideId }} >
                                      {/* <p
                                      className="m-2"
                                      style={{ fontSize: "11px",height:"12px" }}
                                      key={rideData}
                                    >{rideData.ridename === "-" ?
                                    `${rideData.rideName}-${parseInt(rideData.key, 10) - 1 + 1}` :
                                    rideData.rideName.charAt(0).toUpperCase() +
                                    rideData.rideName.slice(1).toLowerCase()
                                  }</p> */}
                                      <p
                                        style={{
                                          fontSize: "13px",
                                          height: "12px",
                                          marginTop: "5px",
                                        }}
                                        key={rideData}
                                      >
                                        {rideData.rideName
                                          .charAt(0)
                                          .toUpperCase() +
                                          rideData.rideName
                                            .slice(1)
                                            .toLowerCase()}
                                      </p>
                                    </Link>
                                  ))}
                                </div>
                              ) : (
                                <p
                                  className="p-0 m-0"
                                  style={{
                                    fontWeight: 300,
                                    fontSize: "20px",
                                    height: "48px",
                                  }}
                                >
                                  -
                                </p>
                              )}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
              {display === false ? (
                ""
              ) : display === "failed" ? (
                ""
              ) : display === null ? (
                ""
              ) : (
                <div
                  // className="d-flex justify-content-between"
                  style={{
                    margin: "10px",
                    marginTop: "0px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm"
                    onClick={previous}
                    style={{
                      visibility: prevShow ? "visible" : "hidden",
                      width: "45px",
                      float: "left",
                    }}
                  >
                    Prev
                  </button>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="pagination">
                      <a>{pageNumber}</a>
                    </div>
                    <p
                      style={{
                        marginTop: "6px",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      of
                    </p>
                    <div className="pagination ">
                      <a>{totalPageCount}</a>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="btn btn-outline-success btn-sm"
                    onClick={next}
                    style={{
                      visibility: nextShow ? "visible" : "hidden",
                      float: "right",
                    }}
                  >
                    Next
                  </button>
                </div>
              )}
            </Card>
          </div>
        </Col>

        <Col xl={7}>
          <Card style={{ height: 560 }}>
            <CardBody>
              <div className="flex-wrap align-items-center ">
                <h5 className="sub_heading me-2">Safe Driver (C1)</h5>
                <h5 style={{ fontSize: "14px", fontWeight: 400 }}>
                  An effective driver stays consistently alert and careful,
                  making safety the main focus throughout the entire ride.
                </h5>
              </div>
              <Row className="align-items-center">
                <Col xl={12}>
                  <div className="box chart">
                    <div className="d-flex justify-content-between">
                      <h6 className="table-data">Unsafe</h6>
                      {display === false ? (
                        <p className="data_not_available">
                          {displayText.DATA_NOT_AVAILABLE}
                        </p>
                      ) : display === "failed" ? (
                        <p className="data_not_available">
                          {displayText.ER_007}
                        </p>
                      ) : display === null ? (
                        <p className="fetch_data">
                          {displayText.FETCHING_DATA}
                        </p>
                      ) : (
                        ""
                      )}
                      <h6 className="table-data">Unsafe</h6>
                    </div>
                    <Bubble safetyData={safetyDataList} />
                    <div className="d-flex justify-content-between">
                      <h6 className="table-data">Safe</h6>
                      <h6 className="table-data">Unsafe</h6>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default C1Chart;
