export const protectionText = {
    //helmet
    user_with_helmet: "Commendable commitment to personal safety – wearing a helmet is a smart and responsible choice.",   
    just_helmet: "Just user's helmet is captured, though the act of wearing it is not visually confirmed. Kindly wear it from next ride.",
    user_without_helmet: "Riding without a helmet is not safe – it puts the user at risk of serious head injuries. Kindly wear it from next ride.",

    //seatbelt
    user_with_seatbelt: "Commendable commitment to personal safety – wearing a seatbelt is a smart and responsible choice.",
    just_seatbelt: "Justseatbelt is captured, though the act of wearing it is not visually confirmed. Kindly wear it from next ride.",
    user_without_seatbelt: "Riding without a seatbelt is not safe – it puts the user at risk during the event of a sudden stop or collision. Kindly wear it from next ride.",    

    //no image
    no_image: "No image is recorded. From next ride onwards, kindly ensure to provide an image depicting your adherence to safety protocols. This will assist us in verifying the appropriate use of safety gear depending on your vehicle type and implementing necessary measures. Thank you for your cooperation.",

    //blank image
    image_without_data: "Image is taken, but it does not seem to have any safety gear recorded. From next ride onwards, kindly wear safety gear first and then record an image so that we can take the required measures by checking if you are following the safety protocols while driving or not. Thank you.",
};
