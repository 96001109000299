import React, { Component } from "react"
import cloneDeep from "lodash.clonedeep"
import ReactEcharts from "echarts-for-react"
import { color } from "echarts"

const  GuagesTwo = ({drivingStatus}) => {
  

  const getOption = () => {
    return {
    //   tooltip: {
    //     formatter: "{a} <br/>{b} : {c}%",
    //   },
    //   toolbox: {
    //     feature: {
    //       restore: {title: "Refresh"},
    //         saveAsImage: {title: "Download Image"}
    //     },
    //   },
      series:[
        {
          type: 'gauge',
          startAngle: 152.2,
          endAngle: 25,
          min: 0,
          max: 100,
          splitNumber:"none",
          itemStyle: {
            height:"10px",
            width:"10px",
            color: '#4B4D4E',
            shadowColor: '#4B4D4E',
            shadowBlur: 10,
            shadowOffsetX: 2,
            shadowOffsetY: 2
          },
        //   progress: {
        //     show: true,
        //     roundCap: true,
        //     width: 13
        //   },
          pointer: {
            icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
            length: '55%',           
            width: 5,
            offsetCenter: [0, '1%']
          },
          axisLine: {
            roundCap: true,
            lineStyle: {
                color: [
                    [0.2, "#DFBA2D"],
                    [0.8, "#DFBA2D"],
                    [1, "#DFBA2D"],                    
                  ],
              width: 13              
              
            }
          },
          axisTick: {
            splitNumber: 2,
            lineStyle: {
              width: 2,
              color: '#999'
            }
          },
          splitLine: {
            length: 12,
            lineStyle: {
              width: 3,
              color: '#999'
            }
          },
          axisLabel: {
            distance: 30,
            color: '#999',
            fontSize: 20
          },
          title: {
            show: false
          },
          detail: {
            valueAnimation: true,
            formatter: function (value) {
              return '{value|' + value.toFixed(0) + '}';
            },
            rich: {
              value: {
                fontSize: 20,
                fontWeight: 'bolder',
                color: '#DFBA2D'
              },
              unit: {
                fontSize: 10,
                color: '#999',
                padding: [0, 0, -10, 10]
              }
            }
          },
         
          data: [
            {
              value: drivingStatus.drivingSkill
            }
          ]
        }
      ]
    };
  };


    return (
      <React.Fragment>
        <ReactEcharts style={{ height: "200px" }} option={getOption()} />
      </React.Fragment>
    )
  }

export default GuagesTwo
