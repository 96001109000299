import React from 'react'
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'

const TimeBatteryEventJourneyView = ({ timeBatteryEventJourneyViewObj }) => {

    const rideData = [
        { header: 'Start Date Time', icon: 'StartDate.svg', value: timeBatteryEventJourneyViewObj.startDateTimeToTimeZone, unit: '', alt: 'Days' },
        { header: 'End Date Time', icon: 'EndDate.svg', value: timeBatteryEventJourneyViewObj.endDateTimeToTimeZone, unit: '', alt: 'Rides' },
        { header: 'Battery Level Start', icon: 'StartBattery.svg', value: timeBatteryEventJourneyViewObj.batteryLevelStart, unit: '%', alt: 'Distance' },
        { header: 'Battery Level End', icon: 'EndBattery.svg', value: timeBatteryEventJourneyViewObj.batteryLevelEnd, unit: '%', alt: 'Duration' },
        { header: 'Event', icon: 'Event.svg', value: timeBatteryEventJourneyViewObj.subType, unit: '', alt: 'Red Alerts' },
        { header: 'Detailed View', icon: 'DetailedView.svg', value: timeBatteryEventJourneyViewObj.accidentType, unit: '', alt: 'Red Alerts' },
    ];

    return (
        <div>
            <Row>
                {rideData.map((data, index) => (
                    <Col key={index} xl={2} lg={2} md={4} sm={4} xs={6}>
                        <Card>
                            <CardHeader className="ps-2 pe-2 pb-1 pt-2 border-0 text-nowrap overflow-hidden">
                                <span className='text-muted text-truncate sub_heading'>{data.header}</span>
                            </CardHeader>
                            <CardBody className="border-0 ps-2 pe-2 py-0 d-flex justify-content-start align-items-center" style={{ height: '50px', gap: '20px' }}>
                                <img src={require(`../../assets/images/rideDetails/${data.icon}`)} alt={data.alt} height={35} width={35} />
                                {data.header !== "Detailed View" ? (
                                    <div>
                                        {data.value} {data.unit}
                                    </div>
                                ) : (
                                    <div className="d-flex flex-column">
                                        <Link
                                            to="/journeyView"
                                            state={{
                                                userId: timeBatteryEventJourneyViewObj.userId,
                                                divisionId: timeBatteryEventJourneyViewObj.divisionId,
                                                rideId: timeBatteryEventJourneyViewObj.rideId,
                                                rideNo: timeBatteryEventJourneyViewObj.rideNo,
                                            }}
                                            className='m-0 p-0'
                                        >
                                            <span style={{ fontSize: "13.5px", textAlign: "left" }}>
                                                Journey View
                                            </span>
                                        </Link>
                                        {(timeBatteryEventJourneyViewObj.accidentType === "MEDIUM_ACCIDENT" || timeBatteryEventJourneyViewObj.accidentType === "MILD_ACCIDENT" ||
                                            timeBatteryEventJourneyViewObj.accidentType === "SEVERE_ACCIDENT") && (
                                                <Link
                                                    to="/crashView"
                                                    state={{
                                                        userId: timeBatteryEventJourneyViewObj.userId,
                                                        divisionId: timeBatteryEventJourneyViewObj.divisionId,
                                                        rideId: timeBatteryEventJourneyViewObj.rideId,
                                                        rideNo: timeBatteryEventJourneyViewObj.rideNo,
                                                    }}
                                                    className='m-0 p-0'
                                                >
                                                    <span style={{ fontSize: "12px", textAlign: "left" }}>
                                                        Crash View
                                                    </span>
                                                </Link>
                                            )}
                                    </div>
                                )}

                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    )
}

export default TimeBatteryEventJourneyView
