const AdminDashboardTopRowItems = [
    {
        type: "TOTAL",
        title: "Total Users",
        tooltip: "Total Users",
        totalCount: 0,
        subTotalCount: 0,
        postFix: "",
        statusColor: "success",
        bottomTitle: "(License Issued)",
        linkTo: "/myusertabledata",
        tab: "",
        description: "Total issued licenses",
        percentage: 100
    },
    {
        type: "ACTIVE",
        title: "Active Users",
        tooltip: "Active Users",
        totalCount: 0,
        subTotalCount: 0,
        postFix: "",
        statusColor: "success",
        bottomTitle: "(Minimum 1 Ride)",
        linkTo: "/activeuserspage",
        tab: "",
        description: "Minimum 1 ride taken",
        percentage: 0
    },
    {
        type: "INACTIVE",
        title: "Inactive Users",
        tooltip: "Inactive Users",
        totalCount: 0,
        subTotalCount: 0,
        postFix: "",
        statusColor: "danger",
        bottomTitle: "(0 Ride)",
        linkTo: "/myusertabledata",
        tab: "INACTIVE",
        description: "No ride taken",
        percentage: 0
    },
    {
        type: "PENDING",
        title: "Pending Users",
        tooltip: "Verification Pending Users",
        totalCount: 0,
        subTotalCount: 0,
        postFix: "",
        statusColor: "success",
        bottomTitle: "(Verification Pending Users)",
        linkTo: "/myusertabledata",
        tab: "REGISTERED",
        description: "Activation pending",
        percentage: 0
    },
    {
        type: "UNREGISTER",
        title: "Unregistered Users",
        tooltip: "Unregistered Users",
        totalCount: 0,
        subTotalCount: 0,
        postFix: "",
        statusColor: "success",
        bottomTitle: "(Profile Not Registered)",
        linkTo: "/myusertabledata",
        tab: "INITIATED",
        description: "Registration pending",
        percentage: 0
    },
    {
        type: "DEACTIVATED",
        title: "Deactivated Users",
        tooltip: "Deactivated Users",
        totalCount: 0,
        subTotalCount: 0,
        postFix: "",
        statusColor: "success",
        bottomTitle: "",
        linkTo: "/myusertabledata",
        tab: "DEACTIVATED",
        description: "Deactivated Users",
        percentage: 0,
    }
];

const SAdminDashboardTopRowItems = [

    {
        type: "TOTAL",
        title: "Total Users",
        tooltip: "Total Users",
        totalCount: 0,
        subTotalCount: 0,
        postFix: "",
        statusColor: "success",
        bottomTitle: "(License Issued)",
        linkTo: "/myusertabledata",
        tab: "",
        description: "Total issued licenses",
        percentage: 100
    },
    {
        type: "ACTIVE",
        title: "Active Users",
        tooltip: "Active Users",
        totalCount: 0,
        subTotalCount: 0,
        postFix: "",
        statusColor: "success",
        bottomTitle: "(Minimum 1 Ride)",
        linkTo: "/activeuserspage",
        tab: "",
        description: "Minimum 1 ride taken",
        percentage: 0

    },
    {
        type: "INACTIVE",
        title: "Inactive Users",
        tooltip: "Inactive Users",
        totalCount: 0,
        subTotalCount: 0,
        postFix: "",
        statusColor: "danger",
        bottomTitle: "(0 Ride)",
        linkTo: "/myusertabledata",
        tab: "INACTIVE",
        description: "No ride taken",
        percentage: 0

    },
    {
        type: "PENDING",
        title: "Pending Users",
        tooltip: "Verification Pending Users",
        totalCount: 0,
        subTotalCount: 0,
        postFix: "",
        statusColor: "success",
        bottomTitle: "(Verification Pending Users)",
        linkTo: "/myusertabledata",
        tab: "REGISTERED",
        description: "Activation pending",
        percentage: 0

    },
    {
        type: "UNREGISTER",
        title: "Unregistered Users",
        tooltip: "Unregistered Users",
        totalCount: 0,
        subTotalCount: 0,
        postFix: "",
        statusColor: "success",
        bottomTitle: "(Profile Not Registered)",
        linkTo: "/myusertabledata",
        tab: "INITIATED",
        description: "Registration pending",
        percentage: 0

    },
];

const SuperAdminDashboardTopRowItems = [
    {
        type: "TOTAL",
        title: "Total Licenses",
        tooltips: "Total Licenses",
        totalLicenseCount: 0,
        availableLicenseCount: 0,
    },
    {
        type: "IOS",
        title: "iOS License Used",
        tooltips: "iOS License Used",
        totalLicenseCount: 0,
        availableLicenseCount: 0,
    },
    {
        type: "IOS_SDK",
        title: "iOS SDK License Used",
        tooltips: "iOS SDK License Used",
        totalLicenseCount: 0,
        availableLicenseCount: 0,
    },
    {
        type: "ANDROID",
        title: "Android License Used",
        tooltips: "Android License Used",
        totalLicenseCount: 0,
        availableLicenseCount: 0,
    },
    {
        type: "ANDROID_SDK",
        title: "Android SDK License Used",
        tooltips: "Android SDK License Used",
        totalLicenseCount: 0,
        availableLicenseCount: 0,
    },
];

const AdminDashboardRideBoxCount = {
    totalRides: 0,
    count: [
        { totalRides: 0, rideBox: 1, percentage: 0, totalKm: 0, totalTravelTime: 0 },
        { totalRides: 0, rideBox: 2, percentage: 0, totalKm: 0, totalTravelTime: 0 },
        { totalRides: 0, rideBox: 3, percentage: 0, totalKm: 0, totalTravelTime: 0 },
        { totalRides: 0, rideBox: 4, percentage: 0, totalKm: 0, totalTravelTime: 0 },
        { totalRides: 0, rideBox: 5, percentage: 0, totalKm: 0, totalTravelTime: 0 },
        { totalRides: 0, rideBox: 6, percentage: 0, totalKm: 0, totalTravelTime: 0 }
    ]
};

const MarketOverViewAllData = [

    {
        name: "Profit",
        data: [
            12.45, 16.2, 8.9, 11.42, 12.6, 18.1, 18.2, 14.16, 11.1, 8.09, 16.34,
            12.88,
        ],
    },
    {
        name: "Loss",
        data: [
            -11.45, -15.42, -7.9, -12.42, -12.6, -18.1, -18.2, -14.16, -11.1, -7.09,
            -15.34, -11.88,
        ],
    },
]

const MarketOver1MData = [

    {
        name: "Profit",
        data: [
            14.45, 10.2, 8.9, 154.42, 6.6, 18.1, 18.2, 18.16, 11.1, 8.09, 16.34,
            12.88,
        ],
    },
    {
        name: "Loss",
        data: [
            -1.45, -15.42, -7.9, -12.42, -12.6, -8.1, -18.2, -14.16, -11.1, -7.09,
            -15.34, -17.88,
        ],
    },
];
const MarketOver6MData = [

    {
        name: "Profit",
        data: [
            12.45, 14.2, 8.9, 11.42, 12.6, 18.1, 12.2, 14.16, 11.1, 8.09, 16.34,
            8.88,
        ],
    },
    {
        name: "Loss",
        data: [
            -11.45, -15.42, -17.9, -12.42, -12.6, -8.1, -18.2, -14.16, -1.1, -7.09,
            -5.34, -11.88,
        ],
    },
];
const MarketOver1YData = [

    {
        name: "Profit",
        data: [
            2.45, 16.2, 18.9, 11.42, 12.6, 18.1, 8.2, 14.16, 11.1, 18.09, 16.34,
            12.88,
        ],
    },
    {
        name: "Loss",
        data: [
            -11.45, -5.42, -7.9, -12.42, -2.6, -18.1, -18.2, -4.16, -11.1, -7.09,
            -15.34, -1.88,
        ],
    },
];

const PieChart1YData = [15, 70, 45];

const PieChart6MData = [55, 35, 10];

const PieChart1MData = [75, 10, 65];

const PieChartAllData = [55, 35, 25];

const InvestedOverviewMay = [80];

const InvestedOverviewApril = [20];

const InvestedOverviewMarch = [40];

const InvestedOverviewFeb = [90];

const InvestedOverviewJan = [65];

const InvestedOverviewDec = [45];

export {
    AdminDashboardTopRowItems, MarketOver1YData, MarketOver6MData, MarketOver1MData, MarketOverViewAllData, PieChart1YData, PieChart6MData,
    PieChart1MData, PieChartAllData, InvestedOverviewMay, InvestedOverviewApril, InvestedOverviewMarch, InvestedOverviewFeb,
    InvestedOverviewJan, InvestedOverviewDec, AdminDashboardRideBoxCount, SuperAdminDashboardTopRowItems, SAdminDashboardTopRowItems
};