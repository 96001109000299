import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Progress,
  Row,
  Table,
} from "reactstrap";
import MyRideDetailController from "../../controller/MyRideDetail/MyRideDetail";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/Common/loader";
import ValueFormat from "../../util/ValueFormat";
import ViewPdf from "../../components/ExportScreenPdf/indexReact2Pdf";
import UtilDateTime from "../../util/UtilDateTime";
import { crashViewListTopRow } from "../../common/data/crashViewList";
import CountUp from "react-countup";
import { Tooltip as ReactTooltip } from "react-tooltip";

const HereMapTest = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [topRow, setTopRow] = useState(crashViewListTopRow);
  const [locationParams, setLocationParams] = useState({
    rideId: "",
    userId: "",
    divisionId: "",
    rideNo: "",
  });

  const location = useLocation();
  const history = useNavigate();

  // const getErrorDataWithinRange = (data, range) => {
  //   const errorData = data.filter((entry) => entry.category === "ERROR_DATA");

  //   const result = [];
  //   for (let i = 0; i < errorData.length; i++) {
  //     const errorEntry = errorData[i];
  //     const errorKm = parseFloat(errorEntry.kilo_meter);

  //     // Find start and end kilo_meter values for the 1-kilometer range around the errorEntry
  //     const startKm = errorKm - range;
  //     const endKm = errorKm;

  //     // Collect data within the specified range
  //     let dataWithinRange = data.filter((entry) => {
  //       const km = parseFloat(entry.kilo_meter);
  //       return km >= startKm && km <= endKm;
  //     });

  //     // Extract latitude and longitude from the filtered data
  //     const latLngData = dataWithinRange.map((entry, i, array) => ({
  //       latitude: entry.latitude,
  //       longitude: entry.longitude,
  //       speed: entry.speed,
  //       category: entry.category,
  //       risk: entry.risk,
  //       speedLimit: entry.speedLimit,
  //       kilometer: entry.kilo_meter,
  //       time: entry.createdAtToTimeZone,
  //       previousSpeed: entry.previousSpeed,
  //       accidentType: entry.accidentType,
  //       violationRemarks:
  //         entry.category === "ERROR_DATA" || i === array.length - 1
  //           ? "Vehicle crashed"
  //           : entry.previousSpeed !== null &&
  //             entry.speed - entry.previousSpeed >= 40
  //           ? `The driver forcefully accelerated the vehicle from ${entry.previousSpeed} km/hr to ${entry.speed} km/hr.`
  //           : entry.risk > 90
  //           ? "The Driver Violated the Traffic Rules."
  //           : entry.previousSpeed !== null &&
  //             entry.speed - entry.previousSpeed < -40
  //           ? `The Driver Forcely Brakes the Vehicle from the speed of ${entry.previousSpeed} km/hr to ${entry.speed} km/hr.`
  //           : entry.speed > entry.speedLimit
  //           ? `The driver violated the speed limit. For instance, the actual speed limit in the range was ${
  //               entry.speedLimit === "0.00" ? "40.00" : entry.speedLimit
  //             } km/hr, but the driver's speed was ${entry.speed} km/hr.`
  //           : "The driver followed the rules at that instant.",
  //     }));

  //     // Add to the result array
  //     result.push({
  //       errorEntry,
  //       dataWithinRange: latLngData,
  //     });
  //   }

  //   return result;
  // };

 
  // const getErrorDataWithinRange = (data, range) => {
  //   const errorData = data.filter((entry) => entry.category === "ERROR_DATA");

  //   const result = [];

  //   // Filter and get the latest ERROR_DATA entry based on kilo_meter value
  //   const latestErrorEntry = errorData.reduce((latest, current) => {
  //     const currentKm = parseFloat(current.kilo_meter);
  //     if (!latest || currentKm > parseFloat(latest.kilo_meter)) {
  //       return current;
  //     }
  //     return latest;
  //   }, null);

  //   if (latestErrorEntry) {
  //     const errorKm = parseFloat(latestErrorEntry.kilo_meter);

  //     // Find start and end kilo_meter values for the 1-kilometer range around the latestErrorEntry
  //     const startKm = errorKm - range;
  //     const endKm = errorKm;

  //     // Collect latitude and longitude data within the specified range
  //     const dataWithinRange = data.filter((entry) => {
  //       const km = parseFloat(entry.kilo_meter);
  //       return km >= startKm && km <= endKm;
  //     });

  //     // Extract latitude and longitude from the filtered data
  //     const latLngData = dataWithinRange.map((entry) => ({
  //       latitude: entry.latitude,
  //       longitude: entry.longitude,
  //       speed: entry.speed,
  //       category: entry.category,
  //       risk: entry.risk,
  //       speedLimit: entry.speedLimit,
  //       kilometer: entry.kilo_meter,
  //       time: entry.createdAtToTimeZone,
  //       previousSpeed: entry.previousSpeed,
  //       accidentType: entry.accidentType,
  //     }));

  //     // Add to the result array
  //     result.push({
  //       errorEntry: latestErrorEntry,
  //       dataWithinRange: latLngData,
  //     });
  //   }

  //   return result;
  // };

  const getErrorDataWithinRange = (data, range) => {
    const errorData = data.filter((entry) => entry.category === "ERROR_DATA");
  
    const result = [];
    let lastErrorIndex = -1;
  
    for (let i = 0; i < errorData.length; i++) {
      const errorEntry = errorData[i];
      const errorKm = parseFloat(errorEntry.kilo_meter);
  
      // Determine the start index for data collection
      let startIndex = lastErrorIndex + 1; // Start from the next entry after the last ERROR_DATA
      if (i === 0) {
        startIndex = 0; // If it's the first ERROR_DATA, start from the beginning
      }
  
      // Collect data up to the current ERROR_DATA entry but not exceeding 1 km
      let dataWithinRange = [];
      for (let j = startIndex; j < data.length; j++) {
        const entry = data[j];
        const entryKm = parseFloat(entry.kilo_meter);
  
        if (entry === errorEntry || entryKm >= errorKm - range) {
          dataWithinRange.push(entry);
          if (entry === errorEntry) {
            break; // Stop once we've included the current ERROR_DATA entry
          }
        }
      }
  
      // Extract latitude and longitude from the filtered data
      const latLngData = dataWithinRange.map((entry, i, array) => ({
        latitude: entry.latitude,
        longitude: entry.longitude,
        speed: entry.speed,
        category: entry.category,
        risk: entry.risk,
        speedLimit: entry.speedLimit,
        kilometer: entry.kilo_meter,
        time: entry.createdAtToTimeZone,
        previousSpeed: entry.previousSpeed,
        accidentType: entry.accidentType,
        violationRemarks:
          entry.category === "ERROR_DATA" || i === array.length - 1
            ? "Vehicle crashed"
            : entry.previousSpeed !== null && entry.speed - entry.previousSpeed >= 40
            ? `The driver forcefully accelerated the vehicle from ${entry.previousSpeed} km/hr to ${entry.speed} km/hr.`
            : entry.risk > 90
            ? "The Driver Violated the Traffic Rules."
            : entry.previousSpeed !== null && entry.speed - entry.previousSpeed < -40
            ? `The Driver Forcely Brakes the Vehicle from the speed of ${entry.previousSpeed} km/hr to ${entry.speed} km/hr.`
            : entry.speed > entry.speedLimit
            ? `The driver violated the speed limit. For instance, the actual speed limit in the range was ${
                entry.speedLimit === "0.00" ? "40.00" : entry.speedLimit
              } km/hr, but the driver's speed was ${entry.speed} km/hr.`
            : "The driver followed the rules at that instant.",
      }));
  
      // Add to the result array
      result.push({
        errorEntry,
        dataWithinRange: latLngData,
      });
  
      // Update the last error index
      lastErrorIndex = data.indexOf(errorEntry);
    }
  
    return result;
  };
  
  
  

  // Specify the range in kilometers
  const rangeKm = 1;

  const [errorDataWithinRange, setErrorDataWithinRange] = useState([]);
  

  const getRiskAlertsDataList1 = async () => {
    setShowLoader(true);
    try {
      var result = await MyRideDetailController.getCrash1KmErrorData(
        locationParams.rideId,
        locationParams.divisionId,
        locationParams.userId
      );
      var resultJSON = JSON.parse(result);
      // console.log(JSON.stringify(resultJSON), "resultJSON");
      var status = resultJSON.status;

      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;

        console.log(resultJSONData, "resultJSONData")
        if (resultJSONData.length > 0) {
          const errorDataWithinRange = getErrorDataWithinRange(
            resultJSONData,
            rangeKm
          );
          setErrorDataWithinRange(errorDataWithinRange);
        } else {
          console.log("Data Didn't Fetch");
        }
      } else {
        console.log("Status Failed");
      }
    } catch (e) {
      console.log("catch errr", e);
    } finally {
      setShowLoader(false);
    }
  };

  const topRowItems = async () => {
    setShowLoader(true);
    try {
      var result = await MyRideDetailController.myRideDetailUserLeaderBoard(
        locationParams.rideId,
        locationParams.divisionId,
        locationParams.userId
      );

      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        let topLineItems = [];
        // console.log("resultJSONData",JSON.stringify(resultJSONData));
        resultJSONData.map((value) => {
          var rideNum = ValueFormat.parseAsString(value.name);
          rideNum = rideNum.replace("Ride", "").trim();
          var drivingScore = ValueFormat.parseAsFloat(value.drivingScore);
          var drivingSkill = ValueFormat.parseAsFloat(value.drivingSkill);
          var anticipation = ValueFormat.parseAsFloat(value.anticipation);
          var selfConfidence = ValueFormat.parseAsFloat(value.selfConfidence);
          var rideBoxTemp = value.rideBox;
          var rideBox = ValueFormat.parseAsString(rideBoxTemp.rideBox);
          var duration = ValueFormat.parseAsFloat(value.travelTime);
          var kilometer = ValueFormat.parseAsFloat(value.kiloMeter);
          var alertCount = Math.round(
            ValueFormat.parseAsInt(value.alertDataCount)
          );
          var otherAlertCount = Math.round(
            ValueFormat.parseAsInt(value.manualDataCount)
          );
          var startTime = UtilDateTime.formatTimeAsHHMM(
            value.startDateTimeToTimeZone
          );
          var endTime = UtilDateTime.formatTimeAsHHMM(
            value.endDateTimeToTimeZone
          );
          var driverCategory =
            rideBox == 1
              ? "Safe Driver"
              : rideBox == 2
              ? "Sportive Driver"
              : rideBox == 3
              ? "Quite Safe Driver"
              : rideBox == 4
              ? "Reckless Driver"
              : rideBox == 5
              ? "Quite Dangerous Driver"
              : "Dangerous Driver";

          setTopRow(
            (topRow || []).map((staticObject) => {
              var isMatched = false;
              var overallData = 0;
              var overallDataPostFix = "";
              var bottomValue = "";
              var tooltip = "";
              if (staticObject.type === "SCORE") {
                isMatched = true;
                overallData = drivingScore;
                tooltip = "Driving Score";
                overallDataPostFix = "";
                bottomValue = "Ride Distance: " + kilometer + " kms";
              } else if (staticObject.type === "RIDE") {
                isMatched = true;
                overallData = rideNum;
                // setRideNameTemp(overallData);
                overallDataPostFix = "";
                bottomValue = "Ride Duration: " + duration + " mins";
              } else if (staticObject.type === "SKILL") {
                isMatched = true;
                overallData = drivingSkill;
                overallDataPostFix = "";
                bottomValue = "Risk Alerts: " + alertCount;
              } else if (staticObject.type === "ANTICIPATION") {
                isMatched = true;
                overallDataPostFix = "";
                overallData = anticipation;
                bottomValue = "Other Alerts: " + otherAlertCount;
              } else if (staticObject.type === "SELF CONFIDENCE") {
                isMatched = true;
                overallDataPostFix = "";
                overallData = selfConfidence;
                bottomValue = "Ride Start Time: " + startTime;
              } else if (staticObject.type == "CATEGORY") {
                isMatched = true;
                overallDataPostFix = "";
                overallData = driverCategory;
                bottomValue = "Ride End Time: " + endTime;
              }

              if (isMatched === true) {
                var updatedObject = {
                  overallData: overallData,
                  overallDataPostFix: overallDataPostFix,
                  bottomValue: bottomValue,
                  tooltip: tooltip,
                  // startTime: startTime,
                  // endTime: endTime,
                };
                return { ...staticObject, ...updatedObject };
              } else {
                return staticObject;
              }
            })
          );
          topLineItems.push([
            [
              rideNum,
              drivingScore,
              drivingSkill,
              anticipation,
              selfConfidence,
              driverCategory,
            ],
            [
              duration,
              kilometer,
              alertCount,
              otherAlertCount,
              startTime,
              endTime,
            ],
          ]);
        });
      } else {
        console.log("Status Failed");
      }
    } catch (e) {
      console.log("catch err", e);
    } finally {
      setShowLoader(false);
    }
  };


  useEffect(() => {
    if (location.state != null) {
      const { rideId, userId, divisionId, rideNo } = location.state;
      setLocationParams((prev) => {
        return {
          ...prev,
          rideId: rideId,
          userId: userId,
          divisionId: divisionId,
          rideNo: rideNo,
        };
      });
    } else {
      history("/myRides");
    }
  }, []);

  useEffect(() => {
    getRiskAlertsDataList1();
    topRowItems();
  }, [locationParams]);

  // useEffect(() => {
  //   const platform = new window.H.service.Platform({
  //     apikey: "pAuj6jqBlpXZUMzd30mtgujRlfWV2jA6qZJPF3JP5is",
  //   });

  //   const defaultLayers = platform.createDefaultLayers();

  //   const map = new window.H.Map(
  //     document.getElementById("map-container"),
  //     defaultLayers.vector.normal.map.setMin(3),
  //     {
  //       zoom: 10,
  //       pixelRatio: window.devicePixelRatio || 2,
  //       center: { lat: 0, lng: 0 }, // Initial center coordinates (e.g., set to your default location)
  //       heading: 90,
  //     }
  //   );

  //   const ui = window.H.ui.UI.createDefault(map, defaultLayers);
  //   const behavior = new window.H.mapevents.Behavior(
  //     new window.H.mapevents.MapEvents(map)
  //   );

  //   // Check if errorDataWithinRange is defined and not empty
  //   if (errorDataWithinRange && errorDataWithinRange.length > 0) {
  //     // Prepare arrays to calculate the center
  //     let latitudes = [];
  //     let longitudes = [];

  //     errorDataWithinRange.forEach(({ errorEntry, dataWithinRange }) => {
  //       dataWithinRange.forEach(({ latitude, longitude }) => {
  //         latitudes.push(parseFloat(latitude));
  //         longitudes.push(parseFloat(longitude));
  //       });
  //       const lineString = new window.H.geo.LineString();

  //       // Add latitude and longitude points to the lineString for the route
  //       dataWithinRange.forEach((point , index) => {
  //         const { latitude, longitude, category } = point;
  //         lineString.pushLatLngAlt(point.latitude, point.longitude);
  //       });

  //       // Create a polyline for the route outline
  //       const routeOutline = new window.H.map.Polyline(lineString, {
  //         style: {
  //           lineWidth: 8,
  //           strokeColor: "black",
  //           lineTailCap: "arrow-tail",
  //           lineHeadCap: "arrow-head",
  //         },
  //       });

  //       // Create a patterned polyline
  //       var routeArrows = new window.H.map.Polyline(lineString, {
  //         style: {
  //           lineWidth: 8,
  //           fillColor: "white",
  //           strokeColor: "#ffff",
  //           lineDash: [0, 1],
  //           lineTailCap: "arrow-tail",
  //           lineHeadCap: "arrow-head",
  //         },
  //       });

  //       var routeLine = new window.H.map.Group();
  //       routeLine.addObjects([routeOutline, routeArrows]);

  //       // Add the polyline to the map
  //       map.addObject(routeLine);

  //       // Iterate over each point to add a marker
  //       // dataWithinRange.slice(-4).map((value) => { // recent 4
  //         dataWithinRange.slice(-4).map((value) => {
  //         let imageFileName = "";

  //         if (value.category === "ERROR_DATA") {
  //           imageFileName = "accidentCrash.svg";
  //         } else {
  //           imageFileName = "carInfo.svg";
  //         }

  //         const svgMarkup = require(`../../assets/images/${imageFileName}`);
  //         const icon = new window.H.map.Icon(svgMarkup, {
  //           size: { w: 30, h: 40 },
  //         });

  //         // Create a marker and add it to the map
  //         const marker = new window.H.map.Marker(
  //           { lat: value.latitude, lng: value.longitude },
  //           { icon }
  //         );

  //         // Define content for the marker tooltip
  //         const tooltipContent = `
  //         <div style="display: flex; flex-direction: row; align-items: center; width:350px;">
  //         <img src="${svgMarkup}" style="width: 15px; height: 25px; margin-right:5px;" />
  //         <div style="margin-top: 5px; text-align: center; font-size:12px">
  //           <strong>Risk:</strong> ${value.risk || "N/A"}${" "}<strong>Speed:</strong> ${value.speed || "N/A"} km/h ${" "}<strong>Speed Limit:</strong> ${
  //             value.speedLimit === "0.00" ? "40.00" : value.speedLimit || "N/A"
  //           } km/h<br />
  //         </div>
  //         </div>
  //       </div>
  //     `;

  //         // Create an info bubble for the marker
  //         let bubble = new window.H.ui.InfoBubble(
  //           {
  //             lat: parseFloat(value.latitude),
  //             lng: parseFloat(value.longitude),
  //           },
  //           {
  //             content: tooltipContent,
  //           }
  //         );
  //         ui.addBubble(bubble, true);
  //         marker.setData(tooltipContent);
  //         marker.addEventListener(
  //           "tap",
  //           function (evt) {
  //             bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
  //               content: evt.target.getData(),
  //             });
  //             ui.addBubble(bubble);
  //           },
  //           false
  //         );

  //         map.addObject(marker);
  //       });

  //       // Zoom the map to fit the polyline for the route
  //       map
  //         .getViewModel()
  //         .setLookAtData({
  //           tilt: 40,
  //           heading: 180,
  //           bounds: routeLine.getBoundingBox(),
  //         });
  //         const lastDataPoint = dataWithinRange[dataWithinRange.length - 1];
  //         const avgLatitude = parseFloat(lastDataPoint.latitude);
  //         const avgLongitude = parseFloat(lastDataPoint.longitude);

  //         // Set the map's center to the average coordinates
  //         map.setCenter({ lat: avgLatitude, lng: avgLongitude });
  //        });
  //     // Calculate the average center point

  //   }

  //   return () => {
  //     map.dispose(); // Clean up when the component unmounts
  //   };
  // }, [errorDataWithinRange]);

  useEffect(() => {
    const platform = new window.H.service.Platform({
      apikey: "pAuj6jqBlpXZUMzd30mtgujRlfWV2jA6qZJPF3JP5is",
    });

    const defaultLayers = platform.createDefaultLayers();

    const map = new window.H.Map(
      document.getElementById("map-container"),
      defaultLayers.vector.normal.map.setMin(3),
      {
        zoom: 17,
        pixelRatio: window.devicePixelRatio || 2,
        center: { lat: 0, lng: 0 }, // Initial center coordinates (e.g., set to your default location)
        heading: 180,
        tilt: 40,
      }
    );

    const ui = window.H.ui.UI.createDefault(map, defaultLayers);
    const behavior = new window.H.mapevents.Behavior(
      new window.H.mapevents.MapEvents(map)
    );

    // Check if errorDataWithinRange is defined and not empty
    if (errorDataWithinRange && errorDataWithinRange.length > 0) {
      errorDataWithinRange.forEach(({ errorEntry, dataWithinRange }) => {
        const lineString = new window.H.geo.LineString();

        // Filter out all ERROR_DATA entries except the last one
        const lastErrorData = dataWithinRange.reduce((last, current) => {
          return current.category === "ERROR_DATA" ? current : last;
        }, null);

        // console.log(lastErrorData,"lastErrorData");

        // Process data points based on index and category
        dataWithinRange.slice(-5).forEach((point, index) => {
          const { latitude, longitude, category } = point;
          console.log("latitude: ",latitude + " " + "longitude: ",longitude);

          // Determine if the index is even or the category is "ERROR_DATA"
          if (category !== "ERROR_DATA" || point === lastErrorData) {
            lineString.pushLatLngAlt(latitude, longitude);
          try {
            if (latitude && longitude) {
              lineString.pushLatLngAlt(parseFloat(latitude), parseFloat(longitude));
            } else {
              console.error(`Invalid coordinates: ${latitude}, ${longitude}`);
            }
          } catch (e) {
            console.log("Lat &Long error: ",e );
          }

            let imageFileName =
              category === "ERROR_DATA" ? "accidentCrash.svg" : "carInfo.svg";
            const svgMarkup = require(`../../assets/images/${imageFileName}`);
            const icon = new window.H.map.Icon(svgMarkup, {
              size: { w: 30, h: 40 },
            });

            // Create a marker and add it to the map
            const marker = new window.H.map.Marker(
              { lat: parseFloat(latitude), lng: parseFloat(longitude) },
              { icon }
            );

            const tooltipContent = `
              ${category === "ERROR_DATA" ? "<h5>Crash</h5>" : ""}
              <div style="display: flex; flex-direction: row; align-items: center; width:350px;">
                <img src="${svgMarkup}" style="width: 15px; height: 25px; margin-right:5px;" />
                <div style="margin-top: 5px; text-align: center; font-size:12px">
               
                  <strong>Risk:</strong> ${
                    point.risk || "N/A"
                  }${" "}<strong>Speed:</strong> ${
              point.speed || "N/A"
            } km/h ${" "}<strong>Speed Limit:</strong> ${
              point.speedLimit === "0.00" ? "40.00" : point.speedLimit || "N/A"
            } km/h<br />
                </div>
              </div>
            `;

            // Create an info bubble for the marker
            let bubble = new window.H.ui.InfoBubble(
              { lat: parseFloat(latitude), lng: parseFloat(longitude) },
              { content: tooltipContent }
            );

            // ui.addBubble(bubble, true);
            marker.setData(tooltipContent);
            // Add event listener to open infoBubble on marker tap
            marker.addEventListener(
              "tap",
              (evt) => {
                bubble = new window.H.ui.InfoBubble(evt.target.getGeometry(), {
                  content: evt.target.getData(),
                });
                ui.addBubble(bubble);
              },
              false
            );

            map.addObject(marker);
          }
        });
        if (lineString.getPointCount() > 0) {
        // Add the polyline for the route outline to the map
        const routeOutline = new window.H.map.Polyline(lineString, {
          style: {
            lineWidth: 8,
            strokeColor: "black",
            lineTailCap: "arrow-tail",
            lineHeadCap: "arrow-head",
          },
        });

        var routeArrows = new window.H.map.Polyline(lineString, {
          style: {
            lineWidth: 8,
            fillColor: "white",
            strokeColor: "#ffff",
            lineDash: [0, 1],
            lineTailCap: "arrow-tail",
            lineHeadCap: "arrow-head",
          },
        });

        var routeLine = new window.H.map.Group();
        routeLine.addObjects([routeOutline, routeArrows]);

        //       // Add the polyline to the map
        map.addObject(routeLine);

        map.getViewModel().setLookAtData({
          zoom: 17,
          tilt: 40,
          heading: 180,
          bounds: routeLine.getBoundingBox(),
        });
        // Set the map's center to the last point in dataWithinRange
        const lastDataPoint = dataWithinRange[dataWithinRange.length - 1];
        const avgLatitude = parseFloat(lastDataPoint.latitude);

        const avgLongitude = parseFloat(lastDataPoint.longitude);
        map.setCenter({ lat: avgLatitude, lng: avgLongitude });
      } else {
        console.error("lineString is empty, no polyline will be created.");
      }
    });
    }

    return () => {
      map.dispose(); // Clean up when the component unmounts
    };
  }, [errorDataWithinRange]);

  // Calculate the risk color based on the risk value
  const getRiskColor = (risk, violationRemarks) => {
    if (violationRemarks === "Vehicle crashed") {
      return risk < 50 ? "rgb(237, 115, 115)" : "rgb(224, 13, 13)";
    }
  
    if (risk > 90) {
      return "red";
    } else if (risk > 70 && risk <= 90) {
      return "#FFBF00";
    } else {
      return "#19543e";
    }
  };

  const filterUniqueEntries = (data) => {
    const uniqueEntries = [];
    const visitedLocations = new Set();

    data.forEach((value) => {
      const locationKey = `${value.latitude}-${value.longitude}`;
      if (!visitedLocations.has(locationKey)) {
        visitedLocations.add(locationKey);
        uniqueEntries.push(value);
      }
    });

    return uniqueEntries;
  };

  return (
    <div className="page-content">
      {showLoader && <Loader />}
      <Container fluid>
        <Row className="mt-4">
          <Col sm={6} className="sub_heading">
            <h5 className="heading">Crash Details</h5>
          </Col>
          <Col sm={6}>
            <div className="d-flex justify-content-end">
              <div className="pb-2 d-flex">
                <ViewPdf topRow={topRow} errorDataWithinRange={errorDataWithinRange} />
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-2">
          {(topRow || []).map((widget, key) => (
            <Col xl={2} md={6} key={key}>
              <Card className="card-h-100">
                <CardBody>
                  <Row className="align-items-center">
                    <Col xs={12}>
                      <span
                        data-tooltip-id={key + 1}
                        className="text-muted mb-3  d-block text-truncate sub_heading"
                      >
                        {widget.title}
                        <ReactTooltip
                          id={key + 1}
                          place="top"
                          content={widget.title}
                          style={{ background: "black", zIndex: 1 }}
                        />
                      </span>
                      <h4 className="mb-3 sub_heading">
                        {widget.type === "CATEGORY" ? (
                          <span
                            data-tooltip-id={widget.title}
                            style={{ fontSize: "16px" }}
                          >
                            {widget.overallData}
                          </span>
                        ) : (
                          <span
                            data-tooltip-id={widget.title}
                            className="counter-value"
                          >
                            <CountUp
                              start={0}
                              end={widget.overallData}
                              decimals={widget.type === "OTHERALERTS" ? 2 : 0}
                              decimal="."
                              duration={1}
                            />
                          </span>
                        )}
                        <span style={{ color: "#2ab57d", fontSize: "16px" }}>
                          {widget.overallDataPostFix}
                        </span>
                        {widget.postFix}
                      </h4>
                    </Col>
                  </Row>
                  <div className="text-nowrap body_text">
                    <span>
                      {widget.currentYearValue}
                      <span data-tooltip-id={`tooltip-cpp-${key}`}>
                        {widget.bottomValue}
                      </span>
                    </span>
                    <span className="ms-1 text-muted font-size-13"></span>
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
        <Card className="mt-2">
          <CardHeader className="px-3 py-4 cardHeader">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <h5 className="mb-0">Crash Visualization </h5>
              </div>
            </div>
          </CardHeader>
          <CardBody>
            <div id="map-container" style={{ width: "100%", height: "60vh" }} />
          </CardBody>
        </Card>

        <div>
          <div>
            {/* <h4>Crash Details</h4> */}
            {errorDataWithinRange.map(
              ({ errorEntry, dataWithinRange }, index) => (
                <Card key={index} className="mt-2">
                  <CardHeader className="cardHeader">
                    <h5>Crash: {index + 1}</h5>
                    <h5>
                      {" "}
                      Intensity of crash:{" "}
                      {errorEntry.accidentType == "MILD_ACCIDENT" ? "Mild Crash" : errorEntry.accidentType == "MEDIUM_ACCIDENT" ? "Medium Crash"
                       : errorEntry.accidentType == "SEVERE_ACCIDENT" ? "Severe Crash" : null }
                    </h5>
                  </CardHeader>
                  <CardBody className="table-responsive">
                    <Table bordered>
                      <thead className="cardHeader">
                        <tr>
                          <th>Latitude</th>
                          <th>Longitude</th>
                          <th style={{ whiteSpace: "nowrap" }}>
                            Distance (kms)
                          </th>
                          <th
                            style={{
                              width: "16%",
                              whiteSpace: "nowrap",
                              paddingRight: "110px",
                            }}
                          >
                            Event Date & Time
                          </th>
                          <th style={{ whiteSpace: "nowrap" }}>Speed (km/h)</th>
                          <th style={{ whiteSpace: "nowrap" }}>
                            Speed Limit(km/h)
                          </th>
                          <th
                            style={{
                              width: "15%",
                              whiteSpace: "nowrap",
                              paddingRight: "110px",
                            }}
                          >
                            Risk
                          </th>
                          <th
                            style={{
                              width: "34%",
                              whiteSpace: "nowrap",
                              paddingRight: "110px",
                            }}
                          >
                            Violation Remarks
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {filterUniqueEntries(dataWithinRange).map(
                          (value, i, array) => (
                            <tr key={i}>
                              <td>{value.latitude}</td>
                              <td>{value.longitude}</td>
                              <td>{value.kilometer}</td>
                              <td style={{ width: "16%" }}>{value.time}</td>
                              <td>{value.speed}</td>
                              <td>
                                {value.speedLimit === "0.00"
                                  ? "40.00"
                                  : value.speedLimit}
                              </td>
                              <td style={{ width: "15%" }}>
                                <div className={value.violationRemarks == "Vehicle crashed" ? "d-flex justify-content-center align-items-center" : "d-flex justify-content-around align-items-center"}>
                                  <div style={{ width: value.violationRemarks == "Vehicle crashed" ? "100%" : "70%" }}>
                                    <Progress
                                      value={value.violationRemarks === "Vehicle crashed" ? 100 : value.risk}
                                      style={{ height: "20px", "--bs-progress-bar-bg": getRiskColor(value.risk, value.violationRemarks)}}
                                    />
                                  </div>
                                  <div style={{width: value.violationRemarks == "Vehicle crashed" ? "15%" : "35%", textAlign: "center"}}
                                  >
                                    {value.violationRemarks == "Vehicle crashed" ? "" : value.risk.split(".")[0] || "N/A"}
                                  </div>
                                </div>
                              </td>
                              <td style={{ width: "34%" }}>
                                {value.violationRemarks}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              )
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HereMapTest;
