import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useRowSelect,
} from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from "reactstrap";
import { Card, CardHeader } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Tooltip as ReactTooltip } from "react-tooltip";

import yesicon from "../../assets/images/safetyProtection/yes-icon.png";
import noicon from "../../assets/images/safetyProtection/no-icon.png";
import naicon from "../../assets/images/safetyProtection/no-image.png";
import tripIcon from "../../assets/images/TripreportIcon.png";
import distanceIcon from "../../assets/images/distanceNewIcon.png";

import logo from "../../assets/images/logo-sm.svg";
// import CarouselPage from './CarouselPage';
import "bootstrap/dist/css/bootstrap.min.css";
import motivLogo from "../../assets/images/ruduicon.png";
import { CSVLink } from "react-csv";
import { CopyToClipboard } from "react-copy-to-clipboard";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Link, Navigate, useNavigate } from "react-router-dom";
import MyRidesController from "../../controller/Rides/MyRides.js";
import ValueFormat from "../../util/ValueFormat.js";
import {
  RIDEDETAILS,
  SC_LOGIN_USER_FIRST_NAME,
  SC_USER_FIRST_NAME,
  SC_USER_ID,
  SC_USER_LOGIN_TYPE,
} from "../../constant/StorageConstant";
import { useDispatch, useSelector } from "react-redux";
import { myUserSearch } from "../../store/actions";
import Loader from "../../components/Common/loader";
import MyRidedataList from "./myridetabledata";
import { PiDownloadSimple } from "react-icons/pi";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DateTimePickerPopup from "../ModelPopup/DateTimePopup";
import { displayText } from "../../constant/MessageConstant";
import ToastService from "../../components/Common/toastService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import bluetooth from "../../assets/images/Bluetooth.png";
import greenCar from "../../assets/images/car_icon.png";
import redCar from "../../assets/images/redCar.png";
import sandClock from "../../assets/images/sand_clock.png";
import startIcon from "../../assets/images/start_icon.png";
import calendar from "../../assets/images/calendar_icon.png";
import gpsRoute from "../../assets/images/gps_route_icon.png";
import destinationIcon from "../../assets/images/destination_icon.png";
import startOverlay from "../../assets/images/start_overlay.png";
import dotted from "../../assets/images/dotted_vertical_line.png";
import back from "../../assets/images/back.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import baterryStart from "../../assets/images/batteryHigh.svg";
import baterryEnd from "../../assets/images/baterryLow.svg";
import Converter from "../../components/Common/EncodeDecode.js";

function MyRides() {
  const history = useNavigate();
  const userType = localStorage.getItem(SC_USER_LOGIN_TYPE);
  const [workFilter, setWorkFilter] = useState("");
  const [categoryValue, setCategory] = useState("");
  const [loginType, setLoginType] = useState("");
  const [filter, setFilter] = useState("CURRENT_YEAR");
  const [excelData, setExcelData] = useState([]);
  const [ddData, setDDData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [nextShow, setNextShow] = useState();
  const [prevShow, setPrevShow] = useState();
  const [totalPageCount, setTotalPageCount] = useState();
  const [apiData, setApiData] = useState([]);
  const [indexStart, setIndexStart] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [dataLength, setDataLength] = useState(null);
  const [userId, setUserId] = useState(localStorage.getItem(SC_USER_ID));
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [count, setCount] = useState(0);

  const pageSize = 10;
  const inputRef = useRef();
  const [modalIsOpen, setIsOpen] = useState(false);

  const [userFirstName, setUserFirstName] = useState(
    localStorage.getItem(SC_LOGIN_USER_FIRST_NAME)
  );
  const [userName, setUserName] = useState(
    localStorage.getItem(SC_USER_FIRST_NAME)
  );
  const previous = () => {
    setIndexStart(indexStart - pageSize);
    setPageNumber(pageNumber - 1);
  };
  const next = () => {
    setIndexStart(indexStart + pageSize);
    setPageNumber(pageNumber + 1);
  };
  const [showLoader, setShowLoader] = useState(false);

  const exportExcelData = async () => {
    var dataExcel = [];
    var ddExcel = [];
    var result = await MyRidesController.myRidesList(
      indexStart,
      filter,
      workFilter,
      categoryValue,
      searchText,
      250,
      startDateTime,
      endDateTime
    );
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    var resultJSONData = resultJSON.data;
    // setDataLength(resultJSONData.length);
    // console.log(
    //   "resultJSONData myRidesListExport : " + JSON.stringify(resultJSONData)
    // );
    if (status === "SUCCESS") {
      if (resultJSONData.length > 0) {
        for (let i = 0; i < resultJSONData.length; i++) {
          var drivingScore = "0",
            rideName = "N/A",
            status = "N/A",
            // origin ='N/A',
            // subType = 'N/A',
            alerts = 0,
            createdTime = "N/A",
            dayPercentage = "0",
            nightPercentage = "100%" /*accessLevel = 'N/A',*/,
            endTime = "N/A",
            city = "N/A",
            state = "N/A",
            country = "N/A",
        /*locationName = 'N/A',*/ zipCode = "N/A",
            anticipation = "0",
            drivingSkill = "0",
            selfConfidence = "0",
            travelTime = "0Mins",
            totalKiloMeter = "0Kms",
            urbanPercent = "0%",
            ruralPercent = "0%",
            highwayPercent = "0%",
            batteryLevelStart = "0%",
            batteryLevelEnd = "0%",
            category = "N/A";
          var rideObject = resultJSONData[i];
          var deviceField = rideObject.deviceField;

          try {
            // var totalPercentage = 100;

            rideName = rideObject.name.length > 0 ? rideObject.name : "N/A";
            status = rideObject.status.length > 0 ? rideObject.status : "N/A";
            category = rideObject.category.length > 0 ? rideObject.category : "N/A";
            // origin         = rideObject.origin.length > 0 ? rideObject.origin : 'N/A';
            alerts = rideObject.alertDataCount > 0 ? rideObject.alertDataCount : 0;
            createdTime =
              rideObject.startDateTimeToTimeZone.length > 0
                ? rideObject.startDateTimeToTimeZone
                : "N/A";
            // subType        = rideObject.subType.length > 0 ?  rideObject.subType : 'N/A';
            dayPercentage =
              rideObject.dayPercentage > 0
                ? parseFloat(rideObject.dayPercentage).toFixed(0)
                : 0;
            nightPercentage =
              rideObject.dayPercentage > 0 ? 100 - dayPercentage + "%" : 100 + "%";
            // locationName    =  rideObject.locationName.length > 0 ? rideObject.locationName : 'N/A';
            // accessLevel     = rideObject.accessLevel.length > 0 ? rideObject.accessLevel : 'N/A';
            city =
              deviceField.city != null && deviceField.city.length > 0
                ? deviceField.city
                : "N/A";
            state =
              deviceField.state != null && deviceField.state.length > 0
                ? deviceField.state
                : "N/A";
            country =
              deviceField.country != null && deviceField.country.length > 0
                ? deviceField.country
                : "N/A";
            zipCode =
              deviceField.zipCode != null && deviceField.zipCode > 0
                ? deviceField.zipCode
                : "N/A";

            endTime =
              rideObject.endDateTimeToTimeZone != null
                ? rideObject.endDateTimeToTimeZone
                : "N/A";
            anticipation =
              rideObject.anticipation != null && rideObject.anticipation > 0
                ? parseFloat(rideObject.anticipation).toFixed(0)
                : 0;
            drivingSkill =
              rideObject.drivingSkill != null && rideObject.drivingSkill > 0
                ? parseFloat(rideObject.drivingSkill).toFixed(0)
                : 0;
            drivingScore =
              rideObject.drivingScore != null && rideObject.drivingScore > 0
                ? parseFloat(rideObject.drivingScore).toFixed(0)
                : 0;
            selfConfidence =
              rideObject.selfConfidence != null && rideObject.selfConfidence > 0
                ? parseFloat(rideObject.selfConfidence).toFixed(2)
                : 0;
            travelTime =
              rideObject.travelTime != null && rideObject.travelTime > 0
                ? parseFloat(rideObject.travelTime).toFixed(2) + " Mins"
                : 0 + " Mins";
            totalKiloMeter =
              rideObject.kiloMeter != null && rideObject.kiloMeter > 0
                ? parseFloat(rideObject.kiloMeter).toFixed(2) + " Kms"
                : 0 + " Kms";
            urbanPercent =
              rideObject.urbanPercent != null && rideObject.urbanPercent > 0
                ? parseFloat(rideObject.urbanPercent).toFixed(0) + "%"
                : 0 + "%";
            ruralPercent =
              rideObject.ruralPercent != null && rideObject.ruralPercent > 0
                ? parseFloat(rideObject.ruralPercent).toFixed(0) + "%"
                : 0 + "%";
            highwayPercent =
              rideObject.highwayPercent != null && rideObject.highwayPercent > 0
                ? parseFloat(rideObject.highwayPercent).toFixed(0) + "%"
                : 0 + "%";
            batteryLevelStart =
              rideObject.batteryLevelStart != null &&
                rideObject.batteryLevelStart > 0
                ? parseFloat(rideObject.batteryLevelStart).toFixed(0) + "%"
                : 0 + "%";
            batteryLevelEnd =
              rideObject.batteryLevelEnd != null && rideObject.batteryLevelEnd > 0
                ? parseFloat(rideObject.batteryLevelEnd).toFixed(0) + "%"
                : 0 + "%";
          } catch (errMess) {
            // console.log("MyRide Export Excel Error Message :" + errMess);
          }

          var userDetails = {
            "Ride No": rideName,
            Status: status,
            "Driving Score": drivingScore,
            "Driving Skill": drivingSkill,
            Anticipation: anticipation,
            "Self Confidence": selfConfidence,
            "Start Time": createdTime,
            "End Time": endTime,
            "No Of Alerts": alerts,
            "Day Percentage": dayPercentage + "%",
            "Night Percentage": nightPercentage,
            // 'From - Location Name' : locationName,
            "To-City": city,
            "To-State": state,
            "To-Country": country,
            "To-Zip Code": zipCode,
            "Urban Percentage": urbanPercent,
            "Rural Percentage": ruralPercent,
            "Highway Percentage": highwayPercent,
            // 'Mobile' : origin,
            // 'Event' : subType,
            Distance: totalKiloMeter,
            Duration: travelTime,
            "Battery Level Start": batteryLevelStart,
            "Battery Level End": batteryLevelEnd,
            Category: category,
          };

          var ddDetails = {
            "Ride No": rideName,
            "Ride date/time": createdTime,
            Distance: totalKiloMeter,
            Duration: travelTime,
          };
          dataExcel.push(userDetails);
          ddExcel.push(ddDetails);
        }
        setExcelData(dataExcel);
        setDDData(ddExcel);
      } else {
        setExcelData([{}]);
        setDDData([]);
      }
    } else {
      console.log("Excel api failed");
      setExcelData([{}]);
      setDDData([]);
    }
  };

  useEffect(() => {
    exportExcelData();
  }, [filter, workFilter, categoryValue, searchText])

  const getRidesData = async (indexStartOfSet) => {
    setShowLoader(true);
    var result = await MyRidesController.myRidesList(
      indexStartOfSet,
      filter,
      workFilter,
      categoryValue,
      searchText,
      pageSize,
      startDateTime,
      endDateTime
    );
    setShowLoader(false);
    var updatedTableData = [];
    var safetyTypeImage = null;
    var safetyContent = "";
    try {
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      // console.log("getRidesData: " + JSON.stringify(resultJSON));

      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        // console.log("resultJSONData", resultJSONData);
        setDataLength(resultJSONData.length);
        if (resultJSONData.length > 0) {
          var endTime = "N/A",
            driveScore = "0",
            distance = "0",
            duration = "0",
            deviceMode = "N/A",
            drivingScoreFullValue = "0";
          let rideNumber = "";
          for (let i = 0; i < resultJSONData.length; i++) {
            var userRideDetail = resultJSONData[i];
            var rideName = ValueFormat.parseAsString(
              ValueFormat.capitalizeFirstLetterAndFormat(userRideDetail.name)
            );
            // Check if the ride name is "Ride -"
            // if (rideName == "Ride -") {

            //     // If the previousRideId is null, set it to the current rideId
            //     var previousRideId = resultJSONData[i]?.name || "";
            //     if(previousRideId == "Ride -") {
            //       rideNumber = previousRideId.slice(0,6);
            //       // console.log(rideNumber,previousRideId, "rideNumberrideNumber1");
            //     } else {
            //       rideNumber = previousRideId.slice(0,6);
            //       // console.log(rideNumber,previousRideId, "rideNumberrideNumber2");
            //     }
            //     // console.log(rideNumber,previousRideId.length, "rideNumberrideNumber3");

            // }
            var categoryObject = ValueFormat.parseAsObject(
              userRideDetail.rideBox
            );
            var categoryTemp = ValueFormat.parseAsInt(categoryObject.rideBox);
            var analyticServerStatus = ValueFormat.parseAsString(
              userRideDetail.analyticServerStatus
            );
            var rideCategory = ValueFormat.parseAsString(userRideDetail.category);
            var deviceSafetyField = ValueFormat.parseAsObject(
              userRideDetail.deviceSafetyField
            );
            var helmetAloneCount = ValueFormat.parseAsInt(
              deviceSafetyField.helmetAloneCount
            );
            var userWithHelmetCount = ValueFormat.parseAsInt(
              deviceSafetyField.userWithHelmetCount
            );
            var userWithoutHelmetCount = ValueFormat.parseAsInt(
              deviceSafetyField.userWithoutHelmetCount
            );
            var userWithSeatbeltCount = ValueFormat.parseAsInt(
              deviceSafetyField.userWithSeatbeltCount
            );
            var userWithoutSeatbeltCount = ValueFormat.parseAsInt(
              deviceSafetyField.userWithoutSeatbeltCount
            );
            if (analyticServerStatus == "SUCCESS") {
              if (userWithHelmetCount > 0 || userWithSeatbeltCount > 0) {
                safetyTypeImage = yesicon;
                safetyContent = "Yes";
              } else {
                safetyTypeImage = noicon;
                safetyContent = "No";
              }
            } else {
              safetyTypeImage = naicon;
              safetyContent = "Image not captured";
            }

            var category =
              categoryTemp == 1
                ? "Safe Driver"
                : categoryTemp == 2
                  ? "Sportive Driver"
                  : categoryTemp == 3
                    ? "Quite Safe Driver"
                    : categoryTemp == 4
                      ? "Reckless Driver"
                      : categoryTemp == 5
                        ? "Quite Dangerous"
                        : "Dangerous Driver";
            var rideId = ValueFormat.parseAsString(userRideDetail.deviceId);

            var rideName = ValueFormat.parseAsString(
              ValueFormat.capitalizeFirstLetterAndFormat(userRideDetail.name)
            );

            var status = ValueFormat.parseAsString(
              ValueFormat.capitalizeFirstLetterAndFormat(userRideDetail.status)
            );

            var statusTemp = "Completed";
            if (searchText == "") {
              if (
                i === 0 &&
                indexStartOfSet === 0 &&
                status === "In progress"
              ) {
                statusTemp = "In progress";
              } else if (
                i === 0 &&
                indexStartOfSet === 0 &&
                status === "Registered"
              ) {
                statusTemp = "Registered";
              } else if (status === "Completed") {
                statusTemp = "Completed";
              } else {
                statusTemp = "Completed";
              }
            } else if (searchText != "") {
              if (status === "Completed") {
                statusTemp = "Completed";
              } else {
                statusTemp = "Completed";
              }
            }

            var startTime = ValueFormat.parseAsString(
              userRideDetail.startDateTimeToTimeZone
            );

            var mobile = userRideDetail.origin === "IOS" ? "iOS" : ValueFormat.parseAsString(
              ValueFormat.capitalizeFirstLetterAndFormat(userRideDetail.origin)
            );

            var event = ValueFormat.parseAsString(
              ValueFormat.capitalizeFirstLetterAndFormat(userRideDetail.subType)
            );

            var distanceDataCount =
              userRideDetail.distanceDataCount.length > 0
                ? userRideDetail.distanceDataCount
                : "-";
            var alertDataCount = ValueFormat.parseAsInt(
              userRideDetail.alertDataCount
            );

            var totalCount = ValueFormat.parseAsInt(userRideDetail.totalCount);
            setTotalPageCount(Math.ceil(totalCount / pageSize));
            setNextShow(totalCount - indexStartOfSet > pageSize);

            // var startDataCount =
            //   userRideDetail.startDataCount.length > 0
            //     ? userRideDetail.startDataCount
            //     : "-";
            // var endDataCount =
            //   userRideDetail.endDataCount.length > 0
            //     ? userRideDetail.endDataCount
            //     : "-";
            endTime = ValueFormat.parseAsString(
              userRideDetail.endDateTimeToTimeZone
            );
            driveScore = Math.round(
              ValueFormat.parseAsInt(userRideDetail.drivingScore)
            );

            drivingScoreFullValue = ValueFormat.parseAsInt(
              userRideDetail.drivingScore
            );

            distance = ValueFormat.parseAsFloat(userRideDetail.kiloMeter, 2);

            duration = ValueFormat.parseAsFloat(userRideDetail.travelTime, 2);

            deviceMode =
              userRideDetail.deviceMode == "WORK_AUTO" ||
                userRideDetail.deviceMode == "PERSONAL_AUTO"
                ? "Auto"
                : userRideDetail.deviceMode == "WORK_MANUAL" ||
                  userRideDetail.deviceMode == "PERSONAL_MANUAL"
                  ? "Manual"
                  : "N/A";
            var userId = ValueFormat.parseAsString(userRideDetail.userId);
            var divisionId = ValueFormat.parseAsString(
              userRideDetail.divisionId
            );

            var stressStrain = ValueFormat.parseAsInt(
              userRideDetail.stressStrainDataCount
            );

            // mobile screen data & functions

            var accessLevel = ValueFormat.parseAsString(
              userRideDetail.accessLevel
            );

            var batterylevelStart = ValueFormat.parseAsFloat(
              userRideDetail.batteryLevelStart,
              0
            );
            var batterylevelEnd = ValueFormat.parseAsFloat(
              userRideDetail.batteryLevelEnd,
              0
            );
            var accidentType = ValueFormat.parseAsString(
              userRideDetail.accidentType
            );
            var CPS = ValueFormat.parseAsString(
              userRideDetail.crashProbabilityScore
            );

            var accidentValues = ValueFormat.parseAsArray(
              userRideDetail.accidentKeyValues
            );
            var accidentSeverityValue = ValueFormat.parseAsArray(
              userRideDetail.accidentSeverityValue
            );
            // console.log("accidentValues",accidentSeverityValue);

            var locationName = ValueFormat.parseAsString(
              userRideDetail.locationName.trim() || "-"
            );

            var deviceFieldCity = "-";
            if (userRideDetail.deviceField && userRideDetail.deviceField.city) {
              deviceFieldCity = ValueFormat.parseAsString(
                userRideDetail.deviceField.city.trim()
              );
            }
            var deviceFieldState = "-";
            if (
              userRideDetail.deviceField &&
              userRideDetail.deviceField.state
            ) {
              deviceFieldCity = ValueFormat.parseAsString(
                userRideDetail.deviceField.state.trim()
              );
            }

            var deviceFieldCountry = "-";
            if (
              userRideDetail.deviceField &&
              userRideDetail.deviceField.country
            ) {
              deviceFieldCountry = ValueFormat.parseAsString(
                userRideDetail.deviceField.country.trim()
              );
            }

            var combineValue = [
              locationName,
              deviceFieldCity,
              deviceFieldState,
              deviceFieldCountry,
            ]
              .filter((value) => value)
              .join(", ");

            var lastLocationName = ValueFormat.parseAsString(
              userRideDetail.lastLocationName.trim() || "-"
            );

            var lastDeviceFieldCity = ValueFormat.parseAsString(
              userRideDetail.lastDeviceField.city.trim() || "-"
            );
            var lastDeviceFieldState = ValueFormat.parseAsString(
              userRideDetail.lastDeviceField.state.trim() || "-"
            );
            var lastDeviceFieldCountry = ValueFormat.parseAsString(
              userRideDetail.lastDeviceField.country.trim() || "-"
            );

            var lastCombineValue = [
              lastLocationName,
              lastDeviceFieldCity,
              lastDeviceFieldState,
              lastDeviceFieldCountry,
            ]
              .filter((value) => value)
              .join(", ");

            const inputDateTime = startTime;
            const formatDate = (dateTimeString) => {
              const date = new Date(dateTimeString);
              const day = date.getDate().toString().padStart(2, "0");
              const month = (date.getMonth() + 1).toString().padStart(2, "0");
              const year = date.getFullYear();
              const hours = date.getHours().toString().padStart(2, "0");
              const minutes = date.getMinutes().toString().padStart(2, "0");
              const amOrPm = hours >= 12 ? "PM" : "AM";
              const formattedHours = (hours % 12 || 12)
                .toString()
                .padStart(2, "0");
              const formattedDateTime = `${day}/${month}/${year} | ${formattedHours}:${minutes} ${amOrPm}`;
              return formattedDateTime;
            };
            var formattedStartDate = formatDate(inputDateTime);

            const inputTime = endTime;
            const formatTime = (timeString) => {
              const date = new Date(timeString);
              const hours = date.getHours().toString().padStart(2, "0");
              const minutes = date.getMinutes().toString().padStart(2, "0");
              const amOrPm = hours >= 12 ? "PM" : "AM";
              const formattedHours = (hours % 12 || 12)
                .toString()
                .padStart(2, "0");
              return `${formattedHours}:${minutes} ${amOrPm}`;
            };
            var formattedTime = formatTime(inputTime);

            var userDetails = {
              rideId: rideId,
              userId: userId,
              divisionId: divisionId,
              rideNo: rideName,
              status: status,
              StatusTemp: statusTemp,
              startTime: startTime,
              endTime: endTime,
              mobile: mobile,
              event: event,
              score: driveScore,
              scoreFullValue: drivingScoreFullValue,
              duration: duration,
              distance: distance,
              mode: deviceMode,
              alerts: alertDataCount,
              category: category,
              distanceDataCount: distanceDataCount,
              stressStrain: stressStrain,
              safetyImage: safetyTypeImage,
              safetyContent: safetyContent,
              combineValue: combineValue,
              lastCombineValue: lastCombineValue,
              formattedStartDate: formattedStartDate,
              formattedTime: formattedTime,
              accessLevel: accessLevel,
              batterylevelStart: batterylevelStart,
              batterylevelEnd: batterylevelEnd,
              accidentType: accidentType,
              CPS: CPS,
              rideCategory: rideCategory
            };

            updatedTableData.push(userDetails);
          }

          setApiData(updatedTableData);
        }
      } else {
        console.log("Status failed");
        // var resultJSONCode = resultJSON.error.code;
        // if (resultJSONCode == "E007" && userType == "USER") {
        //   ToastService.errormsg(displayText.ER_007);
        //   setTimeout(() => {
        //     window.location.href = "/dashboard"
        //   }, 3500);
        // }
        // else if (resultJSONCode == "E007" && userType == "ADMIN") {
        //   ToastService.errormsg(displayText.ER_007);
        //   setTimeout(() => {
        //     window.location.href = "/adminDashboard"
        //   }, 3500);
        // }
      }
    } catch (errMess) {
      // console.log("Catch error: ",errMess);
    }

    if (indexStartOfSet == 0) {
      setPrevShow(false);
    } else if (indexStartOfSet > 0) {
      setPrevShow(true);
    }

  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setIndexStart(0);
      setPageNumber((current) => current - current + 1);
      event.preventDefault(); // Prevent page reload
      inputRef.current.click(); // Trigger the button click
    }
  };

  useEffect(() => {
    setPageNumber(1);
    if (indexStart == 0) setCount((prev) => prev + 1);
    else setIndexStart(0);
  }, [filter, workFilter, categoryValue, startDateTime, endDateTime]);

  useEffect(() => {
    if (count > 0) getRidesData(indexStart);
  }, [indexStart, count]);

  const handleSearch = (e) => {
    e.preventDefault();
    getRidesData(0);
  };

  useEffect(() => {
    setLoginType(localStorage.getItem(SC_USER_LOGIN_TYPE));
  }, [loginType]);

  // mobile screen functions
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const cardStyle = {
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "1px solid lightgrey",
    borderRadius: "0px",
  };

  const [totalPadding, setTotalPadding] = useState({
    paddingLeft:
      window.innerWidth >= 769 && window.innerWidth <= 1024 ? "10%" : "0",
    paddingRight:
      window.innerWidth >= 769 && window.innerWidth <= 1024 ? "10%" : "0",
  });
  useEffect(() => {
    function handleResize() {
      setTotalPadding({
        paddingLeft:
          window.innerWidth >= 769 && window.innerWidth <= 1024 ? "10%" : "0",
        paddingRight:
          window.innerWidth >= 769 && window.innerWidth <= 1024 ? "10%" : "0",
      });
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [maxWidth, setMaxWidth] = useState(
    window.innerWidth <= 319
      ? "150px"
      : window.innerWidth <= 375
        ? "200px"
        : window.innerWidth >= 376 && window.innerWidth <= 500
          ? "250px"
          : "350px"
  );
  useEffect(() => {
    const handleResize = () => {
      setMaxWidth(
        window.innerWidth <= 319
          ? "150px"
          : window.innerWidth <= 375
            ? "200px"
            : window.innerWidth >= 376 && window.innerWidth <= 500
              ? "250px"
              : "350px"
      );
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const cppFunction = (data) => {
    let value = "";
    let colorCode = "";
    let levelDescription = "";
    let color = "";

    if (data >= 1 && data <= 50) {
      value = "L1";
      colorCode = "#ff9b9b";
      color = "white";
      levelDescription = "L1 Range (1-50)";
    } else if (data > 50 && data <= 100) {
      value = "L2";
      colorCode = "#ff8585";
      color = "white";
      levelDescription = "L2 Range (50-100)";
    } else if (data > 100 && data <= 150) {
      value = "L3";
      colorCode = "#ff6e6e";
      color = "white";
      levelDescription = "L3 Range (100-150)";
    } else if (data > 150 && data <= 200) {
      value = "L4";
      colorCode = "#ff5757";
      color = "white";
      levelDescription = "L4 Range (150-200)";
    } else if (data > 200 && data <= 250) {
      value = "L5";
      colorCode = "#ff4040";
      color = "white";
      levelDescription = "L5 Range (200-250)";
    } else if (data > 250) {
      value = "L6";
      colorCode = "#ff2929";
      color = "white";
      levelDescription = "L6 Range (>250)";
    } else if (data == 0) {
      value = "L0";
      colorCode = "#7ABA78";
      color = "white";
      levelDescription = "L0 Range (0)";
    } else {
      value = "-";
      colorCode = "#ffff";
      color = "black";
      levelDescription = "No point available";
    }

    return { value, colorCode, levelDescription, color };
  };

  // --------------------------------------------------------------------//

  return (
    <div className="page-content">
      {showLoader && <Loader />}
      <Container fluid>
        <Breadcrumbs title="Rides" breadcrumbItem="Rides" />
        {/* <div style={{ marginTop:"20px"}}>
        {(loginType == "ADMIN" || loginType == "SUPER_ADMIN") ? (
          <span
            style={{
              fontSize: "14px",
              fontWeight: 400,
            }}
          >
            Welcome, You are viewing{" "}
            <span
              style={{
                fontSize: "12px",
                fontWeight: 800,
              }}
            >
              {userFirstName}'s
            </span>{" "}
            ride list.
          </span>
        ) : (
          <h5>Rides</h5>
        )}
        </div> */}

        <Row className="mt-2">
          <Col className="col-12 mt-2">
            <Card>
              <CardHeader style={{ padding: "0px" }}>
                <Row>
                  <Col
                    xl={2}
                    lg={2}
                    style={{
                      borderRight: "1px solid #DFDEDE",
                      paddingRight: 0,
                    }}
                  >
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                      >
                        Ride
                      </h4>
                    </div>
                    <div className="mt-4 mb-4" style={{ textAlign: "center" }}>
                      <button
                        type="button"
                        data-tooltip-id="allrides"
                        className={
                          workFilter == ""
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setWorkFilter("")}
                      >
                        All
                        <ReactTooltip
                          id="allrides"
                          place="top"
                          content="All"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        data-tooltip-id="wrkrides"
                        className={
                          workFilter == "WORK"
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setWorkFilter("WORK")}
                      >
                        Work
                        <ReactTooltip
                          id="wrkrides"
                          place="top"
                          content="Work"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        data-tooltip-id="perrides"
                        className={
                          workFilter == "PERSONAL"
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setWorkFilter("PERSONAL")}
                      >
                        Personal
                        <ReactTooltip
                          id="perrides"
                          place="top"
                          content="Personal"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                    </div>
                  </Col>
                  <Col
                    xl={2}
                    lg={3}
                    style={{
                      borderRight: "1px solid #DFDEDE",
                      padding: 0,
                    }}
                  >
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                      >
                        Category
                      </h4>
                    </div>

                    <div className="mt-4 mb-4" style={{ textAlign: "center" }}>
                      <button
                        type="button"
                        data-tooltip-id="Categoryall"
                        className={
                          categoryValue == ""
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setCategory("")}
                      >
                        All
                        <ReactTooltip
                          id="Categoryall"
                          place="top"
                          content="All"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        data-tooltip-id="Categorydriver"
                        className={
                          categoryValue == "RIDE_DRIVER"
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setCategory("RIDE_DRIVER")}
                      >
                        Driver
                        <ReactTooltip
                          id="Categorydriver"
                          place="top"
                          content="Driver"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        data-tooltip-id="CategoryPassenger"
                        className={
                          categoryValue == "RIDE_PASSENGER"
                            ? "btn btn-primary btn-sm me-0"
                            : "btn btn-soft-secondary btn-sm me-0"
                        }
                        onClick={() => setCategory("RIDE_PASSENGER")}
                      >
                        Passenger
                        <ReactTooltip
                          id="CategoryPassenger"
                          place="top"
                          content="Passenger"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                    </div>
                  </Col>
                  <Col
                    xl={3}
                    lg={3}
                    style={{ borderRight: "1px solid #DFDEDE", padding: 0 }}
                  >
                    <div className="ms-auto ">
                      <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                        <h4
                          className="card-title mb-1 mt-1"
                          style={{ textAlign: "center" }}
                        >
                          Duration
                        </h4>
                      </div>
                      <div
                        className="mt-4 mb-4"
                        style={{ textAlign: "center" }}
                      >
                        <button
                          type="button"
                          className={
                            filter == ""
                              ? "btn btn-primary btn-sm me-1"
                              : "btn btn-soft-secondary btn-sm me-1"
                          }
                          onClick={() => setFilter("")}
                          data-tooltip-id="alal"
                        >
                          All
                          <ReactTooltip
                            id="alal"
                            place="top"
                            content="All"
                            style={{ background: "black" }}
                          />
                        </button>
                        <button
                          type="button"
                          className={
                            (filter == "CURRENT_DAY" && !modalIsOpen)
                              ? "btn btn-primary btn-sm"
                              : "btn btn-soft-secondary btn-sm"
                          }
                          onClick={() => setFilter("CURRENT_DAY")}
                          data-tooltip-id="cdcd"
                        >
                          CD
                          <ReactTooltip
                            id="cdcd"
                            place="top"
                            content="Current Day"
                            style={{ background: "black" }}
                          />
                        </button>{" "}
                        <button
                          type="button"
                          className={
                            (filter == "CURRENT_WEEK" && !modalIsOpen)
                              ? "btn btn-primary btn-sm"
                              : "btn btn-soft-secondary btn-sm"
                          }
                          onClick={() => setFilter("CURRENT_WEEK")}
                          data-tooltip-id="cwcw"
                        >
                          CW
                          <ReactTooltip
                            id="cwcw"
                            place="top"
                            content="Current Week"
                            style={{ background: "black" }}
                          />
                        </button>{" "}
                        <button
                          type="button"
                          className={
                            (filter == "CURRENT_MONTH" && !modalIsOpen)
                              ? "btn btn-primary btn-sm"
                              : "btn btn-soft-secondary btn-sm"
                          }
                          onClick={() => setFilter("CURRENT_MONTH")}
                          data-tooltip-id="cmcm"
                        >
                          CM
                          <ReactTooltip
                            id="cmcm"
                            place="top"
                            content="Current Month"
                            style={{ background: "black" }}
                          />
                        </button>{" "}
                        <button
                          type="button"
                          className={
                            (filter == "CURRENT_YEAR" && !modalIsOpen)
                              ? "btn btn-primary btn-sm"
                              : "btn btn-soft-secondary btn-sm"
                          }
                          onClick={() => setFilter("CURRENT_YEAR")}
                          data-tooltip-id="cycy"
                        >
                          CY
                          <ReactTooltip
                            id="cycy"
                            place="top"
                            content="Current Year"
                            style={{ background: "black" }}
                          />
                        </button>{" "}
                        <button
                          type="button"
                          className={
                            (filter == "CUSTOM_DURATION" || modalIsOpen)
                              ? "btn btn-primary btn-sm"
                              : "btn btn-soft-secondary btn-sm"
                          }
                          onClick={() => {
                            setIsOpen(true);
                          }}
                          data-tooltip-id="dfdf"
                        >
                          <FontAwesomeIcon icon={faClock} />
                          <ReactTooltip
                            id="dfdf"
                            place="top"
                            content="Custom Date Selection"
                            style={{ background: "black" }}
                          />
                        </button>{" "}
                      </div>
                    </div>

                    {/* <Datepicker/> */}
                  </Col>

                  <Col
                    xl={3}
                    lg={2}
                    style={{ borderRight: "1px solid #DFDEDE", padding: 0 }}
                  >
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                        data-tooltip-id="GlobalSearchRide"
                      >
                        Global Search
                        <ReactTooltip
                          id="GlobalSearchRide"
                          place="top"
                          content="Global Search"
                          style={{ background: "black", zIndex: "100" }}
                        />
                      </h4>
                    </div>

                    <div
                      className="my-3"
                      style={{
                        height: "40px",
                        display: "flex",
                        justifyContent: "center",
                        marginLeft: "15px",
                      }}
                    >
                      <input
                        type="text"
                        style={{ border: " 1px solid #EEEBEB", width: "85%" }}
                        value={searchText}
                        onKeyPress={handleKeyPress}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                        }}
                        placeholder="Ride number or Ride name"
                      />
                      <button
                        ref={inputRef}
                        style={{ marginLeft: "5px" }}
                        className="btn btn-primary me-3"
                        // type="button"
                        onClick={handleSearch}
                      >
                        <i className="bx bx-search-alt align-middle"></i>
                      </button>
                    </div>
                  </Col>

                  <Col
                    xl={2}
                    lg={2}
                    style={{ padding: "0px", marginLeft: "-10px" }}
                  >
                    <div>
                      <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                        <h4
                          className="card-title mb-1 mt-1"
                          style={{ textAlign: "center" }}
                          data-tooltip-id="dwn"
                        >
                          Download
                          <ReactTooltip
                            id="dwn"
                            place="top"
                            content="Current Year Rides as Excel"
                            style={{ background: "black" }}
                          />
                        </h4>
                      </div>
                      {/* <CopyToClipboard text={JSON.stringify(copyData)}>
                        <button
                          onClick={() => setCopyData(data)}
                          type="button"
                          className="btn btn-secondary buttons-copy buttons-html5"
                        >
                          <span>Copy</span>
                        </button>
                      </CopyToClipboard> */}

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <div
                          className="mt-3 mb-3"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {/* <span data-tooltip-id="trt" className="mt-1">
                            Trip Report:
                            <ReactTooltip
                              id="trt"
                              place="top"
                              content="Trip Report"
                              style={{ background: "black" }}
                            />
                          </span> */}

                          <div style={{ marginTop: "2px" }}>
                            <CSVLink
                              data={excelData}
                              type="button"
                              data-tooltip-id="TripReportTool"
                              className="btn btn-soft-secondary btn-sm me-3"
                              filename={
                                loginType == "ADMIN" ||
                                  loginType == "SUPER_ADMIN"
                                  ? userFirstName + "'s" + " Trip report.csv"
                                  : userName + "'s" + " Trip report.csv"
                              }
                            >
                              <img
                                src={tripIcon}
                                alt="addIcons"
                                style={{ height: "30px", width: "30px" }}
                              />
                              <ReactTooltip
                                id="TripReportTool"
                                place="top"
                                content="Trip Report"
                                style={{ background: "black" }}
                              />
                            </CSVLink>
                          </div>

                          {/* <span data-tooltip-id="ddr" className="mt-1">
                            DD Report:
                            <ReactTooltip
                              id="ddr"
                              place="top"
                              content="Distance / Duration Report"
                              style={{ background: "black" }}
                            />
                          </span> */}

                          <div style={{ marginTop: "2px" }}>
                            <CSVLink
                              data={ddData}
                              type="button"
                              // onClick={onCloseClick}
                              className="btn btn-soft-secondary btn-sm me-1"
                              filename={
                                loginType == "ADMIN" ||
                                  loginType == "SUPER_ADMIN"
                                  ? userFirstName + "'s" + " km/hr report.csv"
                                  : userName + "'s" + " km/hr report.csv"
                              }
                              data-tooltip-id="distanceDurationReport"
                            >
                              <img
                                src={distanceIcon}
                                alt="addIcons"
                                style={{ height: "30px", width: "30px" }}
                              />
                              <ReactTooltip
                                id="distanceDurationReport"
                                place="top"
                                content="Distance / Duration Report"
                                style={{ background: "black" }}
                              />
                            </CSVLink>
                          </div>
                        </div>

                        {/* <div
                          className="mt-3 mb-3"
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <span className="mt-1">RA Report:</span>

                         <div style={{ marginLeft: "20px" }}>
                            <CSVLink
                              data={excelData}
                              type="button"
                              
                              className="btn btn-secondary btn-sm me-3"
                              filename={"rudu" + ".pdf.csv"}
                             
                            >
                              
                              <PiDownloadSimple
                                data-tooltip-id="rar"
                                size="18px"
                              />
                              <ReactTooltip
                                id="rar"
                                place="top"
                                content="Risk Alerts Report"
                              />
                              
                            </CSVLink>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ padding: "0px" }}>
                {/* <Row>
                  <Col xl={12} lg={12} md={12} sm={12} sx={12}>
                    <form className="d-none d-lg-block">
                      <div
                        className="mb-3 mt-4"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <label className="mt-2 " style={{ fontSize: "15px" }}>
                          &nbsp;Global Search:
                        </label>
                        <input
                          type="text"
                          style={{ border: " 1px solid #EEEBEB", width: "85%" }}
                          value={searchText}
                          onKeyPress={handleKeyPress}
                          onChange={(e) => {
                            setSearchText(e.target.value);
                          }}
                          placeholder="Ride number or Ride name"
                        />
                        <button
                          ref={inputRef}
                          style={{ marginLeft: "5px" }}
                          className="btn btn-primary me-3"
                          // type="button"
                          onClick={handleSearch}
                        >
                          <i className="bx bx-search-alt align-middle"></i>
                        </button>
                      </div>
                    </form>
                  </Col>
                </Row> */}
                <Row>
                  <Col xl={12} md={12} sm={12}>
                    <div className="table-responsive mt-3">
                      <table className="table table-bordered mb-0 table-responsive">
                        <thead className="cardHeader">
                          <tr>
                            <th
                              data-tooltip-id="rid"
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                                textAlign: "center",
                                padding: "8px 14px",
                              }}
                            >
                              Ride No
                              <ReactTooltip
                                id="rid"
                                place="top"
                                content="Ride No"
                                style={{ background: "black" }}
                              />
                            </th>
                            {/* <th
                              data-tooltip-id="sts"
                              style={{verticalAlign:"middle",  textAlign: "center", padding: "8px 14px" }}
                            >
                              Status
                              <ReactTooltip
                                id="sts"
                                place="top"
                                content="Status"
                                style={{ background: "black" }}
                              />
                            </th> */}
                            <th
                              data-tooltip-id="mbl"
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                                textAlign: "center",
                                padding: "8px 14px",
                              }}
                            >
                              Mobile
                              <ReactTooltip
                                id="mbl"
                                place="top"
                                content="Mobile"
                                style={{ background: "black" }}
                              />
                            </th>
                            {/* <th
                              data-tooltip-id="mde"
                              style={{verticalAlign:"middle",  textAlign: "center", padding: "8px 14px" }}
                            >
                              Mode
                              <ReactTooltip
                                id="mde"
                                place="top"
                                content="Mode"
                                style={{ background: "black" }}
                              />
                            </th> */}
                            <th
                              data-tooltip-id="even"
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                                textAlign: "center",
                                padding: "8px 25px",
                              }}
                            >
                              Event
                              <ReactTooltip
                                id="even"
                                place="top"
                                content="Event"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="strt"
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                                textAlign: "center",
                                padding: "8px 25px",
                              }}
                            >
                              Start Time
                              <ReactTooltip
                                id="strt"
                                place="top"
                                content="Start Time"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="endt"
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                                textAlign: "center",
                                padding: "8px 25px",
                              }}
                            >
                              End Time
                              <ReactTooltip
                                id="endt"
                                place="top"
                                content="End Time"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="scor"
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                                textAlign: "center",
                                padding: "8px 14px",
                              }}
                            >
                              Score
                              <ReactTooltip
                                id="scor"
                                place="top"
                                content="Driving Score"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="durat"
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                                textAlign: "center",
                                padding: "8px 14px",
                              }}
                            >
                              Duration
                              <ReactTooltip
                                id="durat"
                                place="top"
                                content="Duration"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="distanc"
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                                textAlign: "center",
                                padding: "8px 14px",
                              }}
                            >
                              Distance
                              <ReactTooltip
                                id="distanc"
                                place="top"
                                content="Distance"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="alrt"
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                                textAlign: "center",
                                padding: "8px 14px",
                              }}
                            >
                              Alerts
                              <ReactTooltip
                                id="alrt"
                                place="top"
                                content="Alerts"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="CPS"
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                                textAlign: "center",
                                padding: "8px 14px",
                              }}
                            >
                              CP
                              <ReactTooltip
                                id="CPS"
                                place="top"
                                content="Crash Probability"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="sp"
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                                textAlign: "center",
                                padding: "8px 14px",
                              }}
                            >
                              Safety
                              {/* <br /> Protection */}
                              <ReactTooltip
                                id="sp"
                                place="top"
                                content="Safety Protection"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="accbrak"
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                                textAlign: "center",
                                padding: "8px 14px",
                              }}
                            >
                              ABC
                              <ReactTooltip
                                id="accbrak"
                                place="top"
                                content="Acceleration / Braking / Cornering"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="categr"
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                                textAlign: "center",
                                padding: "8px 14px",
                              }}
                            >
                              Category
                              <ReactTooltip
                                id="categr"
                                place="top"
                                content="Category"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="Journey"
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                                textAlign: "center",
                                padding: "8px 14px",
                              }}
                            >
                              Detailed
                              <br /> View
                              <ReactTooltip
                                id="Journey"
                                place="top"
                                content="Detailed View"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="sbl"
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                                textAlign: "center",
                                padding: "8px 14px",
                              }}
                            >
                              {/* Battery<br/>Level Start */}
                              <img
                                src={baterryStart}
                                alt="btterystarticon"
                                width={25}
                                height={25}
                              />
                              <br />
                              BLS
                              <ReactTooltip
                                id="sbl"
                                place="top"
                                content="Battery Level Start"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="ebl"
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                                textAlign: "center",
                                padding: "8px 14px",
                              }}
                            >
                              {/* Battery<br/>Level End */}
                              <img
                                src={baterryEnd}
                                alt="btterystarticon"
                                width={25}
                                height={25}
                              />
                              <br />
                              BLE
                              <ReactTooltip
                                id="ebl"
                                place="top"
                                content="Battery Level End"
                                style={{ background: "black" }}
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataLength > 0 ? (
                            apiData.map((apiDataTemp, i) => (
                              <tr key={i}>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "0px",
                                  }}
                                >
                                  <Link
                                    to={`/ridedetails?userId=${encodeURIComponent(Converter.encrypt(apiDataTemp.userId))}&divisionId=${encodeURIComponent(Converter.encrypt(apiDataTemp.divisionId))}&rideId=${encodeURIComponent(Converter.encrypt(apiDataTemp.rideId))}&rideCategory=${encodeURIComponent(Converter.encrypt(apiDataTemp.rideCategory))}`}
                                  // to={"/ridedetails"}
                                  // state={{
                                  //   userId: apiDataTemp.userId,
                                  //   divisionId: apiDataTemp.divisionId,
                                  //   rideId: apiDataTemp.rideId,
                                  // }}
                                  // onClick={() => localStorage.setItem(RIDEDETAILS, JSON.stringify({ userId: apiDataTemp.userId, 
                                  // divisionId: apiDataTemp.divisionId, rideId: apiDataTemp.rideId }))}
                                  >
                                    <span
                                      data-tooltip-id={`rideTooltip_${i}`}
                                      style={{ fontSize: "12px" }}
                                    >
                                      {apiDataTemp.rideNo}
                                    </span>
                                  </Link>
                                  <ReactTooltip
                                    id={`rideTooltip_${i}`}
                                    place="top"
                                    effect="solid"
                                    backgroundColor="black"
                                    // getContent={() => apiDataTemp.StatusTemp}
                                    content={apiDataTemp.StatusTemp}
                                  />
                                </td>
                                {/* <td
                                  style={{
                                    fontSize: "12px",
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "0px",
                                  }}
                                >
                                  {apiDataTemp.StatusTemp}
                                </td> */}

                                <td
                                  style={{
                                    fontSize: "12px",
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "10px",
                                  }}
                                >
                                  {apiDataTemp.mobile}
                                </td>
                                {/* <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "0px",
                                  }}
                                >
                                  <span style={{ fontSize: "12px" }}>
                                    {apiDataTemp.mode}
                                  </span>
                                </td> */}
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "2px 4px",
                                  }}
                                >
                                  <span style={{ fontSize: "12px" }}>
                                    {apiDataTemp.event}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "2px 4px",
                                  }}
                                >
                                  <span style={{ fontSize: "12px" }}>
                                    {apiDataTemp.startTime}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "2px 4px",
                                  }}
                                >
                                  <span style={{ fontSize: "12px" }}>
                                    {apiDataTemp.endTime}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    // padding: "0px",
                                    // textAlign: "end"
                                  }}
                                >
                                  <div
                                    className=" w-100"
                                    data-tooltip-id={i + 1}
                                    style={{ fontSize: "12px" }}
                                  >
                                    {apiDataTemp.score}
                                    <ReactTooltip
                                      id={i + 1}
                                      place="top"
                                      content={apiDataTemp.scoreFullValue}
                                      style={{ background: "black" }}
                                    />
                                  </div>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "0px",
                                  }}
                                >
                                  <span style={{ fontSize: "12px" }}>
                                    {apiDataTemp.duration}
                                  </span>
                                </td>

                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "0px",
                                  }}
                                >
                                  <span style={{ fontSize: "12px" }}>
                                    {apiDataTemp.distance}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    // padding: "0px",
                                    // textAlign: "end"
                                  }}
                                >
                                  <div
                                    className=" w-100"
                                    style={{ fontSize: "12px" }}
                                  >
                                    {apiDataTemp.alerts}
                                  </div>
                                </td>
                                 <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                  }}
                                >
                                  <div style={{ fontSize: "12px" }}>
                                    <button
                                      data-tooltip-id={`cpssl_${i}`}
                                      className="btn px-2 py-0"
                                      style={{
                                        backgroundColor: cppFunction(
                                          apiDataTemp.CPS
                                        ).colorCode,
                                        fontSize: "11px",
                                        color: cppFunction(apiDataTemp.CPS)
                                          .color,
                                      }}
                                    >
                                      {cppFunction(apiDataTemp.CPS).value}
                                    </button>
                                  </div>
                                  <ReactTooltip
                                    id={`cpssl_${i}`}
                                    place="top"
                                    effect="solid"
                                    backgroundColor="black"
                                    content={
                                      cppFunction(apiDataTemp.CPS)
                                        .levelDescription
                                    }
                                  />
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <div className=" w-100">
                                    <img
                                      data-tooltip-id={i + 100}
                                      src={apiDataTemp.safetyImage}
                                      style={{ height: "20px" }}
                                    />
                                    <ReactTooltip
                                      id={i + 100}
                                      place="top"
                                      effect="solid"
                                      backgroundColor="black"
                                      content={apiDataTemp.safetyContent}
                                    />
                                  </div>
                                </td>

                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    // padding: "0px",
                                    // textAlign: "end"
                                  }}
                                >
                                  <div
                                    className=" w-100"
                                    style={{ fontSize: "12px" }}
                                  >
                                    {apiDataTemp.stressStrain}
                                  </div>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "0px",
                                  }}
                                >
                                  <span style={{ fontSize: "12px" }}>
                                    {apiDataTemp.category}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    padding: "0px",
                                  }}
                                >
                                  <Link
                                    to={"/journeyView"}
                                    state={{
                                      userId: apiDataTemp.userId,
                                      divisionId: apiDataTemp.divisionId,
                                      rideId: apiDataTemp.rideId,
                                      rideNo: apiDataTemp.rideNo,
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Journey View
                                    </span>
                                  </Link>
                                  <br />
                                  {(apiDataTemp.accidentType ===
                                    "MILD_ACCIDENT" ||
                                    apiDataTemp.accidentType ===
                                    "MEDIUM_ACCIDENT" ||
                                    apiDataTemp.accidentType ===
                                    "SEVERE_ACCIDENT") && (
                                      <Link
                                        to={"/crashView"}
                                        state={{
                                          userId: apiDataTemp.userId,
                                          divisionId: apiDataTemp.divisionId,
                                          rideId: apiDataTemp.rideId,
                                          rideNo: apiDataTemp.rideNo,
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: "12px",
                                            textAlign: "center",
                                          }}
                                        >
                                          Crash View
                                        </span>
                                      </Link>
                                    )}
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    // padding: "0px",
                                    // textAlign: "end"
                                  }}
                                >
                                  <div
                                    className=" w-100"
                                    // data-tooltip-id={i + 102}
                                    style={{ fontSize: "12px" }}
                                  >
                                    {apiDataTemp.batterylevelStart}
                                    {/* <ReactTooltip
                                      id={i + 102}
                                      place="top"
                                      content={apiDataTemp.batterylevelStart}
                                      style={{ background: "black" }}
                                    /> */}
                                  </div>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    // padding: "0px",
                                    // textAlign: "end"
                                  }}
                                >
                                  <div
                                    className=" w-100"
                                    // data-tooltip-id={i + 16}
                                    style={{ fontSize: "12px" }}
                                  >
                                    {apiDataTemp.batterylevelEnd}
                                    {/* <ReactTooltip
                                      id={i + 16}
                                      place="top"
                                      content={apiDataTemp.batterylevelEnd}
                                      style={{ background: "black" }}
                                    /> */}
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : dataLength === null ? (
                            <tr>
                              {" "}
                              <td colSpan="16">
                                <div
                                  style={{
                                    color: "green",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100px",
                                    width: "100%",
                                  }}
                                >
                                  {displayText.FETCHING_DATA}
                                </div>
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              {" "}
                              <td colSpan="16">
                                <div
                                  style={{
                                    color: "black",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100px",
                                    width: "100%",
                                  }}
                                >
                                  {displayText.NO_RECORDS_FOUND}
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-flex justify-content-between mb-3">
              <button
                type="button"
                className="btn btn-outline-danger btn-sm"
                onClick={previous}
                style={{
                  visibility: prevShow ? "visible" : "hidden",
                  width: "45px",
                  float: "left",
                }}
              >
                Prev
              </button>
              {dataLength > 0 ? (
                <>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div className="pagination ">
                      <a>{pageNumber}</a>
                    </div>
                    <p
                      style={{
                        marginTop: "6px",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      of
                    </p>
                    <div className="pagination ">
                      <a>{totalPageCount}</a>
                    </div>
                  </div>
                </>
              ) : null}
              <button
                type="button"
                className="btn btn-outline-success btn-sm"
                onClick={next}
                style={{
                  visibility: nextShow ? "visible" : "hidden",
                  float: "hidden",
                }}
              >
                Next
              </button>
            </div>
          </Col>
        </Row>
        <DateTimePickerPopup
          modalOpen={modalIsOpen}
          modalClose={setIsOpen}
          setStartDateTime={setStartDateTime}
          setEndDateTime={setEndDateTime}
          setFilter={setFilter}
          filter={filter}
        />
      </Container>
      <ToastContainer style={{ width: "auto" }} />
    </div>
  );
}

export default MyRides;
