
const RideSetailsTopRowItems = [
    {
        type : "SCORE",
        title: "Driving Behaviour Score",
        overallData: 0,    
        tooltip:"-",     
        overallDataPostFix: "", 
        currentYearValue: "", 
        postFix: "",
        statusColor: "success",
        bottomValue:""
    },
    {
        type : "RIDE",
        title: "Ride Number",
        overallData:0, 
        overallDataPostFix: "", 
        currentYearValue: "", 
        postFix:"",
        statusColor: "success",
        bottomValue:""

    },
    {
        type : "RANK",
        title: "Ride Duration",
        overallData: 0, 
        overallDataPostFix: "", 
        currentYearValue:"", 
        postFix: " mins",
        statusColor: "success",
        bottomValue:""

    },
    {
        type : "RISK",
        title: "Risk Alerts",
        overallData:0, 
        overallDataPostFix: "", 
        currentYearValue: "", 
        isDoller: false,
        postFix: "",
        statusColor: "success",
        bottomValue:"-"

    },
    {
        type : "RISKHISTOGRAM",
        title: "Red Risk Histogram",
        overallData:0, 
        overallDataPostFix: "", 
        currentYearValue: "", 
        isDoller: false,
        postFix: "",
        statusColor: "success",
        bottomValue:"-",
        tooltip:"Crash Probability Point Level"

    },
    {
        type : "OTHERALERTS",
        title: "Red Risk Duration",
        overallData:0.00, 
        overallDataPostFix: "", 
        currentYearValue: "", 
        isDoller: false,
        postFix: " mins",
        statusColor: "success",
        bottomValue:"-"
    },
    
   
];



const MarketOverViewAllData = [

    {
        name: "Profit",
        data: [
            12.45, 16.2, 8.9, 11.42, 12.6, 18.1, 18.2, 14.16, 11.1, 8.09, 16.34,
            12.88,
        ],
    },
    {
        name: "Loss",
        data: [
            -11.45, -15.42, -7.9, -12.42, -12.6, -18.1, -18.2, -14.16, -11.1, -7.09,
            -15.34, -11.88,
        ],
    },
]

const MarketOver1MData = [

    {
        name: "Profit",
        data: [
            14.45, 10.2, 8.9, 154.42, 6.6, 18.1, 18.2, 18.16, 11.1, 8.09, 16.34,
            12.88,
        ],
    },
    {
        name: "Loss",
        data: [
            -1.45, -15.42, -7.9, -12.42, -12.6, -8.1, -18.2, -14.16, -11.1, -7.09,
            -15.34, -17.88,
        ],
    },
];
const MarketOver6MData = [

    {
        name: "Profit",
        data: [
            12.45, 14.2, 8.9, 11.42, 12.6, 18.1, 12.2, 14.16, 11.1, 8.09, 16.34,
            8.88,
        ],
    },
    {
        name: "Loss",
        data: [
            -11.45, -15.42, -17.9, -12.42, -12.6, -8.1, -18.2, -14.16, -1.1, -7.09,
            -5.34, -11.88,
        ],
    },
];
const MarketOver1YData = [

    {
        name: "Profit",
        data: [
            2.45, 16.2, 18.9, 11.42, 12.6, 18.1, 8.2, 14.16, 11.1, 18.09, 16.34,
            12.88,
        ],
    },
    {
        name: "Loss",
        data: [
            -11.45, -5.42, -7.9, -12.42, -2.6, -18.1, -18.2, -4.16, -11.1, -7.09,
            -15.34, -1.88,
        ],
    },
];

const PieChart1YData = [15, 70, 45];

const PieChart6MData = [55, 35, 10];

const PieChart1MData = [75, 10, 65];

const PieChartAllData = [55, 35, 25];

const InvestedOverviewMay = [80];

const InvestedOverviewApril = [20];

const InvestedOverviewMarch = [40];

const InvestedOverviewFeb = [90];

const InvestedOverviewJan = [65];

const InvestedOverviewDec = [45];

export {
    RideSetailsTopRowItems, MarketOver1YData, MarketOver6MData, MarketOver1MData, MarketOverViewAllData, PieChart1YData, PieChart6MData,
    PieChart1MData, PieChartAllData, InvestedOverviewMay, InvestedOverviewApril, InvestedOverviewMarch, InvestedOverviewFeb,
    InvestedOverviewJan, InvestedOverviewDec
};