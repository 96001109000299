import { initializeApp, getApps } from "firebase/app";
import { getMessaging } from "firebase/messaging/sw";

const firebaseConfig = {
        apiKey: "AIzaSyA0JHZOk1DxLjfj2T3EkgLIJ_mBN90T41g",
        authDomain: "rudu-47a84.firebaseapp.com",
        databaseURL:"https://rudu-47a84.firebaseio.com",
        projectId: "rudu-47a84",
       storageBucket: "rudu-47a84.appspot.com",
       messagingSenderId: "715833988100",
       appId: "1:715833988100:web:8325b9c1aeb7fed4355151",
       measurementId: "G-N9HG88L67W"
};




const apps = getApps();

const app = !apps.length ? initializeApp(firebaseConfig):apps[0];
const messaging = getMessaging(app);

export default messaging;