import React, { useEffect, useState } from "react";
import Loader from "../../components/Common/loader";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  SC_DIVISION_ID,
  SC_LOGIN_USER_FIRST_NAME,
  SC_LOGIN_USER_ID,
  SC_USER_ID,
  SC_USER_LOGIN_TYPE,
} from "../../constant/StorageConstant";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { ToastContainer } from "react-toastify";
import DateTimePickerPopup from "../ModelPopup/DateTimePopup";
import ValueFormat from "../../util/ValueFormat";
import { displayText } from "../../constant/MessageConstant";
import { FaAngleDown, FaSearch } from "react-icons/fa";
import Report from "../../controller/Report/Report";
import { Link } from "react-router-dom";

export default function CrashList() {
  const [showLoader, setShowLoader] = useState(false);
  const [loginType, setLoginType] = useState("");
  const [dataLength, setDataLength] = useState(0);
  const [apiData, setApiData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState("CURRENT_YEAR");
  const [userId, setUserId] = useState("");
  const [deviceFilter, setDeviceFilter] = useState("");
  const [indexStart, setIndexStart] = useState(0);
  const [count, setCount] = useState(0);
  const [nextShow, setNextShow] = useState();
  const [prevShow, setPrevShow] = useState();
  const [totalPageCount, setTotalPageCount] = useState();
  const userFirstName = localStorage.getItem(SC_LOGIN_USER_FIRST_NAME);
  const [accidentTypeFilter, setAccidentTypeFilter] = useState("");
  const [apiDataUser, setApiDataUser] = useState([]);
  // console.log("apiDataUser",apiDataUser);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const LSuserType = localStorage.getItem(SC_USER_LOGIN_TYPE);
  const LSuserId = localStorage.getItem(SC_LOGIN_USER_ID);
  const divId = localStorage.getItem(SC_DIVISION_ID);

  let colSize1 = 3;
  let colSize2 = 3;
  let colSize3 = 4;

  if (LSuserType == "ADMIN" || LSuserType == "SUPER_ADMIN") {
    colSize1 = 3;
    colSize2 = 3;
    colSize3 = 4;
  } else {
    colSize1 = 4;
    colSize2 = 4;
    colSize3 = 4;
  }

  const pageSize = 10;

  const previous = () => {
    setIndexStart(indexStart - pageSize);
    setPageNumber(pageNumber - 1);
  };
  const next = () => {
    setIndexStart(indexStart + pageSize);
    setPageNumber(pageNumber + 1);
  };

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
    setSearchQuery("");
  };

  const handleSelect = (userIdTemp, firstName) => {
    setSelectedOption(firstName);
    setUserId(userIdTemp);
  };

  const filterUsers = (user) => {
    return user.firstName.toLowerCase().includes(searchQuery.toLowerCase());
  };

  const getUserList = async () => {
    setShowLoader(true);
    var result = await Report.getUserListReport();
    var userData = [];
    try {
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        // console.log("resultJSONData1", resultJSONData);
        if (resultJSONData.length > 0) {
          var firstName = "",
            userIdTemp = "";
          for (let i = 0; i < resultJSONData.length; i++) {
            var userListData = resultJSONData[i];
            firstName = ValueFormat.parseAsString(
              ValueFormat.capitalizeAllLetter(userListData.firstName)
            );
            userIdTemp = userListData.id;

            var userDataListTemp = {
              firstName: firstName,
              userIdTemp: userIdTemp,
            };

            userData.push(userDataListTemp);
            setApiDataUser(userData);
          }
        }
      } else {
        // var resultJSONCode = resultJSON.error.message;
        // console.log("Getting user list else error",resultJSONCode);
        console.log("Status failed");
      }
    } catch (errMes) {
      console.log("Catch error message: ", errMes);
    } finally {
      setShowLoader(false);
    }
  };

  const getCrashReport = async (index) => {
    let uid = "";
    if (LSuserType == "ADMIN" || LSuserType == "SUPER_ADMIN") {
      uid = userId;
    } else if (LSuserType == "USER") {
      uid = LSuserId;
    }
    try {
      setShowLoader(true);
      setDataLength(null);
      var result = await Report.getCrashReport(
        index,
        filter,
        deviceFilter,
        startDateTime,
        endDateTime,
        accidentTypeFilter,
        uid,
        pageSize
      );
      var tableData = [];

      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      // console.log("batteryLevelReport: " + JSON.stringify(resultJSON));
      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        // console.log("risk", resultJSONData);
        setDataLength(resultJSONData.length);
        if (resultJSONData.length > 0) {
          for (let i = 0; i < resultJSONData.length; i++) {
            var userApp = resultJSONData[i];
            var userDetail = ValueFormat.parseAsObject(userApp.userDetail);
            var userid = ValueFormat.parseAsString(userDetail.id);
            var deviceId = ValueFormat.parseAsString(userApp.deviceId);
            var firstName =
            ValueFormat.parseAsString(ValueFormat.capitalizeAllLetter(userDetail.firstName)) || "-";
            var username = ValueFormat.parseAsString(userApp.username) || "-";
            var accidentType =
              ValueFormat.parseAsString(userApp.accidentType) !== null &&
                ValueFormat.parseAsString(userApp.accidentType) !== undefined
                ? ValueFormat.parseAsString(userApp.accidentType)
                : "-";
            var crashTime =
              ValueFormat.parseAsString(userApp.accidentAtToTimeZone) !==
                null &&
                ValueFormat.parseAsString(userApp.accidentAtToTimeZone) !==
                undefined
                ? ValueFormat.parseAsString(userApp.accidentAtToTimeZone)
                : "-";
            var crashValue =
              ValueFormat.parseAsFloat(userApp.accidentSeverityValue) !==
                null &&
                ValueFormat.parseAsFloat(userApp.accidentSeverityValue) !==
                undefined
                ? ValueFormat.parseAsFloat(userApp.accidentSeverityValue) + "g"
                : "-";

        let deviceTypeTemp ="";
        if (userApp.origin === "ANDROID") {
          deviceTypeTemp = "Android"
        } else if (userApp.origin === "ANDROID_SDK"){
          deviceTypeTemp = "Android SDK"
        } else if (userApp.origin === "IOS") {
          deviceTypeTemp = "iOS"
        } else if (userApp.origin === "IOS_SDK"){
          deviceTypeTemp = "iOS SDK"
        } else  {
          deviceTypeTemp = "N/A"
        }
            // var device =
            //   ValueFormat.parseAsString(userApp.origin) !== null &&
            //     ValueFormat.parseAsString(userApp.origin) !== undefined
            //     ? ValueFormat.parseAsString(
            //       ValueFormat.capitalizeFirstLetter(userApp.origin)
            //     )
            //     : "-";
            var rideName =
              ValueFormat.parseAsString(userApp.name) !== null &&
                ValueFormat.parseAsString(userApp.name) !== undefined
                ? ValueFormat.parseAsString(userApp.name)
                : "-";

            var locationDetails = {
              address: "-",
              zipCode: "-",
              latitude: "",
              longitude: "",
              location: "-",
            };

            userApp.accidentKeyValues.forEach((item) => {
              if (item.key === "address")
                locationDetails.address =
                  ValueFormat.parseAsString(item.value) !== null &&
                    ValueFormat.parseAsString(item.value) !== undefined
                    ? ValueFormat.parseAsString(item.value)
                    : "-";
              else if (item.key === "zipCode")
                locationDetails.zipCode =
                  ValueFormat.parseAsString(item.value) !== null &&
                    ValueFormat.parseAsString(item.value) !== undefined
                    ? ValueFormat.parseAsString(item.value)
                    : "-";
              else if (item.key === "latitude")
                locationDetails.latitude =
                  ValueFormat.parseAsString(item.value) !== null &&
                    ValueFormat.parseAsString(item.value) !== undefined
                    ? ValueFormat.parseAsString(item.value)
                    : "-";
              else if (item.key === "longitude")
                locationDetails.longitude =
                  ValueFormat.parseAsString(item.value) !== null &&
                    ValueFormat.parseAsString(item.value) !== undefined
                    ? ValueFormat.parseAsString(item.value)
                    : "-";
              else if (item.key === "location")
                locationDetails.location =
                  ValueFormat.parseAsString(item.value) !== null &&
                    ValueFormat.parseAsString(item.value) !== undefined
                    ? ValueFormat.parseAsString(item.value)
                    : "-";
            });

            var totalCounts = ValueFormat.parseAsInt(userApp.totalCount);
            setTotalPageCount(Math.ceil(totalCounts / pageSize));
            setNextShow(totalCounts - index > pageSize);

            var crashDatadetails = {
              firstName: firstName,
              username: username,
              accidentType: accidentType,
              crashTime: crashTime,
              crashValue: crashValue,
              device: deviceTypeTemp,
              ...locationDetails,
              rideName: rideName,
              userid: userid,
              deviceId: deviceId
            };
            tableData.push(crashDatadetails);
            setApiData(tableData);
          }
        }
      } else {
        // var resultJSONCode = resultJSON.error.message;
        // console.log("Getting user list else error",resultJSONCode);
        setDataLength(0);
        console.log("Status failed");
      }
    } catch (errMes) {
      console.log("Crash Report Catch Error: ", errMes);
    } finally {
      setShowLoader(false);
    }

    if (index == 0) {
      setPrevShow(false);
    } else if (index > 1) {
      setPrevShow(true);
    }

  };

  useEffect(() => {
    getUserList();
  }, []);

  useEffect(() => {
    setPageNumber(1);
    if (indexStart == 0) setCount((prev) => prev + 1);
    else setIndexStart(0);
  }, [ filter, deviceFilter, startDateTime, endDateTime, accidentTypeFilter, userId, ]);

  useEffect(() => {
    if (count > 0)  getCrashReport(indexStart);
  }, [indexStart, count]);

  useEffect(() => {
    setLoginType(localStorage.getItem(SC_USER_LOGIN_TYPE));
  }, [loginType]);

  return (
    <div className="page-content">
      {showLoader && <Loader />}
      <Container fluid>
        <Breadcrumbs title="Battery Level" breadcrumbItem="Battery Level" />
        <Row className="my-2 py-2">
          <Col xs={12} className="d-flex justify-content-start align-items-center text-black">
            <h5 className="mb-0">Vehicle Crashes</h5>
          </Col>
        </Row>
        <Row>
          <Col className="col-12 mt-2">
            <Card>
              <CardHeader style={{ padding: "0px" }}>
                <Row>
                  <Col
                    md={colSize1}
                    style={{
                      borderRight: "1px solid #DFDEDE",
                      paddingRight: 0,
                      paddingLeft: 0,
                    }}
                  >
                    <div
                      style={{
                        marginLeft: "10px",
                        borderBottom: "1px dashed #DFDEDE",
                      }}
                    >
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                      >
                        Crash Selection
                      </h4>
                    </div>
                    <div className="mt-2 mb-2" style={{ textAlign: "center" }}>
                      <button
                        type="button"
                        className={
                          accidentTypeFilter == ""
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setAccidentTypeFilter("")}
                        data-tooltip-id="alle"
                      >
                        All
                        <ReactTooltip
                          id="alle"
                          place="top"
                          content="All"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          accidentTypeFilter == "MILD_ACCIDENT"
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setAccidentTypeFilter("MILD_ACCIDENT")}
                        data-tooltip-id="preIn"
                      >
                        Mild
                        <ReactTooltip
                          id="preIn"
                          place="top"
                          content="Mild Accident"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          accidentTypeFilter == "MEDIUM_ACCIDENT"
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setAccidentTypeFilter("MEDIUM_ACCIDENT")}
                        data-tooltip-id="postin"
                      >
                        Medium
                        <ReactTooltip
                          id="postin"
                          place="top"
                          content="Medium Accident"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          accidentTypeFilter == "SEVERE_ACCIDENT"
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setAccidentTypeFilter("SEVERE_ACCIDENT")}
                        data-tooltip-id="preLo"
                      >
                        Severe
                        <ReactTooltip
                          id="preLo"
                          place="top"
                          content="Severe Accident"
                          style={{ background: "black" }}
                        />
                      </button>
                    </div>
                  </Col>

                  <Col
                    md={colSize2}
                    style={{
                      borderRight: "1px solid #DFDEDE",
                      paddingRight: 0,
                      paddingLeft: 0,
                    }}
                  >
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                      >
                        Device Selection
                      </h4>
                    </div>
                    <div className="mt-2 mb-2" style={{ textAlign: "center" }}>
                      <button
                        type="button"
                        className={
                          deviceFilter == ""
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setDeviceFilter("")}
                        data-tooltip-id="allBl"
                      >
                        All
                        <ReactTooltip
                          id="allBl"
                          place="top"
                          content="All"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          deviceFilter == "ANDROID"
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setDeviceFilter("ANDROID")}
                        data-tooltip-id="androidBl"
                      >
                        Android
                        <ReactTooltip
                          id="androidBl"
                          place="top"
                          content="Android"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          deviceFilter == "IOS"
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setDeviceFilter("IOS")}
                        data-tooltip-id="iosBl"
                      >
                        iOS
                        <ReactTooltip
                          id="iosBl"
                          place="top"
                          content="iOS"
                          style={{ background: "black" }}
                        />
                      </button>
                    </div>
                  </Col>

                  <Col
                    md={colSize3}
                    style={{
                      borderRight: "1px solid #DFDEDE",
                      paddingRight: 0,
                      paddingLeft: 0,
                    }}
                  >
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                      >
                        Duration Selection
                      </h4>
                    </div>
                    <div className="mt-2 mb-2" style={{ textAlign: "center" }}>
                      {/* <button
                        type="button"
                        className={
                          filter == ""
                          ? "btn btn-primary btn-sm m-1"
                          : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setFilter("")}
                        data-tooltip-id="allBl"
                      >
                        All
                        <ReactTooltip
                          id="allBl"
                          place="top"
                          content="All"
                          style={{ background: "black" }}
                        />
                      </button> */}
                      <button
                        type="button"
                        className={
                          (filter == "CURRENT_DAY" && !modalIsOpen)
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setFilter("CURRENT_DAY")}
                        data-tooltip-id="cd"
                      >
                        CD
                        <ReactTooltip
                          id="cd"
                          place="top"
                          content="Current Day"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          (filter == "CURRENT_WEEK" && !modalIsOpen)
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilter("CURRENT_WEEK")}
                        data-tooltip-id="cw"
                      >
                        CW
                        <ReactTooltip
                          id="cw"
                          place="top"
                          content="Current Week"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          (filter == "CURRENT_MONTH" && !modalIsOpen)
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setFilter("CURRENT_MONTH")}
                        data-tooltip-id="cm"
                      >
                        CM
                        <ReactTooltip
                          id="cm"
                          place="top"
                          content="Current Month"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          (filter == "CURRENT_YEAR" && !modalIsOpen)
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilter("CURRENT_YEAR")}
                        data-tooltip-id="cy"
                      >
                        CY
                        <ReactTooltip
                          id="cy"
                          place="top"
                          content="Current Year"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          (filter == "CUSTOM_DURATION" || modalIsOpen)
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => {
                          setIsOpen(true);
                        }}
                        data-tooltip-id="dfdf"
                      >
                        <FontAwesomeIcon icon={faClock} />
                        <ReactTooltip
                          id="dfdf"
                          place="top"
                          content="Custom Date Selection"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                    </div>
                  </Col>

                  {LSuserType !== "USER" && (
                    <Col md={2} className="px-0">
                      <div
                        style={{
                          marginRight: "10px",
                          borderBottom: "1px dashed #DFDEDE",
                        }}
                      >
                        <h4
                          className="card-title mb-1 mt-1"
                          style={{ textAlign: "center" }}
                        >
                          User Selection
                        </h4>
                      </div>
                      <div
                        className="mb-2"
                        style={{ textAlign: "center", marginTop: "13px" }}
                      >
                        <Dropdown
                          isOpen={dropdownOpen}
                          toggle={toggleDropdown}
                          size="sm"
                        >
                          <DropdownToggle caret color="primary">
                            {selectedOption ? selectedOption : "All Users"}
                            <FaAngleDown style={{ marginLeft: "5px" }} />
                          </DropdownToggle>
                          <DropdownMenu
                            style={{ maxHeight: "200px", overflowY: "auto" }}
                          >
                            <div className="d-flex align-items-center px-3">
                              <FaSearch className="me-2" />
                              <input
                                type="text"
                                placeholder="Search users"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className="form-control form-control-sm"
                              />
                            </div>
                            <DropdownItem onClick={() => handleSelect("")}>
                              All Users
                            </DropdownItem>
                            {apiDataUser.filter(filterUsers).map((user) => (
                              <DropdownItem
                                key={user.userIdTemp}
                                onClick={() =>
                                  handleSelect(user.userIdTemp, user.firstName)
                                }
                              >
                                {user.firstName}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </Col>
                  )}
                </Row>
              </CardHeader>
              <CardBody style={{ padding: "0px" }}>
                <Row>
                  <Col xl={12} md={12} sm={12}>
                    <div className="table-responsive">
                      <div className="m-3" />
                      <table className="table table-bordered mb-0">
                        <thead className="cardHeader">
                          <tr>
                            {LSuserType !== "USER" && (
                              <th
                                data-tooltip-id="fn"
                                style={{
                                  whiteSpace: "nowrap",
                                  fontSize: "13px",
                                  fontWeight: 600,
                                  padding: "12px 20px 12px 10px",
                                }}
                              >
                                First Name
                                <ReactTooltip
                                  id="fn"
                                  place="top"
                                  content="First Name"
                                  style={{ background: "black" }}
                                />
                              </th>
                            )}
                            {LSuserType !== "USER" && (
                              <th
                                style={{ fontSize: "13px", fontWeight: 600 }}
                                data-tooltip-id="User Name"
                              >
                                Username
                                <ReactTooltip
                                  id="User Name"
                                  place="top"
                                  content="Username"
                                  style={{ background: "black" }}
                                />
                              </th>
                            )}
                            <th
                              data-tooltip-id="RideName"
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "13px",
                                fontWeight: 600,
                                padding: "12px 20px 12px 10px",
                              }}
                            >
                              Ride Name
                              <ReactTooltip
                                id="RideName"
                                place="top"
                                content="Ride Name"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="CrashType"
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "13px",
                                fontWeight: 600,
                                padding: "12px 20px 12px 10px",
                              }}
                            >
                              Crash Type
                              <ReactTooltip
                                id="CrashType"
                                place="top"
                                content="Crash Type"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="CrashTime"
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "13px",
                                fontWeight: 600,
                                padding: "12px 20px 12px 10px",
                              }}
                            >
                              Crash Time
                              <ReactTooltip
                                id="CrashTime"
                                place="top"
                                content="Crash Time"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="CrashValue"
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "13px",
                                fontWeight: 600,
                                padding: "12px 20px 12px 10px",
                              }}
                            >
                              Crash Value
                              <ReactTooltip
                                id="CrashValue"
                                place="top"
                                content="Crash Value"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="Device"
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "13px",
                                fontWeight: 600,
                                padding: "12px 20px 12px 10px",
                              }}
                            >
                              Device
                              <ReactTooltip
                                id="Device"
                                place="top"
                                content="Device"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="Location"
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "13px",
                                fontWeight: 600,
                                padding: "12px 20px 12px 10px",
                              }}
                            >
                              Location
                              <ReactTooltip
                                id="Location"
                                place="top"
                                content="Location"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="Address"
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "13px",
                                fontWeight: 600,
                                padding: "12px 20px 12px 10px",
                              }}
                            >
                              Address
                              <ReactTooltip
                                id="Address"
                                place="top"
                                content="Address"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="zipcode"
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "13px",
                                fontWeight: 600,
                                padding: "12px 20px 12px 10px",
                              }}
                            >
                              Zipcode
                              <ReactTooltip
                                id="zipcode"
                                place="top"
                                content="Zipcode"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="Lat/Lng"
                              style={{
                                whiteSpace: "nowrap",
                                fontSize: "13px",
                                fontWeight: 600,
                                padding: "12px 20px 12px 10px",
                              }}
                            >
                              Lat | Long
                              <ReactTooltip
                                id="Lat/Lng"
                                place="top"
                                content="Latitude & Longitude"
                                style={{ background: "black" }}
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {dataLength > 0 ? (
                            apiData.map((apiDataTemp, i) => (
                              <tr key={i}>
                                {LSuserType !== "USER" && (
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    <span
                                      style={{ fontSize: "13px" }}
                                      onMouseEnter={() => [
                                        localStorage.setItem(
                                          SC_USER_ID,
                                          apiDataTemp.userid
                                        ),
                                        localStorage.setItem(
                                          SC_LOGIN_USER_FIRST_NAME,
                                          apiDataTemp.firstName
                                        ),
                                      ]}
                                    >
                                      {/* <Link
                                      to={
                                        apiDataTemp.status == "Active" &&
                                        "/user-dashboard"
                                      }
                                    > */}
                                      {apiDataTemp.firstName}
                                      {/* </Link> */}
                                    </span>
                                  </td>
                                )}
                                {LSuserType !== "USER" && (
                                  <td
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {apiDataTemp.username}
                                  </td>
                                )}
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    width: "auto",
                                  }}
                                  onMouseEnter={() => [
                                    localStorage.setItem(SC_LOGIN_USER_FIRST_NAME, apiDataTemp.username),
                                    // localStorage.setItem(SC_DIVISION_ID, )
                                  ]}
                                >
                                  <Link to={"/crashView"}
                                    state={{
                                      userId: apiDataTemp.userid,
                                      divisionId: divId,
                                      rideId: apiDataTemp.deviceId,
                                    }}
                                  >
                                    <span style={{ fontSize: "12px" }}>
                                      {apiDataTemp.rideName}
                                    </span>
                                  </Link>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "12px" }}>
                                    {apiDataTemp.accidentType ===
                                      "MILD_ACCIDENT"
                                      ? "Mild Accident"
                                      : apiDataTemp.accidentType ===
                                        "SEVERE_ACCIDENT"
                                        ? "Severe Accident"
                                        : apiDataTemp.accidentType ===
                                          "MEDIUM_ACCIDENT"
                                          ? "Medium Accident"
                                          : "-"}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "12px" }}>
                                    {apiDataTemp.crashTime}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "12px" }}>
                                    {apiDataTemp.crashValue}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "12px" }}>
                                    {apiDataTemp.device}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "12px" }}>
                                    {apiDataTemp.location}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "12px" }}>
                                    {apiDataTemp.address}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "12px" }}>
                                    {apiDataTemp.zipCode}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span
                                    data-tooltip-id={"crashLatLng" + i}
                                    style={{ fontSize: "12px" }}
                                  >
                                    {apiDataTemp.latitude ||
                                      apiDataTemp.longitude ? (
                                      <span style={{ whiteSpace: "nowrap" }}>
                                        {Number(apiDataTemp.latitude).toFixed(
                                          3
                                        )}{" "}
                                        |{" "}
                                        {Number(apiDataTemp.longitude).toFixed(
                                          3
                                        )}
                                      </span>
                                    ) : (
                                      "-"
                                    )}
                                  </span>
                                  <ReactTooltip
                                    id={"crashLatLng" + i}
                                    place="top"
                                    content={
                                      apiDataTemp.latitude +
                                      " " +
                                      "|" +
                                      " " +
                                      apiDataTemp.longitude
                                    }
                                    style={{
                                      backgroundColor: "black",
                                      whiteSpace: "nowrap",
                                    }}
                                  />
                                </td>
                              </tr>
                            ))
                          ) : dataLength === null ? (
                            <tr>
                              {" "}
                              <td colSpan="13">
                                <div
                                  style={{
                                    color: "green",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100px",
                                    width: "100%",
                                  }}
                                >
                                  {displayText.FETCHING_DATA}
                                </div>
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              {" "}
                              <td colSpan="13">
                                <div
                                  style={{
                                    color: "black",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100px",
                                    width: "100%",
                                  }}
                                >
                                  {displayText.NO_RECORDS_FOUND}
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
           {dataLength > 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <button
                type="button"
                className="btn btn-outline-danger btn-sm"
                onClick={previous}
                style={{
                  width: "45px",
                  visibility: prevShow ? "visible" : "hidden",
                }}
              >
                Prev
              </button>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="pagination">
                    <a>{pageNumber}</a>
                  </div>
                  <p
                    style={{
                      marginTop: "6px",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  >
                    of
                  </p>
                  <div className="pagination ">
                    <a>{totalPageCount}</a>
                  </div>
                </div>
              <button
                className="btn btn-outline-success btn-sm"
                onClick={next}
                style={{
                  width: "45px",
                  visibility: nextShow ? "visible" : "hidden",
                }}
              >
                Next
              </button>
            </div>
            ) : null}
          </Col>
        </Row>
        <DateTimePickerPopup
          modalOpen={modalIsOpen}
          modalClose={setIsOpen}
          setStartDateTime={setStartDateTime}
          setEndDateTime={setEndDateTime}
          setFilter={setFilter}
          filter={filter}
        />
      </Container>
      <ToastContainer style={{ width: "auto" }} />
    </div>
  );
}
