import React, { Component, useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { json, useLocation, useNavigate } from "react-router-dom";
import ValueFormat from "../../../util/ValueFormat.js";
import AnalyticalController from "../../../controller/Analytical/AnalyticalController.js";
import { Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useSelector, useDispatch } from "react-redux";
import { allOverSpeedData } from "../../../store/actions.js";
import Line from '../../AllCharts/echart/linechart.js'

import "bootstrap/dist/css/bootstrap.min.css";
import { alertDataType } from "../../../constant/ModelConstant.js";
import riskAlertSubCategory from "../../../common/data/riskAlertCategories.js";
import Loader from "../../../components/Common/loader.js";
import { displayText } from "../../../constant/MessageConstant.js";
import Converter from "../../../components/Common/EncodeDecode.js";
const CategoryLine = ({ otherRiskData, categoryLineChart, overSpeedTableData, mobileUseTableData, mobileScreenTableData }) => {
  const [zoomBarShow, setZoomBarShow] = useState("enabled");
  const [showPointer, setShowPointer] = useState("enabled");

  useEffect(() => {
    const updatedParentObject = {
      ...lineOption,
      dataZoom: lineOption.dataZoom.map((obj, i) => {
        // console.log("linefil"+lineOption)
        if (i === 1) {
          return { ...obj, show: zoomBarShow == "enabled" };
        }
        return obj;
      }),
      series: { ...lineOption.series, showSymbol: showPointer == "enabled" },
    };
    setLineOption(updatedParentObject);
  }, [zoomBarShow, showPointer]);

  const getOption = {
    color: ["#cc3333"],
    title: {
      left: "1%",
    },

    tooltip: {
      show: true,
      trigger: "item",
      formatter: function (parms) {
        const customToolTip =
          '<div style="padding:10px,textAlign:left;> <div class="mb-2">Speed : ' +
          parms.data.value +
          " kmph" +
          "</div>" +
          '<div class="mb-0">Date/Time : ' +
          parms.data.startDate +
          "</div>" +
          "<div> Distance : " +
          parms.data.kiloMeter +
          " kms" +
          "</div> </div>";
        return customToolTip;
      },
    },

    grid: {
      top: "6%",
      bottom: "35%",
      left: 35,
      right: 45,
    },

    xAxis: {
      data: [0],
      axisLabel: {
        rotate: 90,
      },
      axisTick: {
        alignWithLabel: true,
      },
      type: "category",
      name: "All Data",
      nameLocation: "bottom",
      nameGap: 0,
      nameTextStyle: {
        align: "center",
        verticalAlign: "top",
        padding: [128, 0, 0, 0],
      },
    },

    yAxis: {
      name: "        Speed (Kms)",//don't remove the space (-_-)
      type: "value",
      min: 0,
      max: 150,
      axisLabel: {
        align: "center",
        fontSize: "12px",
        padding: [0, 8, 0, 0],
      },
      nameTextStyle: {
        padding: [60, 0, 0, 0]
      },
    },

    dataZoom: [
      {
        type: "inside",
        xAxisIndex: [0, 1],
        start: 0,
        end: 100,
        height: 10,
        fillerColor: "rgba(46, 50, 56, 0.05)",
        borderColor: "rgba(46, 50, 56, 0)",
        textStyle: {
          color: "rgba(28, 31, 35, 0.6)",
          fontFamily: "Inter",
        },
        dataBackground: {
          areaStyle: {
            color: "rgba(46, 50, 56, 0.09)",
          },
        },
        handleIcon:
          "path://M15,30C15,21.71270751953125,21.71270751953125,15,30,15C38.28729248046875,15,45,21.71270751953125,45,30C45,38.28729248046875,38.28729248046875,45,30,45C21.71270751953125,45,15,38.28729248046875,15,30Z",
      },

      {
        show: true,
        xAxisIndex: [0, 1],
        type: "slider",
        top: "90%",
        left: "15%",
        right: "15%",
        start: 0,
        end: 100,
        height: 10,
        fillerColor: "rgba(46, 50, 56, 0.05)",
        borderColor: "rgba(46, 50, 56, 0)",
        textStyle: {
          color: "rgba(28, 31, 35, 0.6)",
          fontFamily: "Inter",
        },

        dataBackground: {
          areaStyle: {
            color: "rgba(46, 50, 56, 0.09)",
          },
        },
        handleIcon:
          "path://M15,30C15,21.71270751953125,21.71270751953125,15,30,15C38.28729248046875,15,45,21.71270751953125,45,30C45,38.28729248046875,38.28729248046875,45,30,45C21.71270751953125,45,15,38.28729248046875,15,30Z",
      },
    ],

    series: {
      animationDuration: 4000,
      showSymbol: false,
      smooth: false,
      lineStyle: { normal: { width: 1.4 } },
      animationEasing: "easein",
      name: "Speed",
      type: "line",
      data: [0],
      data2: [0],
      markLine: {
        silent: false,
        lineStyle: {
          color: "#d3d3d3",
        },
      },
    },
  };
  const history = useNavigate();

  // const location = useLocation();
  const [lineOption, setLineOption] = useState(getOption);
  const [indexStart, setIndexStart] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [dataLength, setDataLength] = useState(null);
  const [locationParams, setLocationParams] = useState({
    rideId: "",
    userId: "",
    divisionId: "",
  });
  const dispatch = useDispatch();
  const [filterValue, setFilterValue] = useState("");
  const [dropdownOpen, setOpen] = React.useState(false);
  const [overSpeedDataList, setOverSpeedDataList] = useState([]);
  const [speedData, setSpeedData] = useState(false);
  const [mobileUseDataList, setMobileUseDataList] = useState([]);

  const [mobileScreenDataList, setMobileScreenDataList] = useState([]);

  const [cardHeaders, setCardHeaders] = useState(true);
  const pageSize = 100;

  const [filterChart, setFilterCharts] = useState({
    alertData: "",
    manualData: "",
    stressStrainData: "",
  });
  const [alertData, setAlertData] = useState("");
  const [manualData, setManualData] = useState("");
  const [stressStrainData, setStressStrainDataData] = useState("");
  const [overallData, setOverAllData] = useState(otherRiskData);
  const [overSpeedData, setOverSpeedData] = useState(0);
  const [mobileUse, setMobileUse] = useState(0);
  const [mobileScreen, setMobileScreen] = useState(0);
  const [noOfRecordsPerPage, setNoOfRecordsPerPage] = useState(25);
  const [showLoader, setShowLoader] = useState(0);
  const [rideDataDisplay, setRideDataDisplay] = useState(false);
  const [callUseData, setCallUseData] = useState(false);
  const [screenUseData, setScreenUseData] = useState(false);

  useEffect(() => {
    // console.log("loader", showLoader);
  }, [showLoader]);

  useEffect(() => {
    setOverAllData(otherRiskData);
  }, [otherRiskData]);
  const previous = () => {
    setIndexStart(indexStart - pageSize);
    setPageNumber(pageNumber - 1);
  };

  const next = () => {
    setIndexStart(indexStart + pageSize);
    setPageNumber(pageNumber + 1);
  };

  // useEffect(() => {
  //   // if (location.state != null) {
  //   //   const { rideId, userId, divisionId } = location.state;
  //   //   setLocationParams((prev) => {
  //   //     return {
  //   //       ...prev,
  //   //       rideId: rideId,
  //   //       userId: userId,
  //   //       divisionId: divisionId,
  //   //     };
  //   //   });
  //   // } else {
  //   //   // history("/myRides");
  //   // }
  //   const storedData = localStorage.getItem('rideDetails');
  //   setLocationParams((prev) => {
  //         return {
  //           ...prev,
  //           rideId: JSON.parse(storedData).rideId,
  //           userId: JSON.parse(storedData).userId,
  //           divisionId: JSON.parse(storedData).divisionId,
  //         };
  //       })
  // }, []);

  const Location = useLocation();
  const [rideCategory, setRideCategory] = useState(""); // ridecategory added

  useEffect(() => {

    const queryParams = new URLSearchParams(Location.search);

    const UID = queryParams.get('userId');
    const DID = queryParams.get('divisionId');
    const RID = queryParams.get('rideId');
    const RIDE_CATEGORY = queryParams.get("rideCategory"); // ridecategory added

    const decodedUID = Converter.decrypt(decodeURIComponent(UID));
    const decodedDID = Converter.decrypt(decodeURIComponent(DID));
    const decodedRID = Converter.decrypt(decodeURIComponent(RID));
    const decodedRIDE_CATEGORY = Converter.decrypt(decodeURIComponent(RIDE_CATEGORY));
    setRideCategory(decodedRIDE_CATEGORY); // ridecategory added

    setLocationParams((prev) => {
      return {
        ...prev,
        rideId: decodedRID,
        userId: decodedUID,
        divisionId: decodedDID,
      };
    })

  }, [Location]);

  let getAlertSummary = async () => {
    setShowLoader((current) => current + 1);
    try {
      var alertsType = "";
      var result = await AnalyticalController.rideCompleteSummary(
        indexStart,
        locationParams.rideId,
        locationParams.divisionId,
        locationParams.userId,
        filterChart,
        noOfRecordsPerPage,
        rideCategory // ridecategory added
      );
      var resultJSON = JSON.parse(result);
      setShowLoader((current) => current - 1);
      var status = resultJSON.status;
      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        // console.log("summaryData: ", resultJSONData);
        var alertSummeryXAxis = [];
        var alertSummeryData = [];
        var tripRiskLatLongList = [];
        var overSpeedData = [];
        var mobileScreenData = [];
        var mobileUseData = [];
        var tripRiskSigList = [];
        setDataLength(resultJSONData.length);
        resultJSONData.map((value, i) => {
          var deviceDataField = ValueFormat.parseAsObject(value.deviceDataField);
          var latitudeTemp = ValueFormat.parseAsString(deviceDataField.latitude);
          var longitudeTemp = ValueFormat.parseAsString(
            deviceDataField.longitude
          );

          var category =
            value.category != "" && value.category != null ? value.category : "";

          alertsType =
            value.category != "" && value.category != null
              ? ValueFormat.capitalizeFirstLetterAndFormat(value.category)
              : "";
          var subCategory =
            value.subCategory != "" && value.subCategory != null
              ? ValueFormat.capitalizeFirstLetterAndFormat(value.subCategory)
              : "";
          var kiloMeter =
            value.kilo_meter != "" && value.kilo_meter != null
              ? value.kilo_meter
              : "";

          var subCategoryLowData = value.subCategoryLevel;

          var startDate = ValueFormat.parseAsString(value.createdAtToTimeZone);

          var deviceDataField = value.deviceDataField;
          var speed =
            value.speed != "" && value.speed != null ? value.speed : "0";

          var speedLimit = ValueFormat.parseAsFloat(value.speedLimit, 2);
          var latLongTempMap = latitudeTemp + "," + longitudeTemp;
          if (subCategory.length > 0) {
            tripRiskSigList.push(subCategory);
          } else {
            tripRiskSigList.push(category);
          }
          if (i == 0) {
          } else if (i == resultJSONData.length - 1) {
          } else {
            tripRiskLatLongList.push(latLongTempMap);
          }

          var xAxisLabel = "-";
          if (category == "START_DATA" || category == "END_DATA") {
            xAxisLabel = alertsType;
          } else if (
            category == "ALERT_DATA" ||
            category == "STRESS_STRAIN_DATA" ||
            category == "MANUAL_DATA"
          ) {
            xAxisLabel = subCategory;
          } else if (category == "DISTANCE_DATA") {
            xAxisLabel = kiloMeter + " Km";
          }


          if (subCategoryLowData != "LOW") {
            alertSummeryXAxis.push(xAxisLabel);
            const result = {
              value: speed,
              kiloMeter: kiloMeter,
              startDate: startDate,
            };
            //alertSummeryData.push(risk);
            alertSummeryData.push(result);
          }
        });

        // setSpeedData(Object.values(overSpeedData).some((value) => value !== 0));
        setRideDataDisplay(
          Object.values(alertSummeryData).some((value) => value !== 0)
        );
        setLineOption((updateData) => {
          const updatedParentObject = {
            ...updateData,
            xAxis: { ...updateData.xAxis, data: alertSummeryXAxis },
            series: { ...updateData.series, data: alertSummeryData },
          };
          return {
            ...updateData,
            ...updatedParentObject,
          };
        });
        // console.log("overSpeedData",overSpeedData)
        // dispatch(allRideData(overSpeedData))
        // setOverSpeedDataList(overSpeedData);
        // setMobileUseDataList(mobileUseData);
        // setMobileScreenDataList(mobileScreenData);
        // overSpeedTableData(overSpeedData)


        // setCallUseData(Object.values(mobileUseData).some((value) => value !== 0));
        // setScreenUseData(
        //   Object.values(mobileScreenData).some((value) => value !== 0)
        // );
      } else {
        var code = resultJSON.error.code;
        if (code == "E007") {
          // setSpeedData("failed")
          // setCallUseData("failed")
          // setScreenUseData("failed")
        }
      }
    } catch (e) {
      // console.log("manualData Heremap Catch err" + e);
    }
  };

  const opMcuMsuList = async () => {
    setShowLoader((current) => current + 1);
    try {
      var response = await AnalyticalController.overSpeedMCUMSUListAPI(locationParams.rideId, locationParams.divisionId, locationParams.userId,rideCategory);
      // console.log("response", response);
      var responseJSON = JSON.parse(response);
      var status = responseJSON.status;
      if (status == "SUCCESS") {
        var responseJSONData = responseJSON.data;
        var overSpeedData = [];
        var mobileScreenData = [];
        var mobileUseData = [];
        responseJSONData.map((value, i) => {

          var subCategory = value.subCategory != "" && value.subCategory != null ? ValueFormat.capitalizeFirstLetterAndFormat(value.subCategory) : "";
          var kiloMeter = value.kilo_meter != "" && value.kilo_meter != null ? value.kilo_meter : "";
          var startDate = ValueFormat.parseAsString(value.createdAtToTimeZone);
          var speed = value.speed != "" && value.speed != null ? value.speed : "0";
          var speedLimit = ValueFormat.parseAsFloat(value.speedLimit, 2);

          var overSpeedDataResult = {
            date: startDate,
            distance: kiloMeter,
            speedLimit: speedLimit,
            speed: speed,
          };

          var mobileScreenDataResult = {
            date: startDate,
            distance: kiloMeter,
            speed: speed,
          };

          if (subCategory == "Over speed") {
            setOverSpeedData((prev) => prev + 1);
            overSpeedData.push(overSpeedDataResult);
          } else if (subCategory == "Mobile screen") {
            setMobileScreen((prev) => prev + 1);
            mobileScreenData.push(mobileScreenDataResult);
          } else if (subCategory == "Mobile use") {
            setMobileUse((prev) => prev + 1);
            mobileUseData.push(mobileScreenDataResult);
          }
        });

        setOverSpeedDataList(overSpeedData);
        setMobileUseDataList(mobileUseData);
        setMobileScreenDataList(mobileScreenData);

        overSpeedTableData(overSpeedData);
        mobileUseTableData(mobileUseData);
        mobileScreenTableData(mobileScreenData);

        setSpeedData(Object.values(overSpeedData).some((value) => value !== 0));
        setCallUseData(Object.values(mobileUseData).some((value) => value !== 0));
        setScreenUseData(Object.values(mobileScreenData).some((value) => value !== 0));

      }
      else {
        var code = responseJSON.error.code;
        if (code == "E007") {
          setSpeedData("failed")
          setCallUseData("failed")
          setScreenUseData("failed")
        }
        overSpeedTableData(overSpeedData);
        mobileUseTableData(mobileUseData);
        mobileScreenTableData(mobileScreenData);
      }
    } catch (e) {
      console.log("error: ", e);
    } finally {
      setShowLoader((current) => current - 1);
    }
  }

  useEffect(() => {
    opMcuMsuList();
  }, [locationParams.rideId, locationParams.divisionId, locationParams.userId])

  useEffect(() => {
    getAlertSummary();
  }, [locationParams, indexStart, filterChart]);

  const [chartWidth, setChartWidth] = useState("98vw");
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 460) {
        setChartWidth("88vw");
      } else if (window.innerWidth >= 461 && window.innerWidth <= 991) {
        setChartWidth("96vw");
      } else {
        setChartWidth("48vw");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);  


  return (
    <React.Fragment>
      {showLoader > 0 && <Loader />}
      <Row >
        <div className="d-lg-flex">
          <Col className="col-lg-6 me-lg-2">
            <Card>
              <CardHeader className="cardHeader cardHeaderHeightInRideDetails">
                <div className="m-0 p-0 d-flex justify-content-between ">
                  <Col xs={9} >
                    <h6 className="sub_heading mb-0 mt-1">
                      Ride Data{" "}
                      <span data-tooltip-id="cw" style={{ fontSize: "11px" }}>
                        (Speed / Risk / Mobile use / Mobile screen / Overspeed
                        / Cor / Brak / Acc)
                      </span>
                      <ReactTooltip
                        id="cw"
                        place="top"
                        width={10}
                        content={<span>(Speed, Risk, Mobile call use, Mobile screen use,<br /> Overspeed, Cornering, Braking, Acceleration)</span>}
                        style={{ backgroundColor: "black", zIndex: 1 }}
                      />
                    </h6>
                  </Col>
                  <Col xs={3}>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                      <div>
                        <button
                          // data-tooltip-id="all"
                          type="button"
                          className={
                            filterValue == ""
                              ? "btn btn-secondary btn-sm"
                              : "btn btn-soft-secondary btn-sm"
                          }
                          style={{ marginBottom: "3px" }}
                          onClick={() => {
                            setFilterCharts((prev) => {
                              return {
                                alertData: "",
                                manualData: "",
                                stressStrainData: "",
                              };
                            });
                            setFilterValue("CURRENT_DAY");
                            setCardHeaders(false);
                          }}
                        >
                          All
                          {/* <ReactTooltip
                            id="all"
                            place="top"
                            content="All"
                            style={{ backgroundColor: "black" }}
                          /> */}
                        </button>{" "}
                        <button
                          // data-tooltip-id="dis"
                          type="button"
                          className={
                            filterValue == ""
                              ? "btn btn-secondary btn-sm"
                              : "btn btn-soft-secondary btn-sm"
                          }
                          style={{ marginBottom: "3px" }}
                          onClick={() => {
                            setFilterValue("DISTANCE");
                            setCardHeaders(!cardHeaders);
                          }}
                        >
                          Filter
                          {/* <ReactTooltip
                            id="dis"
                            place="top"
                            content="By Distance"
                            style={{ backgroundColor: "black" }}
                          /> */}
                        </button>{" "}
                      </div>
                      {/* <div>
                <label className="me-3">No of records per page</label>
                <select
                  className="btn btn-soft-secondary btn-sm"
                  name="cars"
                  id="cars"
                  onChange={(e) => setNoOfRecordsPerPage(e.target.value)}
                >
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div> */}
                    </div>
                  </Col>
                </div>
                {cardHeaders && (
                  <Row className="mt-2 mb-2">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                      <Col className="pe-1">
                        <div>
                          <span style={{ fontSize: "13px", fontWeight: 500 }}>
                            Risk
                          </span>
                          <div className="mt-1">
                            <select
                              style={{ width: "84px", textOverflow: "ellipsis" }}
                              className="btn btn-soft-secondary btn-sm"
                              name="cars"
                              id="cars"
                              onChange={(e) =>
                                setFilterCharts((prev) => {
                                  return { ...prev, alertData: e.target.value };
                                })
                              }
                            >
                              <option value="" style={{ textAlign: "left" }}>
                                All
                              </option>
                              {riskAlertSubCategory.map((value, index) =>
                                value.category == "ALERT_DATA" ? (
                                  <option
                                    style={{ textAlign: "left" }}
                                    key={index}
                                    value={
                                      value.category == "ALERT_DATA"
                                        ? value.subCategory
                                        : null
                                    }
                                  >
                                    {value.category == "ALERT_DATA"
                                      ? ValueFormat.capitalizeFirstLetterAndFormat(
                                        value.subCategory == ""
                                          ? "All"
                                          : value.subCategory
                                      )
                                      : null}
                                  </option>
                                ) : null
                              )}
                            </select>
                          </div>
                        </div>
                      </Col>
                      <Col className="pe-1">
                        <div>
                          <span style={{ fontSize: "13px", fontWeight: 500 }}>
                            Violation
                          </span>
                          <div className="mt-1">
                            <select style={{ width: "84px", textOverflow: "ellipsis" }}
                              className="btn btn-soft-secondary btn-sm"
                              name="cars"
                              id="cars"
                              onChange={(e) =>
                                setFilterCharts((prev) => {
                                  return { ...prev, manualData: e.target.value };
                                })
                              }
                            >
                              <option value="" style={{ textAlign: "left" }}>
                                All
                              </option>
                              <optgroup style={{ textAlign: "left" }} label="By Mobile">
                                {riskAlertSubCategory.map((value, index) =>
                                  value.category == "MANUAL_DATA" &&
                                    value.subCategory != "OVER_SPEED" ? (
                                    <option
                                      key={index}
                                      style={{ textAlign: "left" }}
                                      value={
                                        value.category == "MANUAL_DATA"
                                          ? value.subCategory
                                          : null
                                      }
                                    >
                                      {value.category == "MANUAL_DATA"
                                        ? ValueFormat.capitalizeFirstLetterAndFormat(
                                          value.subCategory == ""
                                            ? "All"
                                            : value.subCategory
                                        )
                                        : null}
                                    </option>
                                  ) : null
                                )}
                              </optgroup>

                              <optgroup label="By Speed" style={{ textAlign: "left" }}>
                                <option
                                  style={{ textAlign: "left" }}
                                  value="OVER_SPEED"
                                >
                                  Overspeed
                                </option>
                              </optgroup>
                            </select>
                          </div>
                        </div>
                      </Col>
                      <Col className="pe-1">
                        <div>
                          <span style={{ fontSize: "13px", fontWeight: 500, }}>
                            Severe action
                          </span>
                          <div className="mt-1">
                            <select
                              style={{ width: "84px", textOverflow: "ellipsis" }}
                              className="btn btn-soft-secondary btn-sm"
                              name="cars"
                              id="cars"
                              onChange={(e) =>
                                setFilterCharts((prev) => {
                                  return { ...prev, stressStrainData: e.target.value };
                                })
                              }
                            >
                              <option value="" style={{ textAlign: "left" }}>
                                All
                              </option>
                              {riskAlertSubCategory.map((value, index) =>
                                value.category == "STRESS_STRAIN_DATA" ? (
                                  <option
                                    style={{ textAlign: "left" }}
                                    key={index}
                                    value={
                                      value.category == "STRESS_STRAIN_DATA"
                                        ? value.subCategory
                                        : null
                                    }
                                  >
                                    {value.category == "STRESS_STRAIN_DATA"
                                      ? ValueFormat.capitalizeFirstLetterAndFormat(
                                        value.subCategory == "" ? "All" : value.label
                                      )
                                      : null}
                                  </option>
                                ) : null
                              )}
                            </select>
                          </div>
                        </div>
                      </Col>
                      <Col className="pe-1">
                        <div>
                          <span style={{ fontSize: "13px", fontWeight: 500 }}>
                            X - axis scroll
                          </span>
                          <div className="mt-1">
                            <select
                              style={{ width: "84px", textOverflow: "ellipsis" }}
                              className="btn btn-soft-secondary btn-sm"
                              name="cars"
                              id="cars"
                              value={zoomBarShow}
                              onChange={(e) => {
                                setZoomBarShow(e.target.value);
                              }}
                            >
                              <option value={"disabled"} style={{ textAlign: "left" }}>
                                Disabled
                              </option>
                              <option value={"enabled"} style={{ textAlign: "left" }}>
                                Enabled
                              </option>
                            </select>
                          </div>
                        </div>
                      </Col>
                      <Col className="pe-1">
                        <div>
                          <span style={{ fontSize: "13px", fontWeight: 500, whiteSpace: 'nowrap' }}>
                            Show pointer
                          </span>
                          <div className="mt-1">
                            <select
                              style={{ width: "84px", textOverflow: "ellipsis" }}
                              className="btn btn-soft-secondary btn-sm"
                              name="cars"
                              id="cars"
                              value={showPointer}
                              onChange={(e) => {
                                setShowPointer(e.target.value);
                              }}
                            >
                              <option value={"disabled"} style={{ textAlign: "left" }}>
                                Disabled
                              </option>
                              <option value={"enabled"} style={{ textAlign: "left" }}>
                                Enabled
                              </option>
                            </select>
                          </div>
                        </div>
                      </Col>
                    </div>
                  </Row>
                )}
              </CardHeader>
              {rideDataDisplay === true ? (
                <CardBody style={{ height: '615px' }}>
                  <ReactEcharts
                    style={{ height: "595px", width: chartWidth }}
                    option={lineOption}
                  />
                  {/* <div className="d-flex justify-content-between">
              <button
                style={{
                  visibility: indexStart === 0 ? "hidden" : "visible",
                  width: "45px",
                }}
                type="button"
                className="btn btn-outline-danger btn-sm"
                onClick={previous}
              >
                Prev
              </button>
              <div className="pagination">
                <a href="#">{pageNumber}</a>
              </div>
              <button
                type="button"
                className="btn btn-outline-success btn-sm"
                onClick={next}
                style={{
                  visibility:
                    dataLength === 0 || dataLength < 100 ? "hidden" : "visible",
                  width: "45px",
                  float: "right",
                }}
              >
                Next
              </button>
            </div> */}
                </CardBody>
              ) : rideDataDisplay === false ? (
                <CardBody>
                  <p className="data_not_available">
                    {displayText.DATA_NOT_AVAILABLE}
                  </p>
                  <ReactEcharts
                    style={{ height: "556.3px", width: chartWidth }}
                    option={lineOption}
                  />
                  {/* <div className="d-flex justify-content-between">
                    <button
                      style={{
                        visibility: indexStart === 0 ? "hidden" : "visible",
                        width: "45px",
                      }}
                      type="button"
                      className="btn btn-outline-danger btn-sm"
                      onClick={previous}
                    >
                      Prev
                    </button>
                    <div className="pagination">
                      <a href="#">{pageNumber}</a>
                    </div>
                    <button
                      type="button"
                      className="btn btn-outline-success btn-sm"
                      onClick={next}
                      style={{
                        visibility:
                          dataLength === 0 || dataLength < 100 ? "hidden" : "visible",
                        width: "45px",
                        float: "right",
                      }}
                    >
                      Next
                    </button>
                  </div> */}
                </CardBody>
              ) : (
                <CardBody>
                  <p className="fetch_data">{displayText.FETCHING_DATA}</p>
                  <ReactEcharts
                    style={{ height: "556.3px", chartWidth }}
                    option={lineOption}
                  />
                  {/* <div className="d-flex justify-content-between">
                    <button
                      style={{
                        visibility: indexStart === 0 ? "hidden" : "visible",
                        width: "45px",
                      }}
                      type="button"
                      className="btn btn-outline-danger btn-sm"
                      onClick={previous}
                    >
                      Prev
                    </button>
                    <div className="pagination">
                      <a href="#">{pageNumber}</a>
                    </div>
                    <button
                      type="button"
                      className="btn btn-outline-success btn-sm"
                      onClick={next}
                      style={{
                        visibility:
                          dataLength === 0 || dataLength < 100 ? "hidden" : "visible",
                        width: "45px",
                        float: "right",
                      }}
                    >
                      Next
                    </button>
                  </div> */}
                </CardBody>
              )}
            </Card>
          </Col>

          <Col className="col-lg-6 me-lg-2">
            <Line />
          </Col>
        </div>
      </Row>
      <Card>
        <Card>
          <CardHeader className="cardHeader">
            <h4 className="sub_heading">Other Alerts</h4>
          </CardHeader>
          <CardBody>
            <Row>
              {categoryLineChart === true ? (
                ""
              ) : categoryLineChart === false ? (<p className="data_not_available">{displayText.DATA_NOT_AVAILABLE}</p>) : categoryLineChart === "failed" ? (
                <p className="data_not_available">{displayText.ER_007}</p>
              ) : (
                <p className="fetch_data">{displayText.FETCHING_DATA}</p>
              )}
              {(overallData || []).map((apiData) => (
                <Col xl={4} lg={4} md={4} key={apiData.name}>
                  <Row>
                    <Col
                      xl={6}
                      lg={12}
                      md={12}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={require("../../../assets/images/" + apiData.img)}
                        alt={apiData.name}
                        style={{ width: "70%", height: "70%" }}
                      />
                    </Col>
                    <Col xl={6} lg={12} md={12}>
                      <div className="table-responsive">
                        <p
                          style={{
                            fontSize: "16px",
                            fontWeight: 600,
                            textAlign: "center",
                            alignItems: "center",
                          }}
                        >
                          {apiData.name}
                        </p>

                        <Table
                          id="riskstyles"
                          className="table table-stripe mb-0 text-center"
                        >
                          <tbody>
                            <tr className="body_text2">
                              <td style={{ textAlign: "start" }}>Count</td>
                              <td style={{ textAlign: "end" }}>
                                {apiData.count}
                              </td>
                            </tr>
                            <tr className="body_text2">
                              <td style={{ textAlign: "start" }}>Minutes</td>
                              <td style={{ textAlign: "end" }}>
                                {apiData.duration}
                              </td>
                            </tr>
                            <tr className="body_text2">
                              <td style={{ textAlign: "start" }}>Kilometer</td>
                              <td style={{ textAlign: "end" }}>
                                {apiData.kilometer}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </CardBody>
        </Card>
        <Row>
          <Col sm={6} lg={4}>
            <Card>
              <CardHeader className="cardHeader">
                <h4 className="sub_heading">Overspeed</h4>
              </CardHeader>
              {speedData === true ? (
                <CardBody
                  style={{ height: 300, overflow: "auto", paddingLeft: "8px", paddingTop: "0px", paddingRight: '0px', paddingBottom: '0px' }}
                >
                  <div>
                    <Table className="table mb-0">
                      <thead
                        style={{
                          zIndex: 1,
                          position: "sticky",
                          top: "0",
                          background: "white",
                        }}
                      >
                        <tr>
                          <th
                            style={{ fontSize: "13px", fontWeight: 600, verticalAlign: "middle" }}
                          >
                            Date / Time
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, verticalAlign: "middle", }}>
                            Distance
                            <span style={{ fontSize: "10px", fontWeight: 400 }}>
                              &nbsp;(km)
                            </span>
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, verticalAlign: "middle" }}>
                            Speed Limit
                            <span style={{ fontSize: "10px", fontWeight: 400 }}>
                              &nbsp;(km/h)
                            </span>
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, verticalAlign: "middle" }}>
                            Speed
                            <span style={{ fontSize: "10px", fontWeight: 400 }}>
                              &nbsp;(km/h)
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {(overSpeedDataList || []).map((apiData, key) => (
                          <tr key={key}>
                            <td style={{ fontSize: "11px" }}>{apiData.date}</td>
                            <td style={{ fontSize: "11px" }}>
                              {apiData.distance}
                            </td>
                            <td style={{ fontSize: "11px" }}>
                              {apiData.speedLimit}
                            </td>
                            <td style={{ fontSize: "11px" }}>
                              {apiData.speed}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              ) : speedData === false ? (
                <CardBody
                  style={{
                    height: 300,
                    overflow: "auto",
                    paddingLeft: "8px", paddingTop: "0px", paddingRight: '0px', paddingBottom: '0px'
                  }}
                >
                  <div>
                    <Table className="table mb-0">
                      <thead
                        style={{
                          zIndex: 1,
                          position: "sticky",
                          top: "0",
                          background: "white",
                        }}
                      >
                        <tr>
                          <th
                            style={{ fontSize: "13px", fontWeight: 600, verticalAlign: "top" }}
                          >
                            Date / Time
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, }}>
                            Distance
                            <span style={{ fontSize: "10px", fontWeight: 400 }}>
                              &nbsp;(km)
                            </span>
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, }}>
                            Speed Limit
                            <span style={{ fontSize: "10px", fontWeight: 400 }}>
                              &nbsp;(km/h)
                            </span>
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, }}>
                            Speed
                            <span style={{ fontSize: "10px", fontWeight: 400 }}>
                              &nbsp;(km/h)
                            </span>
                          </th>
                        </tr>
                      </thead>
                    </Table>
                    <p
                      className="data_not_available"
                      style={{
                        height: 200,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {displayText.DATA_NOT_AVAILABLE}
                    </p>
                  </div>
                </CardBody>
              ) : speedData === "failed" ? (
                <CardBody
                  style={{ height: 300, overflow: "auto", paddingLeft: "8px", paddingTop: "0px", paddingRight: '0px', paddingBottom: '0px' }}
                >
                  <div>
                    <Table className="table mb-0">
                      <thead
                        style={{
                          zIndex: 1,
                          position: "sticky",
                          top: "0",
                          background: "white",
                        }}
                      >
                        <tr>
                          <th
                            style={{ fontSize: "13px", fontWeight: 600, verticalAlign: "top" }}
                          >
                            Date / Time
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, }}>
                            Distance
                            <span style={{ fontSize: "10px", fontWeight: 400 }}>
                              &nbsp;(km)
                            </span>
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, }}>
                            Speed Limit
                            <span style={{ fontSize: "10px", fontWeight: 400 }}>
                              &nbsp;(km/h)
                            </span>
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, }}>
                            Speed
                            <span style={{ fontSize: "10px", fontWeight: 400 }}>
                              &nbsp;(km/h)
                            </span>
                          </th>
                        </tr>
                      </thead>
                    </Table>
                    <p
                      className="fetch_data"
                      style={{
                        height: 200,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {displayText.ER_007}
                    </p>
                  </div>
                </CardBody>
              ) : (
                <CardBody
                  style={{ height: 300, overflow: "auto", paddingLeft: "8px", paddingTop: "0px", paddingRight: '0px', paddingBottom: '0px' }}
                >
                  <div>
                    <Table className="table mb-0">
                      <thead
                        style={{
                          zIndex: 1,
                          position: "sticky",
                          top: "0",
                          background: "white",
                        }}
                      >
                        <tr>
                          <th
                            style={{ fontSize: "13px", fontWeight: 600, verticalAlign: "top" }}
                          >
                            Date / Time
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, }}>
                            Distance
                            <span style={{ fontSize: "10px", fontWeight: 400 }}>
                              &nbsp;(km)
                            </span>
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, }}>
                            Speed Limit
                            <span style={{ fontSize: "10px", fontWeight: 400 }}>
                              &nbsp;(km/h)
                            </span>
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, }}>
                            Speed
                            <span style={{ fontSize: "10px", fontWeight: 400 }}>
                              &nbsp;(km/h)
                            </span>
                          </th>
                        </tr>
                      </thead>
                    </Table>
                    <p
                      className="fetch_data"
                      style={{
                        height: 200,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {displayText.FETCHING_DATA}
                    </p>
                  </div>
                </CardBody>
              )}
            </Card>
          </Col>
          <Col sm={6} lg={4}>
            <Card>
              <CardHeader className="cardHeader">
                <h4 className="sub_heading">Mobile Call Use</h4>
              </CardHeader>
              {callUseData === true ? (
                <CardBody
                  style={{ height: 300, overflow: "auto", paddingLeft: "8px", paddingTop: "0px", paddingRight: '0px', paddingBottom: '0px' }}
                >
                  <div>
                    <Table className="table mb-0">
                      <thead
                        style={{
                          zIndex: 1,
                          position: "sticky",
                          top: "0",
                          background: "white",
                        }}
                      >
                        <tr>
                          <th
                            style={{ fontSize: "13px", fontWeight: 600, verticalAlign: "top" }}
                          >
                            Date / Time
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, }}>
                            Distance
                            <span style={{ fontSize: "10px", fontWeight: 400 }}>
                              &nbsp;(km)
                            </span>
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, }}>
                            Speed
                            <span style={{ fontSize: "10px", fontWeight: 400 }}>
                              &nbsp;(km/h)
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {(mobileUseDataList || []).map((apiData, key) => (
                          <tr key={key}>
                            <td style={{ fontSize: "11px" }}>{apiData.date}</td>
                            <td style={{ fontSize: "11px" }}>
                              {apiData.distance}
                            </td>
                            <td style={{ fontSize: "11px" }}>
                              {apiData.speed}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              ) : callUseData === false ? (
                <CardBody
                  style={{ height: 300, overflow: "auto", paddingLeft: "8px", paddingTop: "0px", paddingRight: '0px', paddingBottom: '0px' }}
                >
                  <div>
                    <Table className="table mb-0">
                      <thead
                        style={{
                          zIndex: 1,
                          position: "sticky",
                          top: "0",
                          background: "white",
                        }}
                      >
                        <tr>
                          <th
                            style={{ fontSize: "13px", fontWeight: 600, verticalAlign: "top" }}
                          >
                            Date / Time
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, }}>
                            Distance
                            <span style={{ fontSize: "10px", fontWeight: 400 }}>
                              &nbsp;(km)
                            </span>
                          </th>
                          <th style={{ fontSize: "12px" }}>
                            Speed
                            <span style={{ fontSize: "10px", fontWeight: 400 }}>
                              &nbsp;(km/h)
                            </span>
                          </th>
                        </tr>
                      </thead>
                    </Table>
                    <p
                      className="data_not_available"
                      style={{
                        height: 200,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {displayText.DATA_NOT_AVAILABLE}
                    </p>
                  </div>
                </CardBody>
              ) : callUseData === "failed" ? (
                <CardBody
                  style={{ height: 300, overflow: "auto", paddingLeft: "8px", paddingTop: "0px", paddingRight: '0px', paddingBottom: '0px' }}
                >
                  <div>
                    <Table className="table mb-0">
                      <thead
                        style={{
                          zIndex: 1,
                          position: "sticky",
                          top: "0",
                          background: "white",
                        }}
                      >
                        <tr>
                          <th
                            style={{ fontSize: "13px", fontWeight: 600, verticalAlign: "top" }}
                          >
                            Date / Time
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, }}>
                            Distance
                            <span style={{ fontSize: "10px", fontWeight: 400 }}>
                              &nbsp;(km)
                            </span>
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, }}>
                            Speed
                            <span style={{ fontSize: "10px", fontWeight: 400 }}>
                              &nbsp;(km/h)
                            </span>
                          </th>
                        </tr>
                      </thead>
                    </Table>
                    <p
                      className="data_not_available"

                    >
                      {displayText.ER_007}
                    </p>
                  </div>
                </CardBody>
              ) : (
                <CardBody
                  style={{ height: 300, overflow: "auto", paddingLeft: "8px", paddingTop: "0px", paddingRight: '0px', paddingBottom: '0px' }}
                >
                  <div>
                    <Table className="table mb-0">
                      <thead
                        style={{
                          zIndex: 1,
                          position: "sticky",
                          top: "0",
                          background: "white",
                        }}
                      >
                        <tr>
                          <th
                            style={{ fontSize: "13px", fontWeight: 600, verticalAlign: "top" }}
                          >
                            Date / Time
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, }}>
                            Distance
                            <span style={{ fontSize: "10px", fontWeight: 400 }}>
                              &nbsp;(km)
                            </span>
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, }}>
                            Speed
                            <span style={{ fontSize: "10px", fontWeight: 400 }}>
                              &nbsp;(km/h)
                            </span>
                          </th>
                        </tr>
                      </thead>
                    </Table>
                    <p
                      className="fetch_data"
                      style={{
                        height: 200,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {displayText.FETCHING_DATA}
                    </p>
                  </div>
                </CardBody>
              )}
            </Card>
          </Col>
          <Col sm={6} lg={4}>
            <Card>
              <CardHeader className="cardHeader">
                <h4 className="sub_heading">Mobile Screen Use</h4>
              </CardHeader>
              {screenUseData === true ? (
                <CardBody
                  style={{ height: 300, overflow: "auto", paddingLeft: "8px", paddingTop: "0px", paddingRight: '0px', paddingBottom: '0px' }}
                >
                  <div>
                    <Table className="table mb-0">
                      <thead
                        style={{
                          zIndex: 1,
                          position: "sticky",
                          top: "0",
                          background: "white",
                        }}
                      >
                        <tr>
                          <th
                            style={{ fontSize: "13px", fontWeight: 600, verticalAlign: "top" }}
                          >
                            Date / Time
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, }}>
                            Distance
                            <span style={{ fontSize: "10px", fontWeight: 400 }}>
                              &nbsp;(km)
                            </span>
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, }}>
                            Speed
                            <span style={{ fontSize: "10px", fontWeight: 400 }}>
                              &nbsp;(km/h)
                            </span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {(mobileScreenDataList || []).map((apiData, key) => (
                          <tr key={key}>
                            <td style={{ fontSize: "11px" }}>{apiData.date}</td>
                            <td style={{ fontSize: "11px" }}>
                              {apiData.distance}
                            </td>
                            <td style={{ fontSize: "11px" }}>
                              {apiData.speed}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              ) : screenUseData === false ? (
                <CardBody
                  style={{ height: 300, overflow: "auto", paddingLeft: "8px", paddingTop: "0px", paddingRight: '0px', paddingBottom: '0px' }}
                >
                  <div>
                    <Table className="table mb-0">
                      <thead
                        style={{
                          zIndex: 1,
                          position: "sticky",
                          top: "0",
                          background: "white",
                        }}
                      >
                        <tr>
                          <th
                            style={{ fontSize: "13px", fontWeight: 600, verticalAlign: "top" }}
                          >
                            Date / Time
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, }}>
                            Distance
                            <span style={{ fontSize: "10px", fontWeight: 400 }}>
                              &nbsp;(km)
                            </span>
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, }}>
                            Speed
                            <span style={{ fontSize: "10px", fontWeight: 400 }}>
                              &nbsp;(km/h)
                            </span>
                          </th>
                        </tr>
                      </thead>
                    </Table>
                    <p
                      className="data_not_available"
                      style={{
                        height: 200,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {displayText.DATA_NOT_AVAILABLE}
                    </p>
                  </div>
                </CardBody>
              ) : screenUseData === "failed" ? (
                <CardBody
                  style={{ height: 300, overflow: "auto", paddingLeft: "8px", paddingTop: "0px", paddingRight: '0px', paddingBottom: '0px' }}
                >
                  <div>
                    <Table className="table mb-0">
                      <thead
                        style={{
                          zIndex: 1,
                          position: "sticky",
                          top: "0",
                          background: "white",
                        }}
                      >
                        <tr>
                          <th
                            style={{ fontSize: "13px", fontWeight: 600, verticalAlign: "top" }}
                          >
                            Date / Time
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, }}>
                            Distance
                            <span style={{ fontSize: "10px", fontWeight: 400 }}>
                              &nbsp;(km)
                            </span>
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, }}>
                            Speed
                            <span style={{ fontSize: "10px", fontWeight: 400 }}>
                              &nbsp;(km/h)
                            </span>
                          </th>
                        </tr>
                      </thead>
                    </Table>
                    <p
                      className="data_not_available"

                    >
                      {displayText.ER_007}
                    </p>
                  </div>
                </CardBody>
              ) : (
                <CardBody
                  style={{ height: 300, overflow: "auto", paddingLeft: "8px", paddingTop: "0px", paddingRight: '0px', paddingBottom: '0px' }}
                >
                  <div>
                    <Table className="table mb-0">
                      <thead
                        style={{
                          zIndex: 1,
                          position: "sticky",
                          top: "0",
                          background: "white",
                        }}
                      >
                        <tr>
                          <th
                            style={{ fontSize: "13px", fontWeight: 600, verticalAlign: "top" }}
                          >
                            Date / Time
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, }}>
                            Distance
                            <span style={{ fontSize: "10px", fontWeight: 400 }}>
                              &nbsp;(km)
                            </span>
                          </th>
                          <th style={{ fontSize: "13px", fontWeight: 600, }}>
                            Speed
                            <span style={{ fontSize: "10px", fontWeight: 400 }}>
                              &nbsp;(km/h)
                            </span>
                          </th>
                        </tr>
                      </thead>
                    </Table>
                    <p
                      className="fetch_data"
                      style={{
                        height: 200,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {displayText.FETCHING_DATA}
                    </p>
                  </div>
                </CardBody>
              )}
            </Card>
          </Col>

          {/* <Card>
              <div id="mapContainerOtherAlerts" style={{height:300, width:"100%"}}/>
        </Card> */}
        </Row>
        {/* <div className="d-flex justify-content-between">
          <button
            style={{
              visibility: indexStart === 0 ? "hidden" : "visible",
              width: "45px",
            }}
            type="button"
            className="btn btn-outline-danger btn-sm"
            onClick={previous}
          >
            Prev
          </button>
          <div className="pagination">
            <a href="#">{pageNumber}</a>
          </div>
          <button
            type="button"
            className="btn btn-outline-success btn-sm"
            onClick={next}
            style={{
              visibility:
                dataLength === 0 || dataLength < 100 ? "hidden" : "visible",
              width: "45px",
              float: "right",
            }}
          >
            Next
          </button>
        </div> */}
        {/* <br /> */}
      </Card>
    </React.Fragment>
  );
};

export default CategoryLine;
