

import React, { useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ReactApexChart from "react-apexcharts";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table,
  CardHeader,
} from "reactstrap";
// import { Row, Col, Card, CardBody, CardHeader, Container } from "reactstrap"

import CountUp from "react-countup";

/** import Mini Widget data */
import { SAdminDashboardTopRowItems } from "../../common/data/admin/dashboard";
import { SuperAdminDashboardTopRowItems } from "../../common/data/admin/dashboard";

import ValueFormat from "../../util/ValueFormat.js";
import AdminDashboardController from "../../controller/Dashboard/AdminDasahboardController.js";

import AdminDailySummeryController from "../../controller/AdminReport/ADDailyReportController.js";
import AdminWeeklySummeryController from "../../controller/AdminReport/ADWeeklyReportController.js";
import { useSelector } from "react-redux";
import { layoutTypes } from "../../constants/layout";
import giftBox from "../../assets/images/giftbox.png";
import Loader from "../../components/Common/loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastService from "../../components/Common/toastService";
import { Link } from "react-router-dom";
import ExportScreenPdf from "../../components/ExportScreenPdf/index.js";
import { displayText } from "../../constant/MessageConstant"
import { SC_LOCK_STATUS, SC_USER_FIRST_NAME } from "../../constant/StorageConstant";
import { Col as COL } from "react-grid-system";

const options = {
  chart: {
    height: 50,
    type: "line",
    toolbar: { show: false },
  },
  colors: ["#5156be"],
  stroke: {
    curve: "smooth",
    width: 2,
  },
  xaxis: {
    labels: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
  tooltip: {
    fixed: {
      enabled: false,
    },
    x: {
      show: false,
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return "";
        },
      },
    },
    marker: {
      show: false,
    },
  },
};

const classs = [
  {
    name: "table-light",
  },
  {
    name: "table-success",
  },
  {
    name: "table-info",
  },
  {
    name: "table-warning",
  },
  {
    name: "table-danger",
  },
  {
    name: "table-secondary",
  },
];

const SuperAdminAmazonDashboard = () => {
  const {
    layoutType,
    layoutWidth,
    layoutPosition,
    topbarTheme,
    leftSideBarType,
    leftSideBarTheme,
    layoutMode,
  } = useSelector((state) => ({
    layoutType: state.Layout.layoutType,
    layoutWidth: state.Layout.layoutWidth,
    layoutPosition: state.Layout.layoutPosition,
    topbarTheme: state.Layout.topbarTheme,
    leftSideBarType: state.Layout.leftSideBarType,
    leftSideBarTheme: state.Layout.leftSideBarTheme,
    layoutMode: state.Layout.layoutMode,
  }));
  const [ADTopRowItemsTemp, setADTopRowItemsTemp] = useState(
    SAdminDashboardTopRowItems
  );

  const [newSADTopRowItemsTemp, setNewSADTopRowItemsTemp] = useState(
    SuperAdminDashboardTopRowItems
  );

  const [rideRiskData, setRideRiskData] = useState({
    rideRiskXaxis: [],
    rideSeries: [],
    riskAmberSeries: [],
    riskRedSeries: [],
  });
  const [dailyData, setDailyData] = useState(false);
  const [userUsagePer, setUserUsagePer] = useState([]);
  const [activeDriverData, setActiveDriverData] = useState([]);
  const [activeDriverPie, setActiveDriverPie] = useState([]);
  const [currentFilter, setCurrentFilter] = useState("CURRENT_YEAR");
  const [pageNumber, setPageNumber] = useState(1);
  const [totalSafeRidePer, setTotalSafeRidePer] = useState(0);
  const [showLoader, setShowLoader] = useState(0);
  const [indexStart, setIndexStart] = useState(0);
  const [pdUsageData, setPdUsageData] = useState(false);
  const [rcChart, setRcChart] = useState(null);
  const [rccData, setRccData] = useState(null);
  const [userFirstName, setUserFirstName] = useState(
    localStorage.getItem(SC_USER_FIRST_NAME)
  );
  const pageSize = 4;
  const previous = () => {
    setIndexStart(indexStart - pageSize);
    setPageNumber(pageNumber - 1);
  };

  const next = () => {
    setIndexStart(indexStart + pageSize);
    setPageNumber(pageNumber + 1);
  };

  useEffect(() => {
    // console.log("loader", showLoader);
  }, [showLoader]);

  const [dataLength, setDataLength] = useState(null);

  const getTotalValues = async () => {
    setShowLoader((current) => current + 1);
    var result = await AdminDashboardController.superAdminDashboardController();
    setShowLoader((current) => current - 1);
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    // console.log("admintopitem:", JSON.stringify(resultJSON))
    if (status == "SUCCESS") {
      var resultJSONData = resultJSON.data;
      var totalCount = 0;
      var totalRide = 0;
      var safeRide = 0;
      var userCountPercentages = [];
      // console.log("AdminTopRows: " + JSON.stringify(resultJSONData));
      resultJSONData.map((apiResultTemp) => {
        var totalUserCount = parseInt(
          ValueFormat.parseAsInt(apiResultTemp.totalUserCount)
        );
        var activeUserCount = parseInt(
          ValueFormat.parseAsInt(apiResultTemp.activeUserCount)
        );

        var inactiveUserCount = parseInt(
          ValueFormat.parseAsInt(apiResultTemp.inactiveUserCount)
        );
        var unregisteredUserCount = parseInt(
          ValueFormat.parseAsInt(apiResultTemp.unregisteredUserCount)
        );

        var registeredUserCount = parseInt(ValueFormat.parseAsInt(apiResultTemp.registeredUserCount))

        var purchasedLicenseCount = ValueFormat.parseAsObject(apiResultTemp.purchasedLicenseCount);
        var purchasediosLicenseCount = parseInt(ValueFormat.parseAsInt(purchasedLicenseCount.iosLicenseCount));
        var purchasedandroidLicenseCount = parseInt(ValueFormat.parseAsInt(purchasedLicenseCount.androidLicenseCount));
        var purchasediosSdkLicenseCount = parseInt(ValueFormat.parseAsInt(purchasedLicenseCount.iosSdkLicenseCount));
        var purchasedandroidSdkLicenseCount = parseInt(ValueFormat.parseAsInt(purchasedLicenseCount.androidSdkLicenseCount));
        var purchasedtotalLicenseCount = parseInt(ValueFormat.parseAsInt(purchasedLicenseCount.totalLicenseCount));

        var availableLicenseCount = ValueFormat.parseAsObject(apiResultTemp.availableLicenseCount);
        var availableiosLicenseCount = parseInt(ValueFormat.parseAsInt(availableLicenseCount.iosLicenseCount));
        var availableandroidLicenseCount = parseInt(ValueFormat.parseAsInt(availableLicenseCount.androidLicenseCount));
        var availableiosSdkLicenseCount = parseInt(ValueFormat.parseAsInt(availableLicenseCount.iosSdkLicenseCount));
        var availableandroidSdkLicenseCount = parseInt(ValueFormat.parseAsInt(availableLicenseCount.androidSdkLicenseCount));
        var availabletotalLicenseCount = parseInt(ValueFormat.parseAsInt(availableLicenseCount.availableLicenseCount));


        const overallRideBoxList = ValueFormat.parseAsArray(
          apiResultTemp.overallRideBoxList
        );
        overallRideBoxList.map((apiData) => {
          var rideBox = ValueFormat.parseAsInt(apiData.rideBox);
          totalRide += ValueFormat.parseAsInt(apiData.totalRides);
          if (rideBox == 1) {
            safeRide = apiData.totalRides;
          }
        });
        var totalSafeRideCount = ValueFormat.parseAsInt(safeRide);
        setTotalSafeRidePer(
          ValueFormat.parseAsFloat((safeRide / totalRide) * 100),
          0
        );

        userCountPercentages.push(
          activeUserCount,
          inactiveUserCount,
          unregisteredUserCount
        );

        setNewSADTopRowItemsTemp(
          newSADTopRowItemsTemp.map((staticObjectTemp) => {
            var isTypeMatched = false;
            var totalLicenseCount = 0;
            var availableLicenseCount = 0;

            if (staticObjectTemp.type === "TOTAL") {
              isTypeMatched = true;
              totalLicenseCount = purchasedtotalLicenseCount;
              availableLicenseCount = availabletotalLicenseCount;
            } else if (staticObjectTemp.type === "IOS") {
              isTypeMatched = true;
              totalLicenseCount = purchasediosLicenseCount;
              availableLicenseCount = availableiosLicenseCount;
            } else if (staticObjectTemp.type === "IOS_SDK") {
              isTypeMatched = true;
              totalLicenseCount = purchasediosSdkLicenseCount;
              availableLicenseCount = availableiosSdkLicenseCount;
            } else if (staticObjectTemp.type === "ANDROID") {
              isTypeMatched = true;
              totalLicenseCount = purchasedandroidLicenseCount;
              availableLicenseCount = availableandroidLicenseCount;
            } else if (staticObjectTemp.type === "ANDROID_SDK") {
              isTypeMatched = true;
              totalLicenseCount = purchasedandroidSdkLicenseCount;
              availableLicenseCount = availableandroidSdkLicenseCount;
            }

            if (isTypeMatched === true) {
              let staticObjectTempReplace = {
                totalLicenseCount: totalLicenseCount,
                availableLicenseCount: availableLicenseCount
              };
              return { ...staticObjectTemp, ...staticObjectTempReplace };
            } else {
              return staticObjectTemp;
            }
          })
        );

        setADTopRowItemsTemp(
          ADTopRowItemsTemp.map((staticObjectTemp) => {
            var isTypeMatched = false;
            var totalCount = 0;
            if (staticObjectTemp.type === "TOTAL") {
              isTypeMatched = true;
              totalCount = totalUserCount;
            } else if (staticObjectTemp.type === "ACTIVE") {
              isTypeMatched = true;
              totalCount = activeUserCount;
            } else if (staticObjectTemp.type === "INACTIVE") {
              isTypeMatched = true;
              totalCount = inactiveUserCount;
              // console.log("inac", inactiveUserCount);
            } else if (staticObjectTemp.type === "UNREGISTER") {
              isTypeMatched = true;
              totalCount = unregisteredUserCount;
            } else if (staticObjectTemp.type === "PENDING") {
              isTypeMatched = true;
              totalCount = registeredUserCount;
            } else if (staticObjectTemp.type === "CATEGORY") {
              isTypeMatched = true;
              totalCount = totalSafeRideCount;
            }

            if (isTypeMatched === true) {
              let staticObjectTempReplace = {
                totalCount: totalCount,
              };
              return { ...staticObjectTemp, ...staticObjectTempReplace };
            } else {
              return staticObjectTemp;
            }
          })
        );
      });

      setUserUsagePer(userCountPercentages);
    } else {
      console.log("Status failed");
      // const code = resultJSON.error.code;

      // if (code == "E007") {
      //   ToastService.errormsg(displayText.ER_007);
      //   setTimeout(() => {
      //     window.location.href = "/contacts-profile"
      //   }, 3500);
      // }
    }

  };

  const getRiskRideCount = async () => {
    setRccData(null)

    setShowLoader((current) => current + 1);
    var result = await AdminDailySummeryController.adminDailyRideRiskCount(
      currentFilter,
      indexStart,
      pageSize
    );
    setShowLoader((current) => current - 1);
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    if (status == "SUCCESS") {


      var resultJSONData = resultJSON.data;
      var xAxisData = [];
      var rideCount = [];
      var redAlertCount = [];
      var amberAlertCount = [];
      setDataLength(resultJSONData.length);
      // console.log("getRiskRideCount :" + JSON.stringify(resultJSONData));
      resultJSONData.map((apiData) => {
        var firstName = ValueFormat.parseAsString(
          ValueFormat.capitalizeFirstLetterAndFormat(apiData.firstName)
        );
        var rideCountTemp = ValueFormat.parseAsInt(apiData.rideCount);
        var alertCountList = ValueFormat.parseAsObject(apiData.riskAlertCount);
        var redCountTemp = ValueFormat.parseAsInt(alertCountList.highRiskCount);
        var amberCountTemp = ValueFormat.parseAsInt(
          alertCountList.mediumRiskCount
        );

        xAxisData.push(firstName);
        rideCount.push(rideCountTemp);
        redAlertCount.push(redCountTemp);
        amberAlertCount.push(amberCountTemp);
      });
      setRcChart(Object.values(rideCount).some((value) => value !== 0));
      setRccData(
        Object.values(redAlertCount).some((value) => value !== 0) ||
        Object.values(amberAlertCount).some((value) => value !== 0)
      );
      setRideRiskData((prev) => {
        return {
          ...prev,
          rideRiskXaxis: xAxisData,
          rideSeries: rideCount,
          riskAmberSeries: amberAlertCount,
          riskRedSeries: redAlertCount,
        };
      });

    } else {
      setShowLoader((current) => current - current);
      setRcChart(false)
      setRccData(false)
      var code = resultJSON.error.code;
      if (code == "E007") {
        setRcChart("failed")
        setRccData("failed")
      }
    }
  };

  let driverAnalysis = async () => {
    var result =
      await AdminWeeklySummeryController.adminWeeklyDriverAnalysisController();
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    if (status == "SUCCESS") {
      setDailyData(null);
      var resultJSONData = resultJSON.data;
      var usageList = [];
      var usageValue = {};
      var userPerOption = [];
      var driverAnalysis = [{}];
      var dayUserCountList = [{}];
      var activeDriverData = [];
      // console.log("driverAnalysis: " + JSON.stringify(resultJSONData));
      var useageData = 0;
      for (let dr = 0; dr < resultJSONData.length; dr++) {
        var driverAnalysisList = resultJSONData[dr].driverAnalysisList;
        var dayUserCount = resultJSONData[dr].dayUserCount;
        var sunDayUserCount = dayUserCount.day0;
        var monDayUserCount = dayUserCount.day1;
        var tueDayUserCount = dayUserCount.day2;
        var wedDayUserCount = dayUserCount.day3;
        var thuDayUserCount = dayUserCount.day4;
        var friDayUserCount = dayUserCount.day5;
        var satDayUserCount = dayUserCount.day6;

        dayUserCountList = [
          {
            value: sunDayUserCount > 0 ? sunDayUserCount : null,
            name: sunDayUserCount > 0 ? "Sunday" : "",
          },
          {
            value: monDayUserCount > 0 ? monDayUserCount : null,
            name: monDayUserCount > 0 ? "Monday" : "",
          },
          {
            value: tueDayUserCount > 0 ? tueDayUserCount : null,
            name: tueDayUserCount > 0 ? "Tuesday" : "",
          },
          {
            value: wedDayUserCount > 0 ? wedDayUserCount : null,
            name: wedDayUserCount > 0 ? "Wednesday" : "",
          },
          {
            value: thuDayUserCount > 0 ? thuDayUserCount : null,
            name: thuDayUserCount > 0 ? "Thursday" : "",
          },
          {
            value: friDayUserCount > 0 ? friDayUserCount : null,
            name: friDayUserCount > 0 ? "Friday" : "",
          },
          {
            value: satDayUserCount > 0 ? satDayUserCount : null,
            name: satDayUserCount > 0 ? "Saturday" : "",
          },
        ];
        var dayCount = sunDayUserCount + monDayUserCount + tueDayUserCount + wedDayUserCount + thuDayUserCount + friDayUserCount + satDayUserCount;
        setDailyData(dayCount > 0 ? true : false);

        activeDriverData.push(...dayUserCountList);
        for (let da = 0; da < driverAnalysisList.length; da++) {
          usageList = [];
          usageValue = {};
          var dayList = driverAnalysisList[da].dayList;
          var firstName = ValueFormat.capitalizeFirstLetterAndFormat(
            driverAnalysisList[da].firstName
          );
          for (let dl = 0; dl < dayList.length; dl++) {
            var usage = dayList[dl].usage > 0 ? Math.round(dayList[dl].usage) : 0;
            useageData = useageData + usage;
            usageList.push(usage);
          }
          usageValue = {
            name: firstName,
            type: "line",
            data: usageList,
            markPoint: {
              symbolSize: [35, 40],
              data: [
                { type: "max", name: "Max" },
                { type: "min", name: "Min" },
              ],
            },
          };
          userPerOption.push(usageValue);
        }
      }

      setActiveDriverData(userPerOption);
      setActiveDriverPie(activeDriverData);
      setPdUsageData(useageData > 0 ? true : false);
    } else {
      const code = resultJSON.error.code;
      if (code == 'E007') {
        setPdUsageData("failed");
        setDailyData("failed");

      }
    }
  };

  useEffect(() => {
    // async function fetchData() {
    // await 
    getTotalValues();
    // await 
    getRiskRideCount();
    // await 
    // driverAnalysis();
    // }
    // fetchData();
  }, [currentFilter, indexStart]);

  useEffect(() => {
    setPdUsageData();
  }, []);

  useEffect(() => {
    setRcChart();
  }, [currentFilter, indexStart]);

  // useEffect(()=>{
  //   localStorage.removeItem("_grecaptcha")
  // },[])
  //meta title

  return (
    <React.Fragment>
      <div className="page-content">
        {/* <div className="ps-5 pe-5"> */}
        <Container fluid>
          <div id="container1">
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title="Super Admin Dashboard"
              breadcrumbItem="Super Admin Dashboard"
            />
            {showLoader > 0 && <Loader />}
            <ToastContainer style={{ width: "auto" }} />
            <Row className="my-2 py-2">
              <Col xs={6} className="d-flex justify-content-start align-items-center text-black">
                <h5 className="mb-0">Dashboard</h5>
              </Col>
              <Col xs={6}>
                <div className="pb-2 d-flex justify-content-end">
                  <ExportScreenPdf fileName={userFirstName + "'s" + " Dashboard"} />
                </div>
              </Col>
            </Row>

            <Row className="my-2 py-2">
              <Col xs={12} className="d-flex justify-content-start align-items-center text-black">
                <h6 className="mb-0">Current / Subscription Status</h6>
              </Col>
            </Row>

            <Row>
              {(newSADTopRowItemsTemp || []).map((widget, key) => (
                <COL lg={2.4} md={6} key={key} className="px-2">
                  <Card className="card-h-100">
                    <CardBody style={{ padding: "10px" }}>
                      <Row className="align-items-center">
                        <Col xs={12}>
                          <span
                            data-tooltip-id={key + "fd"}
                            className="text-muted mb-3 d-block text-truncate fw-semibold"
                          >
                            {widget.title}
                          </span>
                          <h4 className="mb-3">
                            <span className="counter-value">
                              {`${widget.availableLicenseCount} / ${widget.totalLicenseCount}`}
                            </span>
                          </h4>
                          <ReactTooltip
                            id={key + "fd"}
                            place="top"
                            content={widget.tooltips}
                            style={{ backgroundColor: "black" }}
                          />
                        </Col>
                      </Row>
                      <div className="text-nowrap">
                        <span className="ms-1 text-muted font-size-13">
                          {widget.bottomTitle}
                        </span>
                      </div>
                    </CardBody>
                  </Card>
                  {/* </Link> */}
                </COL>
              ))}
            </Row>

            <Row className="my-2 py-2">
              <Col xs={12} className="d-flex justify-content-start align-items-center text-black">
                <h6 className="mb-0">User Onboard Status</h6>
              </Col>
            </Row>
            <Row>
              {(ADTopRowItemsTemp || []).map((widget, key) => (
                <COL lg={2.4} md={6} key={key} className="px-2">
                  {/* <Link to={widget.linkTo} state={{ filterLink: widget.tab }}> */}
                  <Card className="card-h-100">
                    <CardBody style={{ padding: "10px" }}>
                      <Row className="align-items-center">
                        <Col xs={12}>
                          <span
                            data-tooltip-id={key + 111}
                            className="text-muted mb-3 d-block text-truncate fw-semibold"
                          >
                            {widget.title}
                          </span>
                          <h4 className="mb-3">
                            {/* {widget.isDoller === true ? '$' : ''} */}
                            <span className="counter-value">
                              <CountUp
                                start={0}
                                end={widget.totalCount}
                                decimals={0}
                                decimal="."
                                duration={1}
                              />
                              {widget.postFix}
                            </span>
                          </h4>
                          <ReactTooltip
                            id={key + 111}
                            place="top"
                            content={widget.tooltip}
                            style={{ backgroundColor: "black" }}
                          />
                        </Col>
                      </Row>
                      <div className="text-nowrap">
                        <span className="ms-1 text-muted font-size-13">
                          {widget.bottomTitle}
                        </span>
                      </div>
                    </CardBody>
                  </Card>
                  {/* </Link> */}
                </COL>
              ))}
            </Row>



          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SuperAdminAmazonDashboard;
