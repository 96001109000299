import { apiConfig } from "../../config/Config.js";
import { apiEndPoint } from "../../constant/ApiConstant.js";
import { ApiService } from "../../service/ApiService.js";
import {
  SC_USERNAME,
  SC_USER_EMAIL,
  SC_NAME,
  SC_USER_FIRST_NAME,
  SC_USER_LAST_NAME,
  SC_USER_ID,
  SC_FCM_NOTIFICATION_COUNT,
  SC_LOGIN_USER_ID,
  SC_LOGIN_USER_FIRST_NAME,
  SC_COMPANY_ID,
  SC_DIVISION_ID,
  SC_MODULE_ID,
  SC_LOGIN_DIVISION_ID,
  SC_LOGIN_MODULE_ID,
  SC_USER_SESSION_ID,
  SC_EXPIRATION_TIME,
  SC_USER_LOGIN_TYPE,
  SC_FCM_NOTIFICATION_TOKEN,
  SC_LANGUAGES,
  SC_GROUP_ID,
  SC_COMPANY_NAME,
  SC_LOGIN_GROUP_ID,
  SC_DASHBOARD_TYPE,
  SC_LOCK_STATUS,
  SC_PASSWORD,
  SC_TOTAL_RIDE_COUNT,
  SC_SECRET_KEY,
  SC_PREVIOUS_FCM_NOTIFICATION_TOKEN,
  SC_DIVISION_NAME,
  SC_REGISTRATION_DATE,
  SC_TIMEZONE_ABBREVIATION,
  SC_TIMEZONE_DISPLAY_NAME,
  SC_TIMEZONE_GMT_OFFSET,
  SC_TIMEZONE_ZONE_NAME,
  SC_LANGUAGE_ID,
  SC_TIME_ZONE_ID,
  SC_COUNTRY_ID,
  SC_TIMEZONE_DST_OFFSET,
  RIDEDETAILS,
} from "../../constant/StorageConstant.js";
import { GlobalConst } from "../../constant/GlobalConstant.js";
import { apiStatus, apiType, apiCategory } from "../../constant/ApiConstant.js";
import { pageNames } from "../../constant/PageConstant.js";
import ToastService from "../../components/Common/toastService.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let userSessionController = {
  getUserProfilePicturePath() {
    var path =
      apiConfig.API_ROOT_URL + apiConfig.API_VERSION_1 + apiEndPoint.FILE;
    var companyId = localStorage.getItem(SC_COMPANY_ID);
    var userId = localStorage.getItem(SC_USER_ID);
    var sessionId = localStorage.getItem(SC_USER_SESSION_ID);
    return (
      path +
      "?companyId=" +
      companyId +
      "&type=USER_PROFILE_PICTURE&referredBy=WEB&userId=" +
      userId +
      "&sessionId=" +
      sessionId +
      ""
    );
  },

  async checkUserSessionPageAccess(pageName) {
    var userLoginType = localStorage.getItem(SC_USER_LOGIN_TYPE);
    var userPages = [
      pageNames.LOGIN,
      pageNames.MY_PROFILE,
      pageNames.COMPANY_PROFILE,
      pageNames.MY_RIDES,
      pageNames.ABOUT,
      pageNames.LOGOUTALERT,
      pageNames.LOGOUT,
      pageNames.FAQ,
      pageNames.TERMSANDCONDITIONS,
      pageNames.REPORT,
      pageNames.FEEDBACK,
      pageNames.SETTINGS,
      pageNames.DRIVINGBEHAVIOUR,
      pageNames.ADAS,
      pageNames.OBD,
      pageNames.DMS,
    ];
    var adminPages = [
      pageNames.LOGIN,
      pageNames.MY_PROFILE,
      pageNames.COMPANY_PROFILE,
      pageNames.COMPANY_USERS,
      pageNames.FCM_NOTIFICATION_USERS,

      pageNames.ABOUT,
      pageNames.LOGOUTALERT,
      pageNames.LOGOUT,
      pageNames.FAQ,
      pageNames.TERMSANDCONDITIONS,
      pageNames.REPORT,
      pageNames.FEEDBACK,
      pageNames.SETTINGS,
      pageNames.MY_DEVICE,
      pageNames.MY_VEHICLE,
      pageNames.MY_OBD,
      pageNames.DRIVINGBEHAVIOUR,
      pageNames.ADAS,
      pageNames.OBD,
      pageNames.DMS,
      pageNames.SITE,
      pageNames.MY_RIDES,
    ];
    var superAdminPages = [
      pageNames.LOGIN,
      pageNames.LOGOUTALERT,
      pageNames.LOGOUT,
      pageNames.SUPER_ADMIN_DASHBOARD,
      pageNames.ALL_RIDES,
      pageNames.MY_RIDES,
    ];
    var superAdminAmazonPages = [
      pageNames.LOGIN,
      pageNames.LOGOUTALERT,
      pageNames.LOGOUT,
      pageNames.SUPER_ADMIN_DASHBOARD,
      pageNames.ALL_RIDES,
      pageNames.MY_RIDES,
    ];
    var macthed = 0;
    if (userLoginType == "USER") {
      var ulen = userPages.length;
      for (var upc = 0; upc < ulen; upc++) {
        if (userPages[upc] == pageName) {
          macthed = 1;
        }
      }
    } else if (userLoginType == "ADMIN") {
      var alen = adminPages.length;
      for (var apc = 0; apc < alen; apc++) {
        if (adminPages[apc] == pageName) {
          macthed = 1;
        }
      }
    } else if (userLoginType == "SUPER_ADMIN") {
      var slen = superAdminPages.length;
      for (let spc = 0; spc < slen; spc++) {
        if (superAdminPages[spc] == pageName) {
          macthed = 1;
        }
      }
    } else if (userLoginType == "SUPER_ADMIN_AMAZON") {
      var salen = superAdminAmazonPages.length;
      for (let spc = 0; spc < salen; spc++) {
        if (superAdminPages[spc] == pageName) {
          macthed = 1;
        }
      }
    }

    if (macthed > 0) {
      return GlobalConst.SUCCESS;
    } else {
      return GlobalConst.FAILED;
    }
  },

  async resetUserIdUsingLoginUserId() {
    try {
      var userIdTemp = localStorage.getItem(SC_LOGIN_USER_ID);
      localStorage.setItem(SC_USER_ID, userIdTemp);
    } catch (errMess) {
      // console.log("Local Storage Parsing Exception: ", errMess);
    }
  },

  async checkUserSessionInformation() {
    var expirationTime = "";
    try {
      expirationTime = localStorage.getItem(SC_EXPIRATION_TIME);
    } catch (errMess) {
      // console.log("Local Storage Parsing Exception: ", errMess);
    }

    if (
      expirationTime != null &&
      expirationTime != "" &&
      expirationTime.length > 0
    ) {
      if (expirationTime > new Date()) {
        // alert("Session is valid");
        return GlobalConst.SUCCESS;
      } else {
        ToastService.errormsg("Session is expired!");
        // const currentPath = window.location.pathname;
        // if (currentPath !== "/login") { window.location.href = "/login"; }
        setTimeout(() => {
          window.location.href = "/login";
        }, 3500);
        localStorage.removeItem(SC_EXPIRATION_TIME);
        localStorage.removeItem(SC_USERNAME);
        localStorage.removeItem(SC_NAME);
        localStorage.removeItem(SC_USER_EMAIL);
        localStorage.removeItem(SC_USER_FIRST_NAME);
        localStorage.removeItem(SC_USER_LAST_NAME);
        localStorage.removeItem(SC_USER_ID);
        localStorage.removeItem(SC_LOGIN_USER_ID);
        localStorage.removeItem(SC_LOGIN_USER_FIRST_NAME);
        localStorage.removeItem(SC_COMPANY_ID);
        localStorage.removeItem(SC_DIVISION_ID);
        localStorage.removeItem(SC_MODULE_ID);
        localStorage.removeItem(SC_GROUP_ID);
        localStorage.removeItem(SC_COMPANY_NAME);
        localStorage.removeItem(SC_LOGIN_MODULE_ID);
        localStorage.removeItem(SC_LOGIN_DIVISION_ID);
        localStorage.removeItem(SC_LOGIN_GROUP_ID);
        localStorage.removeItem(SC_USER_SESSION_ID);
        localStorage.removeItem(SC_USER_LOGIN_TYPE);
        localStorage.removeItem(SC_DASHBOARD_TYPE);
        localStorage.removeItem(SC_LOCK_STATUS);
        localStorage.removeItem(SC_PASSWORD);
        localStorage.removeItem(SC_TOTAL_RIDE_COUNT);
        localStorage.removeItem(SC_SECRET_KEY);
        localStorage.removeItem(SC_LANGUAGE_ID);
        localStorage.removeItem(SC_TIME_ZONE_ID);
        localStorage.removeItem(SC_COUNTRY_ID);
        localStorage.removeItem(SC_TIMEZONE_ABBREVIATION);
        localStorage.removeItem(SC_TIMEZONE_DISPLAY_NAME);
        localStorage.removeItem(SC_TIMEZONE_GMT_OFFSET);
        localStorage.removeItem(SC_TIMEZONE_ZONE_NAME);
        localStorage.removeItem(SC_TIMEZONE_DST_OFFSET);
        localStorage.removeItem(RIDEDETAILS);

        return GlobalConst.FAILED;
      }
    } else {
      // console.log("Session is empty");
      localStorage.removeItem(SC_EXPIRATION_TIME);
      localStorage.removeItem(SC_USERNAME);
      localStorage.removeItem(SC_NAME);
      localStorage.removeItem(SC_USER_EMAIL);
      localStorage.removeItem(SC_USER_FIRST_NAME);
      localStorage.removeItem(SC_USER_LAST_NAME);
      localStorage.removeItem(SC_USER_ID);
      localStorage.removeItem(SC_FCM_NOTIFICATION_COUNT);
      localStorage.removeItem(SC_FCM_NOTIFICATION_TOKEN);
      localStorage.removeItem(SC_LOGIN_USER_FIRST_NAME);
      localStorage.removeItem(SC_LOGIN_USER_ID);
      localStorage.removeItem(SC_COMPANY_ID);
      localStorage.removeItem(SC_DIVISION_ID);
      localStorage.removeItem(SC_MODULE_ID);
      localStorage.removeItem(SC_GROUP_ID);
      localStorage.removeItem(SC_COMPANY_NAME);
      localStorage.removeItem(SC_LOGIN_MODULE_ID);
      localStorage.removeItem(SC_LOGIN_DIVISION_ID);
      localStorage.removeItem(SC_LOGIN_GROUP_ID);
      localStorage.removeItem(SC_USER_SESSION_ID);
      localStorage.removeItem(SC_USER_LOGIN_TYPE);
      localStorage.removeItem(SC_LANGUAGES);
      localStorage.removeItem(SC_DASHBOARD_TYPE);
      localStorage.removeItem(SC_LOCK_STATUS);
      localStorage.removeItem(SC_PASSWORD);
      localStorage.removeItem(SC_TOTAL_RIDE_COUNT);
      localStorage.removeItem(SC_SECRET_KEY);
      localStorage.removeItem(SC_LANGUAGE_ID);
      localStorage.removeItem(SC_TIME_ZONE_ID);
      localStorage.removeItem(SC_COUNTRY_ID);
      localStorage.removeItem(SC_TIMEZONE_ABBREVIATION);
      localStorage.removeItem(SC_TIMEZONE_DISPLAY_NAME);
      localStorage.removeItem(SC_TIMEZONE_GMT_OFFSET);
      localStorage.removeItem(SC_TIMEZONE_ZONE_NAME);
      localStorage.removeItem(SC_TIMEZONE_DST_OFFSET);
      localStorage.removeItem(RIDEDETAILS);
      return GlobalConst.FAILED;
    }
  },

  getLocalSessionValue(inputFieldName) {
    if (
      inputFieldName != null &&
      inputFieldName != "" &&
      inputFieldName.length > 0
    ) {
      try {
        return localStorage.getItem(inputFieldName);
      } catch (errMess) {
        // console.log("Local Storage Parsing Exception: ", errMess);
        return "";
      }
    } else {
      return "";
    }
  },

  async createUserSession(formData) {
    var username = formData.username;
    var password = formData.password;
    var FCMToken = localStorage.getItem(SC_FCM_NOTIFICATION_TOKEN);
    var preFCMToken = localStorage.getItem(SC_PREVIOUS_FCM_NOTIFICATION_TOKEN);
    var reqPayload = {
      category: apiCategory.USER_PASSWORD,
      type: apiType.USER_SESSION,
      companyId: apiConfig.COMPANY_ID,
      status: apiStatus.REGISTERED,
      username: username,
      password: password,
      userSessionField: {
        deviceType: "WEB",
        deviceStatus: "REGISTERED",
        deviceToken: FCMToken,
        deviceTokenPrevious: preFCMToken || "",
      },
      payloadVersion: "1",
    };

    var resultGet = await ApiService.callGET(
      apiConfig.API_VERSION_1,
      apiEndPoint.HEALTH,
      0,
      {}
    );

    var result = await ApiService.callPOST(
      apiConfig.API_VERSION_1,
      apiEndPoint.USER_SESSION,
      0,
      reqPayload
    );
    var resultJSON = JSON.parse(result);
    var resultJSONStatus = resultJSON.status;
    var resultJSONData = resultJSON.data;

    var userFirstName = "",
      userLastName = "",
      loginUserFirstName = "",
      fcmCount = "";
    var userId = "",
      userSessionId = "",
      companyId = "",
      divisionId = "",
      moduleId = "",
      groupId = "",
      companyName = "",
      languages = "English",
      dashboardType = "",
      rideCount = "",
      email = "",
      name = "",
      secretKey = "";

    try {
      userFirstName = resultJSONData.user.firstName;
      userLastName = resultJSONData.user.lastName;
      userId = resultJSONData.user.id;
      companyId = resultJSONData.user.companyId;
      userSessionId = resultJSONData.userSession.id;
      divisionId = resultJSONData.user.divisionId;
      moduleId = resultJSONData.user.moduleId;
      groupId = resultJSONData.user.groupId;
      email = resultJSONData.user.email;
      name = resultJSONData.group.name;

      // To be removed in future - Starts
      //if (divisionId == undefined) divisionId = "divi00000000000000000001";
      //if (moduleId == undefined) moduleId = "modu00000000000000000001";
      // To be removed in future - Ends
    } catch (errMess) {
      // console.log("Login Response JSON Parsing Exception: ", errMess);
    }

    if (resultJSONStatus == GlobalConst.SUCCESS) {
      var currentDateTime = new Date();
      currentDateTime.setHours(currentDateTime.getHours() + 24);

      localStorage.setItem(SC_EXPIRATION_TIME, currentDateTime.getTime());
      localStorage.setItem(SC_USERNAME, username);
      localStorage.setItem(SC_NAME, name);
      localStorage.setItem(SC_USER_EMAIL, email);
      localStorage.setItem(SC_NAME, name);
      localStorage.setItem(SC_USER_FIRST_NAME, userFirstName);
      localStorage.setItem(SC_USER_LAST_NAME, userLastName);
      localStorage.setItem(SC_USER_ID, userId);
      localStorage.setItem(SC_FCM_NOTIFICATION_COUNT, fcmCount);
      localStorage.setItem(SC_LOGIN_USER_ID, userId);
      localStorage.setItem(SC_LOGIN_USER_FIRST_NAME, loginUserFirstName);
      localStorage.setItem(SC_COMPANY_ID, companyId);
      localStorage.setItem(SC_DIVISION_ID, divisionId);
      localStorage.setItem(SC_MODULE_ID, moduleId);
      localStorage.setItem(SC_GROUP_ID, groupId);
      localStorage.setItem(SC_COMPANY_NAME, companyName);
      localStorage.setItem(SC_LOGIN_DIVISION_ID, divisionId);
      localStorage.setItem(SC_LOGIN_MODULE_ID, moduleId);
      localStorage.setItem(SC_LOGIN_GROUP_ID, groupId);
      localStorage.setItem(SC_USER_SESSION_ID, userSessionId);
      localStorage.setItem(SC_LANGUAGES, languages);
      localStorage.setItem(SC_DASHBOARD_TYPE, dashboardType);
      localStorage.setItem(SC_TOTAL_RIDE_COUNT, rideCount);
      localStorage.setItem(SC_SECRET_KEY, secretKey);
    }
    return result;
  },

  async deleteUserSession() {
    localStorage.removeItem(SC_EXPIRATION_TIME);
    localStorage.removeItem(SC_USERNAME);
    localStorage.removeItem(SC_NAME);
    localStorage.removeItem(SC_USER_EMAIL);
    localStorage.removeItem(SC_USER_FIRST_NAME);
    localStorage.removeItem(SC_USER_LAST_NAME);
    localStorage.removeItem(SC_USER_ID);
    localStorage.removeItem(SC_LOGIN_USER_ID);
    localStorage.removeItem(SC_LOGIN_USER_FIRST_NAME);
    localStorage.removeItem(SC_COMPANY_ID);
    localStorage.removeItem(SC_DIVISION_ID);
    localStorage.removeItem(SC_MODULE_ID);
    localStorage.removeItem(SC_GROUP_ID);
    localStorage.removeItem(SC_COMPANY_NAME);
    localStorage.removeItem(SC_LOGIN_DIVISION_ID);
    localStorage.removeItem(SC_LOGIN_MODULE_ID);
    localStorage.removeItem(SC_LOGIN_GROUP_ID);
    localStorage.removeItem(SC_USER_LOGIN_TYPE);
    localStorage.removeItem(SC_LANGUAGES);
    localStorage.removeItem(SC_DASHBOARD_TYPE);
    localStorage.removeItem(SC_LOCK_STATUS);
    localStorage.removeItem(SC_PASSWORD);
    localStorage.removeItem(SC_TOTAL_RIDE_COUNT);
    localStorage.removeItem(SC_SECRET_KEY);
    localStorage.removeItem(SC_LANGUAGE_ID);
    localStorage.removeItem(SC_TIME_ZONE_ID);
    localStorage.removeItem(SC_COUNTRY_ID);
    localStorage.removeItem(SC_TIMEZONE_ABBREVIATION);
    localStorage.removeItem(SC_TIMEZONE_DISPLAY_NAME);
    localStorage.removeItem(SC_TIMEZONE_GMT_OFFSET);
    localStorage.removeItem(SC_TIMEZONE_ZONE_NAME);
    localStorage.removeItem(SC_TIMEZONE_DST_OFFSET);
    localStorage.removeItem(RIDEDETAILS);

    var reqParams = {};
    var result = await ApiService.callDELETE(
      apiConfig.API_VERSION_1,
      apiEndPoint.USER_SESSION,
      1,
      reqParams
    );
    localStorage.removeItem(SC_USER_SESSION_ID);

    return result;
  },
  async questionAnsPopup(divisionId) {
    // var companyId = localStorage.getItem(SC_COMPANY_ID);
    // var divisionId = localStorage.getItem(SC_DIVISION_ID);
    // if (companyId.length > 0) {
      var reqPayload = {
        companyId: "comp00000000000000000001",
        divisionId: divisionId,
        type: "SECURITY_QUESTION",
      };

      return await ApiService.callGET(
        apiConfig.API_VERSION_1,
        apiEndPoint.DIVISION_PREFERENCE,
        0,
        reqPayload
      );
    // } else {
    //   return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    // }
  },

  async answerPost(
    companyId,
    divisionId,
    questionCode,
    answer,
    moduleId,
    userId
  ) {
    if (companyId.length > 0 && divisionId.length > 0) {
      var reqParams = {
        companyId: companyId,
        divisionId: divisionId,
        fieldName: "questionCode" + questionCode,
        fieldValue: answer,
        moduleId: moduleId,
        type: "SECURITY_QUESTION_ANSWER",
        userId: userId,
        id: userId,
      };
      return await ApiService.callPOST(
        apiConfig.API_VERSION_1,
        apiEndPoint.USER_QUESTIONS,
        1,
        reqParams
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async twoFactorSendOTP(companyId, userId){
    if (companyId.length > 0 && userId.length > 0) {
      var reqPayload = {
        companyId: companyId,
        userId: userId,
        type: "MFA_AUTHENTICATION"
      }
      return await ApiService.callPOST(
        apiConfig.API_VERSION_1,
        apiEndPoint.USER_AUTHENTICATION,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async twoFactorVerifyOTP(companyId, userId, otpCode){
    if (companyId.length > 0 && userId.length > 0) {
      var reqPayload = {
        companyId: companyId,
        userId: userId,
        otpCode: otpCode,
        type: "MFA_AUTHENTICATION"
      }
      return await ApiService.callPUT(
        apiConfig.API_VERSION_1,
        apiEndPoint.USER_AUTHENTICATION,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async QndAVerifyOTP(companyId, userId, questionCode, questionValue){
    if (companyId.length > 0 && userId.length > 0) {
      var reqPayload = {
        companyId: companyId,
        userId: userId,
        questionCode: "questionCode"+questionCode,
        questionValue:questionValue,
        type: "MFA_AUTHENTICATION"
      }
      return await ApiService.callPUT(
        apiConfig.API_VERSION_1,
        apiEndPoint.USER_AUTHENTICATION,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async savePrimaryType(companyId,divisionId, moduleId, userId, fieldName, fieldValue){
    if (companyId.length > 0 && userId.length > 0 && moduleId.length > 0 ) {
      var reqPayload = {
        companyId: companyId,
        divisionId:divisionId,
        moduleId: moduleId,
        userId: userId,
        id:userId,
        fieldName:fieldName,
        fieldValue:fieldValue,
        type: "USER_SECRET"
      }
      return await ApiService.callPATCH(
        apiConfig.API_VERSION_1,
        apiEndPoint.USER_SECRECT,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async loginSendOTP(companyId, userId, verificationMethod,fieldValue){
    // let type = verificationMethod === "mfcMobileNumber" ? "MFA_MOBILE_NUMBER" : verificationMethod === "mfcEmailAddress" ? "MFA_EMAIL_ADDRESS" : "MFA_SECURITY_QUESTIONS";
    if (companyId.length > 0 && userId.length > 0) {
      var reqPayload = {
        companyId: companyId,
        id: userId,
        status:"REGISTERED",
        type: "MFA_"+verificationMethod,
        username:fieldValue
      }
      return await ApiService.callPOST(
        apiConfig.API_VERSION_1,
        apiEndPoint.USER_SECRET_VERIFICATION,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async initialVerifyOTP(userId,companyId,userName,verificationCode, verificationMethod){
    if (companyId.length > 0 && userId.length > 0) {
      var reqPayload = {
        id: userId,
        companyId: companyId,
        username: userName,
        verificationCode:verificationCode,
        userVerificationField:{},
        status:"REGISTERED",
        type:"MFA_"+verificationMethod,
        payloadVersion:0
      }
      return await ApiService.callPUT(
        apiConfig.API_VERSION_1,
        apiEndPoint.USER_SECRET_VERIFICATION,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async changeAnswerPatch(companyId,divisionIds,moduleIds,userId,answer){
    if (companyId.length > 0 && userId.length > 0 ) {
      var reqPayload = {
        companyId: companyId,
        divisionId: divisionIds,
        moduleId: moduleIds,
        userId:userId,
        type: "USER_SECRET",
        mfaSecurityQuestions:answer,
        
      }
      return await ApiService.callPATCH(
        apiConfig.API_VERSION_1,
        apiEndPoint.USER_SECRET_SECURITY_QUESTIONS  ,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
// -----------------------------------------------------------------------------------------------//
  async UserLoginButtonApi(userName){
    var token = localStorage.getItem(SC_FCM_NOTIFICATION_TOKEN);
    var preFCMToken = localStorage.getItem(SC_PREVIOUS_FCM_NOTIFICATION_TOKEN);
    if (userName.length > 0) {
      var reqPayload = {
        category: "USER_PASSWORD",
        type: "USER_SESSION",
        companyId: apiConfig.COMPANY_ID,
        status: "REGISTERED",
        username:userName,
        userSessionField: {
        deviceType: "WEB",
        deviceStatus: "REGISTERED",
        deviceToken: token,
        deviceTokenPrevious: preFCMToken || "",
        }
      }
      var result= await ApiService.callPOST(
        apiConfig.API_VERSION_2,
        apiEndPoint.USER_SESSION,
        0,
        reqPayload
      );
      return result;

    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async existingPasswordCheckPatch(userId,password,divId){
    if (userId.length > 0 ) {
      var reqPayload = {
        companyId: apiConfig.COMPANY_ID,
        divisionId: divId,
        moduleId: "modu00000000000000000001",
        userId:userId,
        fieldName: "password",
        fieldValue: password,
        type: "USER_SESSION",
        action: "VALIDATE_CREDENTIAL"
        
      }
      return await ApiService.callPATCH(
        apiConfig.API_VERSION_2,
        apiEndPoint.USER_SESSION,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async sendOtpMobileEmail(userId,SendOrResend){
    if (userId.length > 0) {
      var reqPayload = {  
        companyId: apiConfig.COMPANY_ID, 
        userId:userId,
        type: "MFA_AUTHENTICATION",
        action: SendOrResend
      }
      return await ApiService.callPOST(
        apiConfig.API_VERSION_2,
        apiEndPoint.USER_AUTHENTICATION,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async VerifyOTP(userId,otpCode){
    if ( userId.length > 0) {
      var reqPayload = {  
        companyId: apiConfig.COMPANY_ID, 
        userId:userId, 
        otpCode:otpCode,
        type: "MFA_AUTHENTICATION"
      }
      return await ApiService.callPUT(
        apiConfig.API_VERSION_2,
        apiEndPoint.USER_AUTHENTICATION,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async newUserLoginChangePasswordPatch(userId,newPassword,oldpassword,divId,modId){
    if (userId.length > 0 ) {
      var reqPayload = {
        companyId: apiConfig.COMPANY_ID,
        divisionId: divId,
        fieldName: "password",
        fieldValue: newPassword,
        fieldValuePrevious: oldpassword,
        id: userId,
        moduleId: modId,
        type: "CHANGE_PASSWORD",
        userId: userId
      }
      return await ApiService.callPATCH(
        apiConfig.API_VERSION_2,
        apiEndPoint.USER,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async newUserForceChangePasswordPatch(userId,newPassword,oldpassword,divId,modId){
    if (userId.length > 0 ) {
      var reqPayload = {
        companyId: apiConfig.COMPANY_ID,
        divisionId: divId,
        fieldName: "password",
        fieldValue: newPassword,
        fieldValuePrevious: oldpassword,
        id: userId,
        moduleId: modId,
        type: "FORCE_CHANGE_PASSWORD",
        userId: userId
      }
      return await ApiService.callPATCH(
        apiConfig.API_VERSION_2,
        apiEndPoint.USER,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async SaveAnswerForSQpatch(userId,answer,modId,divId){
    if ( userId.length > 0 ) {
      var reqPayload = {
        companyId: apiConfig.COMPANY_ID,
        divisionId: divId,
        moduleId: modId,
        userId:userId,
        type: "USER_SECRET",
        mfaSecurityQuestions:answer,
        
      }
      return await ApiService.callPATCH(
        apiConfig.API_VERSION_2,
        apiEndPoint.USER_SECRET_SECURITY_QUESTIONS  ,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async QndAVerifyOTP1( userId, questionCode, questionValue){
    if ( userId.length > 0) {
      var reqPayload = {
        companyId: apiConfig.COMPANY_ID,
        userId: userId,
        questionCode: "questionCode"+questionCode,
        questionValue:questionValue,
        type: "MFA_AUTHENTICATION"
      }
      return await ApiService.callPUT(
        apiConfig.API_VERSION_2,
        apiEndPoint.USER_AUTHENTICATION,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
  async getResponseToMoveDashboard(userId,divisionId) {
      var reqPayload = {
        companyId: "comp00000000000000000001",
        divisionId: divisionId,
        userId:userId
      };

      const result = await ApiService.callGET(
        apiConfig.API_VERSION_2,
        apiEndPoint.USER_SECRECT,
        1,
        reqPayload
      );
    return result;
  },
  async forceChangePassword(userId, password,oldPassword,divId, moduleId ){
    if (userId.length > 0 ) {
      var reqPayload = {
        companyId: apiConfig.COMPANY_ID,
        divisionId: divId,
        fieldName: "password",
        fieldValue: password,
        fieldValuePrevious: oldPassword,
        id: userId,
        moduleId: moduleId,         
        type: "FORCE_CHANGE_PASSWORD",
        userId: userId,
        
      }
      return await ApiService.callPATCH(
        apiConfig.API_VERSION_2,
        apiEndPoint.USER,
        1,
        reqPayload
      );
    } else {
      return '{"status": "FAILED", "error" : "Request Process Failed", "data": []}';
    }
  },
};



export default userSessionController;
