import React from "react";
import { Nav, NavItem } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import "./UserDashboardSubMenu.css";

const UserAdminDashboardSubMenu = () => {
  const { pathname } = useLocation();
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
        }}
      >
        <h5 style={{ marginTop: "5px" }} className="heading">Dashboard</h5>&nbsp;&nbsp;&nbsp;
        <div>
          <Nav justified pills>
            <Link to="/user-dashboard">
              <button
                type="button"
                className={
                  pathname === "/user-dashboard"
                    ? "btn btn-primary btn-sm me-1"
                    : "btn btn-secondary btn-sm me-1"
                }
              >
                Overall Summary
              </button>
            </Link>
            <Link to="/user-dashboard/RiskAlertSummary">
              <button
                type="button"
                className={
                  pathname === "/user-dashboard/RiskAlertSummary"
                    ? "btn btn-primary btn-sm me-1"
                    : "btn btn-secondary btn-sm me-1"
                }
              >
                Risk Alert Summary
              </button>
            </Link>
            <Link to="/user-dashboard/CategorySummary">
              <button
                type="button"
                className={
                  pathname === "/user-dashboard/CategorySummary"
                    ? "btn btn-primary btn-sm me-1"
                    : "btn btn-secondary btn-sm me-1"
                }
              >
                Category Summary
              </button>
            </Link>

            {/* <NavItem className={pathname === "/dashboardNew/section4" ? "menu-active" : "menu-inactive"}>
               <Link  to="/dashboardNew/section4">Monthly & Weekly Summary </Link>
              </NavItem> */}
          </Nav>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserAdminDashboardSubMenu;
