import React, { Component } from "react"
import ReactEcharts from "echarts-for-react"
 
const LineTwo = ({ alertSummaryXAxis }) => {
  const getOption = {
 
    tooltip: {
      function(parms) {
        return parms[0];
      },
    },
    dataZoom: [
      {
        type: "inside",
        xAxisIndex: [0, 1],
        start: 0,
        end: 100,
        height: 10,
        fillerColor: "rgba(46, 50, 56, 0.05)",
        borderColor: "rgba(46, 50, 56, 0)",
        textStyle: {
          color: "rgba(28, 31, 35, 0.6)",
          fontFamily: "Inter"
        },
        dataBackground: {
          areaStyle: {
            color: "rgba(46, 50, 56, 0.09)"
          }
        },
        handleIcon: "path://M15,30C15,21.71270751953125,21.71270751953125,15,30,15C38.28729248046875,15,45,21.71270751953125,45,30C45,38.28729248046875,38.28729248046875,45,30,45C21.71270751953125,45,15,38.28729248046875,15,30Z",
 
      },
      {
        show: false,
        xAxisIndex: [0, 1],
        type: "slider",
        top: "95%",
        start: 0,
        end: 100,
        height: 10,
        fillerColor: "rgba(46, 50, 56, 0.05)",
        borderColor: "rgba(46, 50, 56, 0)",
        textStyle: {
          color: "rgba(28, 31, 35, 0.6)",
          fontFamily: "Inter"
        },
        dataBackground: {
          areaStyle: {
            color: "rgba(46, 50, 56, 0.09)"
          }
        },
        handleIcon: "path://M15,30C15,21.71270751953125,21.71270751953125,15,30,15C38.28729248046875,15,45,21.71270751953125,45,30C45,38.28729248046875,38.28729248046875,45,30,45C21.71270751953125,45,15,38.28729248046875,15,30Z",
 
      },
    ],
    grid: { bottom: 165, left: 50, right: 0 },
    xAxis: {
      name: "Category",
      nameLocation: "middle",
      nameTextStyle: { 
        fontFamily: "IBM Plex Sans, sans-serif",
      },
      nameGap: 150,
      nameTextStyle: {
        fontFamily: "IBM Plex Sans, sans-serif",
        fontWeight:200,
      },
      label: {
        align: "center",
        padding: [0, 0, 0, 35],
        fontFamily: "IBM Plex Sans, sans-serif",
      },
      type: "category",
      axisTick: { show: false },
      axisLabel: {
        show: true,
        rotate: 90,
        fontSize: 12,
      },
      data: alertSummaryXAxis.xaxis,
    },
    yAxis: {
      name: "Risk",
      nameLocation: "middle",
      label: {
       
        fontFamily: "IBM Plex Sans, sans-serif",
      },
      nameGap: 35,
      nameTextStyle: {
        fontFamily: "IBM Plex Sans, sans-serif",
        fontWeight:200,
      },
      type: "value",
      min: 0,
      max: 100,
      interval: 10,
      splitLine: {
        lineStyle: {
          color: [
            "#827f7f", // default split line color 10
            "#e2e3e1", // default split line color 20
            "#e2e3e1", // default split line color 30
            "#e2e3e1", // default split line color 40
            "#e2e3e1", // darker split line color at 50
            "#e2e3e1", // default split line color 60
            "#e2e3e1", // darker split line color at 70
            "#827f7f", // darker split line color at 80
            "#e2e3e1", // darker split line color at 90
            "#827f7f", // darker split line color at 100
          ],
          opacity: 1, // set opacity to 1 to make the colors fully visible
          type: "dashed",
        },
      },
    },
    visualMap: {
      // show: false,
      top: 0,
      right: 0,
      orient: "horizontal",
      seriesIndex: 0,
      pieces: [
        {
          gte: 0,
          lte: 70,
          color: "#19543E",
          label: "0-70 →   " + alertSummaryXAxis.data.filter(value => value > -1 && value <= 70).length
        },
        {
          gt: 70,
          lte: 90,
          color: "#ffbf00",
          label: "71-90 →   " + alertSummaryXAxis.data.filter(value => value > 70 && value <= 90).length
        },
        {
          gt: 90,
          lte: 100,
          color: "#FD0100",
          label: "91-100 →   " + alertSummaryXAxis.data.filter(value => value > 90 && value <= 100).length
        },
      ],
      outOfRange: {
        color: "#999",
      },
    },
    series: [
      {
        type: "scatter",
        data: alertSummaryXAxis.data,
        itemStyle: {
          color: "#f08080",
          borderWidth: 0.7,
        },
      },
      {
        type: "bar",
        barWidth: 1,
        data: alertSummaryXAxis.data,
        itemStyle: {
          color: "#c7c1c1",
        },
      },
    ],
    markLine: {
      data: [
        {
          yAxis: 90,
          lineStyle: {
            color: "#00040a",
            type: "dashed",
          },
          label: {
            formatter: "Threshold: {c}",
          },
        },
      ],
    },
  }
 
  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "400px", width: "93vw" }} option={getOption} />
    </React.Fragment>
  )
}
 
export default LineTwo;