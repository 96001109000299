import React, { useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

import ReactApexChart from "react-apexcharts";

//import Breadcrumbs
import Breadcrumbs from "../../../components/Common/Breadcrumb";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  Row,
  Table,
} from "reactstrap";

import CountUp from "react-countup";

/** import Mini Widget data */
import { UDMainDashboardTopRowItems } from "../../../common/data/dashboard";
import { widgetUsersData } from "../../../common/data/dashboarduserdata";
import WalletBalance from "../WalletBalance";
import InvestedOverview from "../InvestedOverview";
import MarketOverview from "../MarketOverview";
import Locations from "../Locations";
import Trading from "../Trading";
import Transactions from "../Transactions";
import RecentActivity from "../RecentActivity";
import NewSlider from "../NewSlider";
import UserDrivingScoreController from "../../../controller/Dashboard/UserDashboardController";
import ValueFormat from "../../../util/ValueFormat.js";
import { useSelector } from "react-redux";
import giftBox from "../../../assets/images/giftbox.png";
import {
  layoutTheme,
  layoutTypes,
  layoutWidthTypes,
  layoutPositions,
  topBarThemeTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes,
} from "../../../constants/layout";
import {
  SC_USER_ID,
  SC_USER_LOGIN_TYPE,
  SC_USER_FIRST_NAME,
  SC_LOGIN_USER_FIRST_NAME,
  SC_TOTAL_RIDE_COUNT,
  SC_LOCK_STATUS,
} from "../../../constant/StorageConstant";
import { Link, useLocation } from "react-router-dom";
import RiskCountChart from "../RiskCountChart";
import OneRiskAlertChart from "../../Admin/Charts/oneRiskeChart";
import StreesStrainChart from "../../Admin/Charts/streesStrainChart";
import Loader from "../../../components/Common/loader";
import ExportScreenPdf from "../../../components/ExportScreenPdf/index.js";
import OverSpeedLocations from "../OverSpeedLocation";
import Converter from "../../../components/Common/EncodeDecode";
import { overSpeedDataTemps, riskAlertsDataTemps } from "../RiskAlertTableData";
import LineBars from "../../Admin/Charts/weekWiseBehaviourChart";
import ApexChartScore from "../scoreChart";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";

import OverAllDrivingScore from "../overAllDrivingScore";
import ToastService from "../../../components/Common/toastService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { displayText } from "../../../constant/MessageConstant";
import ViewPdf from "../../../components/ExportScreenPdf/indexReact2Pdf.js";
import "./UserDashboardSubMenu.css";
import UserDashboardSubMenu from "./UserDashboardSubMenu.js";

const options0 = {
  chart: {
    type: "line",
    width: 100,
    height: 80,
    sparkline: {
      enabled: true,
    },
    toolbar: { show: false },
  },
  colors: ["#5156be"],
  stroke: {
    curve: "straight",
    width: 2,
  },
  xaxis: {
    labels: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
  tooltip: {
    fixed: {
      enabled: false,
    },
    x: {
      show: true,
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return "";
        },
      },
    },
    marker: {
      show: false,
    },
  },
};
const options1 = {
  chart: {
    type: "line",
    width: 100,
    height: 80,
    sparkline: {
      enabled: true,
    },
    toolbar: { show: false },
  },
  colors: ["#1A9558"],
  stroke: {
    curve: "smooth",
    width: 2,
  },
  xaxis: {
    labels: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
  tooltip: {
    fixed: {
      enabled: false,
    },
    x: {
      show: true,
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return "";
        },
      },
    },
    marker: {
      show: false,
    },
  },
};
const options2 = {
  chart: {
    type: "line",
    width: 100,
    height: 0,
    sparkline: {
      enabled: true,
    },
    toolbar: { show: false },
  },
  colors: ["#F09E18"],
  stroke: {
    curve: "smooth",
    width: 2,
  },
  xaxis: {
    labels: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
  tooltip: {
    fixed: {
      enabled: false,
    },
    x: {
      show: true,
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return "";
        },
      },
    },
    marker: {
      show: false,
    },
  },
};
const options3 = {
  chart: {
    type: "line",
    width: 100,
    height: 0,
    sparkline: {
      enabled: true,
    },
    toolbar: { show: false },
  },
  colors: ["#FA4656"],
  stroke: {
    curve: "smooth",
    width: 2,
  },
  xaxis: {
    labels: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
  },

  tooltip: {
    fixed: {
      enabled: false,
    },
    x: {
      show: true,
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return "";
        },
      },
    },
    marker: {
      show: false,
    },
  },
};

const percentage = 70;

const Section4 = () => {
  const userType = localStorage.getItem(SC_USER_LOGIN_TYPE);

  const [UDMainDashboardTopRowItemsTemp, setUDMainDashboardTopRowItemsTemp] =
    useState(UDMainDashboardTopRowItems);
  const [overSpeedDataList, setOverSpeedDataList] =
    useState(riskAlertsDataTemps);
  const [UDMainDashboardSafetyTableData, setUDMainDashboardSafetyTableData] =
    useState(widgetUsersData);

  const WScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };

  useEffect(() => {
    WScroll();
  }, []);

  const [userFirstName, setUserFirstName] = useState(
    localStorage.getItem(SC_LOGIN_USER_FIRST_NAME)
  );
  const [userName, setUserName] = useState(
    localStorage.getItem(SC_USER_FIRST_NAME)
  );
  const [option0, setOption0] = useState(options0);
  const [option1, setOption1] = useState(options1);
  const [option2, setOption2] = useState(options2);
  const [option3, setOption3] = useState(options3);
  const [topWidgetGraphOptions, setTopWidgetGraphOptions] = useState({
    options0: options0,
    options1: options1,
    options2: options2,
    options3: options3,
  });
  const [totalSafeRidePer, setTotalSafeRidePer] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [loginType, setLoginType] = useState("");
  const { layoutType } = useSelector((state) => ({
    layoutType: state.Layout.layoutType,
  }));
  const [safetyPieData, setSafetyPieData] = useState([]);
  const [osMcuMsuProps, setOsMcuMsuProps] = useState([]);
  const [aCBProps, setACBProps] = useState([]);
  const [riskAlertPdf, setRiskAlertPdf] = useState([]);
  const [leaderBoardProps, setLeaderBoardProps] = useState([]);
  const [overallDataProps, setOverallDataProps] = useState([]);
  const [rccProps, setRccProps] = useState({});
  const [rideNamesPerCat, setRideNamesPerCat] = useState([]);
  const [aCBDataProps, setACBDataProps] = useState([]);
  const [osDataProps, setOsDataProps] = useState([]);
  const [msuPdfDataProps, setMsuPdfDataProps] = useState([]);
  const [mcuPdfDataProps, setMcuPdfDataProps] = useState([]);
  const [locationDataPdf, setLocationDataPdf] = useState([]);



  const getTotalValues = async () => {
    setShowLoader((current) => current + 1);
    var result = await UserDrivingScoreController.totalValuesController();
    setShowLoader((current) => current - 1);
    var resultJSON = JSON.parse(result);
    var currentMontData = [];

    var status = resultJSON.status;

    if (status == "SUCCESS") {
      var resultJSONData = resultJSON.data;

      var totalRide = 0;
      var safeRide = 0;
      // console.log("getTotalValues: " + JSON.stringify(resultJSONData));

      resultJSONData.map((apiResultTemp) => {
        var overallData = ValueFormat.parseAsObject(apiResultTemp.overallData);
        var currentYearData = ValueFormat.parseAsObject(
          apiResultTemp.currentYearData
        );
        var currentMonthData = ValueFormat.parseAsObject(
          apiResultTemp.currentMonthData
        );
        var overallRideBoxList = ValueFormat.parseAsArray(
          apiResultTemp.overallRideBoxList
        );
        var drivingScoreList = ValueFormat.roundArrayValue(
          ValueFormat.parseAsArray(currentMonthData.drivingScoreList)
        );
        var deviceNameList = ValueFormat.parseAsArray(
          currentMonthData.deviceNameList
        );
        for (let i = 0; i < drivingScoreList.length; i++) {
          var driveScoreListTemp = drivingScoreList[i];

          var deviceNameListTemp = deviceNameList[i];
          var deviceName = deviceNameListTemp.name;
          var driveScoreResult = { x: deviceName, y: driveScoreListTemp };
          currentMontData.push(deviceName);
        }

        overallRideBoxList.map((apiData) => {
          var rideBox = ValueFormat.parseAsInt(apiData.rideBox);
          totalRide += ValueFormat.parseAsInt(apiData.totalRides);
          if (rideBox == 1) {
            safeRide = apiData.totalRides;
          }
        });

        setTotalSafeRidePer(
          ValueFormat.parseAsFloat((safeRide / totalRide) * 100, 0)
        );

        setUDMainDashboardTopRowItemsTemp(
          UDMainDashboardTopRowItemsTemp.map((staticObjectTemp, i) => {
            var isTypeMatched = false;
            var overallValue = 0;
            var cYearValue = 0;
            var cMDataList = [];
            var secondaryData = 0;
            if (staticObjectTemp.type === "RIDE") {
              isTypeMatched = true;
              overallValue = ValueFormat.parseAsInt(overallData.totalRides);
              localStorage.setItem(SC_TOTAL_RIDE_COUNT, overallValue);
              cYearValue = ValueFormat.parseAsInt(currentYearData.totalRides);
              cMDataList = ValueFormat.roundArrayValue(
                ValueFormat.parseAsArray(currentMonthData.drivingScoreList)
              );
            } else if (staticObjectTemp.type === "DISTANCE") {
              isTypeMatched = true;
              overallValue = ValueFormat.parseAsFloat(
                overallData.totalKiloMeters,
                2
              );
              cYearValue = ValueFormat.parseAsFloat(
                currentYearData.totalKiloMeters,
                2
              );
              cMDataList = ValueFormat.parseAsArray(
                currentMonthData.kiloMeterList
              );
            } else if (staticObjectTemp.type === "DURATION") {
              isTypeMatched = true;
              overallValue = ValueFormat.parseAsFloat(
                overallData.totalHours,
                2
              );
              cYearValue = ValueFormat.parseAsFloat(
                currentYearData.totalHours,
                2
              );
              cMDataList = ValueFormat.parseAsArray(
                currentMonthData.travelTimeList
              );
            } else if (staticObjectTemp.type === "ALERT") {
              isTypeMatched = true;
              overallValue = ValueFormat.parseAsInt(
                overallData.totalRiskAlerts
              );
              secondaryData = ValueFormat.parseAsInt(
                overallData.totalManualAlerts
              );
              cYearValue =
                ValueFormat.parseAsInt(currentYearData.totalRiskAlerts) +
                " | " +
                ValueFormat.parseAsInt(currentYearData.totalManualAlerts);
              cMDataList = ValueFormat.roundArrayValue(
                ValueFormat.parseAsArray(currentMonthData.riskAlertList)
              );
            }

            if (isTypeMatched === true) {
              let staticObjectTempReplace = {
                overallData: overallValue,
                currentYearValue: cYearValue,
                secondaryData: secondaryData,
                currentMonthGraphData: cMDataList,
              };
              return { ...staticObjectTemp, ...staticObjectTempReplace };
            } else {
              return staticObjectTemp;
            }
          })
        );
        setOption0((prev) => {
          return {
            ...prev,
            tooltip: {
              custom: function ({ series, seriesIndex, dataPointIndex }) {
                const xValue = series[seriesIndex][dataPointIndex];
                const yValue = currentMontData[dataPointIndex];
                const formattedYValue =
                  yValue.charAt(0).toUpperCase() +
                  yValue.slice(1).toLowerCase();

                return (
                  '<div class="custom-tooltip p-3">' +
                  "<span>Data: " +
                  xValue +
                  "</span>" +
                  "<br>" +
                  "<span>Ride Name: " +
                  formattedYValue +
                  "</span>" +
                  "</div>"
                );
              },
            },
          };
        });
        setOption1((prev) => {
          return {
            ...prev,
            tooltip: {
              custom: function ({ series, seriesIndex, dataPointIndex }) {
                const xValue = series[seriesIndex][dataPointIndex];
                const yValue = currentMontData[dataPointIndex];
                const formattedYValue =
                  yValue.charAt(0).toUpperCase() +
                  yValue.slice(1).toLowerCase();

                return (
                  '<div class="custom-tooltip p-3">' +
                  "<span>Data: " +
                  xValue +
                  "</span>" +
                  "<br>" +
                  "<span>Ride Name: " +
                  formattedYValue +
                  "</span>" +
                  "</div>"
                );
              },
            },
          };
        });
        setOption2((prev) => {
          return {
            ...prev,
            tooltip: {
              custom: function ({ series, seriesIndex, dataPointIndex }) {
                const xValue = series[seriesIndex][dataPointIndex];
                const yValue = currentMontData[dataPointIndex];
                const formattedYValue =
                  yValue.charAt(0).toUpperCase() +
                  yValue.slice(1).toLowerCase();

                return (
                  '<div class="custom-tooltip p-3">' +
                  "<span>Data: " +
                  xValue +
                  "</span>" +
                  "<br>" +
                  "<span>Ride Name: " +
                  formattedYValue +
                  "</span>" +
                  "</div>"
                );
              },
            },
          };
        });
        setOption3((prev) => {
          return {
            ...prev,
            tooltip: {
              custom: function ({ series, seriesIndex, dataPointIndex }) {
                const xValue = series[seriesIndex][dataPointIndex];
                const yValue = currentMontData[dataPointIndex];
                const formattedYValue =
                  yValue.charAt(0).toUpperCase() +
                  yValue.slice(1).toLowerCase();

                return (
                  '<div class="custom-tooltip p-3">' +
                  "<span>Data: " +
                  xValue +
                  "</span>" +
                  "<br>" +
                  "<span>Ride Name: " +
                  formattedYValue +
                  "</span>" +
                  "</div>"
                );
              },
            },
          };
        });
      });
    } else {
      // var resultJSONCode = resultJSON.error.code;
      // if (resultJSONCode == "E007" && userType == "USER") {
      //   ToastService.errormsg(displayText.ER_007);
      //   setTimeout(() => {
      //     window.location.href = "/contacts-profile";
      //   }, 3500);
      // } else if (resultJSONCode == "E007" && userType == "ADMIN") {
      //   ToastService.errormsg(displayText.ER_007);
      //   setTimeout(() => {
      //     window.location.href = "/contacts-profile";
      //   }, 3500);
      // }
      console.log("Status failed");
    }
  };

  const getSafetyData = async () => {
    var result = await UserDrivingScoreController.userSafetyDetailController();
    var resultJSON = JSON.parse(result);
    var resultJSONData = resultJSON.data;

    // console.log("getSafetyData: " + JSON.stringify(resultJSONData));
    var totalRideDataList = [];
    var totalKmsList = [];
    var totalHrsList = [];
    var totalRiskList = [];

    for (let categoryCount = 0; categoryCount < 6; categoryCount++) {
      var totalRides = 0;
      var totalKms = 0;
      var totalHrs = 0;
      var totalRiskAlerts = 0;

      let rideBoxList = ValueFormat.parseAsArray(resultJSONData[0].rideBoxList);

      for (let rideBox = 0; rideBox < rideBoxList.length; rideBox++) {
        var rideBoxValue = ValueFormat.parseAsInt(rideBoxList[rideBox].rideBox);

        if (rideBoxValue === categoryCount + 1) {
          totalRides = ValueFormat.parseAsInt(rideBoxList[rideBox].totalRides);
          totalKms = ValueFormat.parseAsFloat(
            rideBoxList[rideBox].totalKiloMeters,
            2
          );
          totalHrs = ValueFormat.parseAsFloat(
            rideBoxList[rideBox].totalHours,
            2
          );
          totalRiskAlerts = ValueFormat.parseAsInt(
            rideBoxList[rideBox].totalRiskAlerts
          );
        }
      }

      totalRideDataList.push(totalRides);
      totalKmsList.push(totalKms);
      totalHrsList.push(totalHrs);
      totalRiskList.push(totalRiskAlerts);
    }

    setUDMainDashboardSafetyTableData(
      UDMainDashboardSafetyTableData.map((staticObjectTemp) => {
        var isTypeMatched = false;
        var rideDataList = [];

        if (staticObjectTemp.type === "RIDE") {
          isTypeMatched = true;
          rideDataList = ValueFormat.parseAsArray(totalRideDataList);
        } else if (staticObjectTemp.type === "DISTANCE") {
          isTypeMatched = true;
          rideDataList = ValueFormat.parseAsArray(totalKmsList);
        } else if (staticObjectTemp.type === "DURATION") {
          isTypeMatched = true;
          rideDataList = ValueFormat.parseAsArray(totalHrsList);
        } else if (staticObjectTemp.type === "ALERT") {
          isTypeMatched = true;
          rideDataList = ValueFormat.parseAsArray(totalRiskList);
        }

        if (isTypeMatched === true) {
          let staticObjectTempReplace = { dataListValues: rideDataList };
          return { ...staticObjectTemp, ...staticObjectTempReplace };
        } else {
          return staticObjectTemp;
        }
      })
    );
  };

  useEffect(() => {
    getTotalValues();
  }, []);

  useEffect(() => {
    getSafetyData();
  }, []);
  useEffect(() => {
    setLoginType(localStorage.getItem(SC_USER_LOGIN_TYPE));
  }, [loginType]);

  const [overSpeedData, setOverSpeedData] = useState({
    overSpeedData: [0],
    alertData: 0,
    mobileScreen: [0],
    stressStrainData: [0],
  });
  const [overSpeedData2, setOverSpeedData2] = useState({
    overSpeedData: [0],
    alertData: 0,
    mobileScreen: [0],
    stressStrainData: [0],
  });
  const [overSpeedData3, setOverSpeedData3] = useState({
    overSpeedData: [0],
    alertData: 0,
    mobileScreen: [0],
    stressStrainData: [0],
  });
  const [overSpeedData4, setOverSpeedData4] = useState({
    overSpeedData: [0],
    alertData: 0,
    mobileScreen: [0],
    stressStrainData: [0],
  });
  const [overSpeedData5, setOverSpeedData5] = useState({
    overSpeedData: [0],
    alertData: 0,
    mobileScreen: [0],
    stressStrainData: [0],
  });
  // const [overSpeedData6, setOverSpeedData6] = useState({overSpeedData:[0],alertData:0,mobileScreen:[0],stressStrainData:[0]});
  const [month, setMonth] = useState("Aug");
  const [shouldBreak, setShouldBreak] = useState(
    window.innerWidth >= 1200 && window.innerWidth <= 1375
  );
  const getWeek1Behaviour = async () => {
    setShowLoader((current) => current + 1);
    var result = await UserDrivingScoreController.week1BehaviourController({
      startDate: month == "Aug" ? "2023-08-01 00:00:00" : "2023-09-01 00:00:00",
      endDate: month == "Aug" ? "2023-08-05 23:59:59" : "2023-09-02 23:59:59",
    });

    setShowLoader((current) => current - 1);
    var resultJSON = JSON.parse(result);
    var resultJSONData = resultJSON.data;
    var overSpeedDataTemp = 0;
    var mobileScreen = {
      on: 0,
      call: 0,
    };
    var alertDataCount = 0;
    var stressStrainData = {
      acc: 0,
      cor: 0,
      brak: 0,
    };
    // console.log("getWeekWiseBehaviour: " + JSON.stringify(resultJSONData));
    resultJSONData.map((apiData) => {
      apiData.riskAlertDetailCount.map((value) => {
        // console.log(value);

        if (value.subCategory == "OVER_SPEED") {
          value.riskValueList.map((val) => {
            overSpeedDataTemp += 1;
          });
        } else if (value.category == "ALERT_DATA") {
          value.riskValueList.map((val) => {
            alertDataCount += 1;
          });
        } else if (value.subCategory == "MOBILE_SCREEN") {
          value.riskValueList.map((val) => {
            mobileScreen.on += 1;
          });
        } else if (value.subCategory == "MOBILE_USE") {
          value.riskValueList.map((val) => {
            mobileScreen.call += 1;
          });
        } else if (value.subCategory == "SEVERE_ACCELERATION") {
          value.riskValueList.map((val) => {
            stressStrainData.acc += 1;
          });
        } else if (value.subCategory == "SEVERE_CORNERING") {
          value.riskValueList.map((val) => {
            stressStrainData.cor += 1;
          });
        } else if (value.subCategory == "SEVERE_BRAKING") {
          value.riskValueList.map((val) => {
            stressStrainData.brak += 1;
          });
        }
      });
    });
    setOverSpeedData({
      overSpeedData: overSpeedDataTemp,
      alertData: alertDataCount,
      mobileScreen: mobileScreen,
      stressStrainData: stressStrainData,
    });
  };

  const getWeek2Behaviour = async () => {
    setShowLoader((current) => current + 1);
    var result = await UserDrivingScoreController.week2BehaviourController({
      startDate: month == "Aug" ? "2023-08-06 00:00:00" : "2023-09-03 00:00:00",
      endDate: month == "Aug" ? "2023-08-12 23:59:59" : "2023-09-09 00:00:00",
    });

    setShowLoader((current) => current - 1);
    var resultJSON = JSON.parse(result);
    var resultJSONData = resultJSON.data;
    var overSpeedDataTemp = 0;
    var mobileScreen = {
      on: 0,
      call: 0,
    };
    var alertDataCount = 0;
    var stressStrainData = {
      acc: 0,
      cor: 0,
      brak: 0,
    };
    // console.log("getWeekWiseBehaviour: " + JSON.stringify(resultJSONData));
    resultJSONData.map((apiData) => {
      apiData.riskAlertDetailCount.map((value) => {
        // console.log(value);

        if (value.subCategory == "OVER_SPEED") {
          value.riskValueList.map((val) => {
            overSpeedDataTemp += 1;
          });
        } else if (value.category == "ALERT_DATA") {
          value.riskValueList.map((val) => {
            alertDataCount += 1;
          });
        } else if (value.subCategory == "MOBILE_SCREEN") {
          value.riskValueList.map((val) => {
            mobileScreen.on += 1;
          });
        } else if (value.subCategory == "MOBILE_USE") {
          value.riskValueList.map((val) => {
            mobileScreen.call += 1;
          });
        } else if (value.subCategory == "SEVERE_ACCELERATION") {
          value.riskValueList.map((val) => {
            stressStrainData.acc += 1;
          });
        } else if (value.subCategory == "SEVERE_CORNERING") {
          value.riskValueList.map((val) => {
            stressStrainData.cor += 1;
          });
        } else if (value.subCategory == "SEVERE_BRAKING") {
          value.riskValueList.map((val) => {
            stressStrainData.brak += 1;
          });
        }
      });
    });
    setOverSpeedData2({
      overSpeedData: overSpeedDataTemp,
      alertData: alertDataCount,
      mobileScreen: mobileScreen,
      stressStrainData: stressStrainData,
    });
  };
  const getWeek3Behaviour = async () => {
    setShowLoader((current) => current + 1);
    var result = await UserDrivingScoreController.week3BehaviourController({
      startDate: month == "Aug" ? "2023-08-13 00:00:00" : "2023-09-10 00:00:00",
      endDate: month == "Aug" ? "2023-08-19 23:59:59" : "2023-09-16 00:00:00",
    });

    setShowLoader((current) => current - 1);
    var resultJSON = JSON.parse(result);
    var resultJSONData = resultJSON.data;
    var overSpeedDataTemp = 0;
    var mobileScreen = {
      on: 0,
      call: 0,
    };
    var alertDataCount = 0;
    var stressStrainData = {
      acc: 0,
      cor: 0,
      brak: 0,
    };
    // console.log("getWeekWiseBehaviour: " + JSON.stringify(resultJSONData));
    resultJSONData.map((apiData) => {
      apiData.riskAlertDetailCount.map((value) => {
        // console.log(value);

        if (value.subCategory == "OVER_SPEED") {
          value.riskValueList.map((val) => {
            overSpeedDataTemp += 1;
          });
        } else if (value.category == "ALERT_DATA") {
          value.riskValueList.map((val) => {
            alertDataCount += 1;
          });
        } else if (value.subCategory == "MOBILE_SCREEN") {
          value.riskValueList.map((val) => {
            mobileScreen.on += 1;
          });
        } else if (value.subCategory == "MOBILE_USE") {
          value.riskValueList.map((val) => {
            mobileScreen.call += 1;
          });
        } else if (value.subCategory == "SEVERE_ACCELERATION") {
          value.riskValueList.map((val) => {
            stressStrainData.acc += 1;
          });
        } else if (value.subCategory == "SEVERE_CORNERING") {
          value.riskValueList.map((val) => {
            stressStrainData.cor += 1;
          });
        } else if (value.subCategory == "SEVERE_BRAKING") {
          value.riskValueList.map((val) => {
            stressStrainData.brak += 1;
          });
        }
      });
    });
    setOverSpeedData3({
      overSpeedData: overSpeedDataTemp,
      alertData: alertDataCount,
      mobileScreen: mobileScreen,
      stressStrainData: stressStrainData,
    });
  };

  const containerIds = ["container1", "container2"];

  const getWeek4Behaviour = async () => {
    setShowLoader((current) => current + 1);
    var result = await UserDrivingScoreController.week4BehaviourController({
      startDate: month == "Aug" ? "2023-08-20 00:00:00" : "2023-09-17 00:00:00",
      endDate: month == "Aug" ? "2023-08-26 23:59:59" : "2023-09-23 00:00:00",
    });

    setShowLoader((current) => current - 1);
    var resultJSON = JSON.parse(result);
    var resultJSONData = resultJSON.data;
    var overSpeedDataTemp = 0;
    var mobileScreen = {
      on: 0,
      call: 0,
    };
    var alertDataCount = 0;
    var stressStrainData = {
      acc: 0,
      cor: 0,
      brak: 0,
    };
    // console.log("getWeekWiseBehaviour: " + JSON.stringify(resultJSONData));
    resultJSONData.map((apiData) => {
      apiData.riskAlertDetailCount.map((value) => {
        // console.log(value);

        if (value.subCategory == "OVER_SPEED") {
          value.riskValueList.map((val) => {
            overSpeedDataTemp += 1;
          });
        } else if (value.category == "ALERT_DATA") {
          value.riskValueList.map((val) => {
            alertDataCount += 1;
          });
        } else if (value.subCategory == "MOBILE_SCREEN") {
          value.riskValueList.map((val) => {
            mobileScreen.on += 1;
          });
        } else if (value.subCategory == "MOBILE_USE") {
          value.riskValueList.map((val) => {
            mobileScreen.call += 1;
          });
        } else if (value.subCategory == "SEVERE_ACCELERATION") {
          value.riskValueList.map((val) => {
            stressStrainData.acc += 1;
          });
        } else if (value.subCategory == "SEVERE_CORNERING") {
          value.riskValueList.map((val) => {
            stressStrainData.cor += 1;
          });
        } else if (value.subCategory == "SEVERE_BRAKING") {
          value.riskValueList.map((val) => {
            stressStrainData.brak += 1;
          });
        }
      });
    });
    setOverSpeedData4({
      overSpeedData: overSpeedDataTemp,
      alertData: alertDataCount,
      mobileScreen: mobileScreen,
      stressStrainData: stressStrainData,
    });
  };
  const getWeek5Behaviour = async () => {
    setShowLoader((current) => current + 1);
    var result = await UserDrivingScoreController.week5BehaviourController({
      startDate: month == "Aug" ? "2023-08-27 00:00:00" : "2023-09-24 00:00:00",
      endDate: month == "Aug" ? "2023-08-31 23:59:59" : "2023-09-30 00:00:00",
    });

    setShowLoader((current) => current - 1);
    var resultJSON = JSON.parse(result);
    var resultJSONData = resultJSON.data;
    var overSpeedDataTemp = 0;
    var mobileScreen = {
      on: 0,
      call: 0,
    };
    var alertDataCount = 0;
    var stressStrainData = {
      acc: 0,
      cor: 0,
      brak: 0,
    };
    // console.log("getWeekWiseBehaviour: " + JSON.stringify(resultJSONData));
    resultJSONData.map((apiData) => {
      apiData.riskAlertDetailCount.map((value) => {
        // console.log(value);

        if (value.subCategory == "OVER_SPEED") {
          value.riskValueList.map((val) => {
            overSpeedDataTemp += 1;
          });
        } else if (value.category == "ALERT_DATA") {
          value.riskValueList.map((val) => {
            alertDataCount += 1;
          });
        } else if (value.subCategory == "MOBILE_SCREEN") {
          value.riskValueList.map((val) => {
            mobileScreen.on += 1;
          });
        } else if (value.subCategory == "MOBILE_USE") {
          value.riskValueList.map((val) => {
            mobileScreen.call += 1;
          });
        } else if (value.subCategory == "SEVERE_ACCELERATION") {
          value.riskValueList.map((val) => {
            stressStrainData.acc += 1;
          });
        } else if (value.subCategory == "SEVERE_CORNERING") {
          value.riskValueList.map((val) => {
            stressStrainData.cor += 1;
          });
        } else if (value.subCategory == "SEVERE_BRAKING") {
          value.riskValueList.map((val) => {
            stressStrainData.brak += 1;
          });
        }
      });
    });
    setOverSpeedData5({
      overSpeedData: overSpeedDataTemp,
      alertData: alertDataCount,
      mobileScreen: mobileScreen,
      stressStrainData: stressStrainData,
    });
  };

  useEffect(() => { }, [month]);

  // useEffect(()=>{
  //   localStorage.removeItem("_grecaptcha")
  // },[])

  //meta title

  useEffect(() => {
    function handleResize() {
      setShouldBreak(window.innerWidth >= 1200 && window.innerWidth <= 1375);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getSafetyDataProps = (data) => {
    setSafetyPieData(data);
  };
  const getOsMcuMsuProps = (data) => {
    setOsMcuMsuProps(data);
  };
  const getACBProps = (data) => {
    setACBProps(data);
  };

  const getRiskAlertPdf = (data) => {
    setRiskAlertPdf(data);
  }
  const getLeaderboardProps = (data) => {
    setLeaderBoardProps(data);
  };

  const getOverAllDataProps = (data) => {
    setOverallDataProps(data);
  };

  const getRCCProps = (data) => {
    setRccProps(data);
  }

  const getRideNameList = (data) => {
    setRideNamesPerCat(data);
  }

  const getACBDataProps = (data) => {
    setACBDataProps(data);
  }

  const getOSDataProps = (data) => {
    setOsDataProps(data);
  }

  const getMcuDataProps = (data) => {
    setMsuPdfDataProps(data);
  }

  const getMsuDataProps = (data) => {
    setMcuPdfDataProps(data);
  }

  const getLocationDataPdf = (newData) => {
    setLocationDataPdf(newData);
  }

  return (
    <React.Fragment>
      {showLoader && <Loader />}
      <div className="page-content">
        <div className="ps-2 pe-2">
          <div id="container1">
            {/* <Container fluid> */}
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard" />
            
            <Row>
              <Col sm={6}>
                {(loginType == "ADMIN" || loginType == "SUPER_ADMIN") ? (
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: 400,
                    }}
                  >
                    Welcome, You are viewing{" "}
                    <span
                      style={{
                        fontSize: "12px",
                        fontWeight: 800,
                      }}
                    >
                      {userFirstName}'s
                    </span>{" "}
                    dashboard summary.
                  </span>
                ) : (
                    <UserDashboardSubMenu />
                      )}
              </Col>
              <Col sm={6}>
                <div className="d-flex justify-content-end pb-2">
                  <div className="d-flex justify-content-between ">
                    {(loginType == "ADMIN" ||
                      loginType == "SUPER_ADMIN" ||
                      loginType == "SUPER_ADMIN_AMAZON") && (
                        <Link to={"/myRides"} className="pe-2">
                          <button
                            data-tooltip-id="gtr"
                            className="btn btn-primary btn-sm"
                          >
                            Go To Rides
                            <ReactTooltip
                              id="gtr"
                              place="bottom"
                              content="Go To Rides"
                              style={{ zIndex: "100", backgroundColor: "black" }}
                            />
                          </button>
                        </Link>
                      )}
                    <div className="">
                      <ExportScreenPdf
                        containerIds={containerIds}
                        fileName={
                          loginType == "ADMIN" || loginType == "SUPER_ADMIN"
                            ? userFirstName + "'s" + " dashboard"
                            : userName + "'s" + " dashboard"
                        }
                      />
                    </div>
                    {/* <div
                      className="p-2 pt-0 d-flex"
                    >
                      <ViewPdf
                        safetyPieData={safetyPieData}
                        osMcuMsuProps={osMcuMsuProps}
                        aCBProps={aCBProps}
                        riskAlertPdf={riskAlertPdf}
                        leaderBoardProps={leaderBoardProps}
                        overallDataProps={overallDataProps}
                        UDMainDashboardTopRowItemsTemp={
                          UDMainDashboardTopRowItemsTemp
                        }
                        usertotalSafeRidePer={totalSafeRidePer}
                        UDMainDashboardSafetyTableData={
                          UDMainDashboardSafetyTableData
                        }
                        rccProps={rccProps}
                        rideNamesPerCat={rideNamesPerCat}
                        aCBDataProps={aCBDataProps}
                        osDataProps={osDataProps}
                        msuPdfDataProps={msuPdfDataProps}
                        mcuPdfDataProps={mcuPdfDataProps}
                        locationDataPdf={locationDataPdf}
                      />
                    </div> */}
                  </div>
                </div>
              </Col>
            </Row>

          </div>
          <div id="container2">
         
            <Row>
              <MarketOverview loaderCount={setShowLoader} />
              <Locations
                getLocationDataPdf={getLocationDataPdf}
              />
            </Row>

            <Trading getRideNameList={getRideNameList} loaderCount={setShowLoader} />

            <br />
            <Row>
              <Col xl={3}>
                <Card>
                  <CardBody>
                    <Row style={{ display: "flex" }}>
                      <Col xl={12} lg={12}>
                        <p
                          style={{
                            fontStyle: "13px !important",
                            fontWeight: 600,
                            textAlign: "center",
                          }}
                        >
                          Anticipation
                        </p>
                      </Col>
                      <Col xl={12} lg={12}>
                        <p
                          style={{
                            fontStyle: "12px !important",
                            fontWeight: 400,
                            textAlign: "center",
                          }}
                        >
                          Ability not to enter into the red risk
                        </p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={5}>
                <Card>
                  <CardBody>
                    <Row style={{ display: "flex" }}>
                      <Col xl={12} lg={12}>
                        <p
                          style={{
                            fontStyle: "13px",
                            fontWeight: 600,
                            textAlign: "center",
                          }}
                        >
                          Self-Confidence
                        </p>
                      </Col>
                      <Col xl={12} lg={12}>
                        <p
                          style={{
                            fontStyle: "12px",
                            fontWeight: 400,
                            textAlign: "center",
                          }}
                        >
                          Ability to maintain a range of risk for majority time
                          of the ride
                        </p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4}>
                <Card>
                  <CardBody>
                    <Row style={{ display: "flex" }}>
                      <Col xl={12} lg={12}>
                        <p
                          style={{
                            fontStyle: "13px !important",
                            fontWeight: 600,
                            textAlign: "center",
                          }}
                        >
                          Driving Skill
                        </p>
                      </Col>
                      <Col xl={12} lg={12}>
                        <p
                          style={{
                            fontStyle: "12px !important",
                            fontWeight: 400,
                            textAlign: "center",
                          }}
                        >
                          Ability to maintain the same level of risk
                        </p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <ToastContainer style={{ width: "auto" }} />
    </React.Fragment>
  );
};

export default Section4;