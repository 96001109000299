export const displayText = {
  DATA_ERR:"Data error",
  DATA_NOT_AVAILABLE: "Data not available",
  FETCHING_DATA: "Fetching data...",
  NO_RECORDS_FOUND: "No records found",
  LOADING: "Loading...",
  LOADING_PDF: "Generating your data into a PDF. This may take a few seconds. Please wait until the process is complete.",
  ER_007:"Sorry, you don't have permission to access this resource",
  SESSION_TIMEOUT:"Your Session has expired",
  DATA_NOT_AVAILABLE_PROFILE:"Sorry, You don't have permission to access this resource. Please contact MotivAI Admin. Thank You.",
  DRIVER_BEHAVIOUR_DESCRIPTION: "Driver Behaviour Score is calculated on a scale of 100 and represents a cumulative sum of the Driving Point, Anticipation Point, Self Confidence Point, Driving Skill Point, Driving Style Point, Driver State Point, Mobile Screen Usage Point, Mobile Call Usage Point, Overspeed KMS Driven Point and Acc/Brak/Cor Point"
};
