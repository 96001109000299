import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { getMarketoverview } from "../../store/actions";
import OverviewCharts from "./OverviewCharts";
import LineColumnArea from "../AllCharts/apex/LineColumnArea";
import UserDrivingScoreController from "../../controller/Dashboard/UserDashboardController.js";
import ValueFormat from "../../util/ValueFormat";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { displayText } from "../../constant/MessageConstant";
import DrivingBehaviour from "../AllCharts/apex/DrivingBehaviour";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import DateTimePickerPopup from "../ModelPopup/DateTimePopup.js";

const   MarketOverview = ({filter, loaderCount }) => {
  const dispatch = useDispatch();

  const [state, setState] = useState("ALL");
  const [dataDisplay, setDataDisplay] = useState(null);
  const { Marketoverview } = useSelector((state) => ({
    Marketoverview: state.dashboard.Marketoverview,
  }));
  const [modalIsOpen, setIsOpen] = useState(false);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");

  const onChangeHandle = (data) => {
    setState(data);
    dispatch(getMarketoverview(data));
  };

  useEffect(() => {
    dispatch(getMarketoverview(state));
  }, [state]);

  useEffect(() => {
    dispatch(getMarketoverview("All"));
  }, [dispatch]);

  const series = [
    {
      name: "Driving Score",
      type: "line",
      data: [0],
    },
    // {
    //   name: "Anticipation",
    //   type: "line",
    //   data: [0],
    // },
    // {
    //   name: "Self Confidence",
    //   type: "line",
    //   data: [0],
    // },
    // {
    //   name: "Driving Skill",
    //   type: "line",
    //   data: [0],
    // },
  ];

  const [drivingData, setDrivingData] = useState(series);
  // const [filter, setFilter] = useState("CURRENT_YEAR");
  const [xAxisData, setXAxisData] = useState([]);
  const getUserBoardData = async () => {
    setDataDisplay(null)
    loaderCount((current) => current + 1);
    var result = await UserDrivingScoreController.getDrivingBehaviourAlertCount(
      filter.durationFilter,
      filter.startDateTime,
      filter.endDateTime,
    );
    loaderCount((current) => current - 1);
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    if(status == "SUCCESS"){
    var resultJSONData = resultJSON.data;
    var drivingScoreDataList = [];
    // var anticipationDataList = [];

    // var selfConfidenceDataList = [];
    // var drivingSkillDataList = [];
    var rideNameList = [];
    var resultData = [];
    var displayData = null;

    // console.log("getUserBoardData: " + JSON.stringify(resultJSONData));
      if(resultJSONData.length > 0){
        resultJSONData.map((apiDataTemp) => {
          var drivingScoreList = ValueFormat.parseAsArray(
            apiDataTemp.drivingScoreList
          );
    
          drivingScoreList.map((apiDrivingData) => {
            var drivingScore = ValueFormat.parseAsInt(apiDrivingData.drivingScore);
            displayData = displayData + drivingScore
            var anticipation = ValueFormat.parseAsInt(apiDrivingData.anticipation);
            var selfConfidence = ValueFormat.parseAsInt(
              apiDrivingData.selfConfidence
            );
            var drivingSkill = ValueFormat.parseAsInt(apiDrivingData.drivingSkill);
            var rideName = ValueFormat.parseAsString(
              apiDrivingData.rideName
            ).substring(4);
            drivingScoreDataList.push(drivingScore);
            // anticipationDataList.push(anticipation);
            // selfConfidenceDataList.push(selfConfidence);
            // drivingSkillDataList.push(drivingSkill);
            rideNameList.push(rideName);
          });
    
    
    
        });


      }
    
      setDataDisplay(displayData > 0 ? true : displayData <= 0 ? false : null  );


    var resultObject = [
      {
        name: "Driving Score",
        type: "line",
        data: drivingScoreDataList,
      },
      // {
      //   name: "Anticipation",
      //   type: "line",
      //   data: anticipationDataList,
      // },
      // {
      //   name: "Self Confidence",
      //   type: "line",
      //   data: selfConfidenceDataList,
      // },
      // {
      //   name: "Driving Skill",
      //   type: "line",
      //   data: drivingSkillDataList,
      // }
    ];

    resultData.push(...resultObject);

    setDrivingData(resultData);
    setXAxisData(rideNameList);
  }else{
    const code = resultJSON.error.code;
    if(code == "E007"){
      setDataDisplay("failed")
    }
  }
  };

  useEffect(() => {
    getUserBoardData();
  }, [filter.durationFilter, filter.startDateTime, filter.endDateTime]);

  return (
    <React.Fragment>
      <Col xl={8}>
        <Card style={{height: 550}}>
          <CardHeader className="cardHeader">
            <div className="d-flex flex-wrap align-items-center ">
              <h5 className="sub_heading my-0">Driving Behaviour</h5>
              {/* <div className="ms-auto">
                <div>
                  <button
                    data-tooltip-id="curd"
                    type="button"
                    className={
                      filter == "CURRENT_DAY"
                        ? "btn btn-secondary btn-sm"
                        : "btn btn-soft-secondary btn-sm"
                    }
                    onClick={() => setFilter("CURRENT_DAY")}
                  >
                    CD
                    <ReactTooltip
                      id="curd"
                      place="top"
                      content="Current Day"
                      style={{ backgroundColor: "black" }}
                    />
                  </button>{" "}
                  <button
                    data-tooltip-id="curw"
                    type="button"
                    className={
                      filter == "CURRENT_WEEK"
                        ? "btn btn-secondary btn-sm"
                        : "btn btn-soft-secondary btn-sm"
                    }
                    onClick={() => setFilter("CURRENT_WEEK")}
                  >
                    CW
                    <ReactTooltip
                      id="curw"
                      place="top"
                      content="Current Week"
                      style={{ backgroundColor: "black" }}
                    />
                  </button>{" "}
                  <button
                    data-tooltip-id="curm"
                    type="button"
                    className={
                      filter == "CURRENT_MONTH"
                        ? "btn btn-secondary btn-sm"
                        : "btn btn-soft-secondary btn-sm"
                    }
                    onClick={() => setFilter("CURRENT_MONTH")}
                  >
                    CM
                    <ReactTooltip
                      id="curm"
                      place="top"
                      content="Current Month"
                      style={{ backgroundColor: "black" }}
                    />
                  </button>{" "}
                  <button
                    data-tooltip-id="cury"
                    type="button"
                    className={
                      filter == "CURRENT_YEAR"
                        ? "btn btn-primary btn-sm"
                        : "btn btn-soft-secondary btn-sm"
                    }
                    onClick={() => setFilter("CURRENT_YEAR")}
                  >
                    CY
                    <ReactTooltip
                      id="cury"
                      place="top"
                      content="Current Year"
                      style={{ backgroundColor: "black" }}
                    />
                  </button>{" "}
                  <button
                          type="button"
                          className={
                            filter == "CUSTOM_DURATION"
                              ? "btn btn-secondary btn-sm"
                              : "btn btn-soft-secondary btn-sm"
                          }
                          onClick={() =>{
                            setIsOpen(true);
                            // setFilter("CUSTOM_DURATION");
                          }}
                          data-tooltip-id="dfdf"
                        >
                          <FontAwesomeIcon icon={faClock}/>
                          <ReactTooltip
                            id="dfdf"
                            place="top"
                            content="Custom Date Selection"
                            style={{ background: "black" ,zIndex:1}}
                          />
                        </button>{" "}
                </div>
              </div> */}
            </div>
          </CardHeader>
          
            <CardBody>
            {dataDisplay == "failed" ? (
          <p className="data_not_available">{displayText.ER_007}</p>
        ): dataDisplay == false ? (<p className="data_not_available">{displayText.DATA_NOT_AVAILABLE}</p>
        ) : dataDisplay == null ? <p className="fetch_data">{displayText.FETCHING_DATA}</p> : "" }
              <Row className="align-items-center">
                <Col xl={12}>
                  <DrivingBehaviour
                    drivingDataList={drivingData}
                    xAxisDataList={xAxisData}
                  />
                </Col>
                {/* <Col xl={4}>
                                <div className="p-4">
                                    <div className="mt-0">
                                        <div className="d-flex align-items-center">
                                            <div className="avatar-sm m-auto">
                                                <span className="avatar-title rounded-circle bg-light-subtle text-dark font-size-16">
                                                    1
                                                </span>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <span className="font-size-16">Coinmarketcap</span>
                                            </div>

                                            <div className="flex-shrink-0">
                                                <span className="badge rounded-pill badge-soft-success font-size-12 fw-medium">+2.5%</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-3">
                                        <div className="d-flex align-items-center">
                                            <div className="avatar-sm m-auto">
                                                <span className="avatar-title rounded-circle bg-light-subtle text-dark font-size-16">
                                                    2
                                                </span>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <span className="font-size-16">Binance</span>
                                            </div>

                                            <div className="flex-shrink-0">
                                                <span className="badge rounded-pill badge-soft-success font-size-12 fw-medium">+8.3%</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-3">
                                        <div className="d-flex align-items-center">
                                            <div className="avatar-sm m-auto">
                                                <span className="avatar-title rounded-circle bg-light-subtle text-dark font-size-16">
                                                    3
                                                </span>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <span className="font-size-16">Coinbase</span>
                                            </div>

                                            <div className="flex-shrink-0">
                                                <span className="badge rounded-pill badge-soft-danger font-size-12 fw-medium">-3.6%</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-3">
                                        <div className="d-flex align-items-center">
                                            <div className="avatar-sm m-auto">
                                                <span className="avatar-title rounded-circle bg-light-subtle text-dark font-size-16">
                                                    4
                                                </span>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <span className="font-size-16">Yobit</span>
                                            </div>

                                            <div className="flex-shrink-0">
                                                <span className="badge rounded-pill badge-soft-success font-size-12 fw-medium">+7.1%</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-3">
                                        <div className="d-flex align-items-center">
                                            <div className="avatar-sm m-auto">
                                                <span className="avatar-title rounded-circle bg-light-subtle text-dark font-size-16">
                                                    5
                                                </span>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <span className="font-size-16">Bitfinex</span>
                                            </div>

                                            <div className="flex-shrink-0">
                                                <span className="badge rounded-pill badge-soft-danger font-size-12 fw-medium">-0.9%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4 pt-2">
                                        <Link to="/invoices-list" className="btn btn-primary w-100">See All Balances <i
                                            className="mdi mdi-arrow-right ms-1"></i></Link>
                                    </div>

                                </div>
                            </Col> */}
              </Row>
            </CardBody>
       
        </Card>
      </Col>
      <DateTimePickerPopup modalOpen={modalIsOpen} modalClose={setIsOpen} setStartDateTime={setStartDateTime} setEndDateTime={setEndDateTime}/>

    </React.Fragment>
  );
};

export default MarketOverview;
