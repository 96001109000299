import {
  Document,
  Image,
  PDFViewer,
  Page,
  Rect,
  StyleSheet,
  Svg,
  Text,
  View,
} from "@react-pdf/renderer";
import React, { useEffect } from "react";
import Logo from "../../../assets/images/logo/motivAiLogoPng.png";
import {
  SC_COMPANY_NAME,
  SC_USER_FIRST_NAME,
} from "../../../constant/StorageConstant";
import { useSelector } from "react-redux";
import ValueFormat from "../../../util/ValueFormat";
import UtilDateTime from "../../../util/UtilDateTime";

const styles = StyleSheet.create({
  pdfViewer: {
    height: "96%",
    width: "100%",
    marginTop: "20px",
    marginBottom: "5px",
  },
  document: {
    marginTop: "15px",
    marginBottom: "5px",
  },
  page: {
    paddingLeft: 30,
    paddingRight: 30,
    width: "100%",
  },
  watermark: {
    position: "absolute",
    fontSize: 95,
    // opacity: 0.3,
    color: "#e6e6e6",
    transform: "rotate(-52deg)",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    top: "42%",
    left: "22%",
  },
  footer: {
    position: "absolute",
    marginLeft: 30,
    marginRight: 30,
    bottom: 0,
    left: 0,
    right: 0,
    height: "44.5px",
  },
  container: {
    height: 260,
    marginBottom: "10px",
  },
  table: {
    // height: 189,
    borderWidth: 1,
    borderColor: "black",
    borderBottom: "none",
    flexDirection: "column",
    marginBottom: "20px  ",
  },
  headerRow: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  headerCell: {
    height: 30,
    width: "33.33%",
    justifyContent: "center",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
    alignItems: "center",
  },
  dataRow: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  dataCell: {
    height: 30,
    width: "33.33%",
    borderRight: "1px solid black",
    borderBottom: "1px solid black",
    justifyContent: "center",
    alignItems: "center",
  },
  hheading: {
    fontSize: "14px",
    fontFamily: "Helvetica-Bold",
  },
  ssubheading: {
    fontSize: "13px",
    fontFamily: "Helvetica-Bold",
  },
  sssubheading: {
    fontSize: "12px",
    fontFamily: "Helvetica-Bold",
  },
  bbodytext: {
    fontSize: "11px",
  },
});

const fontBold = {
  fontFamily: "Helvetica-Bold",
};

const fontItalic = {
  fontFamily: "Times-BoldItalic",
};

const watermarkText = "MotivAI";

const footer = [
  { f: "https://motivai.tech" },
  { f: "https://app.motivai.tech" },
  { f: '"Drive Safe, Arrive Safe"' },
  { f: "A MotivAI Product" },
];

const CategorySummaryPdf = ({
  safetyPieData,
  leaderBoardProps,
  locationDataPdf,
  rideNamesPerCat,
  filtersUDCS,
}) => {
    console.log("rideNamesPerCat",rideNamesPerCat)
  const companyName = localStorage.getItem(SC_COMPANY_NAME);
  const firstName = localStorage.getItem(SC_USER_FIRST_NAME);
  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  var currentDate = new Date();

  const reversedDateFormat =
    currentDate.getDate().toString().padStart(2, "0") +
    "-" +
    months[currentDate.getMonth()] +
    "-" +
    currentDate.getFullYear();

    let period = "";
  let startDate = "";
  let endDate = "";
  if (filtersUDCS.durationFilter === "CURRENT_YEAR") {
    period = ValueFormat.smallLettersFormat(filtersUDCS.durationFilter);
    startDate = UtilDateTime.getCurrentYear(filtersUDCS.startDateTime);
    endDate = "";
  } else if (filtersUDCS.durationFilter === "CURRENT_MONTH") {
    period = ValueFormat.smallLettersFormat(filtersUDCS.durationFilter);
    startDate = UtilDateTime.getCurrentMonth(filtersUDCS.startDateTime);
    endDate = "";
  } else if (filtersUDCS.durationFilter === "CURRENT_WEEK") {
    period = ValueFormat.smallLettersFormat(filtersUDCS.durationFilter);
    startDate = UtilDateTime.getCurrentWeek1(filtersUDCS.startDateTime);
    endDate = "";
  } else if (filtersUDCS.durationFilter === "CURRENT_DAY") {
    period = ValueFormat.smallLettersFormat(filtersUDCS.durationFilter);
    startDate = UtilDateTime.getCurrentDay(filtersUDCS.startDateTime);
    endDate = "";
  } else {
    period = ValueFormat.smallLettersFormat(filtersUDCS.durationFilter);
    startDate = filtersUDCS.startDateTime.split(" ")[0];
    endDate = filtersUDCS.endDateTime.split(" ")[0];
  }

  const header = [
    { title: "App Name", name: "Motiv AI" },
    { title: "Customer Name", name: companyName },
    { title: "Date", name: reversedDateFormat },
    { title: "First Name", name: firstName },
  ];

  var totalPages = 0;

  // Define the full set of categories with their display names
  const categories = [
    { id: "C1", displayName: "Safe Driver (C1)" },
    { id: "C2", displayName: "Sportive Driver (C2)" },
    { id: "C3", displayName: "Quiet Safe Driver (C3)" },
    { id: "C4", displayName: "Reckless Driver (C4)" },
    { id: "C5", displayName: "Quiet Dangerous Driver (C5)" },
    { id: "C6", displayName: "Dangerous Driver (C6)" },
  ];

  const processSafetyData = (safetyPieData) => {
    const dataMap = safetyPieData.reduce((acc, { name, value }) => {
      acc[name] = value;
      return acc;
    }, {});

    const total = safetyPieData.reduce((acc, { value }) => acc + value, 0);

    return categories.map((category) => ({
      name: category.displayName,
      value: dataMap[category.id] !== undefined ? dataMap[category.id] : "-",
      percentage:
        total > 0 && dataMap[category.id] !== undefined
          ? ((dataMap[category.id] / total) * 100).toFixed(2) + "%"
          : "-",
    }));
  };

  const fifthTabledata = {
    columns: [
      { title: "Ride", width: "25%" },
      { title: "Risk", width: "25%" },
      { title: "Driving Score", width: "50%" },
    ],
    rowsData: leaderBoardProps.data.map((item) => ({
      rideName: item.rideName,
      risk: item.riskCount,
      drivingScore: item.score,
    })),
  };

  const safetyPie = processSafetyData(safetyPieData);

 


  const generateRows = (data) => {
    let maxRowCountPerPage = 48;
    const pages = [];
    let currentPageContent = [];
    let currentRowCount = 0;

    data.forEach((item, index) => {
      let itemRowCount;
      if (item.rideNameList.length >= 40) {
        itemRowCount = Math.ceil(40 / 8);
      } else {
        itemRowCount = Math.ceil(item.rideNameList.length / 8);
      }

      currentRowCount += itemRowCount + 2;

      if (currentRowCount > maxRowCountPerPage) {
        pages.push(currentPageContent);
        currentPageContent = [];
        currentRowCount = 0;
      }

      currentPageContent.push(
        <View key={`additional_${index}`}>
          <View
            style={{
              flexDirection: "column",
              marginTop: 5,
              paddingBottom: 5,
              borderBottom: "1px solid black",
            }}
            key={index}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 5,
              }}
            >
              <Text style={{ fontSize: 10 }}>Category: {item.name}({item.prefix})</Text>
              <Text style={{ fontSize: 10 }}>Percentage: {item.percentage}</Text>
              <Text style={{ fontSize: 10 }}>
                Rides: Showing (
                {item.totalRides && item.totalRides > 0 ? "1" : "0"} -{" "}
                {item.totalRides && item.totalRides >= 40
                  ? " 40"
                  : item.totalRides < 40
                  ? " " + item.totalRides
                  : " 0"}
                ) of {item.totalRides}
              </Text>
            </View>
            {item.rideNameList && item.rideNameList.length > 0 && (
              <View>
                {[
                  ...Array(Math.min(Math.ceil(item.rideNameList.length / 8), 5)),
                ].map((_, rowIndex) => (
                  <View
                    key={rowIndex}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 5,
                    }}
                  >
                    {item.rideNameList
                      .slice(rowIndex * 8, Math.min((rowIndex + 1) * 8, 40)) // Ensure max 30 items
                      .map((ride, rideIndex) => (
                        <Text
                          style={{
                            fontSize: 9,
                            border: "0.9px solid black",
                            width: rideIndex === 7 ? "12%" : "11.5%",
                            marginRight: rideIndex === 7 ? "0px" : "1%",
                            textAlign: "center",
                            padding: 1,
                          }}
                          key={rideIndex}
                        >
                          {ride.rideName}
                        </Text>
                      ))}
                  </View>
                ))}
              </View>
            )}
          </View>
        </View>
      );
    });

    // Add the remaining content to the last page
    if (currentPageContent.length > 0) {
      pages.push(currentPageContent);
    }

    // Wrap each page's content in a Page component
    const formattedPages = pages.map((pageContent, index) => {
      const pageCount = index + 2;
    //   if (pageCount > 1) {
    //     maxRowCountPerPage = 48; // Increase rowCount
    //   }
      return (
        <Page key={index} style={styles.page}>
          <Text style={{ ...styles.watermark }}>{watermarkText}</Text>
          <View style={{ height: "5.3%" }}>
            <View
              style={{
                flexDirection: "row",
                borderBottom: "1px solid black",
                backgroundColor: "lightgrey",
              }}
            >
              {/* <View style={{ width: "77%", marginBottom: "5px"}}> */}

              <View
                style={{
                  marginTop: "5px",
                  width: "100%",
                  flexDirection: "row",
                  // justifyContent: "space-between",
                }}
              >
                <Image
                  src={Logo}
                  style={{
                    height: "21px",
                    width: "100px",
                    marginTop: "2px",
                    marginBottom: "5px",
                    marginLeft: "31px",
                  }}
                />
                <View
                  style={{
                    flexDirection: "Row",
                    marginBottom: "5px",
                    marginLeft: "30px",
                  }}
                >
                  <View style={{ flexDirection: "row" }}>
                    <Text style={[fontBold, { fontSize: 11 }]}>
                      {header[1].title}:
                    </Text>
                    <Text style={{ fontSize: 11, marginLeft: "3px" }}>
                      {header[1].name}
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row" }}>
                    <View
                      style={{
                        flexDirection: "row",
                        marginTop: "2px",
                        width: "60%",
                      }}
                    >
                      <Text style={[fontBold, { fontSize: 11, width: "85px" }]}>
                        {header[3].title}:
                      </Text>
                      <Text style={{ fontSize: 11, width: "100%" }}>
                        {header[3].name}
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        marginTop: "2px",
                        marginLeft: "42px",
                      }}
                    >
                      <View style={{ flexDirection: "row" }}>
                        <Text style={[fontBold, { fontSize: 11 }]}>
                          {header[2].title}:{" "}
                        </Text>
                        <Text style={{ fontSize: 11 }}>{header[2].name}</Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
          {pageCount === 2 ? (
            <>
              <View
                style={{
                  fontSize: "11px",
                  marginTop: "15px",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <Text style={[fontBold, { fontSize: 11 }]}>
                  4. Driver Category
                </Text>{" "}
              </View>
            </>
          ) : (
            ""
          )}
          {pageContent}
          <View
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              height: "44.5px",
            }}
          >
            <View style={{ borderBottom: "1px solid black" }} />
            <View
              style={{
                flexDirection: "row",
                marginLeft: 30,
                marginRight: 30,
                bottom: "0px",
              }}
            >
              <View
                style={{
                  marginTop: "5px",
                  width: "30%",
                  marginBottom: "5px",
                }}
              >
                <Text style={{ fontSize: "11px", marginBottom: "3px" }}>
                  {footer[0].f}
                </Text>
                <Text style={[fontItalic, { fontSize: "10px" }]}>
                  {footer[2].f}
                </Text>
              </View>

              <View
                style={{
                  marginTop: "10px",
                  width: "40%",
                  textAlign: "center",
                }}
              >
                <Text style={{ fontSize: "11px" }}>Page {pageCount} of {pageCount}</Text>
              </View>
              <View
                style={{
                  marginTop: "5px",
                  width: "30%",
                  flexDirection: "column",
                }}
              >
                <Text
                  style={{
                    fontSize: "11px",
                    marginBottom: "3px",
                    textAlign: "right",
                  }}
                >
                  {footer[1].f}
                </Text>
                <Text style={{ fontSize: "10px", textAlign: "right" }}>
                  {footer[3].f}
                </Text>
              </View>
            </View>
          </View>
        </Page>
      );
    });

    return formattedPages;
  };

  const RenderedPages = generateRows(rideNamesPerCat);


  return (
    <React.Fragment>
      <PDFViewer style={styles.pdfViewer}>
        <Document style={styles.document}>
          <Page totalPages={totalPages++} style={styles.page}>
            <Text style={styles.watermark}>{watermarkText}</Text>

            <View style={{ height: "4.4%", marginBottom: "15px" }}>
              <View
                style={{
                  flexDirection: "row",
                  borderBottom: "1px solid black",
                  backgroundColor: "lightgrey",
                }}
              >
                <View
                  style={{
                    marginTop: "5px",
                    width: "100%",
                    flexDirection: "row",
                  }}
                >
                  <Image
                    src={Logo}
                    style={{
                      height: "21px",
                      width: "100px",
                      marginTop: "2px",
                      marginBottom: "5px",
                      marginLeft: "31px",
                    }}
                  />
                  <View
                    style={{
                      flexDirection: "Row",
                      marginBottom: "5px",
                      marginLeft: "30px",
                    }}
                  >
                    <View style={{ flexDirection: "row" }}>
                      <Text style={[fontBold, { fontSize: 11 }]}>
                        {" "}
                        {header[1].title}:{" "}
                      </Text>
                      <Text style={{ fontSize: 11, marginLeft: "3px" }}>
                        {" "}
                        {header[1].name}{" "}
                      </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                      <View
                        style={{
                          flexDirection: "row",
                          marginTop: "2px",
                          width: "60%",
                        }}
                      >
                        <Text
                          style={[fontBold, { fontSize: 11, width: "85px" }]}
                        >
                          {" "}
                          {header[3].title}:{" "}
                        </Text>
                        <Text style={{ fontSize: 11, width: "100%" }}>
                          {" "}
                          {header[3].name}{" "}
                        </Text>
                      </View>
                      <View
                        style={{
                          flexDirection: "row",
                          marginTop: "2px",
                          marginLeft: "42px",
                        }}
                      >
                        <View style={{ flexDirection: "row" }}>
                          <Text style={[fontBold, { fontSize: 11 }]}>
                            {" "}
                            {header[2].title}:{" "}
                          </Text>
                          <Text style={{ fontSize: 11 }}>{header[2].name}</Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>

            <View
              style={{
                width: "100%",
                flexDirection: "column",
                marginBottom: "30px",
              }}
            >
              <Text
                style={[
                  styles.hheading,
                  {
                    margin: "3px",
                    marginBottom: "10px",
                    textAlign: "center",
                    color: "#003cb3",
                    textDecoration: "underline",
                  },
                ]}
              >
                {" "}
                User Dashboard (Category Summary){" "}
              </Text>
              <Text
                  style={{
                    color: "black",
                    fontSize: "11px",
                    textDecoration: "none",
                    fontWeight: 600,
                    textAlign: "center",
                    marginTop: 5,
                  }}
                >
                  This report is generated for the period {period}{" "}
                  {endDate === ""
                    ? `(${startDate})`
                    : `(Between ${startDate} and ${endDate})`}
                </Text>{" "}
            </View>

            {/* <View style={{height:'229px', backgroundColor:'red'}}>
                        <View style={{ marginBottom: "10px" }} >
                            <Text style={[fontBold, { fontSize: 11 }]}>
                                1. Safety Category
                            </Text>
                        </View>
                        <View style={{height:'189px', border:'1px solid black', flexDirection:'column'}}> 
                            <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                                <View style={[fontBold,{height:'27px', width:'33.33%',border:'1px solid black', fontSize:'11px', flexDirection:'row', justifyContent:'center', alignItems:'center'}]}> 
                                <Text> Category </Text>
                                </View>
                                <View style={[fontBold,{height:'27px', width:'33.33%',border:'1px solid black', fontSize:'11px', flexDirection:'row', justifyContent:'center', alignItems:'center'}]}> 
                                <Text> Value </Text>
                                </View>
                                <View style={[fontBold,{height:'27px', width:'33.33%',border:'1px solid black', fontSize:'11px', flexDirection:'row', justifyContent:'center', alignItems:'center'}]}> 
                                <Text> Percentage </Text>
                                </View>
                            </View>

                            <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                                <View style={{height:'27px', width:'33.33%',border:'1px solid black', fontSize:'11px', flexDirection:'row', justifyContent:'center', alignItems:'center'}}> 
                                <Text> C1 </Text>
                                </View>
                                <View style={{height:'27px', width:'33.33%',border:'1px solid black', fontSize:'11px', flexDirection:'row', justifyContent:'center', alignItems:'center'}}> 
                                <Text> Value </Text>
                                </View>
                                <View style={{height:'27px', width:'33.33%',border:'1px solid black', fontSize:'11px', flexDirection:'row', justifyContent:'center', alignItems:'center'}}> 
                                <Text> Percentage </Text>
                                </View>
                            </View>

                            <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                                <View style={{height:'27px', width:'33.33%',border:'1px solid black', fontSize:'11px', flexDirection:'row', justifyContent:'center', alignItems:'center'}}> 
                                <Text> C2 </Text>
                                </View>
                                <View style={{height:'27px', width:'33.33%',border:'1px solid black', fontSize:'11px', flexDirection:'row', justifyContent:'center', alignItems:'center'}}> 
                                <Text> Value </Text>
                                </View>
                                <View style={{height:'27px', width:'33.33%',border:'1px solid black', fontSize:'11px', flexDirection:'row', justifyContent:'center', alignItems:'center'}}> 
                                <Text> Percentage </Text>
                                </View>
                            </View>

                            <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                                <View style={{height:'27px', width:'33.33%',border:'1px solid black', fontSize:'11px', flexDirection:'row', justifyContent:'center', alignItems:'center'}}> 
                                <Text> C3 </Text>
                                </View>
                                <View style={{height:'27px', width:'33.33%',border:'1px solid black', fontSize:'11px', flexDirection:'row', justifyContent:'center', alignItems:'center'}}> 
                                <Text> Value </Text>
                                </View>
                                <View style={{height:'27px', width:'33.33%',border:'1px solid black', fontSize:'11px', flexDirection:'row', justifyContent:'center', alignItems:'center'}}> 
                                <Text> Percentage </Text>
                                </View>
                            </View>

                            <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                                <View style={{height:'27px', width:'33.33%',border:'1px solid black', fontSize:'11px', flexDirection:'row', justifyContent:'center', alignItems:'center'}}> 
                                <Text> C4 </Text>
                                </View>
                                <View style={{height:'27px', width:'33.33%',border:'1px solid black', fontSize:'11px', flexDirection:'row', justifyContent:'center', alignItems:'center'}}> 
                                <Text> Value </Text>
                                </View>
                                <View style={{height:'27px', width:'33.33%',border:'1px solid black', fontSize:'11px', flexDirection:'row', justifyContent:'center', alignItems:'center'}}> 
                                <Text> Percentage </Text>
                                </View>
                            </View>
                            <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                                <View style={{height:'27px', width:'33.33%',border:'1px solid black', fontSize:'11px', flexDirection:'row', justifyContent:'center', alignItems:'center'}}> 
                                <Text> C5 </Text>
                                </View>
                                <View style={{height:'27px', width:'33.33%',border:'1px solid black', fontSize:'11px', flexDirection:'row', justifyContent:'center', alignItems:'center'}}> 
                                <Text> Value </Text>
                                </View>
                                <View style={{height:'27px', width:'33.33%',border:'1px solid black', fontSize:'11px', flexDirection:'row', justifyContent:'center', alignItems:'center'}}> 
                                <Text> Percentage </Text>
                                </View>
                            </View>
                            <View style={{flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                                <View style={{height:'27px', width:'33.33%',border:'1px solid black', fontSize:'11px', flexDirection:'row', justifyContent:'center', alignItems:'center'}}> 
                                <Text> C6 </Text>
                                </View>
                                <View style={{height:'27px', width:'33.33%',border:'1px solid black', fontSize:'11px', flexDirection:'row', justifyContent:'center', alignItems:'center'}}> 
                                <Text> Value </Text>
                                </View>
                                <View style={{height:'27px', width:'33.33%',border:'1px solid black', fontSize:'11px', flexDirection:'row', justifyContent:'center', alignItems:'center'}}> 
                                <Text> Percentage </Text>
                                </View>
                            </View>

                        </View>
                        </View> */}

            <View style={styles.container}>
              <View style={[styles.ssubheading, { marginBottom: 10 }]}>
                <Text>1. Safety Category</Text>
              </View>
              <View style={styles.table}>
                <View style={[fontBold, styles.headerRow]}>
                  <View
                    style={[
                      styles.headerCell,
                      styles.sssubheading,
                      { alignItems: "flex-start", paddingLeft: "20px" },
                    ]}
                  >
                    <Text>Driver Category</Text>
                  </View>
                  <View style={[styles.headerCell, styles.sssubheading]}>
                    <Text>Value</Text>
                  </View>
                  <View style={[styles.headerCell, styles.sssubheading]}>
                    <Text>Percentage</Text>
                  </View>
                </View>
                {safetyPie.map((item, index) => (
                  <View key={index} style={styles.dataRow}>
                    <View
                      style={[
                        styles.dataCell,
                        styles.bbodytext,
                        { alignItems: "flex-start", paddingLeft: "20px" },
                      ]}
                    >
                      <Text>{item.name}</Text>
                    </View>
                    <View
                      style={[
                        styles.dataCell,
                        styles.bbodytext,
                        { alignItems: "flex-end", paddingRight: "73px" },
                      ]}
                    >
                      <Text>{item.value}</Text>
                    </View>
                    <View
                      style={[
                        styles.dataCell,
                        styles.bbodytext,
                        { alignItems: "flex-end", paddingRight: "55px" },
                      ]}
                    >
                      <Text>{item.percentage}</Text>
                    </View>
                  </View>
                ))}
              </View>
            </View>

            <View
              style={{
                width: "100%",
                height: "200px",
                marginBottom: "30px",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <View style={{ width: "100%" }}>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: "11px",
                    justifyContent: "space-between",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text style={[fontBold, styles.ssubheading]}>
                      2. Leaderboard{" "}
                    </Text>
                    <Text style={styles.bbodytext}>(Last 5 Rides)</Text>
                  </View>
                  <View>
                    <Text style={[styles.bbodytext, { textAlign: "right" }]}>
                      Showing for{" "}
                      {(() => {
                        switch (
                          // ValueFormat.capitalizeFirstLetterAndFormat(
                          leaderBoardProps.filter
                        ) {
                          // )
                          case "allLeaderBoard":
                            return "All";
                          case "workLeaderBoard":
                            return "Work";
                          case "personalLeaderBoard":
                            return "Personal";
                        }
                      })()}
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    borderTop: "1px solid black",
                    height: "100%",
                  }}
                >
                  <View style={{ flexDirection: "row", height: "30px" }}>
                    {fifthTabledata.columns.map((column, colIndex) => (
                      <View
                        key={colIndex}
                        style={{
                          width: column.width,
                          borderRight: "1px solid black",
                          borderLeft:
                            colIndex === 0 ? "1px solid black" : "none",
                          borderBottom: "1px solid black",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "12px",
                        }}
                      >
                        <Text style={fontBold}>{column.title}</Text>
                      </View>
                    ))}
                  </View>

                  {Array.from({ length: 5 }).map((_, rowIndex) => {
                    const rowdata = fifthTabledata.rowsData[rowIndex] || {};
                    return (
                      <View
                        key={rowIndex}
                        style={{
                          flexDirection: "row",
                          height: 30,
                          borderBottom:
                            rowIndex === 4
                              ? "1px solid black"
                              : "1px solid black",
                        }}
                      >
                        {fifthTabledata.columns.map((column, colIndex) => (
                          <View
                            key={colIndex}
                            style={{
                              width: column.width,
                              borderLeft:
                                colIndex === 0 ? "1px solid black" : "none",
                              borderRight:
                                colIndex === 2
                                  ? "1px solid black"
                                  : "1px solid black",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: 11,
                            }}
                          >
                            {colIndex === 2 ? (
                              <View
                                style={{
                                  width: "100%",
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <View
                                  style={{
                                    width: "75%",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                  }}
                                >
                                  {rowdata.drivingScore !== undefined ? (
                                    <View style={{ width: "75%" }}>
                                      <Svg viewBox="0 0 700 20">
                                        <Rect
                                          x="0"
                                          rx="2"
                                          ry="2"
                                          width="100%"
                                          height="25"
                                          fill="rgb(0, 149, 255)"
                                        />
                                        <Rect
                                          x="0"
                                          rx="2"
                                          ry="2"
                                          width={`${
                                            rowdata.drivingScore || 0
                                          }%`}
                                          height="25"
                                          fill="#E4A11B"
                                        />
                                      </Svg>
                                    </View>
                                  ) : (
                                    <Text
                                      style={{
                                        textAlign: "center",
                                        marginRight: "20%",
                                      }}
                                    >
                                      -
                                    </Text>
                                  )}
                                </View>
                                <View style={{ width: "25%" }}>
                                  <Text style={{ fontSize: 11 }}>
                                    {rowdata.rideName !== undefined ||
                                    (rowdata.rideName !== null &&
                                      rowdata.drivingScore)
                                      ? rowdata.drivingScore
                                      : null}
                                  </Text>
                                </View>
                              </View>
                            ) : (
                              <Text>
                                {colIndex === 1
                                  ? rowdata.risk !== undefined
                                    ? rowdata.risk
                                    : "-"
                                  : rowdata.rideName !== undefined
                                  ? rowdata.rideName
                                  : "-"}
                              </Text>
                            )}
                          </View>
                        ))}
                      </View>
                    );
                  })}
                </View>
              </View>
            </View>

            <View
              style={{
                height: "115px",
                marginBottom: "20px",
              }}
            >
              <View
                style={{
                  width: "100%",
                  height: "20px",
                  marginBottom: "5px",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text style={styles.ssubheading}>3. Vehicle Last Location</Text>
              </View>
              <View
                style={{
                  borderRight: "1px solid black",
                  borderTop: "1px solid black",
                  height: "90px",
                  width: "100%",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    height: "60px",
                    width: "100%",
                  }}
                >
                  <View
                    style={{
                      borderBottom: "1px solid black",
                      borderLeft: "1px solid black",
                      width: "15%",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text style={styles.sssubheading}>Last Ride</Text>
                  </View>
                  <View
                    style={{
                      borderBottom: "1px solid black",
                      borderLeft: "1px solid black",
                      width: "25%",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text style={styles.sssubheading}>Driving Score</Text>
                  </View>
                  <View
                    style={{
                      borderBottom: "1px solid black",
                      borderLeft: "1px solid black",
                      width: "20%",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text style={styles.sssubheading}>Geo-Location</Text>
                  </View>
                  <View
                    style={{
                      borderBottom: "1px solid black",
                      borderLeft: "1px solid black",
                      width: "40%",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text style={styles.sssubheading}>Location</Text>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    height: "100px",
                    width: "100%",
                  }}
                >
                  <View
                    style={{
                      borderBottom: "1px solid black",
                      borderLeft: "1px solid black",
                      width: "15%",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text style={styles.bbodytext}>
                      {locationDataPdf.deviceName && locationDataPdf.deviceName}
                      {!locationDataPdf.deviceName && "-"}
                    </Text>
                  </View>
                  <View
                    style={{
                      borderBottom: "1px solid black",
                      borderLeft: "1px solid black",
                      width: "25%",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <View
                      style={{
                        width: "100%",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {locationDataPdf.deviceName !== undefined ||
                      (locationDataPdf.deviceName !== null &&
                        locationDataPdf.drivingScore) ? (
                        <>
                          <View style={{ width: "70%" }}>
                            <Svg viewBox="0 0 700 40">
                              <Rect
                                x="0"
                                rx="2"
                                ry="2"
                                width="100%"
                                height="40"
                                fill="#D3D3D3"
                              />
                              <Rect
                                x="0"
                                rx="2"
                                ry="2"
                                width={`${locationDataPdf.drivingScore}%`}
                                height="40"
                                fill="#6b6fc8"
                              />
                            </Svg>
                          </View>
                          <View style={{ width: "20%", textAlign: "center" }}>
                            <Text style={styles.bbodytext}>
                              {`${locationDataPdf.drivingScore}%`}
                            </Text>
                          </View>
                        </>
                      ) : (
                        <Text style={styles.bbodytext}>-</Text>
                      )}
                    </View>
                  </View>
                  <View
                    style={{
                      borderBottom: "1px solid black",
                      borderLeft: "1px solid black",
                      width: "20%",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {locationDataPdf.latitude !== undefined &&
                    locationDataPdf.longitude !== undefined ? (
                      <View>
                        <Text style={styles.bbodytext}>
                          Lat: {locationDataPdf.latitude}
                        </Text>
                        <Text style={styles.bbodytext}>
                          Lon: {locationDataPdf.longitude}
                        </Text>
                      </View>
                    ) : (
                      <Text style={styles.bbodytext}>-</Text>
                    )}
                  </View>

                  <View
                    style={{
                      borderBottom: "1px solid black",
                      borderLeft: "1px solid black",
                      width: "40%",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Text style={[styles.bbodytext, { padding: "2px" }]}>
                      {locationDataPdf.location !== undefined &&
                      locationDataPdf.location !== null
                        ? locationDataPdf.location
                        : "-"}
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            <View style={styles.footer}>
              <View style={{ borderBottom: "1px solid black" }} />
              <View style={{ flexDirection: "row", bottom: "0px" }}>
                <View
                  style={{
                    marginTop: "5px",
                    width: "30%",
                    marginBottom: "5px",
                  }}
                >
                  <Text style={{ fontSize: "11px", marginBottom: "3px" }}>
                    {" "}
                    {footer[0].f}{" "}
                  </Text>
                  <Text style={[fontItalic, { fontSize: "10px" }]}>
                    {" "}
                    {footer[2].f}{" "}
                  </Text>
                </View>

                <View
                  style={{
                    marginTop: "10px",
                    width: "40%",
                    textAlign: "center",
                  }}
                >
                  <Text style={{ fontSize: "11px" }}>
                    {" "}
                    Page {totalPages} of 2{" "}
                  </Text>
                </View>
                <View
                  style={{
                    marginTop: "5px",
                    width: "30%",
                    flexDirection: "column",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "11px",
                      marginBottom: "3px",
                      textAlign: "right",
                    }}
                  >
                    {" "}
                    {footer[1].f}{" "}
                  </Text>
                  <Text style={{ fontSize: "10px", textAlign: "right" }}>
                    {" "}
                    {footer[3].f}{" "}
                  </Text>
                </View>
              </View>
            </View>
          </Page>
          {RenderedPages}
        </Document>
      </PDFViewer>
    </React.Fragment>
  );
};

export default CategorySummaryPdf;
