import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Collapse } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import classname from "classnames";
import withRouter from "../Common/withRouter";

//i18n
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import ActiveDashboardIcon from "../../assets/images/ActiveDashboardIcon.svg";
import DashboardIcon from "../../assets/images/DashboardIcon.svg";
import ActiveUsersIcon from "../../assets/images/ActiveUsersIcon.svg";
import UsersIcon from "../../assets/images/UsersIcon.svg";
import ActiveSettingIcon from "../../assets/images/ActiveSettingIcon.svg";
import SettingIcon from "../../assets/images/SettingIcon.svg";
import ActiveReportIcon from "../../assets/images/ActiveReportIcon.svg";
import ReportIcon from "../../assets/images/ReportIcon.svg";
import Bell from "../../assets/images/bell.svg";
import ActiveBell from "../../assets/images/bellBlue.svg";
import CarCrashBlue from "../../assets/images/carCrashBlue.svg";
import CarCrash from "../../assets/images/carCrash.svg";
import classNames from "classnames";

const SuperAdminClientNavbar = (props) => {

  const [app, setApp] = useState(false);
  const [appReports, setAppReports] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [onboardStatus, setOnboardStatus] = useState(false);
  const { pathname } = useLocation();
  const dropdownRef = useRef(null);
  const dropdownRef1 = useRef(null); 
  const dropdownRef2 = useRef(null);  
  const dropdownRef3 = useRef(null);

  const toggleAppDropdown = (e) => {
    e.preventDefault();
    setApp((prevApp) => !prevApp);
  };

  const toggleAppDropdownReports = (e) => {
    e.preventDefault();
    setAppReports((prevApp) => !prevApp);
  };

  const toggleTriggerDropdown = (e) => {
    e.preventDefault();
    setTrigger((prevApp) => !prevApp);
  };

  const toggleOnboardDropdown = (e) => {
    e.preventDefault();
    setOnboardStatus((prevApp) => !prevApp);
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setApp(false);
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Remove event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef1.current && !dropdownRef1.current.contains(event.target)) {
        setAppReports(false);
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Remove event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef1]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
        setTrigger(false);
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Remove event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef2]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef3.current && !dropdownRef3.current.contains(event.target)) {
        setOnboardStatus(false);
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Remove event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef3]);

  return (
    <React.Fragment>
      <div className="topnav cardHeader">

        <div className="container-fluid ps-0">
          <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation" >
            <Collapse isOpen={props.leftMenu} className="navbar-collapse" id="topnav-menu-content" >
              <ul className="navbar-nav">

                <li className="nav-item dropdown">
                  <Link className="nav-link  dropdown-toggle arrow-none p-0 m-3 ms-0" to="/superAdminClientDashboard" >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                      {pathname === "/superAdminClientDashboard" ? <img src={ActiveDashboardIcon} alt="ActiveDashboardIcon" height={30} width={30} /> : <img src={DashboardIcon} alt="DashboardIcon" height={30} width={30} />}
                      <span style={{ color: pathname === "/superAdminClientDashboard" ? "#5156be" : "#313533", paddingLeft: "4px" }}>{props.t("Dashboard")}</span>
                    </div>
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle arrow-none p-0 m-3" to="/companyUsers" >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                      {pathname === "/companyUsers" ? <img src={ActiveUsersIcon} alt="ActiveUsersIcon" height={30} width={30} /> : <img src={UsersIcon} alt="UsersIcon" height={30} width={30} />}
                      <span style={{ color: pathname === "/companyUsers" ? "#5156be" : "#313533", paddingLeft: "4px" }}>{props.t("Users")}</span>
                    </div>
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle arrow-none p-0 m-3" to="/notifications" style={{ display: "flex", alignItems: "center" }} >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                      {pathname == "/notifications" ? <img src={ActiveBell} alt="notifications" height={30} width={30} /> : <img src={Bell} alt="Notifications" height={30} width={30} />}
                      <span style={{ color: pathname === "/notifications" ? "#5156be" : "#313533", paddingLeft: "4px" }}> {props.t("Notifications")} </span>
                    </div>
                  </Link>
                </li>

                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle arrow-none p-0 m-3" to="/vehicleCrashList" style={{ display: "flex", alignItems: "center" }} >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                      {pathname == "/vehicleCrashList" ? <img src={CarCrashBlue} alt="ride" height={30} width={30} /> : <img src={CarCrash} alt="Notifications" height={30} width={30} />}
                      <span style={{ color: pathname === "/vehicleCrashList" ? "#5156be" : "#313533", paddingLeft: "4px" }}> {props.t("Vehicle Crashes")} </span>
                    </div>
                  </Link>
                </li>

                <li className="nav-item" ref={dropdownRef}>
                  <Link to="/#" onClick={toggleAppDropdown} className="nav-link dropdown-togglez arrow-none p-0 m-3" >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                      {pathname === "/mySubscription" || pathname === "/shiftConfiguration" ?
                        <img src={ActiveSettingIcon} alt="ActiveSeetingIcon" height={30} width={30} /> :
                        <img src={SettingIcon} alt="SettingIcon" height={30} width={30} />}
                      <span style={{ color: pathname === "/mySubscription" || pathname === "/shiftConfiguration" ? "#5156be" : "#313533", paddingLeft: "4px" }}>{props.t("Settings")} <div className="arrow-down"></div></span>
                    </div>
                  </Link>

                  <div className={classNames("dropdown-menu", { show: app })}>
                    <Link to="/mySubscription" className="dropdown-item" onClick={() => setApp(false)}>
                      {props.t("User Licenses")}
                    </Link>
                    <Link to="/shiftConfiguration" className="dropdown-item" onClick={() => setApp(false)}>
                      {props.t("Shifts")}
                    </Link>
                  </div>
                </li>

                <li className="nav-item" ref={dropdownRef1}>
                  <Link to="/#" onClick={toggleAppDropdownReports} className="nav-link dropdown-togglez arrow-none p-0 m-3" >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                      {pathname === "/appExceptionActivities" || pathname === "/userLoginActivities" || pathname === "/userLastActivities" || pathname === "/deviceHealth" ?
                        <img src={ActiveReportIcon} alt="ActiveReportIcon" height={30} width={30} /> : <img src={ReportIcon} alt="ReportIcon" height={30} width={30} />}
                      <span style={{ color: pathname === "/appExceptionActivities" ? "#5156be" : pathname === "/userLastActivities" ? "#5156be" : pathname === "/userLoginActivities" ? "#5156be" : pathname === "/deviceHealth" ? "#5156be" : "#313533", paddingLeft: "4px" }}>{props.t("Reports")}</span>
                      <div className="arrow-down" />
                    </div>
                  </Link>
                  <div className={classNames("dropdown-menu", { show: appReports })}>
                    <Link to="/appExceptionActivities" className="dropdown-item" onClick={() => setAppReports(false)}>
                      {props.t("User Exception Activities")}
                    </Link>
                    <Link to="/userLoginActivities" className="dropdown-item" onClick={() => setAppReports(false)}>
                      {props.t("User Login Activities")}
                    </Link>
                    <Link to="/userLastActivities" className="dropdown-item" onClick={() => setAppReports(false)}>
                      {props.t("User Last Activities")}
                    </Link>
                    <Link to="/deviceHealth" className="dropdown-item" onClick={() => setAppReports(false)}>
                      {props.t("User Device Health")}
                    </Link>
                  </div>
                </li>

                <li className="nav-item" ref={dropdownRef2}>
                  <Link to="/#" onClick={toggleTriggerDropdown} className="nav-link dropdown-togglez arrow-none p-0 m-3" >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                      {pathname === "/triggerSmsUnregisteredUsers" ?
                        <img src={ActiveReportIcon} alt="ActiveReportIcon" height={30} width={30} /> : <img src={ReportIcon} alt="ReportIcon" height={30} width={30} />}
                      <span style={{ color: pathname === "/triggerSmsUnregisteredUsers" ? "#5156be" : "#313533", paddingLeft: "4px" }}>{props.t("Triggers")}</span>
                      <div className="arrow-down" />
                    </div>
                  </Link>
                  <div className={classNames("dropdown-menu", { show: trigger })}>
                    <Link to="/triggerSmsUnregisteredUsers" className="dropdown-item" onClick={() => setTrigger(false)}>
                      {props.t("Send SMS to unregistered users")}
                    </Link>
                  </div>
                </li>

                <li className="nav-item" ref={dropdownRef3}>
                  <Link to="/#" onClick={toggleOnboardDropdown} className="nav-link dropdown-togglez arrow-none p-0 m-3" >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                      {pathname === "/userOnboardStatus" ?
                        <img src={ActiveReportIcon} alt="ActiveReportIcon" height={30} width={30} /> : <img src={ReportIcon} alt="ReportIcon" height={30} width={30} />}
                      <span style={{ color: pathname === "/userOnboardStatus" ? "#5156be" : "#313533", paddingLeft: "4px" }}>{props.t("Onboard Status")}</span>
                      <div className="arrow-down" />
                    </div>
                  </Link>
                  <div className={classNames("dropdown-menu", { show: onboardStatus })}>
                    <Link to="/userOnboardStatus" className="dropdown-item" onClick={() => setOnboardStatus(false)}>
                      {props.t("User Onboard Status")}
                    </Link>
                  </div>
                </li>
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

SuperAdminClientNavbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(SuperAdminClientNavbar))
);
