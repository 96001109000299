import React, { useEffect, useState } from 'react'
import PdfHeader from '../Components/pdfHeaderV1';
import { Document, Image, Line, Page, PDFViewer, Rect, StyleSheet, Svg, Text, View } from '@react-pdf/renderer';
import PdfHeading from '../Components/pdfHeading';
import UtilDateTime from '../../../util/UtilDateTime';
import ValueFormat from '../../../util/ValueFormat';
import { SC_COMPANY_NAME, SC_USER_FIRST_NAME } from '../../../constant/StorageConstant';
import { useLocation } from 'react-router-dom';
import PdfFooter from '../Components/pdfFooterV1';

import rank from "../../../assets/images/adminDashboard/pdf/pdf_rank.png";
import firstName from "../../../assets/images/adminDashboard/pdf/pdf_firstname.png";
import username from "../../../assets/images/adminDashboard/pdf/pdf_username.png";
import efficiency from "../../../assets/images/adminDashboard/pdf/pdf_efficiency.png";
import totalRides from "../../../assets/images/adminDashboard/pdf/pdf_total_rides.png";
import drivingScore from "../../../assets/images/adminDashboard/pdf/pdf_driving_score.png";
import anticipation from "../../../assets/images/adminDashboard/pdf/pdf_anticipation.png";
import selfConfidence from "../../../assets/images/adminDashboard/pdf/pdf_self_confidence.png";
import drivingSkill from "../../../assets/images/adminDashboard/pdf/pdf_driving_skill.png";
import drivingStyle from "../../../assets/images/adminDashboard/pdf/pdf_driving_style.png";
import drivingState from "../../../assets/images/adminDashboard/pdf/pdf_driver_state.png";
import mobScreen from "../../../assets/images/adminDashboard/pdf/pdf_mobile_screen.png";
import mobCall from "../../../assets/images/adminDashboard/pdf/pdf_mobile_call.png";
import kmsDriven from "../../../assets/images/adminDashboard/pdf/pdf_kms_driven.png";
import stressStrain from "../../../assets/images/adminDashboard/pdf/pdf_stress_strain.png";
import tdistance from "../../../assets/images/adminDashboard/pdf_distance.png";
import tduration from "../../../assets/images/adminDashboard/pdf_duration.png";
import i5 from "../../../assets/images/adminDashboard/pdf_high_alerts.png";
import i6 from "../../../assets/images/adminDashboard/pdf_medium_alerts.png";
import i7 from "../../../assets/images/adminDashboard/pdf_overspeed.png";
import i8 from "../../../assets/images/adminDashboard/pdf_mobile_call.png";
import i9 from "../../../assets/images/adminDashboard/pdf_mobile_screen.png";
import i10 from "../../../assets/images/adminDashboard/pdf_acceleration.png";
import i11 from "../../../assets/images/adminDashboard/pdf_braking.png";
import i12 from "../../../assets/images/adminDashboard/pdf_cornering.png";
import crash from "../../../assets/images/adminDashboard/pdf/pdf_crash.png";
import { displayText } from '../../../constant/MessageConstant';
import { globalPDF } from '../../../assets/css/commonCssTextForPdf';

const styles = StyleSheet.create({
    pdfViewer: {
        height: "96%",
        width: "100%",
        marginTop: "20px",
        marginBottom: "5px",
    },
    document: {
        marginTop: "15px",
        marginBottom: "5px",
    },
    page: {
        width: "100%",
    },
    tablestyle: {
        border: "1px solid black",
        borderTop: "none",
        borderRight: "none",
        padding: "6px",
        fontSize: "10px",
        textAlign: "center",
    }
});

const fontBold = {
    fontFamily: "Helvetica-Bold",
};
const ColouredoverAllSummaryPdf = ({
    UDMainDashboardSafetyTableData,
    yearlyDatas,
    UDMainDashboardTopRowItemsTemp,
    USRideBoxCount,
    overallDataProps,
    getSelectedYearData,
    lastOverAllSyData,
    totalSafeRidePer,
    aggregateData,
    filtersData,
    totalDays,
    summaryDataUser
}) => {
    console.log(summaryDataUser.data, ": summaryDataUser");

    const [UDMainDashboardData, setUDMainDashboardData] = useState([]);
    var totalPages = 0;
    const companyName = localStorage.getItem(SC_COMPANY_NAME);
    const firstName = localStorage.getItem(SC_USER_FIRST_NAME);
    const pathname = useLocation();
    const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

    let period = "";
    let startDate = "";
    let endDate = "";

    if (filtersData.durationFilter === "CURRENT_YEAR") {
        period = ValueFormat.smallLettersFormat(filtersData.durationFilter);
        startDate = UtilDateTime.getCurrentYear(filtersData.startDateTime);
        endDate = "";
    } else if (filtersData.durationFilter === "CURRENT_MONTH") {
        period = ValueFormat.smallLettersFormat(filtersData.durationFilter);
        startDate = UtilDateTime.getCurrentMonth(filtersData.startDateTime);
        endDate = "";
    } else if (filtersData.durationFilter === "CURRENT_WEEK") {
        period = ValueFormat.smallLettersFormat(filtersData.durationFilter);
        startDate = UtilDateTime.getCurrentWeek1(filtersData.startDateTime);
        endDate = "";
    } else if (filtersData.durationFilter === "CURRENT_DAY") {
        period = ValueFormat.smallLettersFormat(filtersData.durationFilter);
        startDate = UtilDateTime.getCurrentDay(filtersData.startDateTime);
        endDate = "";
    } else {
        period = ValueFormat.smallLettersFormat(filtersData.durationFilter);
        startDate = filtersData.startDateTime.split(" ")[0];
        endDate = filtersData.endDateTime.split(" ")[0];
    }

    const periodFormat = [period, startDate, endDate];
    const periodFormatWeek = [ValueFormat.smallLettersFormat(filtersData), UtilDateTime.getCurrentWeek1(currentDate), ""];
    var currentDate = new Date();
    const heading = "User Dashboard (Overall Summary)";
    const reversedDateFormat = currentDate.getDate().toString().padStart(2, "0") + "-" + months[currentDate.getMonth()] + "-" + currentDate.getFullYear();

    const tableHeaders = [
        "Duration",
        "Ride Count",
        "Distance (Kms)",
        "Duration (Hrs)",
        "Risk Alerts",
        "Other Alerts",
    ];

    const overallData = yearlyDatas[0] || {};
    const cyData = yearlyDatas[1] || {};
    const cmData = yearlyDatas[2] || {};
    const cwData = yearlyDatas[3] || {};
    const cdData = yearlyDatas[4] || {};

    const generateCategoryData = (title, data) => [
        title,
        data.totalRides || 0,
        data.totalKiloMeters || 0,
        isNaN(parseFloat(data.totalHours).toFixed(2)) ? 0 : parseFloat(data.totalHours).toFixed(2),
        data.totalRiskAlerts || 0,
        data.totalManualAlerts || 0,
    ];

    const tableData = {
        headers: tableHeaders.map((title) => ({ title })),
        overall: generateCategoryData("Overall", overallData),
        currentYear: generateCategoryData("Current Year", cyData),
        currentMonth: generateCategoryData("Current Month", cmData),
        currentWeek: generateCategoryData("Current Week", cwData),
        currentDay: generateCategoryData("Current Day", cdData),
    };

    const maxDataListKeysLengthActivitySummary = 5;
    const maxDataListKeysLength = 6;

    const widget1 = () => {
        const dataByType = [];
        console.log(
            "UDMainDashboardTopRowItemsTemp",
            UDMainDashboardTopRowItemsTemp
        );
        UDMainDashboardTopRowItemsTemp.forEach((item) => {
            const {
                type,
                currentYearValue,
                currentMonthValue,
                currentWeekValue,
                currentDayValue,
                overallData,
            } = item;

            dataByType.push([
                overallData,
                currentYearValue,
                currentMonthValue,
                currentWeekValue,
                currentDayValue,
            ]);
        });

        setUDMainDashboardData(dataByType);
    };

    // Zone Summary
    const maxValW2 = USRideBoxCount.totalRides;

    console.log("usrideboxcount", USRideBoxCount);

    const categoryData = () => {
        return USRideBoxCount.count.map((item, index) => {
            return { total: item.totalRides, percentage: item.percentage };
        });
    };
    const categoryCircle = categoryData();

    const modifiedWidget2 = () => {
        return categoryCircle.map((item) => {
            // Create a new object to avoid modifying the original data
            const sItem = { ...item };

            // Apply your conditions to each item
            if (sItem.total > 0) {
                if (sItem.total < maxValW2) {
                    sItem.total = ((sItem.total / maxValW2) * 230).toFixed(1);
                } else {
                    sItem.total = 230;
                }
            } else {
                sItem.total = 0;
            }

            return sItem;
        });
    };

    const w2 = modifiedWidget2();

    // overall driving score
    const safetyCategoryData = [];
    const safetyCategoryDataCY = [];
    const safetyCategoryDataSY = [];

    const safetyData = overallDataProps.map((item) => item.score);
    const safetyDataSY = lastOverAllSyData.map((item) => item.lastScore);

    const maxSafetyCategoryHeight = 0;
    const minSafetyCategoryHeight = -139.5;
    const safetyCategoryScalingFactor =
        Math.abs(minSafetyCategoryHeight) / (maxSafetyCategoryHeight - 110);

    const elementWidth = 10;
    const gap = 35;
    const initialX = 37;
    const initialXX = 27;
    const textYOffset = 10;

    safetyData.forEach((value, index) => {
        const x = initialX + index * (elementWidth + gap);
        const textX = x + 7;
        const textY = 139.5 - value * safetyCategoryScalingFactor + textYOffset;

        safetyCategoryData.push({
            x,
            y: 139.5,
            color: value > 92 ? "rgb(25, 84, 62)" : value > 80 ? "#DEA602" : "red",
            width: elementWidth,
            height: value * safetyCategoryScalingFactor,
            textX,
            textY,
            text: value,
        });
    });

    safetyData.slice(2, -1).map((value, index) => {
        const x = initialX + index * (elementWidth + gap);
        const textX = x + 7;
        const textY = 139.5 - value * safetyCategoryScalingFactor + textYOffset;
        safetyCategoryDataCY.push({
            x,
            y: 139.5,
            color:
                totalSafeRidePer > 92
                    ? "rgb(25, 84, 62)"
                    : totalSafeRidePer > 80
                        ? "#DEA602"
                        : "red",
            width: elementWidth,
            height: -totalSafeRidePer === 0 ? 0 : -totalSafeRidePer - 16,
            textX,
            textY,
            text: totalSafeRidePer,
        });
    });

    safetyDataSY.map((value, index) => {
        const x = initialX + index * (elementWidth + gap);
        const textX = x + 7;
        const textY = 139.5 - value * safetyCategoryScalingFactor + textYOffset;
        safetyCategoryDataSY.push({
            x,
            y: 139.5,
            color: value > 92 ? "rgb(25, 84, 62)" : value > 80 ? "#DEA602" : "red",
            width: elementWidth,
            height: value * safetyCategoryScalingFactor,
            textX,
            textY,
            text: value,
        });
    });
    var selectedYear =
        new Date(getSelectedYearData).getFullYear() ||
        UtilDateTime.getCurrentYear();
    const titleHead = ["Units", "C1", "C2", "C3", "C4", "C5", "C6"];

    const titleHead1 = [
        "Total Days",
        "Total Rides",
        "Total Distance",
        "Total Duration",
        "High Risk",
        "Medium Risk",
    ];

    const T1 = {
        title: titleHead.map((title) => ({ title })),
        totalKm: [
            "Kilometers",
            ...(USRideBoxCount.count || []).map((item) =>
                item && !isNaN(item.totalKm) ? Math.round(item.totalKm) : 0
            ),
        ],
        totalTt: [
            "Minutes",
            ...(USRideBoxCount.count || []).map((item) =>
                item && !isNaN(item.totalTravelTime)
                    ? Math.round(item.totalTravelTime)
                    : 0
            ),
        ],
    };

    const T2 = {
        title: titleHead1.map((title) => ({ title })),
        data: [
            totalDays || 0,
            aggregateData.totalRides || 0,
            aggregateData.totalKilometer
                ? aggregateData.totalKilometer.toFixed(2) + " kms"
                : 0 + " kms",
            aggregateData.totalTravelTime
                ? aggregateData.totalTravelTime.toFixed(2) + " hrs"
                : 0 + " hrs",
            aggregateData.totalHighRiskCount || 0,
            aggregateData.totalMediumRiskCount || 0,
        ],
    };

    useEffect(() => {
        widget1();
    }, []);

    const originalData = summaryDataUser.data || [
        {
            ABCPoint: "-",
            DSP: "-",
            DSTP: "-",
            drivingScore: "-",
            efficiency: "0",
            efficiencyTooltipValue: "0",
            firstName: "-",
            mobileScreenPointNew: "-",
            mobileUsePointNew: "-",
            overallAnticipation: "-",
            overallDrivingSkill: "-",
            overallSelfConfidence: "-",
            rank: "-",
            totalKmSPoint: "-",
            totalRides: "-",
            userId: "-",
            username: "-",
            tKiloMeterTV: "-",
            tTravelTimeTV: "-",
            totalHighRiskCount: "-",
            totalMediumRiskCount: "-",
            totalOverSpeedDurationTV: "-",
            totalOverSpeedCount: "-",
            totalMobileScreenScreenOnDurationTV: "-",
            totalMobileScreenScreenOnCount: "-",
            totalMobileUseInAcceptedDurationTV: "-",
            totalMobileUseInAcceptedCount: "-",
            totalAccelerationCount: "-",
            totalBrakingCount: "-",
            totalCorneringCount: "-",
        },
    ];
    const dupData = [...originalData, ...originalData, ...originalData]
    const data = originalData.map((item) => {
        // Extract and parse the necessary values
        const efficiencyValues = [
          parseFloat(item.drivingScore),
          parseFloat(item.overallAnticipation),
          parseFloat(item.overallSelfConfidence),
          parseFloat(item.overallDrivingSkill),
          parseFloat(item.DSTP),
          parseFloat(item.DSP),
          parseFloat(item.mobileScreenPointNew),
          parseFloat(item.mobileUsePointNew),
          parseFloat(item.totalKmSPoint),
          parseFloat(item.ABCPoint),
        ];
      
        // Calculate the sum of efficiency values
        const sum = efficiencyValues.reduce((total, value) => total + (value || 0), 0);
      
        // Determine rank based on sum
        let rank;
        if (sum >= 0 && sum <= 80) {
          rank = "Poor";
        } else if (sum >= 81 && sum <= 93) {
          rank = "Fair";
        } else {
          rank = "Good";
        }
      
        return {
          ...item,
          efficiency: sum.toFixed(0),
            rank, // Update rank
            drivingScore: `${item.drivingScore} / 20`,
            overallAnticipation: `${item.overallAnticipation} / 10`,
            overallSelfConfidence: `${item.overallSelfConfidence} / 10`,
            overallDrivingSkill: `${item.overallDrivingSkill} / 10`,
            DSTP: `${item.DSTP} / 10`,
            DSP: `${item.DSP} / 10`,
            mobileScreenPointNew: `${item.mobileScreenPointNew} / 5`,
            mobileUsePointNew: `${item.mobileUsePointNew} / 5`,
            totalKmSPoint: `${item.totalKmSPoint} / 10`,
            ABCPoint: `${item.ABCPoint} / 10`,
        };
    });

    const chunks = [];
    const firstChunkSize = 1;
    const subsequentChunkSize = 1;

    for (let i = 0; i < data.length; i += subsequentChunkSize) {
        if (i === 0) {
            chunks.push(data.slice(0, firstChunkSize));
            i = firstChunkSize - subsequentChunkSize;
        } else {
            chunks.push(data.slice(i, i + subsequentChunkSize));
        }
    }

    const drCol = [
        "rank",
        "efficiency",
        // "firstName",
        // "username",
        "totalRides",
        "drivingScore",
        "overallAnticipation",
        "overallSelfConfidence",
        "overallDrivingSkill",
        "DSTP",
        "DSP",
        // "overSpeedPoint",
        "mobileScreenPointNew",
        "mobileUsePointNew",
        "totalKmSPoint",
        "ABCPoint",
      ];
      const drTitle = {
        rank: "\n \nRating",
        efficiency: "Driving \nBehaviour \nScore",
        // firstName: "\n\nFirst Name",
        // username: "\n\nUsername",
        totalRides: "\nTotal \nRides",
        drivingScore: "\nDriving \nPoints",
        overallAnticipation: "\nAnticipation\nPoints",
        overallSelfConfidence: "\nSelf Confidence\nPoints",
        overallDrivingSkill: "\nDriving Skill\nPoints",
        DSTP: "\nDriving Style\nPoints",
        DSP: "\nDriver State\nPoints",
        // overSpeedPoint: "\nOverspeed\nPoints",
        mobileScreenPointNew: "\nMobile Screen\nUsage Points",
        mobileUsePointNew: "\nMobile Call\nUsage Points",
        totalKmSPoint: "Overspeed \nKMS Driven\nPoints",
        ABCPoint: "\nAcc / Brak / Cor \nPoints",
      };
    
      const drImages = {
        rank: rank,
        // firstName: firstName,
        // username: username,
        efficiency: efficiency,
        totalRides: totalRides,
        drivingScore: drivingScore,
        overallAnticipation: anticipation,
        overallSelfConfidence: selfConfidence,
        overallDrivingSkill: drivingSkill,
        DSTP: drivingStyle,
        DSP: drivingState,
        // overSpeedPoint: i7,
        mobileScreenPointNew: mobScreen,
        mobileUsePointNew: mobCall,
        totalKmSPoint: kmsDriven,
        ABCPoint: stressStrain,
      };

    const chunksViolation = [];
    const firstChunkSizeViolation = 1;
    const subsequentChunkSizeViolation = 1;

    for (let i = 0; i < data.length; i += subsequentChunkSizeViolation) {
        if (i === 0) {
            chunksViolation.push(data.slice(0, firstChunkSizeViolation));
            i = firstChunkSizeViolation - subsequentChunkSizeViolation;
        } else {
            chunksViolation.push(data.slice(i, i + subsequentChunkSizeViolation));
        }
    }

    const violationCol = [
        "rank",
        "efficiency",
        // "firstName",
        // "username",
        "totalRides",
        "tKiloMeterTV",
        "tTravelTimeTV",
        "crashCount",
        "totalHighRiskCount",
        "totalMediumRiskCount",
        "totalOverSpeedDurationTV",
        "totalOverSpeedCount",
        "totalMobileScreenScreenOnDurationTV",
        "totalMobileScreenScreenOnCount",
        "totalMobileUseInAcceptedDurationTV",
        "totalMobileUseInAcceptedCount",
        "totalAccelerationCount",
        "totalBrakingCount",
        "totalCorneringCount"
      ];
    
      const violationTitle = {
        rank: "\n \nRating",
        efficiency: "Driving \nBehaviour \nScore",
        // firstName: "\n\nFirst Name",
        // username: "\n\nUsername",
        totalRides: "\nTotal \nRides",
        tKiloMeterTV: "Total \nDistance\n (kms)",
        tTravelTimeTV: "Total\n Duration \n(Mins)",
        crashCount: "\nCrash\n(Count)",
        totalHighRiskCount: "\nHigh Risk\n (Count)",
        totalMediumRiskCount: "\nMedium Risk\n (Count)",
        totalOverSpeedDurationTV: "\nOverspeed\n (Mins)",
        totalOverSpeedCount: "\nOverspeed\n (Count)",
        totalMobileScreenScreenOnDurationTV: "Mobile\nScreen\n(Mins)",
        totalMobileScreenScreenOnCount: "Mobile\nScreen\n(Count)",
        totalMobileUseInAcceptedDurationTV: "\nMobile Call \n(Mins)",
        totalMobileUseInAcceptedCount: "\nMobile Call \n(Count)",
        totalAccelerationCount: "\nAcceleration\n (Count)",
        totalBrakingCount: "\nBraking\n (Count)",
        totalCorneringCount: "\nCornering \n(Count)",
      };
    
      const violationImages = {    
        rank: rank,
        // firstName: firstName,
        // username: username,
        efficiency: efficiency,
        totalRides: totalRides,
        tKiloMeterTV:tdistance,
        tTravelTimeTV:tduration,
        crashCount: crash,
        totalHighRiskCount:i5,
        totalMediumRiskCount:i6,
        totalOverSpeedDurationTV:i7,
        totalOverSpeedCount:i7,
        totalMobileScreenScreenOnDurationTV:i9,
        totalMobileScreenScreenOnCount:i9,
        totalMobileUseInAcceptedDurationTV:i8,
        totalMobileUseInAcceptedCount:i8,
        totalAccelerationCount:i10,
        totalBrakingCount:i11,
        totalCorneringCount:i12
        
      };

    return (
        <React.Fragment>
            <PDFViewer style={styles.pdfViewer}>
                <Document style={styles.document}>

                    <Page size={"A2"} totalPages={totalPages++} style={styles.page}>

                        <PdfHeader />
                        <View style={{ paddingHorizontal: 30, marginTop: 10, marginBottom: 10 }}>
                            <PdfHeading heading={heading} periodFormat={periodFormat} reversedDateFormat={reversedDateFormat} />

                            <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                                <View style={{ marginTop: 10, marginBottom: 5, padding: 15, paddingTop: 10, borderRadius: 10, width: '49.5%', border: '1px solid lightgrey' }} >
                                    <Text style={{ fontSize: 11, marginBottom: 5.3 }}>1. My Activity Summary</Text>

                                    <View
                                        style={{
                                            // marginTop: 15,
                                            // marginBottom: 15,
                                            flexDirection: "column", // Set flexDirection to row
                                            justifyContent: "center",
                                        }}
                                    >
                                        <View style={{ flexDirection: "row" }}>
                                            {tableData.headers.map((item, index) => (
                                                <View
                                                    style={{
                                                        height: '26.8px',
                                                        flex: 2,
                                                        justifyContent: "center",
                                                        padding: 5,
                                                        alignItems: "center",
                                                        fontSize: "9px",
                                                        border: "1px solid lightgrey",
                                                        borderLeft: index === 0 ? "1px solid lightgrey" : "none",
                                                        backgroundColor: globalPDF.GREY_COLOR,
                                                    }}
                                                    key={index}
                                                >
                                                    <Text style={[fontBold, { textAlign: "center" }]} break>
                                                        {item.title}
                                                    </Text>
                                                </View>
                                            ))}
                                        </View>
                                        <View style={{ flexDirection: "row" }}>
                                            {tableData.overall.map((item, index) => (
                                                <View
                                                    style={{
                                                        flex: 2,
                                                        height: '26.8px',
                                                        justifyContent: "center",
                                                        padding: 5,
                                                        fontSize: 9, // removed quotes around 9px
                                                        border: "1px solid lightgrey",
                                                        borderTop: "none",
                                                        borderLeft: index === 0 ? "1px solid lightgrey" : "none",
                                                        textAlign: index === 0 ? "left" : "right",
                                                    }}
                                                    key={index}
                                                >
                                                    <Text
                                                        style={{
                                                            padding:
                                                                index === 0 ? "0px 0px 0px 10px" : "0px 15px 0px 0px",
                                                        }}
                                                        break
                                                    >
                                                        {item} {/* Assuming T1.totalKm is an array of numbers */}
                                                    </Text>
                                                </View>
                                            ))}
                                        </View>
                                        <View style={{ flexDirection: "row" }}>
                                            {tableData.currentYear.map((item, index) => (
                                                <View
                                                    style={{
                                                        flex: 2,
                                                        height: '26.8px',
                                                        justifyContent: "center",
                                                        padding: 5,
                                                        fontSize: 9, // removed quotes around 9px
                                                        border: "1px solid lightgrey",
                                                        borderTop: "none",
                                                        borderLeft: index === 0 ? "1px solid lightgrey" : "none",
                                                        textAlign: index === 0 ? "left" : "right",
                                                    }}
                                                    key={index}
                                                >
                                                    <Text
                                                        style={{
                                                            padding:
                                                                index === 0 ? "0px 0px 0px 10px" : "0px 15px 0px 0px",
                                                        }}
                                                        break
                                                    >
                                                        {item} {/* Assuming T1.totalKm is an array of numbers */}
                                                    </Text>
                                                </View>
                                            ))}
                                        </View>
                                        <View style={{ flexDirection: "row" }}>
                                            {tableData.currentMonth.map((item, index) => (
                                                <View
                                                    style={{
                                                        flex: 2,
                                                        height: '26.8px',
                                                        justifyContent: "center",
                                                        padding: 5,
                                                        fontSize: 9, // removed quotes around 9px
                                                        border: "1px solid lightgrey",
                                                        borderTop: "none",
                                                        borderLeft: index === 0 ? "1px solid lightgrey" : "none",
                                                        textAlign: index === 0 ? "left" : "right",
                                                    }}
                                                    key={index}
                                                >
                                                    <Text
                                                        style={{
                                                            padding:
                                                                index === 0 ? "0px 0px 0px 10px" : "0px 15px 0px 0px",
                                                        }}
                                                        break
                                                    >
                                                        {item} {/* Assuming T1.totalKm is an array of numbers */}
                                                    </Text>
                                                </View>
                                            ))}
                                        </View>
                                        <View style={{ flexDirection: "row" }}>
                                            {tableData.currentWeek.map((item, index) => (
                                                <View
                                                    style={{
                                                        flex: 2,
                                                        height: '26.8px',
                                                        justifyContent: "center",
                                                        padding: 5,
                                                        fontSize: 9, // removed quotes around 9px
                                                        border: "1px solid lightgrey",
                                                        borderTop: "none",
                                                        borderLeft: index === 0 ? "1px solid lightgrey" : "none",
                                                        textAlign: index === 0 ? "left" : "right",
                                                    }}
                                                    key={index}
                                                >
                                                    <Text
                                                        style={{
                                                            padding:
                                                                index === 0 ? "0px 0px 0px 10px" : "0px 15px 0px 0px",
                                                        }}
                                                        break
                                                    >
                                                        {item} {/* Assuming T1.totalKm is an array of numbers */}
                                                    </Text>
                                                </View>
                                            ))}
                                        </View>
                                        <View style={{ flexDirection: "row" }}>
                                            {tableData.currentDay.map((item, index) => (
                                                <View
                                                    style={{
                                                        flex: 2,
                                                        height: '26.8px',
                                                        justifyContent: "center",
                                                        padding: 5,
                                                        fontSize: 9, // removed quotes around 9px
                                                        border: "1px solid lightgrey",
                                                        borderTop: "none",
                                                        borderLeft: index === 0 ? "1px solid lightgrey" : "none",
                                                        textAlign: index === 0 ? "left" : "right",
                                                    }}
                                                    key={index}
                                                >
                                                    <Text
                                                        style={{
                                                            padding:
                                                                index === 0 ? "0px 0px 0px 10px" : "0px 15px 0px 0px",
                                                        }}
                                                        break
                                                    >
                                                        {item} {/* Assuming T1.totalKm is an array of numbers */}
                                                    </Text>
                                                </View>
                                            ))}
                                        </View>
                                    </View>
                                </View>

                                <View style={{ marginTop: 10, marginBottom: 5, padding: 15, paddingTop: 10, borderRadius: 10, width: '49.5%', border: '1px solid lightgrey' }} >
                                    <Text style={{ fontSize: 11, marginBottom: 5 }}>2. Driver Performance Overview</Text>

                                    <View style={{ flexDirection: "row", height: "160px", border: "1px solid lightgrey", borderBottom: "none" }} >
                                        {["Driver Category", "Ride Count", "Distance (Kms)", "Duration (Hrs)", "Risk Alerts", "Other Alerts"].map((header, columnIndex) => (
                                            <View key={columnIndex} style={{ flexDirection: "column", flex: 1 / UDMainDashboardSafetyTableData.length }} >
                                                <Text
                                                    style={[
                                                        fontBold,
                                                        {
                                                            border: "1px solid lightgrey",
                                                            padding: "6px",
                                                            fontSize: "9px",
                                                            textAlign: "center",
                                                            borderTop: "none",
                                                            borderRight:
                                                                columnIndex !==
                                                                    UDMainDashboardSafetyTableData.length + 1
                                                                    ? "none"
                                                                    : "1px solid lightgrey",
                                                            borderLeft:
                                                                columnIndex === 0 ? "none" : "1px solid lightgrey",
                                                            backgroundColor: globalPDF.GREY_COLOR,
                                                        },
                                                    ]}
                                                >
                                                    {header}
                                                </Text>
                                                {Array.from({ length: maxDataListKeysLength }).map(
                                                    (_, rowIndex) => (
                                                        <Text
                                                            key={rowIndex}
                                                            style={[
                                                                styles.tablestyle,
                                                                {
                                                                    borderBottom:
                                                                        rowIndex === maxDataListKeysLength - 1 &&
                                                                            maxDataListKeysLength > 1
                                                                            ? "1px solid lightgrey"
                                                                            : "1px solid lightgrey",
                                                                    fontSize: "9px",
                                                                    borderRight:
                                                                        columnIndex !==
                                                                            UDMainDashboardSafetyTableData.length + 1
                                                                            ? "none"
                                                                            : "1px solid lightgrey",
                                                                    borderLeft:
                                                                        columnIndex === 0 ? "none" : "1px solid lightgrey",
                                                                    textAlign: columnIndex === 0 ? "left" : "right",
                                                                    paddingRight: columnIndex === 0 ? "none" : "15px",
                                                                },
                                                            ]}
                                                        >
                                                            {columnIndex === 0
                                                                ? [
                                                                    "Safe",
                                                                    "Sportive",
                                                                    "Quite Safe",
                                                                    "Reckless",
                                                                    "Quite Dangerous",
                                                                    "Dangerous",
                                                                ][rowIndex]
                                                                : columnIndex === 2 || columnIndex === 3 // Check if column is "Distance" or "Duration"
                                                                    ? parseFloat(
                                                                        UDMainDashboardSafetyTableData[columnIndex - 1]
                                                                            ?.dataListValues[rowIndex] || "0"
                                                                    ).toFixed(2) // Format to 2 decimal places
                                                                    : UDMainDashboardSafetyTableData[columnIndex - 1]
                                                                        ?.dataListValues[rowIndex] || "0"}
                                                        </Text>
                                                    )
                                                )}
                                            </View>
                                        ))}
                                    </View>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between' }}>
                                <View style={{ marginTop: 10, marginBottom: 5, padding: 15, paddingTop: 10, paddingBottom: 5, borderRadius: 10, width: '49.5%', border: '1px solid lightgrey' }} >
                                    <Text style={{ fontSize: 11, marginBottom: 15 }}>3. Zone Summary</Text>

                                    <View>
                                        <View style={{ flexDirection: "column", width: "100%", height: "80px" }}>
                                            <Text style={{ fontSize: 10.5 }}> 1. Green Zone</Text>
                                            <Svg width="100%" height="80">
                                                <Text x="0" y="18" style={{ fontSize: "10px" }}>
                                                    {"(C1) Safe Driving" + " (" + (categoryCircle.length > 0 && `${categoryCircle[0].total} / ${USRideBoxCount.totalRides}`) + ")"}
                                                </Text>
                                                <Rect x="0" y="25" rx="5" ry="5" width="230" height="25" fill="#e6e6e6" />
                                                <Rect x="0" y="25" rx="5" ry="5" width={w2[0].total} height="25" fill="#2ab57d" />
                                                <Text x={115} y={42} textAnchor="middle" alignmentBaseline="middle" style={{ fontSize: "10px" }} >
                                                    {categoryCircle[0].percentage + "%"}
                                                </Text>
                                                <Text x="275" y="18" style={{ fontSize: "10px" }}>
                                                    {"(C2) Sportive Driving" + " (" + (categoryCircle.length > 0 && `${categoryCircle[1].total} / ${USRideBoxCount.totalRides}`) + ")"}
                                                </Text>
                                                <Rect x="275" y="25" rx="5" ry="5" width="230" height="25" fill="#e6e6e6" />
                                                <Rect x="275" y="25" rx="5" ry="5" width={w2[1].total} height="25" fill="#2ab57d" />
                                                <Text x={392} y={42} textAnchor="middle" alignmentBaseline="middle" style={{ fontSize: "10px" }} >
                                                    {categoryCircle[1].percentage + "%"}
                                                </Text>
                                            </Svg>
                                        </View>
                                        <View style={{ flexDirection: "column", width: "100%", height: "80px" }}>
                                            <Text style={{ fontSize: 10.5 }}>3.2. Amber Zone</Text>
                                            <Svg width="100%" height="80">
                                                <Text x="0" y="18" style={{ fontSize: "10px" }}>
                                                    {"(C3) Quite Safe Driving" + " (" + (categoryCircle.length > 0 && `${categoryCircle[2].total} / ${USRideBoxCount.totalRides}`) + ")"}
                                                </Text>
                                                <Rect x="0" y="25" rx="5" ry="5" width="230" height="25" fill="#e6e6e6" />
                                                <Rect x="0" y="25" rx="5" ry="5" width={w2[2].total} height="25" fill="#ffbf53" />
                                                <Text x={115} y={42} textAnchor="middle" alignmentBaseline="middle" style={{ fontSize: "10px" }}>
                                                    {categoryCircle[2].percentage + "%"}
                                                </Text>
                                                <Text x="275" y="18" style={{ fontSize: "10px" }}>
                                                    {"(C4) Reckless Driving" + " (" + (categoryCircle.length > 0 && `${categoryCircle[3].total} / ${USRideBoxCount.totalRides}`) + ")"}
                                                </Text>
                                                <Rect x="275" y="25" rx="5" ry="5" width="230" height="25" fill="#e6e6e6" />
                                                <Rect x="275" y="25" rx="5" ry="5" width={w2[3].total} height="25" fill="#ffbf53" />
                                                <Text x={392} y={42} textAnchor="middle" alignmentBaseline="middle" style={{ fontSize: "10px" }}>
                                                    {categoryCircle[3].percentage + "%"}
                                                </Text>
                                            </Svg>
                                        </View>
                                        <View style={{ flexDirection: "column", width: "100%", height: "70px" }}>
                                            <Text style={{ fontSize: 10.5 }}>3.3. Red Zone</Text>
                                            <Svg width="100%" height="80">
                                                <Text x="0" y="18" style={{ fontSize: "10px" }}>
                                                    {"(C5) Quite Dangerous Driving" + " (" + (categoryCircle.length > 0 && `${categoryCircle[4].total} / ${USRideBoxCount.totalRides}`) + ")"}
                                                </Text>
                                                <Rect x="0" y="25" rx="5" ry="5" width="230" height="25" fill="#e6e6e6" />
                                                <Rect x="0" y="25" rx="5" ry="5" width={w2[4].total} height="25" fill="#fd625e" />
                                                <Text x={115} y={42} textAnchor="middle" alignmentBaseline="middle" style={{ fontSize: "10px" }}>
                                                    {categoryCircle[4].percentage + "%"}
                                                </Text>
                                                <Text x="275" y="18" style={{ fontSize: "10px" }}>
                                                    {"(C6) Dangerous Driving" + " (" + (categoryCircle.length > 0 && `${categoryCircle[5].total} / ${USRideBoxCount.totalRides}`) + ")"}
                                                </Text>
                                                <Rect x="275" y="25" rx="5" ry="5" width="230" height="25" fill="#e6e6e6" />
                                                <Rect x="275" y="25" rx="5" ry="5" width={w2[5].total} height="25" fill="#fd625e" />
                                                <Text x={392} y={42} textAnchor="middle" alignmentBaseline="middle" style={{ fontSize: "10px" }}>
                                                    {categoryCircle[5].percentage + "%"}
                                                </Text>
                                            </Svg>
                                        </View>
                                    </View>
                                </View>


                                <View style={{ marginTop: 10, marginBottom: 5, padding: 15, paddingTop: 10, borderRadius: 10, width: '49.5%', border: '1px solid lightgrey' }} >
                                    <Text style={{ fontSize: 11, marginBottom: 48 }}></Text>
                                    <View
                                        style={{
                                            marginTop: 15,
                                            marginBottom: 15,
                                            flexDirection: "column", // Set flexDirection to row
                                            justifyContent: "center",
                                        }}
                                    >
                                        <View style={{ flexDirection: "row" }}>
                                            {T1.title.map((item, index) => (
                                                <View
                                                    style={{
                                                        flex: 2,
                                                        justifyContent: "center",
                                                        padding: 5,
                                                        alignItems: "center",
                                                        fontSize: "9px",
                                                        border: "1px solid lightgrey",
                                                        borderLeft: index === 0 ? "1px solid lightgrey" : "none",
                                                        backgroundColor: globalPDF.GREY_COLOR,
                                                    }}
                                                    key={index}
                                                >
                                                    <Text style={[fontBold, { textAlign: "center" }]} break>
                                                        {item.title}
                                                    </Text>
                                                </View>
                                            ))}
                                        </View>
                                        <View style={{ flexDirection: "row" }}>
                                            {T1.totalKm.map((item, index) => (
                                                <View
                                                    style={{
                                                        flex: 2,
                                                        justifyContent: "center",
                                                        padding: 5,
                                                        fontSize: 9, // removed quotes around 9px
                                                        border: "1px solid lightgrey",
                                                        borderTop: "none",
                                                        borderLeft: index === 0 ? "1px solid lightgrey" : "none",
                                                        textAlign: index === 0 ? "left" : "right",
                                                    }}
                                                    key={index}
                                                >
                                                    <Text
                                                        style={{
                                                            padding:
                                                                index === 0 ? "0px 0px 0px 10px" : "0px 15px 0px 0px",
                                                        }}
                                                        break
                                                    >
                                                        {item} {/* Assuming T1.totalKm is an array of numbers */}
                                                    </Text>
                                                </View>
                                            ))}
                                        </View>

                                        {/* TotalTt row */}
                                        <View style={{ flexDirection: "row" }}>
                                            {T1.totalTt.map((item, index) => (
                                                <View
                                                    style={{
                                                        flex: 2,
                                                        justifyContent: "center",
                                                        padding: 5,
                                                        fontSize: 9, // removed quotes around 9px
                                                        border: "1px solid lightgrey",
                                                        borderTop: "none",
                                                        borderLeft: index === 0 ? "1px solid lightgrey" : "none",
                                                        textAlign: index === 0 ? "left" : "right",
                                                    }}
                                                    key={index}
                                                >
                                                    <Text
                                                        style={{
                                                            padding:
                                                                index === 0 ? "0px 0px 0px 10px" : "0px 15px 0px 0px",
                                                        }}
                                                        break
                                                    >
                                                        {item} {/* Assuming T1.totalKm is an array of numbers */}
                                                    </Text>
                                                </View>
                                            ))}
                                        </View>
                                    </View>
                                    <View
                                        style={{
                                            marginTop: 15,
                                            marginBottom: 15,
                                            flexDirection: "column", // Set flexDirection to row
                                            justifyContent: "center",
                                        }}
                                    >
                                        <View style={{ flexDirection: "row" }}>
                                            {T2.title.map((item, index) => (
                                                <View
                                                    style={{
                                                        flex: 2,
                                                        justifyContent: "center",
                                                        padding: 5,
                                                        alignItems: "center",
                                                        fontSize: "9px",
                                                        border: "1px solid lightgrey",
                                                        borderLeft: index === 0 ? "1px solid lightgrey" : "none",
                                                        backgroundColor: globalPDF.GREY_COLOR,
                                                    }}
                                                    key={index}
                                                >
                                                    <Text style={[fontBold, { textAlign: "center" }]} break>
                                                        {item.title}
                                                    </Text>
                                                </View>
                                            ))}
                                        </View>
                                        <View style={{ flexDirection: "row" }}>
                                            {T2.data.map((item, index) => (
                                                <View
                                                    style={{
                                                        flex: 2,
                                                        justifyContent: "center",
                                                        padding: 5,
                                                        fontSize: 9, // removed quotes around 9px
                                                        border: "1px solid lightgrey",
                                                        borderTop: "none",
                                                        borderLeft: index === 0 ? "1px solid lightgrey" : "none",
                                                        textAlign: index === 0 ? "left" : "right",
                                                    }}
                                                    key={index}
                                                >
                                                    <Text
                                                        style={{
                                                            padding:
                                                                index === 0 ? "0px 0px 0px 10px" : "0px 15px 0px 0px",
                                                        }}
                                                        break
                                                    >
                                                        {item} {/* Assuming T1.totalKm is an array of numbers */}
                                                    </Text>
                                                </View>
                                            ))}
                                        </View>
                                    </View>
                                </View>
                            </View>

                            {chunks.map((chunk, i) => (
                                <View key={i}
                                    style={{
                                        marginBottom: 5,
                                        paddingTop: 7,
                                    }}
                                >
                                    <Text style={{ fontSize: 13, marginBottom: 7 }}>
                                        4. Driving Behaviour Rating
                                    </Text>

                                    <Text style={{ fontSize: 10, marginBottom: 7 }}>{displayText.DRIVER_BEHAVIOUR_DESCRIPTION}</Text>
                                    <View>
                                        <View
                                            style={{
                                                flexDirection: "row",
                                                height: "70px",
                                                marginTop: 5,
                                            }}
                                        >
                                            {drCol.map((column, index) => (
                                                <View
                                                    key={index}
                                                    style={{
                                                        textAlign: 'center',
                                                        flex: column === "firstName" ? 1.7 : 1,
                                                        justifyContent: "center",
                                                        backgroundColor: globalPDF.GREY_COLOR,
                                                        // alignItems: "flex-start",
                                                        // paddingLeft: 5,
                                                        fontSize: "9px",
                                                        border: "1px solid lightgrey",
                                                        borderLeft:
                                                            index === 0 ? "1px solid lightgrey" : "none",
                                                        flexDirection: 'column'
                                                    }}
                                                >
                                                    <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 5, textAlign: "center" }}>
                                                        <View style={{ height: '45px', paddingTop: 7 }}>
                                                            <Text style={fontBold} break> {drTitle[column]} </Text>
                                                        </View>
                                                        <View style={{ height: '25px' }}>
                                                            <Image src={drImages[column]} style={{ width: "15px", height: "15px" }} />
                                                        </View>
                                                    </View>
                                                </View>
                                            ))}
                                        </View>
                                        {chunk.map((item, rowIndex) => (
                                            <View
                                                key={rowIndex}
                                                style={{ flexDirection: "row", height: "25px" }}
                                            >
                                                {drCol.map((column, colIndex) => (
                                                    <View
                                                        key={colIndex}
                                                        style={{
                                                            flex: column === "firstName" ? 1.7 : 1,
                                                            backgroundColor: column === "totalRides" ? globalPDF.LIGHT_BLUE_COLOR : "transparent",
                                                            justifyContent: "center",
                                                            fontSize: 9,
                                                            borderLeft:
                                                                item[column] !== undefined
                                                                    ? "1px solid lightgrey"
                                                                    : "none",
                                                            borderRight:
                                                                colIndex === 6
                                                                    ? item[column] !== undefined
                                                                        ? "1px solid lightgrey"
                                                                        : "none"
                                                                    : colIndex === drCol.length - 1
                                                                        ? "1px solid lightgrey"
                                                                        : "none",
                                                            borderBottom:
                                                                item[column] !== undefined
                                                                    ? "1px solid lightgrey"
                                                                    : "none",
                                                        }}
                                                    >
                                                        <Text
                                                            style={{
                                                                fontSize: 9,
                                                                textAlign: "left",
                                                                paddingLeft: 10
                                                            }}
                                                            break
                                                        >
                                                            {item[column]}
                                                        </Text>
                                                    </View>
                                                ))}
                                            </View>
                                        ))}
                                    </View>
                                </View>
                            ))}
                            {chunksViolation.map((chunk, pageIndex) => (
                                <View key={pageIndex} style={{ marginBottom: 5, paddingVertical: 15 }}>
                                    <Text style={{ fontSize: 13, marginBottom: 7 }}>
                                        5. Dashboard for Violation
                                    </Text>
                                    <View>
                                        <View
                                            style={{
                                                flexDirection: "row",
                                                height: "70px",
                                                marginTop: 5,
                                            }}
                                        >
                                            {violationCol.map((column, index) => (
                                                <View
                                                    key={index}
                                                    style={{
                                                        flex: column === "firstName" ? 1.7 : 1,
                                                        textAlign: 'center',
                                                        justifyContent: "center",
                                                        backgroundColor: globalPDF.GREY_COLOR,
                                                        fontSize: "9px",
                                                        border: "1px solid lightgrey",
                                                        borderLeft:
                                                            index === 0 ? "1px solid lightgrey" : "none",
                                                    }}
                                                >
                                                    <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 5, textAlign: "center" }}>
                                                        <View style={{ height: '45px', paddingTop: 7 }}>
                                                            <Text style={fontBold} break> {violationTitle[column]} </Text>
                                                        </View>
                                                        <View style={{ height: '25px' }}>
                                                            <Image src={violationImages[column]} style={{ width: "15px", height: "15px" }} />
                                                        </View>
                                                    </View>
                                                </View>
                                            ))}
                                        </View>
                                        {chunk.map((item, rowIndex) => (
                                            <View
                                                key={rowIndex}
                                                style={{ flexDirection: "row", height: "25px" }}
                                            >
                                                {violationCol.map((column, colIndex) => (
                                                    <View
                                                        key={colIndex}
                                                        style={{
                                                            flex: column === "firstName" ? 1.7 : 1,
                                                            backgroundColor: column === "totalRides" || column === "tKiloMeterTV" || column === "tTravelTimeTV" ? globalPDF.LIGHT_BLUE_COLOR : "transparent",
                                                            justifyContent: "center",
                                                            fontSize: 9,
                                                            borderLeft:
                                                                item[column] !== undefined
                                                                    ? "1px solid lightgrey"
                                                                    : "none",
                                                            borderRight:
                                                                colIndex === 6
                                                                    ? item[column] !== undefined
                                                                        ? "1px solid lightgrey"
                                                                        : "none"
                                                                    : colIndex === violationCol.length - 1
                                                                        ? "1px solid lightgrey"
                                                                        : "none",
                                                            borderBottom:
                                                                item[column] !== undefined
                                                                    ? "1px solid lightgrey"
                                                                    : "none",
                                                        }}
                                                    >
                                                        <Text
                                                            style={{
                                                                fontSize: 9,
                                                                textAlign: "left",
                                                                paddingLeft: 10
                                                            }}
                                                            break
                                                        >
                                                            {item[column]}
                                                        </Text>
                                                    </View>
                                                ))}
                                            </View>
                                        ))}
                                    </View>
                                </View>
                            ))}
                            {/* <View style={{ width: '100%' }}>
                                <View style={{ marginTop: 10, marginBottom: 5, padding: 15, paddingTop: 10, borderRadius: 10, width: '50%' }} >
                                    <View style={{ fontSize: "11px", width: "100%" }}>
                                        <Text style={{ marginBottom: "10px" }}>
                                            6. Overall Driving Score
                                        </Text>
                                    </View>
                                    <View
                                        style={{
                                            width: "100%",
                                            flexDirection: "row",
                                            gap: 80,
                                        }}
                                    >
                                        <View style={{ fontSize: "10.5px" }}>
                                            <Text style={{ marginBottom: "10px" }}>6.1. All Year</Text>
                                        </View>
                                        <View style={{ fontSize: "10.5px", marginLeft: '17px' }}>
                                            <Text style={{ marginBottom: "10px" }}>
                                                6.2. Selected Year
                                            </Text>
                                        </View>
                                        <View style={{ fontSize: "10.5px", marginLeft: '-12px' }}>
                                            <Text style={{ marginBottom: "10px" }}>6.3. Current Year</Text>
                                        </View>
                                    </View>
                                    <View
                                        style={{
                                            display: "flex",
                                            textAlign: "center",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Svg style={{ width: "190px" }} height={"20px"}>
                                            <Rect x="0" rx="2" ry="2" width="15" height="15" fill="red" />
                                            <Text x="20" y="10" style={{ height: "110", fontSize: "11px" }}>
                                                {" "}
                                                Poor
                                            </Text>
                                            <Rect
                                                x="60"
                                                rx="2"
                                                ry="2"
                                                width="15"
                                                height="15"
                                                fill="#DEA602"
                                            />
                                            <Text
                                                x="80"
                                                y="10"
                                                style={{
                                                    height: "15",
                                                    fontSize: "11px",
                                                    marginLeft: "5px",
                                                }}
                                            >
                                                {" "}
                                                Average
                                            </Text>
                                            <Rect
                                                x="140"
                                                rx="2"
                                                ry="2"
                                                width="15"
                                                height="15"
                                                fill="rgb(25, 84, 62)"
                                            />
                                            <Text x="160" y="10" style={{ height: "15", fontSize: "11px" }}>
                                                {" "}
                                                Good
                                            </Text>
                                        </Svg>
                                    </View>
                                    <View
                                        style={{
                                            width: "100%",
                                            height: "185.666px",
                                            // paddingHorizontal: 30,
                                            // marginBottom: "10px",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <View style={{ width: "75%" }}>
                                            <View
                                                style={{
                                                    fontSize: "10px",
                                                    marginBottom: "4px",
                                                }}
                                            >
                                                <Text> </Text>
                                            </View>
                                            <View style={{ height: "100%" }}>
                                                <Svg>
                                                    <Line
                                                        x1="0.5"
                                                        y1="0"
                                                        x2="0.5"
                                                        y2="140"
                                                        stroke="black"
                                                        strokeWidth="1"
                                                    />
                                                    <Line
                                                        x1="0"
                                                        y1="140"
                                                        x2="67"
                                                        y2="140"
                                                        stroke="black"
                                                        strokeWidth="1"
                                                    />

                                                    {safetyCategoryDataCY.map((item, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <Rect
                                                                    x={item.x}
                                                                    y={item.y}
                                                                    fill={item.color}
                                                                    width={item.width}
                                                                    height={item.height}
                                                                />
                                                                <Text
                                                                    x={item.x + 5}
                                                                    y={item.y + item.height - 4}
                                                                    style={{ fontSize: "10px" }}
                                                                    textAnchor="middle"
                                                                >
                                                                    {item.text + "%"}
                                                                </Text>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </Svg>
                                            </View>

                                            <View
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginTop: "-40px",
                                                }}
                                            >
                                                <Text style={{ fontSize: "10px", marginLeft: "28px" }}>
                                                    {"All Year"}
                                                </Text>
                                            </View>
                                        </View>
                                        <View style={{ width: "75%" }}>
                                            <View
                                                style={{
                                                    fontSize: "10px",
                                                    marginBottom: "4px",
                                                }}
                                            >
                                                <Text> </Text>
                                            </View>
                                            <View style={{ height: "100%" }}>
                                                <Svg>
                                                    <Line
                                                        x1="0.5"
                                                        y1="0"
                                                        x2="0.5"
                                                        y2="140"
                                                        stroke="black"
                                                        strokeWidth="1"
                                                    />
                                                    <Line
                                                        x1="0"
                                                        y1="140"
                                                        x2="67"
                                                        y2="140"
                                                        stroke="black"
                                                        strokeWidth="1"
                                                    />

                                                    {safetyCategoryDataSY.map((item, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <Rect
                                                                    x={item.x}
                                                                    y={item.y}
                                                                    fill={item.color}
                                                                    width={item.width}
                                                                    height={item.height}
                                                                />
                                                                <Text
                                                                    x={item.x + 5}
                                                                    y={item.y + item.height - 4}
                                                                    style={{ fontSize: "10px" }}
                                                                    textAnchor="middle"
                                                                >
                                                                    {item.text + "%"}
                                                                </Text>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </Svg>
                                            </View>

                                            <View
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginTop: "-40px",
                                                }}
                                            >
                                                <Text style={{ fontSize: "10px", marginLeft: "30px" }}>
                                                    {selectedYear}
                                                </Text>
                                            </View>
                                        </View>
                                        <View style={{ width: "100%" }}>
                                            <View
                                                style={{
                                                    fontSize: "10px",
                                                    marginBottom: "4px",
                                                }}
                                            >
                                                <Text> </Text>
                                            </View>
                                            <View style={{ height: "100%" }}>
                                                <Svg>
                                                    <Line
                                                        x1="0.5"
                                                        y1="0"
                                                        x2="0.5"
                                                        y2="140"
                                                        stroke="black"
                                                        strokeWidth="1"
                                                    />
                                                    <Line
                                                        x1="0"
                                                        y1="140"
                                                        x2="227"
                                                        y2="140"
                                                        stroke="black"
                                                        strokeWidth="1"
                                                    />

                                                    {safetyCategoryData.map((item, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <Rect
                                                                    x={item.x}
                                                                    y={item.y}
                                                                    fill={item.color}
                                                                    width={item.width}
                                                                    height={item.height}
                                                                />
                                                                <Text
                                                                    x={item.x + 5}
                                                                    y={item.y + item.height - 4}
                                                                    style={{ fontSize: "10px" }}
                                                                    textAnchor="middle"
                                                                >
                                                                    {item.text + "%"}
                                                                </Text>
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </Svg>
                                            </View>

                                            <View
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    marginTop: "-40px",
                                                }}
                                            >
                                                <Text style={{ fontSize: "10px", marginLeft: "0px" }}>
                                                    {UtilDateTime.getCurrentDay()}
                                                </Text>
                                                <Text style={{ fontSize: "10px", marginLeft: "10px" }}>
                                                    {UtilDateTime.getCurrentWeek()}
                                                </Text>
                                                <Text style={{ fontSize: "10px", marginLeft: "0px" }}>
                                                    {UtilDateTime.getCurrentMonth()}
                                                </Text>
                                                <Text style={{ fontSize: "10px", marginLeft: "15px" }}>
                                                    {UtilDateTime.getCurrentYear()}
                                                </Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View> */}
                        </View>

                        <PdfFooter pageNumber={1} totalPages={1} pathname={pathname} />
                    </Page>
                </Document>
            </PDFViewer>
        </React.Fragment>
    )
}

export default ColouredoverAllSummaryPdf;
