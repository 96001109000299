import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import MyRidesController from "../../controller/Rides/MyRides";
import ToastService from "../../components/Common/toastService";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Typography } from "@mui/material";
import { Button as BUTTON } from "@mui/material"

const MobileLicenseOrder = ({
  setIsOpen,
  subscriptionId,
  subscriptionData,
}) => {
  const [postOrderDetails, setPostOrderDetails] = useState({
    id: subscriptionId,
    orderName: "",
    androidCount: "",
    iosCount: "",
    androidSDKCount: "",
    iosSDKCount: "",
    total: 0,
    paymentMode: "DIRECT",
  });

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [validationlengthError, setValidationlengthError] = useState("");
  useEffect(() => {
    const calculateTotal = () => {
      const androidCount = parseInt(postOrderDetails.androidCount) || 0;
      const iosCount = parseInt(postOrderDetails.iosCount) || 0;
      const androidSDKCount = parseInt(postOrderDetails.androidSDKCount) || 0;
      const iosSDKCount = parseInt(postOrderDetails.iosSDKCount) || 0;
      const total = androidCount + iosCount + androidSDKCount + iosSDKCount;
      validation.setFieldValue("total", total);
      setPostOrderDetails((prev) => ({ ...prev, total: total.toString() }));
    };
    calculateTotal();
  }, [postOrderDetails.androidCount, postOrderDetails.iosCount, postOrderDetails.androidSDKCount, postOrderDetails.iosSDKCount]);

  const postData = async () => {
    var response = await MyRidesController.PostSubscription(postOrderDetails);
    var resultJSON = JSON.parse(response);
    const status = resultJSON.status;
    console.log(response);
    if (status === "SUCCESS") {
      ToastService.successmsg("Order Placed Successfully");

      subscriptionData();
    } else if (status === "FAILED") {
      const error = resultJSON.error;
      const message = error.message;
      ToastService.errormsg(message);
    }
  };

  const handleOrder = () => {
    validation.validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        validation.handleSubmit();
      } else {
        if (errors.hasOwnProperty("total"))
          setValidationlengthError("Total order count should be minimum 1");
        Object.keys(errors).forEach((fieldName) => {
          validation.setFieldTouched(fieldName, true, false);
        });
      }
    });
  };

  const handleReset = () => {
    validation.resetForm();
    setValidationlengthError("");
    setPostOrderDetails((prev) => {
      return {
        ...prev,
        orderName: "",
        androidCount: "",
        iosCount: "",
        androidSDKCount: "",
        iosSDKCount: "",
        total: 0,
      };
    });
  };

  const openConfirmationModal = () => {
    setConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const handleConfirmClose = () => {
    validation.handleReset();
    setIsOpen(false);
    closeConfirmationModal();
  };

  const validation = useFormik({
    initialValues: {
      orderName: "",
      androidCount: "",
      iosCount: "",
      androidSDKCount: "",
      iosSDKCount: "",
      total: 0,
    },
    onSubmit: async (values) => {
      console.log("Form submitted with values:", values);
      await postData();
      setIsOpen(false);
    },
    validationSchema: Yup.object({
      orderName: Yup.string()
        // .matches(/^[a-zA-Z0-9\s]+$/, 'Only letters, numbers are allowed.')
        .matches(
          /^[a-zA-Z0-9][a-zA-Z0-9\s]*$/,
          "Only accepts alphabets and numbers"
        )
        .required("This field is required"),
      androidCount: Yup.number()
        .typeError("Please enter a valid number")
        .integer("Number of licenses must be an integer"),
      iosCount: Yup.number()
        .typeError("Please enter a valid number")
        .integer("Number of licenses must be an integer"),
      androidSDKCount: Yup.number()
        .typeError("Please enter a valid number")
        .integer("Number of licenses must be an integer"),
      iosSDKCount: Yup.number()
        .typeError("Please enter a valid number")
        .integer("Number of licenses must be an integer"),
      total: Yup.number()
        .typeError("Total must be a valid number")
        .min(1, "Total must be greater than 0")
        .required("Total is required"),
    }),
  });

  return (
    <div>
      <div>
        <Card style={{ border: "none" }}>
          <ToastContainer style={{ width: "auto" }} />
          <CardHeader>
            <div className="d-flex justify-content-between">
              <h2 className="card-title">User Licences</h2>
              <Button
                size="small"
                onClick={openConfirmationModal}
                style={{ padding: "0px 8px" }}
              >
                X
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            <Form onSubmit={handleOrder}>
              <FormGroup row>
                <Label for="ordername" sm={5}>
                  Order Name<span>(*)</span>
                </Label>
                <Col sm={7}>
                  <Input
                    value={postOrderDetails.orderName}
                    id="ordername"
                    type="text"
                    name="orderName"
                    placeholder="Enter Order Name"
                    onChange={(e) => {
                      validation.handleBlur(e);
                      validation.handleChange(e);
                      setPostOrderDetails((prev) => {
                        return { ...prev, orderName: e.target.value };
                      });
                    }}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.orderName &&
                      validation.errors.orderName
                    }
                  />
                  {validation.touched.orderName &&
                    validation.errors.orderName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.orderName}
                    </FormFeedback>
                  ) : null}
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="andriod" sm={5}>
                  No Of Licenses - Android
                  <span>(*)</span>
                </Label>
                <Col sm={7}>
                  <Input
                    id="android"
                    value={postOrderDetails.androidCount}
                    name="androidCount"
                    placeholder="Enter no of licence"
                    onChange={(e) => {
                      if (new RegExp(/^[0-9]{0,}$/).test(e.target.value)) {
                        setValidationlengthError("");
                        validation.handleBlur(e);
                        validation.handleChange(e);
                        setPostOrderDetails((prev) => {
                          return { ...prev, androidCount: e.target.value };
                        });
                      }
                    }}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.androidCount &&
                      validation.errors.androidCount
                    }
                  />
                  {validation.touched.androidCount &&
                    validation.errors.androidCount ? (
                    <FormFeedback type="invalid">
                      {validation.errors.androidCount}
                    </FormFeedback>
                  ) : null}
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="androidsdk" sm={5}>
                  No Of Licenses - Android SDK
                  <span>(*)</span>
                </Label>
                <Col sm={7}>
                  <Input
                    id="androidsdk"
                    value={postOrderDetails.androidSDKCount}
                    name="androidSDKCount"
                    placeholder="Enter no of licence"
                    onChange={(e) => {
                      if (new RegExp(/^[0-9]{0,}$/).test(e.target.value)) {
                        setValidationlengthError("");
                        validation.handleBlur(e);
                        validation.handleChange(e);
                        setPostOrderDetails((prev) => {
                          return { ...prev, androidSDKCount: e.target.value };
                        });
                      }
                    }}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.androidSDKCount &&
                      validation.errors.androidSDKCount
                    }
                  />
                  {validation.touched.androidSDKCount &&
                    validation.errors.androidSDKCount ? (
                    <FormFeedback type="invalid">
                      {validation.errors.androidSDKCount}
                    </FormFeedback>
                  ) : null}
                </Col>
              </FormGroup>


              <FormGroup row>
                <Label for="ios" sm={5}>
                  No Of Licenses - iOS<span>(*)</span>
                </Label>
                <Col sm={7}>
                  <Input
                    id="ios"
                    name="iosCount"
                    value={postOrderDetails.iosCount}
                    placeholder="Enter no of licence"
                    onChange={(e) => {
                      if (new RegExp(/^[0-9]{0,}$/).test(e.target.value)) {
                        setValidationlengthError("");
                        validation.handleBlur(e);
                        validation.handleChange(e);
                        setPostOrderDetails((prev) => {
                          return { ...prev, iosCount: e.target.value };
                        });
                      }
                    }}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.iosCount && validation.errors.iosCount
                    }
                  />
                  {validation.touched.iosCount && validation.errors.iosCount ? (
                    <FormFeedback type="invalid">
                      {validation.errors.iosCount}
                    </FormFeedback>
                  ) : null}
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="sdk" sm={5}>
                  No Of Licenses - iOS SDK<span>(*)</span>
                </Label>
                <Col sm={7}>
                  <Input
                    id="sdk"
                    name="iosSDKCount"
                    value={postOrderDetails.iosSDKCount}
                    placeholder="Enter no of licence"
                    onChange={(e) => {
                      if (new RegExp(/^[0-9]{0,}$/).test(e.target.value)) {
                        setValidationlengthError("");
                        validation.handleBlur(e);
                        validation.handleChange(e);
                        setPostOrderDetails((prev) => {
                          return { ...prev, iosSDKCount: e.target.value };
                        });
                      }
                    }}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.iosSDKCount && validation.errors.iosSDKCount
                    }
                  />
                  {validation.touched.iosSDKCount && validation.errors.iosSDKCount ? (
                    <FormFeedback type="invalid">
                      {validation.errors.iosSDKCount}
                    </FormFeedback>
                  ) : null}
                </Col>
              </FormGroup>


              <FormGroup row>
                <Label for="total" sm={5}>
                  Total License<span>(*)</span>
                </Label>
                <Col sm={7}>
                  <Input
                    id="total"
                    name="total"
                    value={postOrderDetails.total}
                    placeholder="Enter  total licence"
                    readOnly
                  />
                </Col>
                <div style={{ color: "red" }}>{validationlengthError}</div>
              </FormGroup>
              <FormGroup row>
                <Label for="payment" sm={5}>
                  Payment Mode<span>(*)</span>
                </Label>
                <Col sm={7}>
                  <Input
                    id=""
                    value={postOrderDetails.paymentMode}
                    type="select"
                    onChange={(e) =>
                      setPostOrderDetails((prev) => {
                        return { ...prev, paymentMode: e.target.value };
                      })
                    }
                  >
                    <option value={"DIRECT"}>Direct 1</option>
                    {/* <option value={"DIRECT2"}>Direct 2</option>
                          <option value={"DIRECT3"}>Direct 3</option> */}
                  </Input>
                </Col>
              </FormGroup>
              <div className="col-md-12 d-md-flex">
                <div className="col-md-12 d-flex p-0 justify-content-between my-3">
                  <Button
                    color="secondary"
                    size="sm"
                    className="ms-md-1"
                    onClick={handleOrder}
                  >
                    Place Order
                  </Button>
                  <Button color="secondary" onClick={handleReset} size="sm">
                    Reset
                  </Button>
                  <Button
                    color="secondary"
                    size="sm"
                    className="me-md-1"
                    onClick={openConfirmationModal}
                  >
                    Cancel
                  </Button>
                </div>
                <div className="col-md-4" />
              </div>

              <Modal
                isOpen={confirmationModalOpen}
                toggle={closeConfirmationModal}
                contentLabel="Confirmation Modal"
                style={{
                  content: { height: "190px", width: "340px", margin: "auto", borderRadius: "10px", padding: "20px" },
                  overlay: { backgroundColor: "rgba(0,0,0,0.5)" }, width:'390px'
                }}>

                <ModalHeader style={{ height: "40px", backgroundColor: "#5156BE", width: "100%", display: "felx", justifyContent: "center" }} >
                  <span style={{ fontWeight: 700, fontSize: "20px", color: "#fff" }}>Confirm Action </span>
                </ModalHeader>
                <ModalBody>

                  <Typography mt={1} sx={{ fontWeight: 500, fontSize: '17px', color: '#000000', textAlign: 'center' }}>Are you sure you want to close?</Typography>
                  <br />
                  <div className="btn-div">
                    <BUTTON variant="outlined" size="small" className='popup_Button' sx={{ width: "50px", borderRadius: "12px" }} onClick={handleConfirmClose}>Yes</BUTTON>
                    <BUTTON variant="outlined" size="small" className='popup_Button' sx={{ width: "50px", borderRadius: "12px", ml: 1 }} onClick={closeConfirmationModal} >No</BUTTON>
                  </div>
                </ModalBody>
              </Modal>

              {/* <Modal
                isOpen={confirmationModalOpen}
                onRequestClose={closeConfirmationModal}
                contentLabel="Confirmation Modal"
                style={{
                  height: "150px",
                  margin: "auto",
                  width: "70%",
                  maxWidth: "400px",
                }}
              >
                <div
                  style={{
                    height: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <p>Are you sure you want to close?</p>
                </div>
                <div
                  style={{
                    height: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <button
                    onClick={handleConfirmClose}
                    className="btn btn-outline-success btn-sm m-1"
                  >
                    Yes
                  </button>
                  <button
                    onClick={closeConfirmationModal}
                    className="btn btn-outline-danger btn-sm m-1"
                  >
                    No
                  </button>
                </div>
              </Modal> */}
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default MobileLicenseOrder;
