import React, { useEffect, useState } from "react";
import { Button, Col, Container, Input, Label, Modal, ModalBody, ModalHeader, Row, Table } from "reactstrap";
import { Button as BUTTON } from "@mui/material"
import { CgAdd } from "react-icons/cg";
import { FaRegEye } from "react-icons/fa";
import { HiPencilSquare } from "react-icons/hi2";
import { FcEmptyTrash } from "react-icons/fc";
import ShiftConfigurationController from "../../controller/ShiftController/ShiftConfigurationController";
import ValueFormat from "../../util/ValueFormat";
import Loader from "../../components/Common/loader";
import {
  LocalizationProvider,
  MobileTimePicker,
  TimePicker,
} from "@mui/x-date-pickers";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { SC_USER_LOGIN_TYPE } from "../../constant/StorageConstant";
import moment from "moment-timezone";
import UtilDateTime from "../../util/UtilDateTime";
import { Box, TextField, Typography } from "@mui/material";
import ToastService from "../../components/Common/toastService";

const noData = [
  {
    shiftName: "-",
    startTime: "-",
    endTime: "-",
    userId: "-",
  },
];

const ShiftConfiguration = () => {
  const [selectedData, setSelectedData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(1);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState("");
  const [shiftErrMsg, setShiftErrMsg] = useState("");
  const [shiftStartErrMsg, setShiftStartErrMsg] = useState("");
  const [shiftEndErrMsg, setShiftEndErrMsg] = useState("");

  const [shiftErrMsgEdit, setShiftErrMsgEdit] = useState("");
  const [shiftStartErrMsgEdit, setShiftStartErrMsgEdit] = useState("");
  const [shiftEndErrMsgEdit, setShiftEndErrMsgEdit] = useState("");

  const [userType, setUserType] = useState(
    localStorage.getItem(SC_USER_LOGIN_TYPE)
  );
  const [editedData, setEditedData] = useState({
    id: "",
    shiftName: "",
    startTime: "",
    endTime: "",
  });
  const [addData, setAddData] = useState({
    name: "",
    startTime: null,
    endTime: null,
  });
  const [shiftData, setShiftData] = useState(noData);
  const [showLoader, setShowLoader] = useState(false);

  const handleDeleteOnClick = (userId) => {
    setDeleteUserId(userId);
    setIsDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmed = () => {
    deleteShiftData(deleteUserId);
    setIsDeleteConfirmationOpen(false);
  };

  const handleView = (rowData) => {
    setSelectedData(rowData);
    setModalContent(1);
    setIsModalOpen(true);
  };

  const handleEdit = (rowData) => {
    setIsModalOpen(true);
    setSelectedData(rowData);
    setEditedData({
      id: rowData.userId,
      shiftName: rowData.shiftName,
      startTime: rowData.startTime,
      endTime: rowData.endTime,
    });
    setModalContent(2);
  };

  const handleEditSubmit = () => {
    // Extract start and end times from addData
    const newEditStartTime = editedData?.startTime;
    const newEditEndTime = editedData?.endTime;
    const newEditShiftName = editedData.shiftName;

    let hasError = false;

    // Empty field validation
    if (!newEditShiftName) {
      setShiftErrMsgEdit("Shift name is required");
      hasError = true;
    } else {
      setShiftErrMsgEdit("");
    }

    if (!newEditStartTime) {
      setShiftStartErrMsgEdit("Start time is required");
      hasError = true;
    } else {
      setShiftStartErrMsgEdit("");
    }

    if (!newEditEndTime) {
      setShiftEndErrMsgEdit("End time is required");
      hasError = true;
    } else {
      setShiftEndErrMsgEdit("");
    }

    if (hasError) {
      return;
    }

    // Check for duplicate shift name
    const isDuplicateName = shiftData.some(
      (shift) => shift.shiftName === newEditShiftName && shift.id !== editedData.id
    );

    if (isDuplicateName) {
      ToastService.errormsg("Shift with the same name already exists");
    } else if (isOverlap(newEditStartTime, newEditEndTime)) {
      ToastService.errormsg("Entered Time already exists");
    } else {
      putShiftData();
      handleClose();
      handleAddClear();
    }
  };

  const handleAddSubmit = () => {
    // Extract start and end times from addData
    const newStartTime = addData?.startTime?.format("HH:mm:ss");
    const newEndTime = addData?.endTime?.format("HH:mm:ss");
    const newShiftName = addData.name;

    let hasError = false;

    // Empty field validation
    if (!newShiftName) {
      setShiftErrMsg("Shift name is required");
      hasError = true;
    } else {
      setShiftErrMsg("");
    }

    if (!newStartTime) {
      setShiftStartErrMsg("Start time is required");
      hasError = true;
    } else {
      setShiftStartErrMsg("");
    }

    if (!newEndTime) {
      setShiftEndErrMsg("End time is required");
      hasError = true;
    } else {
      setShiftEndErrMsg("");
    }

    if (hasError) {
      return;
    }

    // Check for duplicate shift name
    const isDuplicateName = shiftData.some(
      (shift) => shift.shiftName === newShiftName
    );

    if (isDuplicateName) {
      ToastService.errormsg("Shift with the same name already exists");
    } else if (isOverlap(newStartTime, newEndTime)) {
      ToastService.errormsg("Entered Time already exists");
    } else {
      postShiftData();
      getShiftData();
      handleClose();
      handleAddClear();
      setShiftErrMsg("");
      setShiftStartErrMsg("");
      setShiftEndErrMsg("");
    }
  };

  const handleAddClear = () => {
    setAddData({
      name: "",
      startTime: null,
      endTime: null,
    });
    setShiftErrMsg("");
    setShiftStartErrMsg("");
    setShiftEndErrMsg("");
  };

  const handleAdd = () => {
    setModalContent(3);
    setIsModalOpen(true);
  };

  const handleAddClose = () => {
    handleAddClear();
    setShiftErrMsg("");
    setShiftStartErrMsg("");
    setShiftEndErrMsg("");
    setIsModalOpen(false);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setShiftErrMsg("");
    setShiftStartErrMsg("");
    setShiftEndErrMsg("");
  };

  const isOverlap = (newStartTime, newEndTime) => {
    for (const shift of shiftData) {
      const existingStartTime = shift.startTime;
      const existingEndTime = shift.endTime;

      if (
        (newStartTime >= existingStartTime && newStartTime < existingEndTime) ||
        (newEndTime > existingStartTime && newEndTime < existingEndTime) ||
        (newStartTime <= existingStartTime &&
          newEndTime > existingStartTime &&
          newEndTime < existingEndTime) ||
        newStartTime === existingEndTime
      ) {
        return true;
      }
    }
    return false;
  };

  const getShiftData = async () => {
    setShowLoader(true);
    var result = await ShiftConfigurationController.shiftGET();
    setShowLoader(false);
    var resultJSON = JSON.parse(result);
    console.log("resultJSON", resultJSON);
    var status = resultJSON.status;
    if (status == "SUCCESS") {
      try {
        var resultJSONData = resultJSON.data;
        var realTimeShiftdata = [];
        if (resultJSONData.length > 0) {
          resultJSONData.map((value, i) => {
            var shiftName = ValueFormat.parseAsString(value.name);
            var startTimeUtc = ValueFormat.parseAsString(value.startTime);
            var endTimeUtc = ValueFormat.parseAsString(value.endTime);
            var userId = ValueFormat.parseAsString(value.divPrefId);

            // Convert UTC to IST
            var startTimeIST = UtilDateTime.convertToLocalTime(startTimeUtc);
            var endTimeIST = UtilDateTime.convertToLocalTime(endTimeUtc);

            var realShiftData = {
              shiftName: shiftName,
              startTime: startTimeIST,
              endTime: endTimeIST,
              userId: userId,
            };
            realTimeShiftdata.push(realShiftData);
          });
          setShiftData(realTimeShiftdata);
        } else {
          setShiftData(noData);
        }
      } catch (e) {
        console.log("Error : ", e);
      }
    } else {
      console.log("error");
    }
  };

  const postShiftData = async () => {
    setShowLoader(true);
    var startTimeFST = UtilDateTime.convertToUTC(addData?.startTime?.format("HH:mm:ss"));
    var endTimeFST = UtilDateTime.convertToUTC(addData?.endTime?.format("HH:mm:ss"));
    var shiftPOSTDetails = {
      name: addData.name,
      startTime: startTimeFST,
      endTime: endTimeFST,
    };
    console.log("UTC startTime", shiftPOSTDetails.startTime);
    var result = await ShiftConfigurationController.shiftPOST(shiftPOSTDetails);
    setShowLoader(false);
    var resultJSON = JSON.parse(result);

    var status = resultJSON.status;
    if (status == "SUCCESS") {
      // ToastService.successmsg("Successfully Posted")
      getShiftData();
    }
  };

  const putShiftData = async () => {
    setShowLoader(true);
    var shiftPUTDetails = {
      id: editedData.id,
      name: editedData.shiftName,
      startTime: UtilDateTime.convertToUTC(editedData.startTime),
      endTime: UtilDateTime.convertToUTC(editedData.endTime),
    };
    var result = await ShiftConfigurationController.shiftPUT(shiftPUTDetails);
    setShowLoader(false);
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    if (status == "SUCCESS") {
      // ToastService.successmsg("Successfully Updated")
      getShiftData();
    }
  };

  const deleteShiftData = async (userId) => {
    try {
      setShowLoader(true);
      const result = await ShiftConfigurationController.shiftDELETE(userId);
      setShowLoader(false);
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      if (status == "SUCCESS") {
        console.log("Shift deleted successfully");
        getShiftData();
      } else {
        console.log("Error deleting shift");
      }
    } catch (error) {
      console.error("Error deleting shift:", error);
    }
  };

  useEffect(() => {
    getShiftData();
  }, []);

  return (
    <React.Fragment>
      {showLoader == true && <Loader />}
      <div className="page-content">
        <Container fluid>
          <Row className="my-2 py-2">
            <Col xs={6} className="d-flex justify-content-start align-items-center text-black">
              <h5 className="mb-0">Shift</h5>
            </Col>
            <Col xs={6} className="d-flex justify-content-end align-items-center text-black">
              <div className="d-flex justify-content-end mb-2">
                {userType === "SUPER_ADMIN" ? (
                  <Button
                    color="primary"
                    onClick={handleAdd}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <CgAdd />
                    Add
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>

          <div className="mt-2">
            <Table bordered>
              <thead className="text-center cardHeader">
                <tr>
                  <th style={{ fontSize: "13px", fontWeight: 600 }}>Shift Name</th>
                  <th style={{ fontSize: "13px", fontWeight: 600 }}>Start Time</th>
                  <th style={{ fontSize: "13px", fontWeight: 600 }}>End Time</th>
                  <th style={{ fontSize: "13px", fontWeight: 600 }}>Action</th>
                </tr>
              </thead>
              {userType === "SUPER_ADMIN" ? (
                <tbody className="text-center">
                  {shiftData.map((data, i) => (
                    <tr key={i}>
                      <td style={{ fontSize: "13px" }}>{data.shiftName}</td>
                      <td>{data.startTime}</td>
                      <td>{data.endTime}</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          <div
                            style={{
                              marginTop: "1px",
                              cursor: "pointer",
                              width: "25px",
                            }}
                            className="me-1"
                            onClick={() => handleView(data)}
                          >
                            <FaRegEye />
                          </div>
                          <div
                            className="me-1"
                            style={{ cursor: "pointer", width: "25px" }}
                            onClick={() => handleEdit(data)}
                          >
                            <HiPencilSquare />
                          </div>
                          <div
                            className="me-1"
                            style={{ cursor: "pointer", width: "25px" }}
                            onClick={() => handleDeleteOnClick(data.userId)}
                          >
                            <FcEmptyTrash />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody className="text-center">
                  {shiftData.map((data, i) => (
                    <tr key={i}>
                      <td>{data.shiftName}</td>
                      <td>{data.startTime}</td>
                      <td>{data.endTime}</td>
                      <td>
                        <div className="d-flex justify-content-center">
                          <div
                            style={{ marginTop: "1px", cursor: "pointer" }}
                            className="me-1"
                            onClick={() => handleView(data)}
                          >
                            <FaRegEye />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </Table>
            <Modal
              isOpen={isModalOpen}
              toggle={handleClose}
              backdrop="static"
              keyboard={false}
            >
              {modalContent === 1 ? (
                selectedData && (
                  <>
                    <ModalHeader
                      style={{ background: "#5156be1a" }}
                      toggle={handleClose}
                      className="text-right d-flex align-items-center p-3 "
                    >
                      View Shift
                    </ModalHeader>
                    <div
                      className="p-3"
                      style={{ maxHeight: "400px", overflowY: "auto" }}
                    >
                      <div className="py-1">
                        <Label>Shift Name</Label>
                        <Input readOnly value={selectedData.shiftName} />
                      </div>
                      <div className="py-1">
                        <Label>Start Time</Label>
                        <Input readOnly value={selectedData.startTime} />
                      </div>
                      <div className="py-1">
                        <Label>End Time</Label>
                        <Input readOnly value={selectedData.endTime} />
                      </div>
                    </div>

                    <div className="text-center pb-3">
                      <Button
                        color="primary"
                        onClick={handleClose}
                        size="sm"
                        style={{ width: "25%" }}
                      >
                        Close
                      </Button>
                    </div>
                  </>
                )
              ) : modalContent === 2 ? (
                <>
                  <ModalHeader
                    style={{ background: "#5156be1a" }}
                    toggle={handleClose}
                    className="text-right d-flex align-items-center p-3 "
                  >
                    Edit Shift
                  </ModalHeader>
                  <div
                    className="p-3"
                    style={{ maxHeight: "400px", overflowY: "auto" }}
                  >
                    <Box py={1}>
                      <Label>Shift Name</Label>
                      <TextField
                        fullWidth
                        value={editedData.shiftName}
                        onChange={(e) => setEditedData({ ...editedData, shiftName: e.target.value })}
                        size="small"
                      />
                    </Box>
                    <div className="text-danger">{shiftErrMsgEdit}</div>
                    <div className="py-1">
                      <Label className="mb-0">Start Time</Label>
                      <div className="custom-row1">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DateTimePicker"]}>
                            <DemoItem>
                              <TimePicker
                                views={["hours", "minutes", "seconds"]}
                                format="HH:mm:ss"
                                ampm={false}
                                minutesStep={1}
                                value={dayjs(editedData.startTime, "HH:mm:ss")}
                                onChange={(newValue) =>
                                  setEditedData({
                                    ...editedData,
                                    startTime: newValue.format("HH:mm:ss"),
                                  })
                                }
                                slotProps={{ textField: { size: "small" } }}
                              />
                            </DemoItem>
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                    </div>
                    <div className="text-danger">{shiftStartErrMsgEdit}</div>
                    <div className="py-1">
                      <Label className="mb-0">End Time</Label>
                      <div className="custom-row1">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DateTimePicker"]}>
                            <DemoItem>
                              <TimePicker
                                views={["hours", "minutes", "seconds"]}
                                format="HH:mm:ss"
                                ampm={false}
                                minutesStep={1}
                                value={dayjs(editedData.endTime, "HH:mm:ss")}
                                onChange={(newValue) =>
                                  setEditedData({
                                    ...editedData,
                                    endTime: newValue.format("HH:mm:ss"),
                                  })
                                }
                                slotProps={{ textField: { size: "small" } }}
                              />
                            </DemoItem>
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                    </div>
                    <div className="text-danger">{shiftEndErrMsgEdit}</div>
                  </div>

                  <div className="text-center pb-3">
                    <Button
                      color="primary"
                      onClick={handleEditSubmit}
                      size="sm"
                      style={{ width: "20%", marginRight: "5px" }}
                    >
                      Submit
                    </Button>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={handleClose}
                      style={{ width: "20%" }}
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              ) : modalContent === 3 ? (
                <>
                  <ModalHeader
                    toggle={handleAddClose}
                    className="text-right d-flex align-items-center p-3 "
                    style={{ background: "#5156be1a", }}
                  >
                    Add Shift
                  </ModalHeader>
                  <div
                    className="p-3"
                    style={{ maxHeight: "400px", overflowY: "auto" }}
                  >
                    <Box py={1}>
                      <Label>Shift Name</Label>
                      <TextField
                        size="small"
                        fullWidth
                        value={addData.name}
                        onChange={(e) => setAddData({ ...addData, name: e.target.value })}
                        placeholder="Enter shift name"
                      />
                    </Box>
                    <div className="text-danger">{shiftErrMsg}</div>
                    <div className="py-1">
                      <Label className="mb-0">Start Time</Label>
                      <div className="custom-row1">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DateTimePicker"]}>
                            <TimePicker
                              value={addData.startTime}
                              views={["hours", "minutes", "seconds"]}
                              format="HH:mm:ss"
                              ampm={false}
                              minutesStep={1}
                              onChange={(newValue) =>
                                setAddData({
                                  ...addData,
                                  startTime: newValue,
                                })
                              }
                              slotProps={{ textField: { size: "small" } }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                    </div>
                    <div className="text-danger">{shiftStartErrMsg}</div>
                    <div className="py-1">
                      <Label className="mb-0">End Time</Label>
                      <div className="custom-row1">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={["DateTimePicker"]}>
                            <TimePicker
                              value={addData.endTime}
                              views={["hours", "minutes", "seconds"]}
                              format="HH:mm:ss"
                              ampm={false}
                              minutesStep={1}
                              onChange={(newValue) =>
                                setAddData({
                                  ...addData,
                                  endTime: newValue,
                                })
                              }
                              slotProps={{ textField: { size: "small" } }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      </div>
                    </div>
                    <div className="text-danger">{shiftEndErrMsg}</div>
                  </div>
                  <div className="text-center pb-3">
                    <Button
                      color="primary"
                      onClick={handleAddSubmit}
                      size="sm"
                      style={{ width: "20%", marginRight: "5px" }}
                    >
                      Submit
                    </Button>
                    <Button
                      color="primary"
                      size="sm"
                      style={{ width: "20%" }}
                      onClick={handleAddClear}
                    >
                      Reset
                    </Button>
                  </div>{" "}
                </>
              ) : (
                ""
              )}
            </Modal>

            <Modal
              isOpen={isDeleteConfirmationOpen}
              toggle={() => setIsDeleteConfirmationOpen(false)}
              keyboard={false}
              backdrop="static"
              style={{
                content: {
                  height: "190px",
                  width: "390px",
                  margin: "auto",
                  borderRadius: "10px",
                  padding: "0px",
                },
                overlay: { backgroundColor: "rgba(0,0,0,0.5)" },width:'390px'
              }}
            >
              <ModalHeader
                style={{
                  height: "40px",
                  backgroundColor: "#5156BE",
                  width: "100%",
                  display: "felx",
                  justifyContent: "center",
                }}
              >
                <span style={{ fontWeight: 700, fontSize: "20px", color: "#fff" }}>
                  Confirm Action
                </span>
              </ModalHeader>
              <ModalBody>
                <Typography mt={1} sx={{ fontWeight: 500, fontSize: '17px', color: '#000000', textAlign: 'center' }}>Are you sure</Typography>
                <Typography sx={{ fontWeight: 500, fontSize: '17px', color: '#000000', textAlign: 'center' }}> you want to delete </Typography>
                <br />
                <div className="btn-div">
                  <BUTTON variant="outlined" size="small" className='popup_Button' sx={{ width: "50px", borderRadius: "12px" }} onClick={handleDeleteConfirmed}>Yes</BUTTON>
                  <BUTTON variant="outlined" size="small" className='popup_Button' sx={{ width: "50px", borderRadius: "12px", ml: 1 }} onClick={() => setIsDeleteConfirmationOpen(false)} >No</BUTTON>
                </div>
              </ModalBody>
            </Modal>


            {/* <Modal
              isOpen={isDeleteConfirmationOpen}
              toggle={() => setIsDeleteConfirmationOpen(false)}
              backdrop="static"
              keyboard={false}
            >
              <ModalHeader toggle={() => setIsDeleteConfirmationOpen(false)} style={{ background: "#5156be1a" }}>
                Confirm Delete
              </ModalHeader>
              <div className="p-3 text-center">
                <p>Are you sure you want to delete this shift?</p>
              </div>
              <div className="text-center pb-3">
                <Button
                  color="primary"
                  onClick={handleDeleteConfirmed}
                  size="sm"
                  style={{ width: "20%", marginRight: "5px" }}
                >
                  Yes
                </Button>
                <Button
                  color="primary"
                  onClick={() => setIsDeleteConfirmationOpen(false)}
                  size="sm"
                  style={{ width: "20%" }}
                >
                  No
                </Button>
              </div>
            </Modal> */}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ShiftConfiguration;
