// import {
//   Document,
//   Image,
//   PDFViewer,
//   Page,
//   StyleSheet,
//   Text,
//   View,
// } from "@react-pdf/renderer";
// import React, { useEffect, useState } from "react";
// import Logo from "../../../assets/images/logo/motivAiLogoPng.png";
// import {
//   SC_COMPANY_NAME,
//   SC_USER_FIRST_NAME,
// } from "../../../constant/StorageConstant";
// import { color } from "echarts";

// const styles = StyleSheet.create({
//   pdfViewer: {
//     height: "96vh",
//     width: "100%",
//     marginTop: "20px",
//     marginBottom: "5px",
//   },
//   document: {
//     marginTop: "15px",
//     marginBottom: "5px",
//   },
//   page: {
//     paddingLeft: 30,
//     paddingRight: 30,
//     width: "100%",
//   },
//   watermark: {
//     position: "absolute",
//     fontSize: 95,
//     color: "#e6e6e6",
//     transform: "rotate(-52deg)",
//     justifyContent: "center",
//     textAlign: "center",
//     alignItems: "center",
//     top: "42%",
//     left: "22%",
//   },
//   footer: {
//     position: "absolute",
//     marginLeft: 30,
//     marginRight: 30,
//     bottom: 0,
//     left: 0,
//     right: 0,
//     height: "44.5px",
//   },
//   hheading: {
//     fontSize: "14px",
//     fontFamily: "Helvetica-Bold",
//   },
//   ssubheading: {
//     fontSize: "13px",
//     fontFamily: "Helvetica-Bold",
//   },
//   sssubheading: {
//     fontSize: "12px",
//     fontFamily: "Helvetica-Bold",
//   },
//   bbodytext: {
//     fontSize: "11px",
//   },
// });

// const fontBold = {
//   fontFamily: "Helvetica-Bold",
// };

// const watermarkText = "MotivAI";

// const CrashReportPdf = ({ topRow, errorDataWithinRange }) => {
//   const companyName = localStorage.getItem(SC_COMPANY_NAME);
//   const firstName = localStorage.getItem(SC_USER_FIRST_NAME);

//   const months1 = [
//     "01",
//     "02",
//     "03",
//     "04",
//     "05",
//     "06",
//     "07",
//     "08",
//     "09",
//     "10",
//     "11",
//     "12",
//   ];

//   var currentDate = new Date();
//   const reversedDateFormat =
//     currentDate.getDate().toString().padStart(2, "0") +
//     "-" +
//     months1[currentDate.getMonth()] +
//     "-" +
//     currentDate.getFullYear();

//   const header = [
//     { title: "App Name", name: "Motiv AI" },
//     { title: "Customer Name", name: companyName },
//     { title: "Date", name: reversedDateFormat },
//     { title: "First Name", name: firstName },
//   ];
//   const footer = [
//     { f: "https://motivai.tech" },
//     { f: "https://app.motivai.tech" },
//     { f: '"Drive Safe, Arrive Safe"' },
//     { f: "A MotivAI Product" },
//   ];

//   const fontItalic = {
//     fontFamily: "Times-BoldItalic",
//   };

//   const T1 = {
//     title: topRow.map((widget) => widget.title),
//     data: topRow.map((widget) => widget.overallData),
//   };

//   const T2 = {
//     title: topRow.map((widget) => widget.bottomValue),
//   };

//   const [totalPage, setTotalPage] = useState(0);

//   const updateTotalPages = (pagesCount) => {
//     setTotalPage(pagesCount);
//   };

//   useEffect(() => {
//     const totalChunks = Math.ceil(errorDataWithinRange.length / 3);
//     setTotalPage(totalChunks);
//   }, [errorDataWithinRange]);

//   if (!errorDataWithinRange) return <Text>Loading...</Text>;

//   const dataWithinRangeArrays = errorDataWithinRange
//     .filter(
//       (item) => item?.dataWithinRange && Array.isArray(item.dataWithinRange)
//     )
//     .map((item) => item.dataWithinRange);

//   console.log("errorDataWithinRange", errorDataWithinRange);
//   console.log("dataWithinRangeArrays", dataWithinRangeArrays);

//   const formatData = (data) => {
//     if (!data || !Array.isArray(data)) {
//       return [];
//     }
//     return data.length < 6
//       ? data
//       : [...data.slice(0, 2), { separator: true }, ...data.slice(-2)];
//   };

//   const createTable = (data) => [
//     [
//       "Event Date\nEvent Time",
//       "Latitude\nLongitude",
//       "Distance\nRisk",
//       "Speed\nLimit",
//       "Violation Remarks",
//     ],
//     ...data.map((item) => {
//       if (item.separator) {
//         return [".....", ".....", ".....", ".....", "....."];
//       }
//       return [
//         item.time
//           ? `${item.time.split(" ")[0]}\n${item.time.split(" ")[1]} ${
//               item.time.split(" ")[2]
//             }`
//           : "...",
//         `${item.latitude || 0}\n${item.longitude || 0}`,
//         `${item.kilometer || 0}\n${item.risk || 0}`,
//         `${item.speed || 0}\n${
//           item.speedLimit === "0.00" ? "40.00" : item.speedLimit || 0
//         }`,
//         `${item.violationRemarks || ""}`,
//       ];
//     }),
//   ];

//   const chunkArray = (array, chunkSize) => {
//     const chunks = [];
//     for (let i = 0; i < array.length; i += chunkSize) {
//       chunks.push(array.slice(i, i + chunkSize));
//     }
//     return chunks;
//   };

//   const page1Tables = dataWithinRangeArrays.slice(0, 3);
//   const remainingTables = dataWithinRangeArrays.slice(3);
//   const tableChunks = [page1Tables, ...chunkArray(remainingTables, 4)];


//   var totalPages = 0;
//   return (
//     <>
//       <PDFViewer style={styles.pdfViewer}>
//         <Document style={styles.document}>
//           {tableChunks.map((chunk, chunkIndex) => (
//             <Page key={chunkIndex} style={styles.page}>
//               <Text style={styles.watermark}>{watermarkText}</Text>
//               <View style={{ height: "5.3%" }}>
//                 <View
//                   style={{
//                     flexDirection: "row",
//                     borderBottom: "1px solid black",
//                     backgroundColor: "lightgrey",
//                   }}
//                 >
//                   <View
//                     style={{
//                       marginTop: "5px",
//                       width: "100%",
//                       flexDirection: "row",
//                     }}
//                   >
//                     <Image
//                       src={Logo}
//                       style={{
//                         height: "21px",
//                         width: "100px",
//                         marginTop: "2px",
//                         marginBottom: "5px",
//                         marginLeft: "31px",
//                       }}
//                     />
//                     <View
//                       style={{
//                         flexDirection: "Row",
//                         marginBottom: 5,
//                         marginLeft: "30px",
//                       }}
//                     >
//                       <View style={{ flexDirection: "row" }}>
//                         <Text style={[fontBold, { fontSize: 11 }]}>
//                           {header[1].title}:
//                         </Text>
//                         <Text style={{ fontSize: 11, marginLeft: "3px" }}>
//                           {header[1].name}
//                         </Text>
//                       </View>
//                       <View style={{ flexDirection: "row" }}>
//                         <View
//                           style={{
//                             flexDirection: "row",
//                             marginTop: "2px",
//                             width: "60%",
//                           }}
//                         >
//                           <Text
//                             style={[fontBold, { fontSize: 11, width: "85px" }]}
//                           >
//                             {header[3].title}:
//                           </Text>
//                           <Text style={{ fontSize: 11, width: "100%" }}>
//                             {header[3].name}
//                           </Text>
//                         </View>
//                         <View
//                           style={{
//                             flexDirection: "row",
//                             marginTop: "2px",
//                             marginLeft: "42px",
//                           }}
//                         >
//                           <View style={{ flexDirection: "row" }}>
//                             <Text style={[fontBold, { fontSize: 11 }]}>
//                               {header[2].title}:{" "}
//                             </Text>
//                             <Text style={{ fontSize: 11 }}>
//                               {header[2].name}
//                             </Text>
//                           </View>
//                         </View>
//                       </View>
//                     </View>
//                   </View>
//                 </View>
//               </View>
//               {chunkIndex === 0 && (
//                 <>
//                   <View
//                     style={{
//                       marginTop: "5px",
//                       width: "100%",
//                       flexDirection: "column",
//                     }}
//                   >
//                     <Text
//                       style={[
//                         fontBold,
//                         {
//                           fontSize: 11,
//                           margin: "3px",
//                           textAlign: "center",
//                           color: "#003cb3",
//                           textDecoration: "underline",
//                         },
//                       ]}
//                     >
//                       Crash Details
//                     </Text>
//                     <Text
//                       style={{
//                         color: "black",
//                         fontSize: "11px",
//                         textDecoration: "none",
//                         fontWeight: 600,
//                         textAlign: "center",
//                         marginTop: 5,
//                       }}
//                     >
//                       This report is generated for the period current year
//                       (2024)
//                     </Text>
//                   </View>

//                   <View style={{ marginTop: 15 }}>
//                     <View>
//                       <Text style={[fontBold, { fontSize: 11 }]}>
//                         1. Ride Summary
//                       </Text>
//                     </View>
//                     <View
//                       style={{
//                         flexDirection: "column",
//                         justifyContent: "center",
//                         marginTop: 10,
//                       }}
//                     >
//                       <View style={{ flexDirection: "row" }}>
//                         {T1.title.map((item, index) => (
//                           <View
//                             style={{
//                               flex: index === 5 ? 2 : 1.5,
//                               justifyContent: "center",
//                               padding: 5,
//                               alignItems: "center",
//                               fontSize: "9px",
//                               border: "1px solid black",
//                               borderLeft:
//                                 index === 0 ? "1px solid black" : "none",
//                             }}
//                             key={index}
//                           >
//                             <Text
//                               style={[fontBold, { textAlign: "center" }]}
//                               break
//                             >
//                               {item}
//                             </Text>
//                           </View>
//                         ))}
//                       </View>
//                       <View style={{ flexDirection: "row" }}>
//                         {T1.data.map((item, index) => (
//                           <View
//                             style={{
//                               flex: index === 5 ? 2 : 1.5,
//                               justifyContent: "center",
//                               padding: 5,
//                               fontSize: 9, // removed quotes around 9px
//                               border: "1px solid black",
//                               borderTop: "none",
//                               borderLeft:
//                                 index === 0 ? "1px solid black" : "none",
//                               textAlign: index === 0 ? "left" : "right",
//                             }}
//                             key={index}
//                           >
//                             <Text style={{ textAlign: "center" }} break>
//                               {item}
//                             </Text>
//                           </View>
//                         ))}
//                       </View>
//                     </View>
//                   </View>
//                   <View style={{ marginTop: 15 }}>
//                     <View>
//                       <Text style={[fontBold, { fontSize: 11 }]}>
//                         2. Ride History
//                       </Text>
//                     </View>
//                     <View
//                       style={{
//                         flexDirection: "column",
//                         justifyContent: "center",
//                         marginTop: 10,
//                       }}
//                     >
//                       <View style={{ flexDirection: "row" }}>
//                         {T2.title.map((item, index) => (
//                           <View
//                             style={{
//                               flex: 2,
//                               justifyContent: "center",
//                               padding: 5,
//                               alignItems: "center",
//                               fontSize: "9px",
//                               border: "1px solid black",
//                               borderLeft:
//                                 index === 0 ? "1px solid black" : "none",
//                             }}
//                             key={index}
//                           >
//                             <Text
//                               style={[fontBold, { textAlign: "center" }]}
//                               break
//                             >
//                               {item.split(":")[0]}
//                             </Text>
//                           </View>
//                         ))}
//                       </View>
//                       <View style={{ flexDirection: "row" }}>
//                         {T2.title.map((item, index) => (
//                           <View
//                             style={{
//                               flex: 2,
//                               justifyContent: "center",
//                               padding: 5,
//                               fontSize: 9, // removed quotes around 9px
//                               border: "1px solid black",
//                               borderTop: "none",
//                               borderLeft:
//                                 index === 0 ? "1px solid black" : "none",
//                               textAlign: index === 0 ? "left" : "right",
//                             }}
//                             key={index}
//                           >
//                             <Text style={{ textAlign: "center" }} break>
//                               {item.split(":").length > 2
//                                 ? `${item.split(":")[1]}:${item.split(":")[2]}`
//                                 : item.split(":").length > 1
//                                 ? item.split(":")[1]
//                                 : item}
//                             </Text>
//                           </View>
//                         ))}
//                       </View>
//                     </View>
//                   </View>
//                 </>
//               )}
//              <View style={{ marginTop: chunkIndex === 0 ? 15 : 0 }}>
//               {chunkIndex === 0 && (
//                 <View style={{ display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
//                   <Text style={[fontBold, { fontSize: 11, textAlign:"left" }]}>
//                     3. Crash Summary
//                   </Text>
//                   <Text
//                     style={[fontBold ,{ fontSize: 9, textAlign: "right"}]}
//                   >
//                     Note : (Distance in "km", Speed and Limit in "kmph", Speed
//                     Limit as Limit)
//                   </Text>
//                 </View> 
//               )}
//                 <View style={{ flexDirection: "column", marginTop: 10 }}>
//                 {/* {chunkIndex === 0 && (
//                   <Text
//                     style={{ fontSize: 9, textAlign: "right", marginBottom: 5 }}
//                   >
//                     Note : (Distance in "km", Speed and Limit in "kmph", Speed
//                     Limit as Limit)
//                   </Text>
//                 )} */}
//                   {chunk.map((dataArray, dataIndex) => {
//                     const formattedData = formatData(dataArray);
//                     const table = createTable(formattedData);

//                     return (
//                       <View key={dataIndex} style={{ marginBottom: 20 }}>
//                         <Text style={[ fontBold, {fontSize: 9, marginBottom: 3 }]}>Crash: {chunkIndex === 0 ? dataIndex+1 : chunkIndex === 1 ? dataIndex+4 : chunkIndex === 2 ? dataIndex+8 : chunkIndex === 3 ? dataIndex+12 :chunkIndex === 4 ? dataIndex+16 :chunkIndex === 5 ? dataIndex+20 :chunkIndex === 6 ? dataIndex+24 :chunkIndex === 7 ? dataIndex+28 :chunkIndex === 8 ? dataIndex+32 :chunkIndex === 9 ? dataIndex+36 :chunkIndex === 10 ? dataIndex+40 : ""}</Text>
//                         {table.map((row, rowIndex) => (
//                           <View key={rowIndex} style={{ flexDirection: "row" }}>
//                             {row.map((item, columnIndex) => {
//                               const isHeader = rowIndex === 0;
//                               const textStyle = isHeader
//                                 ? [
//                                     fontBold,
//                                     {
//                                       flex:
//                                         columnIndex === 0
//                                           ? 0.5
//                                           : columnIndex === 4
//                                           ? 2
//                                           : 0.4,
//                                       justifyContent: "center",
//                                       padding: 2,
//                                       fontSize: 9,
//                                       border: "1px solid black",
//                                       borderTop:
//                                         rowIndex === 0
//                                           ? "1px solid black"
//                                           : "none",
//                                       borderLeft:
//                                         columnIndex === 0
//                                           ? "1px solid black"
//                                           : "none",
//                                       textAlign: "center",
//                                     },
//                                   ] :
//                                   item === "Vehicle crashed" ? [
//                                     fontBold, {
//                                       flex:
//                                         columnIndex === 0
//                                           ? 0.5
//                                           : columnIndex === 4
//                                           ? 2
//                                           : 0.4,
//                                       justifyContent: "center",
//                                       padding: 2,
//                                       fontSize: 8,
//                                       border: "1px solid black",
//                                       borderTop: "none",
//                                       borderLeft: "none",
//                                       textAlign:"center",
//                                       color: "red",
//                                     },
//                                   ]
//                                 : {
//                                     flex:
//                                       columnIndex === 0
//                                         ? 0.5
//                                         : columnIndex === 4
//                                         ? 2
//                                         : 0.4,
//                                     justifyContent: "center",
//                                     padding: 2,
//                                     fontSize: 9,
//                                     border: "1px solid black",
//                                     borderTop:
//                                       rowIndex === 0
//                                         ? "1px solid black"
//                                         : "none",
//                                     borderLeft:
//                                       columnIndex === 0
//                                         ? "1px solid black"
//                                         : "none",
//                                     textAlign:
//                                       rowIndex === 0
//                                         ? "center"
//                                         : columnIndex === 4
//                                         ? "left"
//                                         : "center",
//                                   };

//                               return (
//                                 <Text key={columnIndex} style={textStyle}>
//                                   {item}
//                                 </Text>
//                               );
//                             })}
//                           </View>
//                         ))}
//                       </View>
//                     );
//                   })}
//                 </View>
//               </View>
//               <View
//                 style={{
//                   position: "absolute",
//                   marginLeft: 30,
//                   marginRight: 30,
//                   bottom: 0,
//                   left: 0,
//                   right: 0,
//                   height: "44.5px",
//                 }}
//               >
//                 <View style={{ borderBottom: "1px solid black" }} />
//                 <View
//                   style={{
//                     flexDirection: "row",
//                     bottom: "0px",
//                   }}
//                 >
//                   <View
//                     style={{
//                       marginTop: "5px",
//                       width: "30%",
//                       marginBottom: "5px",
//                     }}
//                   >
//                     <Text style={{ fontSize: "11px", marginBottom: "3px" }}>
//                       {footer[0].f}
//                     </Text>
//                     <Text style={[fontItalic, { fontSize: "10px" }]}>
//                       {footer[2].f}
//                     </Text>
//                   </View>

//                   <View
//                     style={{
//                       marginTop: "10px",
//                       width: "40%",
//                       textAlign: "center",
//                     }}
//                   >
//                     <Text style={{ fontSize: "11px" }}>
//                       Page {chunkIndex + 1} of {totalPage}
//                     </Text>
//                   </View>
//                   <View
//                     style={{
//                       marginTop: "5px",
//                       width: "30%",
//                       flexDirection: "column",
//                     }}
//                   >
//                     <Text
//                       style={{
//                         fontSize: "11px",
//                         marginBottom: "3px",
//                         textAlign: "right",
//                       }}
//                     >
//                       {footer[1].f}
//                     </Text>
//                     <Text style={{ fontSize: "10px", textAlign: "right" }}>
//                       {footer[3].f}
//                     </Text>
//                   </View>
//                 </View>
//               </View>
//             </Page>
//           ))}
//         </Document>
//       </PDFViewer>
//     </>
//   );
// };

// export default CrashReportPdf;

import {
  Document,
  Image,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import Logo from "../../../assets/images/logo/motivAiLogoPng.png";
import {
  SC_COMPANY_NAME,
  SC_USER_FIRST_NAME,
} from "../../../constant/StorageConstant";
import PdfHeader from '../Components/pdfHeaderV1';
import PdfHeading from '../Components/pdfHeading';
import PdfFooter from '../Components/pdfFooterV1';
import { useLocation } from "react-router-dom";
import UtilDateTime from "../../../util/UtilDateTime";
import { globalPDF } from "../../../assets/css/commonCssTextForPdf";

const styles = StyleSheet.create({
  pdfViewer: {
    height: "96vh",
    width: "100%",
    marginTop: "20px",
    marginBottom: "5px",
  },
  document: {
    marginTop: "15px",
    marginBottom: "5px",
  },
  page: {
    // paddingLeft: 30,
    // paddingRight: 30,
    width: "100%",
  },
  margin: {
    paddingHorizontal: '30px'
  },
  watermark: {
    position: "absolute",
    fontSize: 95,
    color: "#e6e6e6",
    transform: "rotate(-52deg)",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    top: "42%",
    left: "22%",
  },
  footer: {
    position: "absolute",
    marginLeft: 30,
    marginRight: 30,
    bottom: 0,
    left: 0,
    right: 0,
    height: "44.5px",
  },
  hheading: {
    fontSize: "14px",
    fontFamily: "Helvetica-Bold",
  },
  ssubheading: {
    fontSize: "13px",
    fontFamily: "Helvetica-Bold",
  },
  sssubheading: {
    fontSize: "12px",
    fontFamily: "Helvetica-Bold",
  },
  bbodytext: {
    fontSize: "11px",
  },
  viewWithColor: {
    border: '1px solid lightgrey',
    marginTop: 10,
    marginBottom: 0,
    padding: 15,
    paddingTop: 10,
    borderRadius: 10
},
});

const fontBold = {
  fontFamily: "Helvetica-Bold",
};

const watermarkText = "MotivAI";

const CrashReportPdf = ({ topRow, errorDataWithinRange }) => {
  const rideOverallData = topRow?.find(item => item.type === 'RIDE').overallData || "-";
  const pathname = useLocation();
  const T1 = {
    title: topRow.map((widget) => widget.title),
    data: topRow.map((widget) => widget.overallData),
  };

  const T2 = {
    title: topRow.map((widget) => widget.bottomValue),
  };
  const [totalPage, setTotalPage] = useState(0);

  useEffect(() => {
    const totalChunks = Math.ceil(errorDataWithinRange.length / 3);
    setTotalPage(totalChunks);
  }, [errorDataWithinRange]);

  if (!errorDataWithinRange) return <Text>Loading...</Text>;

  const dataWithinRangeArrays = errorDataWithinRange
    .filter(
      (item) => item?.dataWithinRange && Array.isArray(item.dataWithinRange)
    )
    .map((item) => item.dataWithinRange);

  const formatData = (data) => {
    if (!data || !Array.isArray(data)) {
      return [];
    }
    return data.length < 6
      ? data
      : [...data.slice(0, 2), { separator: true }, ...data.slice(-2)];
  };

  const createTable = (data) => [
    [
      "Event Date\nEvent Time",
      "Latitude\nLongitude",
      "Distance\nRisk",
      "Speed\nLimit",
      "Violation Remarks",
    ],
    ...data.map((item) => {
      if (item.separator) {
        return [".....", ".....", ".....", ".....", "....."];
      }
      return [
        item.time
          ? `${item.time.split(" ")[0]}\n${item.time.split(" ")[1]} ${item.time.split(" ")[2]
          }`
          : "...",
        `${item.latitude || 0}\n${item.longitude || 0}`,
        `${item.kilometer || 0}\n${item.risk || 0}`,
        `${item.speed || 0}\n${item.speedLimit === "0.00" ? "40.00" : item.speedLimit || 0
        }`,
        `${item.violationRemarks || ""}`,
      ];
    }),
  ];

  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const page1Tables = dataWithinRangeArrays.slice(0, 3);
  const remainingTables = dataWithinRangeArrays.slice(3);
  const tableChunks = [page1Tables, ...chunkArray(remainingTables, 4)];

  const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
  var currentDate = new Date();
  const heading = "Crash Summary";
  // const period = "Current Year";
  // const startDate = UtilDateTime.getCurrentYear(currentDate);
  // const endDate = "";
  // const periodFormat = [period, startDate, endDate];
  const reversedDateFormat = currentDate.getDate().toString().padStart(2, "0") + "-" + months[currentDate.getMonth()] + "-" + currentDate.getFullYear();

  return (
    <>
      <PDFViewer style={styles.pdfViewer}>
        <Document style={styles.document}>
          {tableChunks.map((chunk, chunkIndex) => (
            <Page size={"A2"} key={chunkIndex} style={styles.page}>
              <Text style={styles.watermark}>{watermarkText}</Text>
              <PdfHeader />
              {chunkIndex === 0 && (
                <View style={styles.margin}>
                  <PdfHeading heading={heading} periodFormat={rideOverallData} reversedDateFormat={reversedDateFormat} />

                  <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                  <View style={[styles.viewWithColor, { width: '49.5%', marginTop: 15 }]}>
                    <View>
                      <Text style={[fontBold, { fontSize: 11 }]}>
                        1. Ride Summary
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        marginTop: 10,
                      }}
                    >
                      <View style={{ flexDirection: "row" }}>
                        {T1.title.map((item, index) => (
                          <View
                            style={{
                              flex: index === 5 ? 2 : 1.5,
                              justifyContent: "center",
                              padding: 5,
                              alignItems: "center",
                              fontSize: "9px",
                              border: "1px solid lightgrey",
                              borderLeft:
                                index === 0 ? "1px solid lightgrey" : "none",
                            }}
                            key={index}
                          >
                            <Text
                              style={[fontBold, { textAlign: "center" }]}
                              break
                            >
                              {item}
                            </Text>
                          </View>
                        ))}
                      </View>
                      <View style={{ flexDirection: "row" }}>
                        {T1.data.map((item, index) => (
                          <View
                            style={{
                              flex: index === 5 ? 2 : 1.5,
                              justifyContent: "center",
                              padding: 5,
                              fontSize: 9, // removed quotes around 9px
                              border: "1px solid lightgrey",
                              borderTop: "none",
                              borderLeft:
                                index === 0 ? "1px solid lightgrey" : "none",
                              textAlign: index === 0 ? "left" : "right",
                            }}
                            key={index}
                          >
                            <Text style={{ textAlign: "center" }} break>
                              {item}
                            </Text>
                          </View>
                        ))}
                      </View>
                    </View>
                  </View>
                  <View style={[styles.viewWithColor, { width: '49.5%', marginTop: 15 }]}>
                    <View>
                      <Text style={[fontBold, { fontSize: 11 }]}>
                        2. Ride History
                      </Text>
                    </View>
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        marginTop: 10,
                      }}
                    >
                      <View style={{ flexDirection: "row" }}>
                        {T2.title.map((item, index) => (
                          <View
                            style={{
                              flex: 2,
                              justifyContent: "center",
                              padding: 5,
                              alignItems: "center",
                              fontSize: "9px",
                              border: "1px solid lightgrey",
                              borderLeft:
                                index === 0 ? "1px solid lightgrey" : "none",
                            }}
                            key={index}
                          >
                            <Text
                              style={[fontBold, { textAlign: "center" }]}
                              break
                            >
                              {item.split(":")[0]}
                            </Text>
                          </View>
                        ))}
                      </View>
                      <View style={{ flexDirection: "row" }}>
                        {T2.title.map((item, index) => (
                          <View
                            style={{
                              flex: 2,
                              justifyContent: "center",
                              padding: 5,
                              fontSize: 9, // removed quotes around 9px
                              border: "1px solid lightgrey",
                              borderTop: "none",
                              borderLeft:
                                index === 0 ? "1px solid lightgrey" : "none",
                              textAlign: index === 0 ? "left" : "right",
                            }}
                            key={index}
                          >
                            <Text style={{ textAlign: "center" }} break>
                              {item.split(":").length > 2
                                ? `${item.split(":")[1]}:${item.split(":")[2]}`
                                : item.split(":").length > 1
                                  ? item.split(":")[1]
                                  : item}
                            </Text>
                          </View>
                        ))}
                      </View>
                    </View>
                  </View>
                  </View>

                </View>
              )}
              <View style={{ marginTop: chunkIndex === 0 ? 15 : 0 }}>
                <View style={{ flexDirection: "column", paddingHorizontal: 30}}>
                  {chunk.map((dataArray, dataIndex) => {
                    const formattedData = formatData(dataArray);
                    const table = createTable(formattedData);

                    return (
                      <View key={dataIndex}  style={[styles.viewWithColor, { marginBottom: 20 }]}>
                        <Text style={[fontBold, { fontSize: 11, marginBottom: 3 }]}>Crash: {chunkIndex === 0 ? dataIndex + 1 : chunkIndex === 1 ? dataIndex + 4 : chunkIndex === 2 ? dataIndex + 8 : chunkIndex === 3 ? dataIndex + 12 : chunkIndex === 4 ? dataIndex + 16 : chunkIndex === 5 ? dataIndex + 20 : chunkIndex === 6 ? dataIndex + 24 : chunkIndex === 7 ? dataIndex + 28 : chunkIndex === 8 ? dataIndex + 32 : chunkIndex === 9 ? dataIndex + 36 : chunkIndex === 10 ? dataIndex + 40 : ""}</Text>
                        {table.map((row, rowIndex) => (
                          <View key={rowIndex} style={{ flexDirection: "row" }}>
                            {row.map((item, columnIndex) => {
                              const isHeader = rowIndex === 0;
                              const textStyle = isHeader
                                ? [
                                  fontBold,
                                  {
                                    flex:
                                      columnIndex === 0
                                        ? 0.5
                                        : columnIndex === 4
                                          ? 2
                                          : 0.4,
                                    justifyContent: "center",
                                    padding: 2,
                                    fontSize: 9,
                                    border: "1px solid lightgrey",
                                    borderTop:
                                      rowIndex === 0
                                        ? "1px solid lightgrey"
                                        : "none",
                                    borderLeft:
                                      columnIndex === 0
                                        ? "1px solid lightgrey"
                                        : "none",
                                    textAlign: "center",
                                    paddingVertical: '10px',
                                    backgroundColor: globalPDF.GREY_COLOR
                                  },
                                ] :
                                item === "Vehicle crashed" ? [
                                  fontBold, {
                                    flex:
                                      columnIndex === 0
                                        ? 0.5
                                        : columnIndex === 4
                                          ? 2
                                          : 0.4,
                                    justifyContent: "center",
                                    padding: 2,
                                    fontSize: 8,
                                    border: "1px solid lightgrey",
                                    borderTop: "none",
                                    borderLeft: "none",
                                    textAlign: "center",
                                    color: "red",
                                    paddingVertical: '10px',
                                  },
                                ]
                                  : {
                                    flex:
                                      columnIndex === 0
                                        ? 0.5
                                        : columnIndex === 4
                                          ? 2
                                          : 0.4,
                                    justifyContent: "center",
                                    padding: 2,
                                    fontSize: 9,
                                    border: "1px solid lightgrey",
                                    borderTop:
                                      rowIndex === 0
                                        ? "1px solid lightgrey"
                                        : "none",
                                    borderLeft:
                                      columnIndex === 0
                                        ? "1px solid lightgrey"
                                        : "none",
                                    textAlign:
                                      rowIndex === 0
                                        ? "center"
                                        : columnIndex === 4
                                          ? "left"
                                          : "center",
                                    paddingVertical: '10px',
                                  };

                              return (
                                <Text key={columnIndex} style={textStyle}>
                                  {item}
                                </Text>
                              );
                            })}
                          </View>
                        ))}
                      </View>
                    );
                  })}
                </View>
              </View>
              <PdfFooter pageNumber={1} totalPages={1} pathname={pathname} />
            </Page>
          ))}
        </Document>
      </PDFViewer>
    </>
  );
};

export default CrashReportPdf;
