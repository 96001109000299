import React, { useEffect, useState } from 'react'
import { FaAngleDown, FaSearch } from 'react-icons/fa';
import { Button, Card, CardBody, CardHeader, Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import Report from "../../controller/Report/Report";
import ValueFormat from "../../util/ValueFormat";
import tick from "../../assets/images/common/Tick.svg"
import cross from "../../assets/images/common/cross.svg"
import Loader from '../../components/Common/loader';
import ToastService from '../../components/Common/toastService';


const UserOnboardStatus = () => {
    const [selectedId, setSelectedId] = useState("");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownOpenDivision, setDropdownOpenDivision] = useState(false);
    const [dropdownOpenUsername, setDropdownOpenUsername] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectUsername, setSelectUsername] = useState("SEARCH_USERNAME");
    const [selectedUsername, setSelectedUsername] = useState("Select");
    const [selectedDivisionName, setSelectedDivisionName] = useState("Select");
    const [searchFilter, setSearchFilter] = useState("");
    const [divisionLists, setDivisionLists] = useState([]);
    const [selectedDivisionId, setSelectedDivisionId] = useState("");

    const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
    const toggleDropdownDivision = () => setDropdownOpenDivision((prevState) => !prevState);
    const toggleDropdownUsername = () => setDropdownOpenUsername((prevState) => !prevState);
    const [apiDataUser, setApiDataUser] = useState([]);

    const filterUsers = (user) => {
        return user.firstName.toLowerCase().includes(searchQuery.toLowerCase());
    };

    const handleSelect = (userIdTemp, firstName) => {
        setSelectedId(userIdTemp);
        setSelectedUsername(firstName);
    };

    const handleSelectDivision = (divisionId, divisionName) => {
        setSelectedDivisionId(divisionId);
        setSelectedDivisionName(divisionName);
    };

    const handleSelectUsername = (userselectionmethod) => {
        setSelectUsername(userselectionmethod);
    };

    const divisionList = async () => {
        setShowLoader(true);
        var result = await Report.getDivisionList();
        var divisionData = [];
        try {
            var resultJSON = JSON.parse(result);
            // console.log("resultJSON: ", resultJSON);

            var status = resultJSON.status;
            if (status === "SUCCESS") {
                var resultJSONData = resultJSON.data;
                if (resultJSONData.length > 0) {
                    for (let i = 0; i < resultJSONData.length; i++) {
                        var divisionDetail = resultJSONData[i];
                        divisionData.push({
                            id: divisionDetail.id,
                            name: divisionDetail.name
                        });
                    }
                    setDivisionLists(divisionData);
                }
            } else {
                console.log("Status failed");
            }
        } catch (errMes) {
            console.log("Catch error message: ", errMes);
        } finally {
            setShowLoader(false);
        }
    };

    const getUserList = async () => {
        setShowLoader(true);
        var result = await Report.getUserListReport();
        var userData = [];
        try {
            var resultJSON = JSON.parse(result);
            var status = resultJSON.status;
            if (status === "SUCCESS") {
                var resultJSONData = resultJSON.data;
                if (resultJSONData.length > 0) {
                    var firstName = "",
                        userIdTemp = "";
                    for (let i = 0; i < resultJSONData.length; i++) {
                        var userListData = resultJSONData[i];
                        firstName = ValueFormat.parseAsString(
                            ValueFormat.capitalizeAllLetter(userListData.firstName)
                        );
                        userIdTemp = userListData.id;

                        var userDataListTemp = {
                            firstName: firstName,
                            userIdTemp: userIdTemp,
                        };

                        userData.push(userDataListTemp);
                        setApiDataUser(userData);
                    }
                }
            } else {
                console.log("Status failed");
            }
        } catch (errMes) {
            console.log("Catch error message: ", errMes);
        } finally {
            setShowLoader(false);
        }
    };
    const [unitName, setUnitName] = useState("");
    const [userNameFiledValue, setUserNameFiledValue] = useState("");
    const [usernameType, setUsernameType] = useState("");
    const [firstName, setFirstName] = useState("");
    const [deviceType, setDeviceType] = useState("");
    const [vehicleType, setVehicleType] = useState("");
    const [displayData, setDisplayData] = useState(0);
    const [defaultFirstNameUpdatedStatus, setDefaultFirstNameUpdatedStatus] = useState("");
    const [defaultPasswordUpdatedStatus, setDefaultPasswordUpdatedStatus] = useState("");
    const [licenseFileDownloadCount, setLicenseFileDownloadCount] = useState("");
    const [otpSentStatus, setOtpSentStatus] = useState("");
    const [totalInvalidRideCount, setTotalInvalidRideCount] = useState("");
    const [totalValidRideCount, setTotalValidRideCount] = useState("");
    const [invalidRidePassengerCount, setInvalidRidePassengerCount] = useState("");
    const [userProfileCompletionStatus, setUserProfileCompletionStatus] = useState("");
    const [usernameVerifiedStatus, setUsernameVerifiedStatus] = useState("");

    const [licenseApprovedStatus, setLicenseApprovedStatus] = useState(null);
    const [licenseAvailableStatus, setLicenseAvailableStatus] = useState(null);
    const [licenseClosedStatus, setLicenseClosedStatus] = useState(null);
    const [licenseDownloadInitiatedStatus, setLicenseDownloadInitiatedStatus] = useState(null);
    const [licenseDownloadUpdatedStatus, setLicenseDownloadUpdatedStatus] = useState(null);
    const [licenseErrorStatus, setLicenseErrorStatus] = useState(null);
    const [licenseExpiredStatus, setLicenseExpiredStatus] = useState(null);
    const [sessionAndroidCount, setSessionAndroidCount] = useState(null);
    const [sessionAndroidSdkCount, setSessionAndroidSdkCount] = useState(null);
    const [sessionIosCount, setSessionIosCount] = useState(null);
    const [sessionIosSdkCount, setSessionIosSdkCount] = useState(null);
    const [sessionWebCount, setSessionWebCount] = useState(null);
    const [userDeviceCount, setUserDeviceCount] = useState(null);

    const [showLoader, setShowLoader] = useState(false);
    const [apiUserName, setApiUserName] = useState("");
    const [dataLength, setDataLength] = useState(true);


    const getOnboardStatusData = async () => {
        setShowLoader(true);
        try {
            var result = await Report.getUserOnboardData(selectedDivisionId, "", apiUserName, selectedId, selectUsername);
            var resultJSON = JSON.parse(result);
            var status = resultJSON.status;
            var resultJSONData = resultJSON.data;
            console.log("resultJSONData: ", resultJSONData, status);
            if (status === "SUCCESS") {
                // resetFields();
                if (resultJSONData.length > 0) {
                    setDisplayData(1);
                    setDataLength(true);
                    resultJSONData.forEach((apiResultTemp) => {
                        setUnitName(ValueFormat.parseAsString(apiResultTemp.divisionName) || "-");
                        setUsernameType(ValueFormat.parseAsString(ValueFormat.capitalizeFirstLetterAndFormat(apiResultTemp.usernameType)) || "-");
                        setUserNameFiledValue(ValueFormat.parseAsString(apiResultTemp.username) || "-");
                        setFirstName(ValueFormat.parseAsString(ValueFormat.capitalizeAllLetter(apiResultTemp.firstName)) || "-");
                        setDeviceType(ValueFormat.parseAsString(ValueFormat.capitalizeFirstLetterAndFormat(apiResultTemp.deviceType)) || "-");
                        setVehicleType(ValueFormat.parseAsString(ValueFormat.capitalizeFirstLetterAndFormat(apiResultTemp.vehicleType)) || "-");
                        setDefaultFirstNameUpdatedStatus(ValueFormat.parseAsString(apiResultTemp.defaultFirstNameUpdatedStatus));
                        setDefaultPasswordUpdatedStatus(ValueFormat.parseAsString(apiResultTemp.defaultPasswordUpdatedStatus));
                        setLicenseFileDownloadCount(ValueFormat.parseAsString(apiResultTemp.licenseDownloadCount));
                        setOtpSentStatus(ValueFormat.parseAsString(apiResultTemp.otpSentStatus));
                        setTotalInvalidRideCount(ValueFormat.parseAsString(apiResultTemp.invalidRideCount));
                        setTotalValidRideCount(ValueFormat.parseAsString(apiResultTemp.validRideDriverCount));
                        setInvalidRidePassengerCount(ValueFormat.parseAsString(apiResultTemp.invalidRidePassengerCount));
                        setUserProfileCompletionStatus(ValueFormat.parseAsString(apiResultTemp.userProfileCompletionStatus));
                        setUsernameVerifiedStatus(ValueFormat.parseAsString(apiResultTemp.usernameVerifiedStatus));
                        setLicenseApprovedStatus(ValueFormat.parseAsString(apiResultTemp.licenseApprovedStatus));
                        setLicenseAvailableStatus(ValueFormat.parseAsString(apiResultTemp.licenseAvailableStatus));
                        setLicenseClosedStatus(ValueFormat.parseAsString(apiResultTemp.licenseClosedStatus));
                        setLicenseDownloadInitiatedStatus(ValueFormat.parseAsString(apiResultTemp.licenseDownloadInitiatedStatus));
                        setLicenseDownloadUpdatedStatus(ValueFormat.parseAsString(apiResultTemp.licenseDownloadUpdatedStatus));
                        setLicenseErrorStatus(ValueFormat.parseAsString(apiResultTemp.licenseErrorStatus));
                        setLicenseExpiredStatus(ValueFormat.parseAsString(apiResultTemp.licenseExpiredStatus));
                        setSessionAndroidCount(ValueFormat.parseAsString(apiResultTemp.sessionAndroidCount));
                        setSessionAndroidSdkCount(ValueFormat.parseAsString(apiResultTemp.sessionAndroidSdkCount));
                        setSessionIosCount(ValueFormat.parseAsString(apiResultTemp.sessionIosCount));
                        setSessionIosSdkCount(ValueFormat.parseAsString(apiResultTemp.sessionIosSdkCount));
                        setSessionWebCount(ValueFormat.parseAsString(apiResultTemp.sessionWebCount));
                        setUserDeviceCount(ValueFormat.parseAsString(apiResultTemp.userDeviceCount));
                    });
                } else {
                    setDisplayData(0);
                    setDataLength(false);
                }
            } else {
                setDisplayData(0)
                setUnitName("-");
                setUsernameType("-");
                setUserNameFiledValue("-");
                setFirstName("-");
                setDeviceType("-");
                setVehicleType("-");
                setDefaultFirstNameUpdatedStatus(null);
                setDefaultPasswordUpdatedStatus(null);
                setLicenseFileDownloadCount(null);
                setOtpSentStatus(null);
                setTotalInvalidRideCount(null);
                setTotalValidRideCount(null);
                setUserProfileCompletionStatus(null);
                setUsernameVerifiedStatus(null);
                setLicenseApprovedStatus(null);
                setLicenseAvailableStatus(null);
                setLicenseClosedStatus(null);
                setLicenseDownloadInitiatedStatus(null);
                setLicenseDownloadUpdatedStatus(null);
                setLicenseErrorStatus(null);
                setLicenseExpiredStatus(null);
                setSessionAndroidCount(null);
                setSessionAndroidSdkCount(null);
                setSessionIosCount(null);
                setSessionIosSdkCount(null);
                setSessionWebCount(null);
                setUserDeviceCount(null);
            }
        } catch (e) {
            console.log("Catch error: ", e);
        } finally {
            setShowLoader(false);
        }
    };

    useEffect(() => {
        getUserList();
        divisionList();
    }, []);

    const resetFields = () => {
        setSelectedId("");
        setDropdownOpen(false);
        setDropdownOpenDivision(false);
        setDropdownOpenUsername(false);
        setSearchQuery("");
        setSelectUsername("SEARCH_USERNAME");
        setSelectedUsername("Select");
        setSearchFilter("");
        setSelectedDivisionId("");
        setUnitName("");
        setUserNameFiledValue("");
        setUsernameType("");
        setFirstName("");
        setDeviceType("");
        setVehicleType("");
        setDisplayData(0);
        setDefaultFirstNameUpdatedStatus("");
        setDefaultPasswordUpdatedStatus("");
        setLicenseFileDownloadCount("");
        setOtpSentStatus("");
        setTotalInvalidRideCount("");
        setTotalValidRideCount("");
        setUserProfileCompletionStatus("");
        setUsernameVerifiedStatus("");
    };

    const userProfileStatus = [
        {
            step: 1,
            title: "Is OTP sent to user mobile Number?",
            status: otpSentStatus,
            successMessage: "Yes. OTP sent to user successfully",
            failureMessage: "No. OTP yet to be sent to user",
        },
        {
            step: 2,
            title: "Is Username verified?",
            status: usernameVerifiedStatus,
            successMessage: "Yes. Username is successfully verified",
            failureMessage: "No. Username yet to be verified",
        },
        {
            step: 3,
            title: "Is user profile verification completed?",
            status: userProfileCompletionStatus,
            successMessage: "Yes. User verification is successfully completed",
            failureMessage: "No. User verification yet to be completed",
        },
    ];

    const defaultSettingStatus = [
        {
            step: 1,
            title: "Is default password updated?",
            status: defaultPasswordUpdatedStatus,
            successMessage: "Yes. Default password updated at very first login",
            failureMessage: "No. Default password yet to be updated at very first login",
        },
        {
            step: 2,
            title: "Is default first name updated?",
            status: defaultFirstNameUpdatedStatus,
            successMessage: "Yes. Default first name updated at very first login",
            failureMessage: "No. Default first name yet to be updated at very first login",
        }
    ];
    
    const userLicenseFileStatus = [
        {
            step: 1,
            title: "Is license file automatically added?",
            status: licenseAvailableStatus,
            successMessage: "Yes. License file available",
            failureMessage: "No. License file not available"
        },
        {
            step: 2,
            title: "Is license file approved?",
            status: licenseApprovedStatus,
            successMessage: "Yes. License file approved",
            failureMessage: "No. License file yet to be approved",
        },
        {
            step: 3,
            title: "Is license file download initiated?",
            status: licenseDownloadInitiatedStatus,
            successMessage: "Yes. License file download initiated successfully",
            failureMessage: "No. License file download yet to be initiated",
        },
        {
            step: 4,
            title: "Is license file download updated?",
            status: licenseDownloadUpdatedStatus,
            successMessage: "Yes. License file download updated successfully",
            failureMessage: "No. License file download yet to be updated",
        },
        {
            step: 5,
            title: "Is license file downloaded without any error(s)?",
            status: licenseErrorStatus === "NO" ? "Success" : "Failure",
            successMessage: "Yes. License file downloaded without any error(s)",
            failureMessage: "No. License file download error has recorded",
        },
        {
            step: 6,
            title: "Is license file currently not expired?",
            status: licenseExpiredStatus === "NO" ? "Success" : "Failure",
            successMessage: "Yes. License file currently not expired",
            failureMessage: "No. License file got expired",
        },
        {
            step: 7,
            title: "Is license file not closed by Super Admin?",
            status: licenseClosedStatus === "NO" ? "Success" : "Failure",
            successMessage: "Yes. License file not closed by Super Admin",
            failureMessage: "No. License file got closed by Super Admin",
        },
        {
            step: 8,
            title: "How many times user downloaded license file?",
            status: licenseFileDownloadCount > 0 ? "Success" : "Failure",
            successMessage: `Yes. User downloaded license files successfully (${licenseFileDownloadCount})`,
            failureMessage: "No. User has not downloaded license file",
        },

    ];

    const userRideStatus = [
        {
            step: 1,
            title: "How many times user taken valid rides as a Driver?",
            status: totalValidRideCount > 0 ? "Success" : "Failure",
            successMessage: `Yes. User has taken valid rides (As a Driver) successfully (${totalValidRideCount})`,
            failureMessage: "No. User has not taken valid rides",
        },
        {
            step: 2,
            title: "How many times user taken valid rides as a Passenger?",
            status: invalidRidePassengerCount > 0 ? "Success" : "Failure",
            successMessage: `Yes. User has taken valid rides (As a Passenger) successfully (${invalidRidePassengerCount})`,
            failureMessage: "No. User has not taken valid rides",
        },
        {
            step: 3,
            title: "How many times user taken invalid rides?",
            status: totalInvalidRideCount > 0 ? "Success" : "Failure",
            successMessage: `Yes. User has taken invalid rides successfully (${totalInvalidRideCount})`,
            failureMessage: "No. User has not taken invalid rides",
        },
    ];

    const userSessionStatus = [
        {
            step: 1,
            title: "How many active session available - Web?",
            status: sessionWebCount > 0 ? "Success" : "Failure",
            successMessage: `Yes. User has active sessions - Web (${sessionWebCount})`,
            failureMessage: "No. User does not have any active session - Web",
        },
        {
            step: 2,
            title: "How many active session available - Android?",
            status: sessionAndroidCount > 0 ? "Success" : "Failure",
            successMessage: `Yes. User has active sessions - Android (${sessionAndroidCount})`,
            failureMessage: "No. User does not have any active session - Android",
        },
        {
            step: 3,
            title: "How many active session available - iOS?",
            status: sessionIosCount > 0 ? "Success" : "Failure",
            successMessage: `Yes. User has active sessions - iOS (${sessionIosCount})`,
            failureMessage: "No. User does not have any active session - iOS",
        },
        {
            step: 4,
            title: "How many active session available - Android SDK?",
            status: sessionAndroidSdkCount > 0 ? "Success" : "Failure",
            successMessage: `Yes. User has active sessions - Android SDK (${sessionAndroidSdkCount})`,
            failureMessage: "No. User does not have any active session - Android SDK",
        },
        {
            step: 5,
            title: "How many active session available - iOS SDK?",
            status: sessionIosSdkCount > 0 ? "Success" : "Failure",
            successMessage: `Yes. User has active sessions - iOS SDK (${sessionIosSdkCount})`,
            failureMessage: "No. User does not have any active session - iOS SDK",
        },
    ];

    const userDeviceStatus = [
        {
            step: 1,
            title: "How many active device available?",
            status: userDeviceCount > 0 ? "Success" : "Failure",
            successMessage: `Yes. User has active devices (${userDeviceCount})`,
            failureMessage: "No. User does not have any active device",
        },
    ]



    const StepCard = ({ title, status, successMessage, failureMessage }) => (
        <Card className='boxShadowCard'>
            <CardHeader className='border-0 d-flex align-items-center py-1' style={{ minHeight: '90px', maxHeight: '200px' }}>
                <Col xs={10}>
                    <h6 className='mb-0 fw-bold'>{title}</h6>
                    {status === "Success" ? <h6 className='mb-0'>{successMessage}</h6> : <h6 className='mb-0'>{failureMessage}</h6>}
                </Col>
                <Col xs={2} style={{ right: 0 }}>
                    <div style={{ float: 'right' }}>
                        <img src={status === "Success" ? tick : cross} alt="status" width={30} height={30} />
                    </div>
                </Col>
            </CardHeader>
        </Card>
    );

    const Steps = ({ steps }) => (
        <Card className=''>
            <CardHeader className='pb-0'>
                <Row>
                    {steps.map((step, index) => (
                        <Col key={index} lg={4} md={6}>
                            <StepCard
                                title={`Step ${step.step}: ${step.title}`}
                                status={step.status}
                                successMessage={step.successMessage}
                                failureMessage={step.failureMessage}
                            />
                        </Col>
                    ))}
                </Row>
            </CardHeader>
        </Card>
    );

    const fieldsData = [
        { label: "Unit Name", value: unitName },
        { label: "Username", value: userNameFiledValue },
        { label: "Username Type", value: usernameType },
        { label: "First Name", value: firstName },
        { label: "Device Type", value: deviceType },
        { label: "Vehicle Type", value: vehicleType },
    ];

    const Field = ({ label, value }) => (
        <Col lg={4} md={6} sm={12} className='mb-2'>
            <Row>
                <div className='d-flex align-items-center'>
                    <Col lg={4} md={3} sm={4}>
                        <Label className='mb-0 fw-bold'>{label}:</Label>
                    </Col>
                    <Col lg={8} md={9} sm={8}>
                        <Input value={value} readOnly />
                    </Col>
                </div>
            </Row>
        </Col>
    );

    const DynamicFields = ({ fields }) => (
        <Card className=''>
            <CardHeader>
                <Row>
                    {fields.map((field, index) => (
                        <Field key={index} label={field.label} value={field.value} />
                    ))}
                </Row>
            </CardHeader>
        </Card>
    );


    useEffect(() => {
        if (selectUsername === "SEARCH_USERNAME") {
            setApiUserName(searchFilter);
        } else if (selectUsername === "SELECT_USERNAME") {
            setApiUserName(selectedUsername);
        };
    }, [searchFilter, selectedUsername])

    const postApiUserOnboardStatusCall = () => {
        if (selectedDivisionName === "Select") {
            ToastService.errormsg("Please select unit")
        } else if (apiUserName.length > 0) {
            getOnboardStatusData();
        } else if (apiUserName === "") {
            ToastService.errormsg("Please enter mobile number or select firstname")
        }
    }
    return (
        <React.Fragment>
            {showLoader && <Loader />}
            <div className='page-content'>
                <Container fluid>
                    <Row className="my-2 py-2">
                        <Col xs={12} className="d-flex justify-content-start align-items-center text-black">
                            <h5 className="mb-0">User Onboard Status</h5>
                        </Col>
                    </Row>

                    <Row className='px-2'>
                        <Card className=''>
                            <CardHeader className='p-0'>
                                <Row>
                                    <Col md={3} className='px-0' style={{ borderRight: "1px solid #DFDEDE" }}>
                                        <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                                            <h4 className="card-title mb-1 mt-1" style={{ textAlign: "center" }}> Unit Selection </h4>
                                        </div>
                                        <div className="my-3 d-flex justify-content-center align-items-center" style={{ height: '40px' }}>
                                            <Dropdown isOpen={dropdownOpenDivision} toggle={toggleDropdownDivision} size="sm">
                                                <DropdownToggle caret color="primary"> {selectedDivisionName} <FaAngleDown style={{ marginLeft: "5px" }} /> </DropdownToggle>
                                                <DropdownMenu style={{ maxHeight: "200px", overflowY: "auto", left: "50%", transform: "translateX(-50%)" }}>
                                                    {divisionLists.map((division, index) => (
                                                        <DropdownItem key={index} onClick={() => handleSelectDivision(division.id, division.name)}>
                                                            {division.name}
                                                        </DropdownItem>
                                                    ))}
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    </Col>

                                    <Col md={3} className='px-0' style={{ borderRight: "1px solid #DFDEDE" }}>
                                        <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                                            <h4 className="card-title mb-1 mt-1" style={{ textAlign: "center" }}> User Selection Method </h4>
                                        </div>
                                        <div className="my-3 d-flex justify-content-center align-items-center" style={{ height: '40px' }}>
                                            <Dropdown isOpen={dropdownOpenUsername} toggle={toggleDropdownUsername} size="sm">
                                                <DropdownToggle caret color="primary">
                                                    {selectUsername === "SELECT_USERNAME" ? "By Firstname" : selectUsername === "SEARCH_USERNAME" ? "By Mobile Number" : null}
                                                    <FaAngleDown style={{ marginLeft: "5px" }} />
                                                </DropdownToggle>
                                                <DropdownMenu style={{ maxHeight: "200px", overflowY: "auto", left: "50%", transform: "translateX(-50%)" }}>
                                                    <DropdownItem onClick={() => handleSelectUsername("SEARCH_USERNAME")}>
                                                        By Mobile Number
                                                    </DropdownItem>
                                                    <DropdownItem onClick={() => handleSelectUsername("SELECT_USERNAME")}>
                                                        By Firstname
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    </Col>

                                    <Col md={3} className='px-0' style={{ borderRight: "1px solid #DFDEDE" }}>
                                        <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                                            <h4 className="card-title mb-1 mt-1" style={{ textAlign: "center" }}> User Selection </h4>
                                        </div>
                                        {selectUsername === "SEARCH_USERNAME" ? (
                                            <div className="my-3" style={{ height: "40px", display: "flex", justifyContent: "center", marginLeft: "15px" }} >
                                                <input
                                                    type="number"
                                                    style={{ border: " 1px solid #EEEBEB", width: "85%" }}
                                                    value={searchFilter}
                                                    onChange={(e) => { setSearchFilter(e.target.value); }}
                                                    placeholder=" Enter Mobile number"
                                                />
                                            </div>
                                        ) : selectUsername === "SELECT_USERNAME" ? (
                                            <div className="my-3 d-flex justify-content-center align-items-center" style={{ height: '40px' }}>
                                                <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} size="sm">
                                                    <DropdownToggle caret color="primary"> {selectedUsername} <FaAngleDown style={{ marginLeft: "5px" }} /> </DropdownToggle>
                                                    <DropdownMenu style={{ maxHeight: "150px", overflowY: "auto" }}>
                                                        <div className="d-flex align-items-center px-3">
                                                            <FaSearch className="me-2" />
                                                            <input
                                                                type="text"
                                                                placeholder="Search users"
                                                                value={searchQuery}
                                                                onChange={(e) => setSearchQuery(e.target.value)}
                                                                className="form-control form-control-sm"
                                                            />
                                                        </div>
                                                        {apiDataUser.filter(filterUsers).map((user) => (
                                                            <DropdownItem
                                                                key={user.userIdTemp}
                                                                onClick={() =>
                                                                    handleSelect(user.userIdTemp, user.firstName)
                                                                }
                                                            >
                                                                {user.firstName}
                                                            </DropdownItem>
                                                        ))}
                                                    </DropdownMenu>
                                                </Dropdown>
                                            </div>
                                        ) : null}
                                    </Col>

                                    <Col md={3} className='px-0' style={{ borderRight: "1px solid #DFDEDE" }}>
                                        <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                                            <h4 className="card-title mb-1 mt-1" style={{ textAlign: "center" }}> Status </h4>
                                        </div>
                                        <div className="my-3 d-flex justify-content-center align-items-center gap-2" style={{ height: '40px' }}>
                                            <Button type='button' size='sm' color='primary' onClick={postApiUserOnboardStatusCall}>View Status</Button>
                                            {/* <Button type='button' size='sm' color='primary' onClick={resetFields}>Reset</Button> */}

                                        </div>

                                    </Col>
                                </Row>
                            </CardHeader>
                        </Card>
                    </Row>

                    {displayData > 0 ? (
                        <>
                            <Row className="mb-2 pb-2">
                                <Col xs={12} className="d-flex justify-content-start align-items-center text-black">
                                    <h5 className="mb-0 fw-bold">User Details</h5>
                                </Col>
                            </Row>
                            <div>
                                <DynamicFields fields={fieldsData} />
                            </div>

                            <Row className="mb-2 pb-2">
                                <Col xs={12} className="d-flex justify-content-start align-items-center text-black">
                                    <h5 className="mb-0 fw-bold">User Profile Status</h5>
                                </Col>
                            </Row>

                            <div> <Steps steps={userProfileStatus} /> </div>

                            <Row className="mb-2 pb-2">
                                <Col xs={12} className="d-flex justify-content-start align-items-center text-black">
                                    <h5 className="mb-0 fw-bold">User Profile - Default Settings Status</h5>
                                </Col>
                            </Row>
                            <div> <Steps steps={defaultSettingStatus} /> </div>

                            <Row className="mb-2 pb-2">
                                <Col xs={12} className="d-flex justify-content-start align-items-center text-black">
                                    <h5 className="mb-0 fw-bold"> User License File Status</h5>
                                </Col>
                            </Row>
                            <div> <Steps steps={userLicenseFileStatus} /> </div>
                            <Row className="mb-2 pb-2">
                                <Col xs={12} className="d-flex justify-content-start align-items-center text-black">
                                    <h5 className="mb-0 fw-bold">User Ride Status</h5>
                                </Col>
                            </Row>
                            <div> <Steps steps={userRideStatus} /> </div>

                            <Row className="mb-2 pb-2">
                                <Col xs={12} className="d-flex justify-content-start align-items-center text-black">
                                    <h5 className="mb-0 fw-bold">User Session Status</h5>
                                </Col>
                            </Row>
                            <div> <Steps steps={userSessionStatus} /> </div>

                            <Row className="mb-2 pb-2">
                                <Col xs={12} className="d-flex justify-content-start align-items-center text-black">
                                    <h5 className="mb-0 fw-bold">User Device Status</h5>
                                </Col>
                            </Row>
                            <div> <Steps steps={userDeviceStatus} /> </div>


                        </>
                    ) : (
                        <div className='d-flex align-items-center justify-content-center' style={{ height: '46vh' }}>
                            {dataLength === true ? (
                                <h3 className='text-center'>Please select the unit and user then click view status to generate details.</h3>
                            ) : (
                                <h3 className='text-center'>No results found</h3>
                            )}
                        </div>
                    )}
                </Container>
            </div>
        </React.Fragment>
    )
}

export default UserOnboardStatus;
