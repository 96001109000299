import React from 'react'
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap'

const UserRidesKmHrsScoreCBar = ({aggregateData, totalDays}) => {

    const floatedDistanceData = parseFloat(aggregateData.totalKilometer).toFixed(2) || 0;
    const floatedDurationData = parseFloat(aggregateData.totalTravelTime/60).toFixed(2) || 0;

    const rideData = [
        { header: 'Total Days', icon: 'ad-2r-calendar.svg', value: totalDays, unit: '', alt:'Days' },
        { header: 'Total Rides', icon: 'ad-2r-ridding.svg', value: aggregateData.totalRides, unit: '', alt:'Rides' },
        { header: 'Total Distance', icon: 'ad-2r-distance.svg', value: floatedDistanceData, unit: 'kms', alt:'Distance' },
        { header: 'Total Duration', icon: 'ad-2r-time.svg', value: floatedDurationData, unit: 'hrs', alt:'Duration'  },
        // { header: 'Total Alerts', icon: 'ad-2r-alert.svg', value: aggregateData.totalAlertDataCount, unit: '', alt:'Alerts'  },
        { header: 'High Risk Alerts', icon: 'ad-2r-red-alerts.svg', value: aggregateData.totalHighRiskCount, unit: '', alt:'Red Alerts'  },
        { header: 'Medium Risk Alerts', icon: 'ad-2r-orange-alerts.svg', value: aggregateData.totalMediumRiskCount, unit: '', alt:'Amber Alerts'  },
      ];

  return (
    <div>
      <Row>
        {rideData.map((data, index) => (
          <Col key={index} xl={2} lg={2} md={4} sm={4} xs={6}>
            <Card>
              <CardHeader className="sub_heading ps-2 pe-2 pb-1 pt-2 border-0">
                {data.header}
              </CardHeader>
              <CardBody className="body_text border-0 ps-2 pe-2 py-0 d-flex justify-content-start align-items-center" style={{ height: '50px', gap: '20px' }}>
                <img src={require(`../../../assets/images/adminDashboard/${data.icon}`)} alt={data.alt} height={30} width={30} />
                <div style={{ fontWeight: 500 }}>{data.value} {data.unit}</div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default UserRidesKmHrsScoreCBar
