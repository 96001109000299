import React from "react";
import { Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap";

const UserDashboardColorIndicators = () => {

    const scoresData = [
        { color: "red", range: "0 - 80", description: "POOR" },
        { color: "#FFBF00", range: "80 - 93", description: "FAIR" },
        { color: "#19543E", range: "93 - 100", description: "GOOD" }
      ];
      const RisksData = [
        { color: "red", range: "90 - 100", description: "HIGH" },
        { color: "#FFBF00", range: "60 - 90", description: "MEDIUM" },
        { color: "#19543E", range: "0 - 60", description: "LOW" }
      ];
      const AlertsData = [
        { color: "red", range: "90 - 100", description: "HIGH" },
        { color: "#FFBF00", range: "60 - 90", description: "MEDIUM" },
        { color: "#19543E", range: "0 - 60", description: "LOW" },
      ];

  return (
    <>
      <Row>
        <h5 className="sub_heading">Colors and Ranges</h5>
      </Row>
      <Row>
        <Col xl={6} lg={6} md={12}>
          <Card style={{height: '219px'}}>
            <CardHeader className="py-1 border-0 cardHeader">
              <h5 className="body_text" style={{fontWeight: 600}}> Driving Behaviour Score (%) </h5>{" "}
            </CardHeader>
            <CardBody className="py-0 cardHeader d-flex align-items-center" >
              <Table borderless>
                <thead>
                  <tr className="body_text2">
                    <th className="pt-0 ps-0">Color</th>
                    <th className="pt-0">Range</th>
                    <th className="pt-0">Description</th>
                  </tr>
                </thead>
                <tbody>
                {scoresData.map((value, index) => (
                  <tr key={index} style={{fontSize:"13px"}}>
                    <td className="pt-0 ps-0">
                      <button
                        style={{
                          backgroundColor: value.color,
                          width: "30px",
                          height: "15px",
                          border: "none",
                          borderRadius: "4px",
                        }}
                      />
                    </td>
                    <td className="pt-0" style={{whiteSpace:'nowrap'}}>{value.range}</td>
                    <td className="pt-0" style={{whiteSpace:'nowrap'}}>{value.description}</td>
                  </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
        <Col xl={6} lg={6} md={12}>
          <Card style={{height: '219px'}}>
            <CardHeader className="py-1 border-0 cardHeader">
              <h5 className="body_text" style={{fontWeight: 600}}> Risk (%) </h5>{" "}
            </CardHeader>
            <CardBody className="py-0 cardHeader d-flex align-items-center" >
              <Table borderless>
                <thead>
                  <tr className="body_text2">
                    <th className="pt-0 ps-0">Color</th>
                    <th className="pt-0">Range</th>
                    <th className="pt-0">Description</th>
                  </tr>
                </thead>
                <tbody>
                {RisksData.map((value, index) => (
                  <tr key={index} style={{fontSize:"13px"}}>
                    <td className="pt-0 ps-0">
                      <button
                        style={{
                          backgroundColor: value.color,
                          width: "30px",
                          height: "15px",
                          border: "none",
                          borderRadius: "4px",
                        }}
                      />
                    </td>
                    <td className="pt-0" style={{whiteSpace:'nowrap'}}>{value.range}</td>
                    <td className="pt-0" style={{whiteSpace:'nowrap'}}>{value.description}</td>
                  </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
        <Col xl={12} lg={12} md={12}>
          <Card style={{height: '219px'}}>
            <CardHeader className="py-1 border-0 cardHeader">
              <h5 className="body_text" style={{fontWeight: 600}}> Alert (%) </h5>{" "}
            </CardHeader>
            <CardBody className="py-0 cardHeader d-flex align-items-center" >
              <Table borderless>
                <thead>
                  <tr className="body_text2">
                    <th className="pt-0 ps-0">Color</th>
                    <th className="pt-0">Range</th>
                    <th className="pt-0">Description</th>
                  </tr>
                </thead>
                <tbody>
                {AlertsData.map((value, index) => (
                  <tr key={index} style={{fontSize:"13px"}}>
                    <td className="pt-0 ps-0">
                      <button
                        style={{
                          backgroundColor: value.color,
                          width: "30px",
                          height: "15px",
                          border: "none",
                          borderRadius: "4px",
                        }}
                      />
                    </td>
                    <td className="pt-0" style={{whiteSpace:'nowrap'}}>{value.range}</td>
                    <td className="pt-0" style={{whiteSpace:'nowrap'}}>{value.description}</td>
                  </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default UserDashboardColorIndicators;
