import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap";
import ReactEcharts from "echarts-for-react";
import dayjs from "dayjs";
import UBIController from "../../../controller/UBI Controllers/UBIController";
import ValueFormat from "../../../util/ValueFormat";
import Mobile from "./UBIDashboardPoints.json";
import { UBIPoints } from "./UBIPointsFunctions";
import { SC_GROUP_ID } from "../../../constant/StorageConstant";
import { Tooltip as ReactTooltip } from "react-tooltip";

const DSxAxisData = ["Normal", "Eco", "Sportive", "Chaotic", "Aggressive"];
const DBxAxisData = ["Normal", "Angry", "Tired"];
const SDxAxisData = ["0-40", "40-60", "60-80", "80-120", "120+"];
const SSCxAxisData = ["Acceleration", "Cornering", "Braking"];
const MUxAxisData = ["Screen", "Call"];

const DrivingSafetyAreav3 = ({
  startYear,
  endYear,
  getDatafromDrivingSafetyArea,
  filters, 
  // startDateTime, endDateTime
}) => {
  const [realDStyledata, setRealDStyledata] = useState([]);
  const [realDStatedata, setrealDStateData] = useState([]);
  const [realSSCData, setRealSSCData] = useState([
    { name: "Low", data: [] },
    { name: "Medium", data: [] },
    { name: "High", data: [] },
  ]);
  // const topRowStressStrainPoint = UBIPoints.calculatestressStrainPoint(
  //   realSSCData,
  //   Mobile
  // );

  const accLow = UBIPoints.getStressStrainLowPoints(filters,realSSCData.find(item => item.name === "Low").data[0], startYear, endYear);
  const corLow = UBIPoints.getStressStrainLowPoints(filters,realSSCData.find(item => item.name === "Low").data[1], startYear, endYear);
  const brakLow = UBIPoints.getStressStrainLowPoints(filters,realSSCData.find(item => item.name === "Low").data[2], startYear, endYear);

  const accMedium = UBIPoints.getStressStrainMediumPoints(filters,realSSCData.find(item => item.name === "Medium").data[0], startYear, endYear);
  const corMedium = UBIPoints.getStressStrainMediumPoints(filters,realSSCData.find(item => item.name === "Medium").data[1], startYear, endYear);
  const brakMedium = UBIPoints.getStressStrainMediumPoints(filters,realSSCData.find(item => item.name === "Medium").data[2], startYear, endYear);

  const accHigh = UBIPoints.getStressStrainHighPoints(filters,realSSCData.find(item => item.name === "High").data[0], startYear, endYear);
  const corHigh = UBIPoints.getStressStrainHighPoints(filters,realSSCData.find(item => item.name === "High").data[1], startYear, endYear);
  const brakHigh = UBIPoints.getStressStrainHighPoints(filters,realSSCData.find(item => item.name === "High").data[2], startYear, endYear);

  const accNew = ((0.1 * accLow) + (0.3 * accMedium) + (0.6 * accHigh));
  const corNew = ((0.1 * corLow) + (0.3 * corMedium) + (0.6 * corHigh));
  const brakNew = ((0.1 * brakLow) + (0.3 * brakMedium) + (0.6 * brakHigh));

  const ABCPoint = ((0.3 * accNew) + (0.4 * brakNew) + (0.3 * corNew)).toFixed(0);
  // console.log(accNew, corNew, brakNew,ABCPoint,"accNew");


  const [realMSCUData, setRealMSCUData] = useState([]);
  const [realMSCUDataPoint, setRealMSCUDataPoint] = useState([]);
  const [forceReRender, setForceReRender] = useState(false);
  const mobileUsagePoint = UBIPoints.calculateMobileUsagePoint(
    realMSCUData[0],
    realMSCUData[1],
    Mobile
  );

  const mobileUsePointNew = UBIPoints.getMobileScreenPoints(filters, realMSCUDataPoint[1], startYear, endYear);
  const mobileScreenPointNew = UBIPoints.getMobileScreenPoints(filters, realMSCUDataPoint[0], startYear, endYear);

  const [normalStateCount, setNormalStateCount] = useState(null);
  const [tiredStateCount, setTiredStateCount] = useState(null);
  const [angryStateCount, setAngryStateCount] = useState(null);
  const [styleNormalCount, setStyleNormalCount] = useState(null);
  const [styleSportiveCount, setStyleSportiveCount] = useState(null);
  const [styleEcoCount, setStyleEcoCount] = useState(null);
  const [styleChaoticCount, setStyleChaoticCount] = useState(null);
  const [styleAggressiveCount, setStyleAggressiveCount] = useState(null);

  const drivingBehaviorPoint = UBIPoints.calculateDrivingBehaviorPoint(
    normalStateCount,
    tiredStateCount,
    angryStateCount,
    styleNormalCount,
    styleSportiveCount,
    styleEcoCount,
    styleChaoticCount,
    styleAggressiveCount,
    Mobile).toFixed(0);

  const DSP = UBIPoints.calculateDrivingStatePoint(normalStateCount, tiredStateCount, angryStateCount, Mobile).toFixed(0);
  const DSTP = UBIPoints.calculateDrivingStylePoint(styleNormalCount, styleSportiveCount, styleEcoCount, styleChaoticCount, styleAggressiveCount, Mobile).toFixed(0);
  // ECharts options
  const DrivingStyleData = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    xAxis: [
      {
        type: "category",
        data: DSxAxisData,
        nameLocation: "middle",
        axisLabel: {
          show: true,
        },
        name: "Category",
        nameTextStyle: {
          lineHeight: 50,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        min: 0,
        max: 100,
        interval: 20,
        axisLabel: {
          formatter: "{value}%", // Show percentage symbol on the y-axis labels
        },
        nameLocation: "middle",
        name: "Style",
        nameTextStyle: {
          lineHeight: 70,
        },
      },
    ],
    grid: { bottom: 50, left: 65, right: 20, top: 20 },
    series: [
      {
        type: "bar",
        barWidth: 20,
        name: "Style",
        data: realDStyledata,
        label: {
          show: true,
          position: "top",
        },
        itemStyle: {
          color: function ({ dataIndex }) {
            const category = DSxAxisData[dataIndex];
            return category === "Normal" || category === "Eco"
              ? "#19543e"
              : category === "Sportive" || category === "Chaotic"
                ? "#FFBF00"
                : category === "Aggressive"
                  ? "red"
                  : "blue";
          },
        },

        emphasis: {
          focus: "series",
        },
      },
    ],
  };

  const DrivingBehaviorData = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    xAxis: [
      {
        type: "category",
        data: DBxAxisData,
        nameLocation: "middle",
        axisLabel: {
          show: true,
        },
        name: "Category",
        nameTextStyle: {
          lineHeight: 50,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        min: 0,
        max: 100,
        interval: 20,
        axisLabel: {
          formatter: "{value}%",
        },
        nameLocation: "middle",
        name: "State",
        nameTextStyle: {
          lineHeight: 70,
        },
      },
    ],
    grid: { bottom: 50, left: 65, right: 20, top: 20 },
    series: [
      {
        type: "bar",
        barWidth: 20,
        name: "Behavior",
        data: realDStatedata,
        label: {
          show: true,
          position: "top",
        },
        itemStyle: {
          color: function ({ dataIndex }) {
            const category = DBxAxisData[dataIndex];
            return category === "Normal"
              ? "#19543e"
              : category === "Angry"
                ? "#FFBF00"
                : category === "Tired"
                  ? "red"
                  : "blue";
          },
        },
        emphasis: {
          focus: "series",
        },
      },
    ],
  };

  const StressStrainCountsData = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    xAxis: [
      {
        type: "category",
        data: SSCxAxisData,
        nameLocation: "middle",
        axisLabel: {
          show: true,
        },
        name: "Category",
        nameTextStyle: {
          lineHeight: 40,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        // min: 0,
        // max: 45,
        // interval: 5,
        axisLabel: {
          formatter: "{value}",
        },
        nameLocation: "middle",
        name: "Counts",
        nameTextStyle: {
          lineHeight: 50,
        },
      },
    ],
    grid: { bottom: 45, left: 50, right: 30, top: 50 },
    series: realSSCData.map((item, index) => ({
      type: "bar",
      name: item.name,
      data: item.data,
      barWidth: 20, // Set the bar width to 20
      label: {
        show: true, // Show data labels
        position: "top", // You can also try 'top', 'bottom', 'left', 'right'
        formatter: "{c}", // Display the actual value
      },
      itemStyle: {
        color: index === 0 ? "#19543E" : index === 1 ? "#FFBF00" : "red",
      },
    })),
    legend: {
      data: realSSCData.map((item) => item.name),
      top: "-1%",
    },
  };

  const MobileUsageData = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    xAxis: {
      type: "category",
      data: MUxAxisData,
      nameLocation: "middle",
      axisLabel: {
        show: true,
      },
      name: "Category",
      nameTextStyle: {
        lineHeight: 40,
      },
    },
    yAxis: [
      {
        type: "value",
        min: 0,
        max: 160,
        interval: 20,
        axisLabel: {
          formatter: "{value}",
        },
        nameLocation: "middle",
        name: "Hours",
        nameTextStyle: {
          lineHeight: 40,
        },
      },
    ],
    grid: { bottom: 50, left: 50, right: 30, top: 30 },
    series: [
      {
        type: "bar",
        barWidth: 20,
        name: "Hours",
        label: {
          show: true,
          position: "top",
          formatter: "{c}",
        },
        itemStyle: {
          color: "#FF6619",
        },
        data: realMSCUData,
      },
    ],
  };

  const [lowData, setLowData] = useState({});
  const [mediumData, setMediumData] = useState({});
  const [highData, setHighData] = useState({});
  const fetchData = () => {
    const low = realSSCData.find((entry) => entry.name === "Low");
    const medium = realSSCData.find((entry) => entry.name === "Medium");
    const high = realSSCData.find((entry) => entry.name === "High");

    if (low && medium && high) {
      setLowData((prevLowData) => ({
        ...prevLowData,
        acceleration: low.data[0],
        cornering: low.data[1],
        braking: low.data[2],
      }));
      setMediumData((prevMediumData) => ({
        ...prevMediumData,
        acceleration: medium.data[0],
        cornering: medium.data[1],
        braking: medium.data[2],
      }));
      setHighData((prevHighData) => ({
        ...prevHighData,
        acceleration: high.data[0],
        cornering: high.data[1],
        braking: high.data[2],
      }));
    } else {
      console.error("One or more data entries not found in the array.");
    }
  };

  useEffect(() => {
    setForceReRender((prev) => !prev);
    fetchData();
  }, [realSSCData]);

  const [realPieData, setRealPieData] = useState([]);
  const [realSDistData, setRealSDistData] = useState([]);

  getDatafromDrivingSafetyArea(
    realDStyledata,
    realDStatedata,
    realPieData,
    realSDistData,
    realSSCData,
    realMSCUData,
    mobileUsagePoint,
    ABCPoint,
    drivingBehaviorPoint,
    DSP,
    DSTP,
    mobileUsePointNew,
    mobileScreenPointNew
  );

  const AreaCategoryData = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c}%",
    },
    legend: {
      // orient: "vertical",
      // left: 10,
      // top:'11%',
      bottom: 10,
      left: "center",
      data: realPieData.map((item) => item.name),
    },
    series: [
      {
        name: "Area Category",
        type: "pie",
        radius: "50%",
        center: ["50%", "50%"],
        selectedMode: "single",
        label: {
          show: true,
          position: "outside",
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        data: realPieData.map((item) => ({
          name: item.name,
          value: item.value,
          itemStyle: {
            color:
              item.name === "Urban"
                ? "#5b73e8"
                : item.name === "Rural"
                  ? "#FF6619"
                  : "lightgrey",
          },
        })),
      },
    ],
  };

  const SpeedDistributionData = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      // formatter: function (params) {
      //   return `<strong>${params[0].name}</strong>: ${params[0].value}%`;
      // },
    },
    xAxis: [
      {
        type: "category",
        data: SDxAxisData,
        nameLocation: "middle",
        axisLabel: {
          show: true,
        },
        name: "Speed (km/hr)",
        nameTextStyle: {
          lineHeight: 40,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        min: 0,
        max: 100,
        interval: 10,
        axisLabel: {
          formatter: "{value}%",
        },
        nameLocation: "middle",
        name: "Percentage",
        nameTextStyle: {
          lineHeight: 60,
        },
      },
    ],
    grid: { bottom: 50, left: 60, right: 20, top: 20 },
    series: [
      {
        type: "bar",
        barWidth: 20,
        name: "Speed",
        data: realSDistData,
        label: {
          show: true,
          position: "top",
        },
        emphasis: {
          focus: "series",
        },
        itemStyle: {
          color: function (params) {
            const index = params.dataIndex;
            const value = SDxAxisData[index];
            if (value === "0-40" || value === "40-60") {
              return "#19543e";
            } else if (value === "60-80") {
              return "#FFBF00";
            } else {
              return "red";
            }
          },
        },
        legend: {
          data: ["Low", "Medium", "High"],
          top: 10,
        },
      },
    ],
  };

  const DStyleBehaviorSSCMUH = async () => {
    try {
      var result = await UBIController.getDStyleBehaviorSSCMUH(
        startYear,
        endYear,
        filters,
        // startDateTime, 
        // endDateTime
      );
      var resultJSON = JSON.parse(result);
      // console.log("resultJSON", resultJSON);
      var status = resultJSON.status;
      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        // console.log("resultJSONData", resultJSONData);
        let dstylePercent = [];
        let dstatePercent = [];
        let ACBData = [];
        let mobData = [];
        let mobDataPoint = [];
        let normalCount = 0;
        let angryCount = 0;
        let tiredCount = 0;
        let normalStyleCount = 0;
        let sportiveCount = 0;
        let ecoCount = 0;
        let chaoticCount = 0;
        let aggressiveCount = 0;
        let pieData = [];
        let SDistributiondata = [];

        if (resultJSONData.length > 0) {
          resultJSONData.map((value) => {
            var deviceSummaryDetail = ValueFormat.parseAsObject(
              value.deviceSummaryDetail
            );
            // console.log("deviceSummaryDetail", deviceSummaryDetail);
            // deviceSummaryDetail.map((data) => {
            var dstyleNormalCount = ValueFormat.parseAsFloat(
              deviceSummaryDetail.dstyleNormalCount
            );
            normalStyleCount = dstyleNormalCount;
            var dstyleSportiveCount = ValueFormat.parseAsFloat(
              deviceSummaryDetail.dstyleSportiveCount
            );
            sportiveCount = dstyleSportiveCount;
            var dstyleEcoCount = ValueFormat.parseAsFloat(
              deviceSummaryDetail.dstyleEcoCount
            );

            ecoCount = dstyleEcoCount;
            var dstyleChaoticCount = ValueFormat.parseAsFloat(
              deviceSummaryDetail.dstyleChaoticCount
            );
            chaoticCount = dstyleChaoticCount;
            var dstyleAggressiveCount = ValueFormat.parseAsFloat(
              deviceSummaryDetail.dstyleAggressiveCount
            );
            aggressiveCount = dstyleAggressiveCount;

            var totaldstyleCount =
              parseFloat(dstyleNormalCount) +
              parseFloat(dstyleSportiveCount) +
              parseFloat(dstyleEcoCount) +
              parseFloat(dstyleChaoticCount) +
              parseFloat(dstyleAggressiveCount);

            var dstyleNormalPercentage = parseFloat(
              (dstyleNormalCount / totaldstyleCount) * 100
            ).toFixed(1);
            if (isNaN(dstyleNormalPercentage)) {
              dstyleNormalPercentage = 0;
            }

            var dstyleSportivePercentage = parseFloat(
              (dstyleSportiveCount / totaldstyleCount) * 100
            ).toFixed(1);
            if (isNaN(dstyleSportivePercentage)) {
              dstyleSportivePercentage = 0;
            }
            var dstyleEcoPercentage = parseFloat(
              (dstyleEcoCount / totaldstyleCount) * 100
            ).toFixed(1);
            if (isNaN(dstyleEcoPercentage)) {
              dstyleEcoPercentage = 0;
            }
            var dstyleChaoticPercentage = parseFloat(
              (dstyleChaoticCount / totaldstyleCount) * 100
            ).toFixed(1);
            if (isNaN(dstyleChaoticPercentage)) {
              dstyleChaoticPercentage = 0;
            }
            var dstyleAggressivePercentage = parseFloat(
              (dstyleAggressiveCount / totaldstyleCount) * 100
            ).toFixed(1);
            if (isNaN(dstyleAggressivePercentage)) {
              dstyleAggressivePercentage = 0;
            }
            dstylePercent.push(
              dstyleNormalPercentage,
              dstyleEcoPercentage,
              dstyleSportivePercentage,
              dstyleChaoticPercentage,
              dstyleAggressivePercentage
            );

            var dstateNormalCount = ValueFormat.parseAsFloat(
              deviceSummaryDetail.dstateNormalCount
            );
            normalCount = dstateNormalCount;
            var dstateAngryCount = ValueFormat.parseAsFloat(
              deviceSummaryDetail.dstateAngryCount
            );
            angryCount = dstateAngryCount;

            var dstateTiredCount = ValueFormat.parseAsFloat(
              deviceSummaryDetail.dstateTiredCount
            );
            tiredCount = dstateTiredCount;

            var totaldststeCount =
              parseFloat(dstateNormalCount) +
              parseFloat(dstateAngryCount) +
              parseFloat(dstateTiredCount);

            var dstateNormalPercentage = parseFloat(
              (dstateNormalCount / totaldststeCount) * 100
            ).toFixed(1);
            if (isNaN(dstateNormalPercentage)) {
              dstateNormalPercentage = 0;
            }

            var dstateAngryPercentage = parseFloat(
              (dstateAngryCount / totaldststeCount) * 100
            ).toFixed(1);

            if (isNaN(dstateAngryPercentage)) {
              dstateAngryPercentage = 0;
            }

            var dstateTiredPercentage = parseFloat(
              (dstateTiredCount / totaldststeCount) * 100
            ).toFixed(1);

            if (isNaN(dstateTiredPercentage)) {
              dstateTiredPercentage = 0;
            }

            dstatePercent.push(
              dstateNormalPercentage,
              dstateAngryPercentage,
              dstateTiredPercentage
            );

            var severeAccelerationLowCount = ValueFormat.parseAsFloat(
              deviceSummaryDetail.severeAccelerationLowCount
            );
            var severeAccelerationMediumCount = ValueFormat.parseAsFloat(
              deviceSummaryDetail.severeAccelerationMediumCount
            );
            var severeAccelerationHighCount = ValueFormat.parseAsFloat(
              deviceSummaryDetail.severeAccelerationHighCount
            );
            var severeCorneringLowCount = ValueFormat.parseAsFloat(
              deviceSummaryDetail.severeCorneringLowCount
            );
            var severeCorneringMediumCount = ValueFormat.parseAsFloat(
              deviceSummaryDetail.severeCorneringMediumCount
            );
            var severeCorneringHighCount = ValueFormat.parseAsFloat(
              deviceSummaryDetail.severeCorneringHighCount
            );
            var severeBrakingLowCount = ValueFormat.parseAsFloat(
              deviceSummaryDetail.severeBrakingLowCount
            );
            var severeBrakingMediumCount = ValueFormat.parseAsFloat(
              deviceSummaryDetail.severeBrakingMediumCount
            );
            var severeBrakingHighCount = ValueFormat.parseAsFloat(
              deviceSummaryDetail.severeBrakingHighCount
            );

            ACBData.push(
              {
                name: "Low",
                data: [
                  severeAccelerationLowCount,
                  severeCorneringLowCount,
                  severeBrakingLowCount,
                ],
              },
              {
                name: "Medium",
                data: [
                  severeAccelerationMediumCount,
                  severeCorneringMediumCount,
                  severeBrakingMediumCount,
                ],
              },
              {
                name: "High",
                data: [
                  severeAccelerationHighCount,
                  severeCorneringHighCount,
                  severeBrakingHighCount,
                ],
              }
            );

            var mobileUseInAcceptedDuration = ValueFormat.parseAsFloat(
              deviceSummaryDetail.mobileUseInAcceptedDuration
            );
            var mobileUseOutAcceptedDuration = ValueFormat.parseAsFloat(
              deviceSummaryDetail.mobileUseOutAcceptedDuration
            );

            var mobileScreenScreenOnDuration = ValueFormat.parseAsFloat(deviceSummaryDetail.mobileScreenScreenOnDuration) / 60;

            var mobileInandOutAcceptedDuration = (parseFloat(mobileUseInAcceptedDuration) + parseFloat(mobileUseOutAcceptedDuration)) / 60;

            var mobileScreenScreenOnDurationPoint = ValueFormat.parseAsFloat(deviceSummaryDetail.mobileScreenScreenOnDuration) * 60;

            var mobileInandOutAcceptedDurationPoint = (parseFloat(mobileUseInAcceptedDuration) + parseFloat(mobileUseOutAcceptedDuration)) * 60;


            mobData.push(
              mobileScreenScreenOnDuration.toFixed(1),
              mobileInandOutAcceptedDuration.toFixed(1)
            );

            mobDataPoint.push(
              mobileScreenScreenOnDurationPoint.toFixed(1),
              mobileInandOutAcceptedDurationPoint.toFixed(1)
            )

            var ruralPercentage = ValueFormat.parseAsFloat(
              value.speedBoxDetail.ruralPercentage
            );
            var urbanPercentage = ValueFormat.parseAsFloat(
              value.speedBoxDetail.urbanPercentage
            );
            var highwayPercentage = ValueFormat.parseAsFloat(
              value.speedBoxDetail.highwayPercentage
            );

            pieData.push({
              name: "Rural",
              value: ruralPercentage,
            });
            pieData.push({
              name: "Urban",
              value: urbanPercentage,
            });
            pieData.push({
              name: "Highway",
              value: highwayPercentage,
            });

            var speedBox1 = ValueFormat.parseAsFloat(
              value.speedBoxDetail.speedBox1
            );
            var speedBox2 = ValueFormat.parseAsFloat(
              value.speedBoxDetail.speedBox2
            );
            var speedBox3 = ValueFormat.parseAsFloat(
              value.speedBoxDetail.speedBox3
            );
            var speedBox4 = ValueFormat.parseAsFloat(
              value.speedBoxDetail.speedBox4
            );
            var speedBox5 = ValueFormat.parseAsFloat(
              value.speedBoxDetail.speedBox5
            );

            var totalSpeedBox =
              parseFloat(speedBox1) +
              parseFloat(speedBox2) +
              parseFloat(speedBox3) +
              parseFloat(speedBox4) +
              parseFloat(speedBox5);

            var speedBox1Percent = parseFloat(
              (speedBox1 / totalSpeedBox) * 100
            ).toFixed(1);

            var speedBox2Percent = parseFloat(
              (speedBox2 / totalSpeedBox) * 100
            ).toFixed(1);

            var speedBox3Percent = parseFloat(
              (speedBox3 / totalSpeedBox) * 100
            ).toFixed(1);

            var speedBox4Percent = parseFloat(
              (speedBox4 / totalSpeedBox) * 100
            ).toFixed(1);

            var speedBox5Percent = parseFloat(
              (speedBox5 / totalSpeedBox) * 100
            ).toFixed(1);

            SDistributiondata.push(
              speedBox1Percent,
              speedBox2Percent,
              speedBox3Percent,
              speedBox4Percent,
              speedBox5Percent
            );
            // });
          });
          setRealDStyledata(dstylePercent);
          setrealDStateData(dstatePercent);
          setRealPieData(pieData);
          setRealSDistData(SDistributiondata);
          if (ACBData.length === 0) {
            setRealSSCData([
              { name: "Low", data: [0, 0, 0] },
              { name: "Medium", data: [0, 0, 0] },
              { name: "High", data: [0, 0, 0] },
            ]);
          } else {
            setRealSSCData(ACBData);
          }
          setRealMSCUData(mobData);
          setRealMSCUDataPoint(mobDataPoint)
          setNormalStateCount(normalCount);
          setAngryStateCount(angryCount);
          setTiredStateCount(tiredCount);
          setStyleNormalCount(normalStyleCount);
          setStyleSportiveCount(sportiveCount);
          setStyleEcoCount(ecoCount);
          setStyleChaoticCount(chaoticCount);
          setStyleAggressiveCount(aggressiveCount);
        } else {
          console.log("Data Not Available");
          setRealPieData([]);
          setRealSDistData([]);
          setRealMSCUData([]);
          setRealMSCUDataPoint([]);
          setRealDStyledata(['0.0','0.0','0.0','0.0','0.0']);
          setrealDStateData(["0.0","0.0","0.0"]);
          setRealSSCData([
            { name: "Low", data: [] },
            { name: "Medium", data: [] },
            { name: "High", data: [] },
          ]);
          setAngryStateCount(null);
          setNormalStateCount(null);
          setTiredStateCount(null);
          setStyleNormalCount(null);
          setStyleSportiveCount(null);
          setStyleEcoCount(null);
          setStyleChaoticCount(null);
          setStyleAggressiveCount(null);
        }
      } else {
        const code = resultJSON.error.code;
        if (code == "E007") {
          console.log("status failed");
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    DStyleBehaviorSSCMUH();
  }, [startYear, endYear, filters, 
    // startDateTime, endDateTime
  ]);

  const [chartWidth, setChartWidth] = useState("98vw");
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 992) {
        setChartWidth("95vw");
      } else {
        setChartWidth("30vw");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [chartWidth1, setChartWidth1] = useState("98vw");
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 992) {
        setChartWidth1("98vw");
      } else {
        setChartWidth1("38vw");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [chartWidth2, setChartWidth2] = useState("98vw");
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 992) {
        setChartWidth2("98vw");
      } else {
        setChartWidth2("23vw");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <React.Fragment>
      <Row className="mb-4">
        <Col lg={4} xs={12}>
          <Card>
            <CardHeader className="cardHeader">
              <h4 className="card-title">Driving Style</h4>
            </CardHeader>
            <CardBody
              className="d-flex align-items-center"
              style={{ height: "450px" }}
            >
              <ReactEcharts
                option={DrivingStyleData}
                style={{ height: "100%", width: chartWidth }}
              />
            </CardBody>
          </Card>
        </Col>
        <Col lg={4} xs={12}>
          <Card>
            <CardHeader className="cardHeader">
              <h4 className="card-title">Driver State</h4>
            </CardHeader>
            <CardBody
              className="d-flex align-items-center"
              style={{ height: "450px" }}
            >
              <ReactEcharts
                option={DrivingBehaviorData}
                style={{ height: "100%", width: chartWidth }}
              />
            </CardBody>
          </Card>
        </Col>
        <Col lg={4} xs={12}>
          <Card>
            <CardHeader className="cardHeader">
              <h4 className="card-title">Area Category</h4>
            </CardHeader>
            <CardBody
              className="d-flex align-items-center"
              style={{ height: "450px" }}
            >
              <ReactEcharts
                option={AreaCategoryData}
                style={{ height: "100%", width: chartWidth }}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col lg={5} xs={12}>
          <Card>
            <CardHeader className="cardHeader">
              <h4 className="card-title">Speed Distribution</h4>
            </CardHeader>
            <CardBody
              className="d-flex flex-column align-items-center"
              style={{ height: "450px" }}
            >
              <ReactEcharts
                option={SpeedDistributionData}
                style={{ height: "100%", width: chartWidth1 }}
              />
            </CardBody>
          </Card>
        </Col>
        <Col lg={4} xs={12}>
          <Card>
            <CardHeader className="cardHeader" >
              <h4 className="card-title" data-tooltip-id="acb">Acc/Brak/Cor Counts</h4>
            </CardHeader>
            <CardBody
              className="d-flex flex-column align-items-center"
              style={{ height: "450px" }}
            >
              <ReactEcharts
                key={forceReRender}
                option={StressStrainCountsData}
                style={{ height: "100%", width: chartWidth }}
              />
              <ReactTooltip id="acb" place="top" content="Acceleration/Braking/Cornering Counts" />
            </CardBody>
          </Card>
        </Col>
        <Col lg={3} xs={12}>
          <Card>
            <CardHeader className="cardHeader">
              <h4 className="card-title">Mobile Usage in Hour</h4>
            </CardHeader>
            <CardBody
              className="d-flex flex-column align-items-center"
              style={{ height: "450px" }}
            >
              <ReactEcharts
                option={MobileUsageData}
                style={{ height: "100%", width: chartWidth2 }}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default DrivingSafetyAreav3;
