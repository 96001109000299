import React, { Component } from "react"
import ReactEcharts from "echarts-for-react"

const Doughnuts = ({dayNightPercentage}) => {
  const getOptions = () => {
    return {
      toolbox: {
        show: !1,
      },
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b}: {d}%",
      },
      legend: {
        orient: "horizontal",
        x: "left",
        data: ["Day", "Night"],
        textStyle: {
          // color: ["#858d98"],
          fontSize:"10px"
        },
      },
      color: ['#DDB43F','#54948A'],
      series: [
        {
          name: "Day & Night %",
          type: "pie",
          radius: ["42%", "70%"],
          avoidLabelOverlap: !1,
          label: {
            normal: {
              show: !1,
              position: "center",
            },
            emphasis: {
              show: !0,
              textStyle: {
                fontSize: "20",
                fontWeight: "bold",
              },
            },
          },
          labelLine: {
            normal: {
              show: !1,
            },
          },
          label:{
            show: true,
            position:'inside',
            color:"white",
            fontSize: 12,
            formatter: function(parms){
              if (parms.data.value> 0){
              var customToolTipUrban = parms.data.value +'%'
              return customToolTipUrban;
              }else{
                var customToolTipUrban = " "
                return customToolTipUrban;
              }
            },
          },
          data: [
            { value: dayNightPercentage.day, name: "Day" },
            { value: dayNightPercentage.night, name: "Night" },
            
           
          ],
        },
      ],
    }
  }

    return (
      <React.Fragment>
        <ReactEcharts style={{ height: "235px" }} option={getOptions()} />
      </React.Fragment>
    )
  }

export default Doughnuts
