import React, { useState,useEffect } from "react"
import ReactApexChart from "react-apexcharts"
import ReactEcharts from "echarts-for-react"

const DrivingBehaviour = ({drivingDataList,xAxisDataList}) => {
  const options = {
    chart: {
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [1.4],
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    colors: ["#5C4033" ],

    fill: {
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: false,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },

    markers: {
      size: 0,
    },
    legend: {      
      offsetY: 4,
    },
    xaxis: {
      categories:xAxisDataList,
      show:true,
      title: {
        text: "Ride",
        position: "bottom",
        style: {  
          fontFamily: "IBM Plex Sans, sans-serif",
          fontWeight:200,         
        },

      }, style: {
        color: undefined,
        fontSize: "14px",
        fontFamily: "Segoe UI",
        fontWeight: 500,
        cssClass: "apexcharts-xaxis-title",
      },
      floating: false,
      labels: {
        show: true,
        rotate: -10,
        rotateAlways: false,
        hideOverlappingLabels: true,
        showDuplicates: false,
        trim: true,
  
        style: {
          fontWeight: 400,
          fontSize: "9px",
          fontFamily: "Segoe UI",
          cssClass: "apexcharts-xaxis-label",
        },
        offsetX: 0,
        offsetY: 0,
        categories: [],
      },
    },
 
    
    yaxis: {
      min:0, 
      max:100,
      show:true,      
      title: {
        text: "Score",
        style: {  
          fontFamily: "IBM Plex Sans, sans-serif", 
          fontWeight:200,        
        },
      },
      
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0) + "%"
          }
          return y
        },
      },
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  }

  const multiUserDrivingOption = {
    xAxis: {
      type: 'category',
    },
    yAxis: {
      type: 'value'
    },
    legend:{
      show:true
    },
    series:drivingDataList
  };

  const [multiLineData, setMultiLineData] = useState(multiUserDrivingOption);

useEffect(()=>{
  setMultiLineData({
    xAxis: {
      type: 'category',
    },
    yAxis: {
      type: 'value'
    },
    legend:{
      show:true
    },
    series:drivingDataList
  })

},[drivingDataList])

  return (
    <div>
    <ReactApexChart
      options={options}
      series={drivingDataList}
      type="line"
      height="430px"
    />
    {/* <ReactEcharts style={{ height: "395px" }} option={multiLineData} /> */}
    </div>
  )
}

export default DrivingBehaviour
