// import { Document, Image, Page, PDFViewer, Rect, StyleSheet, Svg, Text, View } from '@react-pdf/renderer';
// import React from 'react'
// import PdfHeader from '../Components/pdfHeader';
// import PdfHeading from '../Components/pdfHeading';
// import PdfFooter from '../Components/pdfFooter';
// import { useLocation } from 'react-router-dom';
// import UtilDateTime from '../../../util/UtilDateTime';
// import ValueFormat from '../../../util/ValueFormat';

// const styles = StyleSheet.create({
//     pdfViewer: {
//         height: "96%",
//         width: "100%",
//         marginTop: "20px",
//         marginBottom: "5px",
//     },
//     document: {
//         marginTop: "15px",
//         marginBottom: "5px",
//     },
//     page: {
//         width: "100%",
//     },
//     viewWithColor: {
//         backgroundColor: "#615AE41A",
//         marginTop: 10,
//         marginBottom: 5,
//         padding: 15,
//         paddingTop: 10,
//         borderRadius: 10
//     },
//     heading: {
//         fontSize: 11,
//         marginBottom: 5
//     },
//     table: {
//         border: "1px dashed lightgrey",
//         borderBottom: "none",
//         flexDirection: "column",
//     },
//     headerRow: {
//         flexDirection: "row",
//         justifyContent: "center",
//         alignItems: "center",
//         backgroundColor: "#9e98fb"
//     },
//     headerCell: {
//         height: 25,
//         width: "33.33%",
//         justifyContent: "center",
//         borderRight: "1px dashed lightgrey",
//         borderBottom: "1px dashed lightgrey",
//         alignItems: "center",
//     },
//     dataRow: {
//         flexDirection: "row",
//         justifyContent: "center",
//         alignItems: "center",
//     },
//     dataCell: {
//         height: 25,
//         width: "33.33%",
//         borderRight: "1px dashed lightgrey",
//         borderBottom: "1px dashed lightgrey",
//         justifyContent: "center",
//         alignItems: "center",
//     },
//     hheading: {
//         fontSize: "11px",
//         fontFamily: "Helvetica-Bold",
//     },
//     ssubheading: {
//         fontSize: "10px",
//         fontFamily: "Helvetica-Bold",
//     },
//     sssubheading: {
//         fontSize: "10px",
//         fontFamily: "Helvetica-Bold",
//     },
//     bbodytext: {
//         fontSize: "10px",
//     },
// });

// const fontBold = {
//     fontFamily: "Helvetica-Bold",
// };

// const ColouredcategorySummaryPdf = ({
//     safetyPieData,
//     leaderBoardProps,
//     locationDataPdf,
//     rideNamesPerCat,
//     filtersUDCS,
// }) => {

//     const pathname = useLocation();
//     var totalPages = 0;
//     const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

//     let period = "";
//     let startDate = "";
//     let endDate = "";

//     if (filtersUDCS.durationFilter === "CURRENT_YEAR") {
//         period = ValueFormat.smallLettersFormat(filtersUDCS.durationFilter);
//         startDate = UtilDateTime.getCurrentYear(filtersUDCS.startDateTime);
//         endDate = "";
//     } else if (filtersUDCS.durationFilter === "CURRENT_MONTH") {
//         period = ValueFormat.smallLettersFormat(filtersUDCS.durationFilter);
//         startDate = UtilDateTime.getCurrentMonth(filtersUDCS.startDateTime);
//         endDate = "";
//     } else if (filtersUDCS.durationFilter === "CURRENT_WEEK") {
//         period = ValueFormat.smallLettersFormat(filtersUDCS.durationFilter);
//         startDate = UtilDateTime.getCurrentWeek1(filtersUDCS.startDateTime);
//         endDate = "";
//     } else if (filtersUDCS.durationFilter === "CURRENT_DAY") {
//         period = ValueFormat.smallLettersFormat(filtersUDCS.durationFilter);
//         startDate = UtilDateTime.getCurrentDay(filtersUDCS.startDateTime);
//         endDate = "";
//     } else {
//         period = ValueFormat.smallLettersFormat(filtersUDCS.durationFilter);
//         startDate = filtersUDCS.startDateTime.split(" ")[0];
//         endDate = filtersUDCS.endDateTime.split(" ")[0];
//     }

//     const periodFormat = [period, startDate, endDate];
//     var currentDate = new Date();
//     const heading = "User Dashboard (Category Summary)";
//     const reversedDateFormat = currentDate.getDate().toString().padStart(2, "0") + "-" + months[currentDate.getMonth()] + "-" + currentDate.getFullYear();

//     const categories = [
//         { id: "C1", displayName: "Safe Driver (C1)" },
//         { id: "C2", displayName: "Sportive Driver (C2)" },
//         { id: "C3", displayName: "Quiet Safe Driver (C3)" },
//         { id: "C4", displayName: "Reckless Driver (C4)" },
//         { id: "C5", displayName: "Quiet Dangerous Driver (C5)" },
//         { id: "C6", displayName: "Dangerous Driver (C6)" },
//     ];

//     const processSafetyData = (safetyPieData) => {
//         if (!Array.isArray(safetyPieData) || safetyPieData.length === 0) {
//             return categories.map((category) => ({
//                 name: category.displayName,
//                 value: "-",
//                 percentage: "-",
//             }));
//         }
//         const dataMap = safetyPieData?.reduce((acc, { name, value }) => {
//             acc[name] = value;
//             return acc;
//         }, {});

//         const total = safetyPieData?.reduce((acc, { value }) => acc + value, 0);

//         return categories.map((category) => ({
//             name: category.displayName,
//             value: dataMap[category.id] !== undefined ? dataMap[category.id] : "-",
//             percentage:
//                 total > 0 && dataMap[category.id] !== undefined
//                     ? ((dataMap[category.id] / total) * 100).toFixed(2) + "%"
//                     : "-",
//         }));
//     };

//     const fifthTabledata = {
//         columns: [
//             { title: "Ride", width: "25%" },
//             { title: "Risk", width: "25%" },
//             { title: "Driving Score", width: "50%" },
//         ],
//         rowsData: leaderBoardProps?.data?.map((item) => ({
//             rideName: item.rideName,
//             risk: item.riskCount,
//             drivingScore: item.score,
//         })),
//     };

//     const safetyPie = processSafetyData(safetyPieData);

//     // const generateRows = (data) => {
//     //     let maxRowCountPerPage = 48;
//     //     const pages = [];
//     //     let currentPageContent = [];
//     //     let currentRowCount = 0;

//     //     data.forEach((item, index) => {
//     //         let itemRowCount;
//     //         if (item.rideNameList.length >= 40) {
//     //             itemRowCount = Math.ceil(40 / 8);
//     //         } else {
//     //             itemRowCount = Math.ceil(item.rideNameList.length / 8);
//     //         }

//     //         currentRowCount += itemRowCount + 2;

//     //         if (currentRowCount > maxRowCountPerPage) {
//     //             pages.push(currentPageContent);
//     //             currentPageContent = [];
//     //             currentRowCount = 0;
//     //         }

//     //         currentPageContent.push(
//     //             <View key={`additional_${index}`} style={[styles.viewWithColor, { marginHorizontal: 30 }]}>
//     //                 <View
//     //                     style={{
//     //                         flexDirection: "column",
//     //                         marginTop: 5,
//     //                         paddingBottom: 5,
//     //                         borderBottom: "1px solid black",
//     //                     }}
//     //                     key={index}
//     //                 >
//     //                     <View
//     //                         style={{
//     //                             display: "flex",
//     //                             flexDirection: "row",
//     //                             justifyContent: "space-between",
//     //                             alignItems: "center",
//     //                         }}
//     //                     >
//     //                         <Text style={{ fontSize: 10 }}>Category: {item.name}({item.prefix})</Text>
//     //                         <Text style={{ fontSize: 10 }}>Percentage: {item.percentage}%</Text>
//     //                         <Text style={{ fontSize: 10 }}>
//     //                             Rides: Showing (
//     //                             {item.totalRides && item.totalRides > 0 ? "1" : "0"} -{" "}
//     //                             {item.totalRides && item.totalRides >= 40
//     //                                 ? " 40"
//     //                                 : item.totalRides < 40
//     //                                     ? " " + item.totalRides
//     //                                     : " 0"}
//     //                             ) of {item.totalRides}
//     //                         </Text>
//     //                     </View>
//     //                     {item.rideNameList && item.rideNameList.length > 0 && (
//     //                         <View>
//     //                             {[
//     //                                 ...Array(Math.min(Math.ceil(item.rideNameList.length / 8), 5)),
//     //                             ].map((_, rowIndex) => (
//     //                                 <View
//     //                                     key={rowIndex}
//     //                                     style={{
//     //                                         display: "flex",
//     //                                         flexDirection: "row",
//     //                                         alignItems: "center",
//     //                                         marginTop: 5,
//     //                                     }}
//     //                                 >
//     //                                     {item.rideNameList
//     //                                         .slice(rowIndex * 8, Math.min((rowIndex + 1) * 8, 40)) // Ensure max 30 items
//     //                                         .map((ride, rideIndex) => (
//     //                                             <Text
//     //                                                 style={{
//     //                                                     fontSize: 9,
//     //                                                     border: "0.9px solid black",
//     //                                                     width: rideIndex === 7 ? "12%" : "11.5%",
//     //                                                     marginRight: rideIndex === 7 ? "0px" : "1%",
//     //                                                     textAlign: "center",
//     //                                                     padding: 1,
//     //                                                     paddingTop: 3,
//     //                                                     borderRadius: 10
//     //                                                 }}
//     //                                                 key={rideIndex}
//     //                                             >
//     //                                                 {ride.rideName}
//     //                                             </Text>
//     //                                         ))}
//     //                                 </View>
//     //                             ))}
//     //                         </View>
//     //                     )}
//     //                 </View>
//     //             </View>
//     //         );
//     //     });

//     //     // Add the remaining content to the last page
//     //     if (currentPageContent.length > 0) {
//     //         pages.push(currentPageContent);
//     //     }

//     //     // Wrap each page's content in a Page component
//     //     const formattedPages = pages.map((pageContent, index) => {
//     //         const pageCount = index + 2;
//     //         //   if (pageCount > 1) {
//     //         //     maxRowCountPerPage = 48; // Increase rowCount
//     //         //   }
//     //         return (
//     //             <Page totalPages={totalPages++} key={index} style={styles.page}>
//     //                 <PdfHeader />
//     //                 {pageCount === 2 ? (
//     //                     <>
//     //                         <View
//     //                             style={{
//     //                                 fontSize: "11px",
//     //                                 marginTop: "15px",
//     //                                 display: "flex",
//     //                                 justifyContent: "space-between",
//     //                                 flexDirection: "row",
//     //                                 paddingHorizontal: 30
//     //                             }}
//     //                         >
//     //                             <Text style={[fontBold, { fontSize: 11 }]}>
//     //                                 4. Driver Category
//     //                             </Text>{" "}
//     //                         </View>
//     //                     </>
//     //                 ) : (
//     //                     ""
//     //                 )}
//     //                 {pageContent}
//     //                 <PdfFooter pageNumber={2} totalPages={totalPages} pathname={pathname} />
//     //             </Page>
//     //         );
//     //     });

//     //     return formattedPages;
//     // };


//     const generateRows = (data) => {
//         let maxRowCountPerPage = 48;
//         const pages = [];
//         let currentPageContent = [];
//         let currentRowCount = 0;
//         let currentPageNumber = 1;

//         data.forEach((item, index) => {
//             let itemRowCount;
//             if (item.rideNameList.length >= 40) {
//                 itemRowCount = Math.ceil(40 / 8);
//             } else {
//                 itemRowCount = Math.ceil(item.rideNameList.length / 8);
//             }

//             currentRowCount += itemRowCount + 2;

//             if (currentRowCount > maxRowCountPerPage) {
//                 pages.push({ content: currentPageContent, pageNumber: currentPageNumber });
//                 currentPageContent = [];
//                 currentRowCount = 0;
//                 currentPageNumber++;
//             }

//             currentPageContent.push(
//                 <View key={`additional_${index}`} style={[styles.viewWithColor, { marginHorizontal: 30 }]}>
//                     <View
//                         style={{
//                             flexDirection: "column",
//                             marginTop: 5,
//                             paddingBottom: 5,
//                             borderBottom: "1px solid black",
//                         }}
//                         key={index}
//                     >
//                         <View
//                             style={{
//                                 display: "flex",
//                                 flexDirection: "row",
//                                 justifyContent: "space-between",
//                                 alignItems: "center",
//                             }}
//                         >
//                             <Text style={{ fontSize: 10 }}>Category: {item.name}({item.prefix})</Text>
//                             <Text style={{ fontSize: 10 }}>Percentage: {item.percentage}%</Text>
//                             <Text style={{ fontSize: 10 }}>
//                                 Rides: Showing (
//                                 {item.totalRides && item.totalRides > 0 ? "1" : "0"} -{" "}
//                                 {item.totalRides && item.totalRides >= 40
//                                     ? " 40"
//                                     : item.totalRides < 40
//                                         ? " " + item.totalRides
//                                         : " 0"}
//                                 ) of {item.totalRides}
//                             </Text>
//                         </View>
//                         {item.rideNameList && item.rideNameList.length > 0 && (
//                             <View>
//                                 {[
//                                     ...Array(Math.min(Math.ceil(item.rideNameList.length / 8), 5)),
//                                 ].map((_, rowIndex) => (
//                                     <View
//                                         key={rowIndex}
//                                         style={{
//                                             display: "flex",
//                                             flexDirection: "row",
//                                             alignItems: "center",
//                                             marginTop: 5,
//                                         }}
//                                     >
//                                         {item.rideNameList
//                                             .slice(rowIndex * 8, Math.min((rowIndex + 1) * 8, 40)) // Ensure max 30 items
//                                             .map((ride, rideIndex) => (
//                                                 <Text
//                                                     style={{
//                                                         fontSize: 9,
//                                                         border: "0.9px solid black",
//                                                         width: rideIndex === 7 ? "12%" : "11.5%",
//                                                         marginRight: rideIndex === 7 ? "0px" : "1%",
//                                                         textAlign: "center",
//                                                         padding: 1,
//                                                         paddingTop: 3,
//                                                         borderRadius: 10
//                                                     }}
//                                                     key={rideIndex}
//                                                 >
//                                                     {ride.rideName}
//                                                 </Text>
//                                             ))}
//                                     </View>
//                                 ))}
//                             </View>
//                         )}
//                     </View>
//                 </View>
//             );
//         });

//         // Add the remaining content to the last page
//         if (currentPageContent.length > 0) {
//             pages.push({ content: currentPageContent, pageNumber: currentPageNumber });
//         }

//         // Wrap each page's content in a Page component
//         const formattedPages = pages.map((page, index) => (
//             <Page key={index} style={styles.page}>
//                 <PdfHeader />
//                 {page.pageNumber === 1 && (
//                     <View
//                         style={{
//                             fontSize: "11px",
//                             marginTop: "15px",
//                             display: "flex",
//                             justifyContent: "space-between",
//                             flexDirection: "row",
//                             paddingHorizontal: 30
//                         }}
//                     >
//                         <Text style={[fontBold, { fontSize: 11 }]}>
//                             4. Driver Category
//                         </Text>
//                     </View>
//                 )}
//                 {page.content}
//                 <PdfFooter pageNumber={page.pageNumber + 1} totalPages={pages.length + 1} pathname={pathname} />
//             </Page>
//         ));

//         return formattedPages;
//     };
//     const RenderedPages = generateRows(rideNamesPerCat);


//     return (
//         <React.Fragment>
//             <PDFViewer style={styles.pdfViewer}>
//                 <Document style={styles.document}>

//                     <Page style={styles.page}>

//                         <PdfHeader />
//                         <View style={{ paddingHorizontal: 30, marginTop: 10, marginBottom: 10 }}>
//                             <PdfHeading heading={heading} periodFormat={periodFormat} reversedDateFormat={reversedDateFormat} />
//                             <View style={styles.viewWithColor}>
//                                 <Text style={styles.heading}>1. Safety Category</Text>

//                                 <View style={styles.table}>
//                                     <View style={[fontBold, styles.headerRow]}>
//                                         <View
//                                             style={[
//                                                 styles.headerCell,
//                                                 styles.sssubheading,
//                                                 { alignItems: "flex-start", paddingLeft: "20px" },
//                                             ]}
//                                         >
//                                             <Text>Driver Category</Text>
//                                         </View>
//                                         <View style={[styles.headerCell, styles.sssubheading]}>
//                                             <Text>Value</Text>
//                                         </View>
//                                         <View style={[styles.headerCell, styles.sssubheading]}>
//                                             <Text>Percentage</Text>
//                                         </View>
//                                     </View>
//                                     {safetyPie.map((item, index) => (
//                                         <View key={index} style={styles.dataRow}>
//                                             <View
//                                                 style={[
//                                                     styles.dataCell,
//                                                     styles.bbodytext,
//                                                     { alignItems: "flex-start", paddingLeft: "20px" },
//                                                 ]}
//                                             >
//                                                 <Text>{item.name}</Text>
//                                             </View>
//                                             <View
//                                                 style={[
//                                                     styles.dataCell,
//                                                     styles.bbodytext,
//                                                     { alignItems: "flex-end", paddingRight: "73px" },
//                                                 ]}
//                                             >
//                                                 <Text>{item.value}</Text>
//                                             </View>
//                                             <View
//                                                 style={[
//                                                     styles.dataCell,
//                                                     styles.bbodytext,
//                                                     { alignItems: "flex-end", paddingRight: "55px" },
//                                                 ]}
//                                             >
//                                                 <Text>{item.percentage}</Text>
//                                             </View>
//                                         </View>
//                                     ))}
//                                 </View>
//                             </View>
//                             <View style={styles.viewWithColor}>
//                                 <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
//                                     <Text style={styles.heading}>2. Leaderboard <Text style={{ fontSize: '9px', marginBottom: 2 }}>(Last 5 Rides)</Text></Text>
//                                     <View>
//                                         <Text style={[styles.bbodytext, { textAlign: "right" }]}>
//                                             Showing for{" "}
//                                             {(() => {
//                                                 switch (
//                                                 // ValueFormat.capitalizeFirstLetterAndFormat(
//                                                 leaderBoardProps.filter
//                                                 ) {
//                                                     // )
//                                                     case "allLeaderBoard":
//                                                         return "All";
//                                                     case "workLeaderBoard":
//                                                         return "Work";
//                                                     case "personalLeaderBoard":
//                                                         return "Personal";
//                                                 }
//                                             })()}
//                                         </Text>
//                                     </View>
//                                 </View>

//                                 <View
//                                     style={{
//                                         borderTop: "1px dashed lightgrey",
//                                     }}
//                                 >
//                                     <View style={{ flexDirection: "row", height: "25px" }}>
//                                         {fifthTabledata.columns.map((column, colIndex) => (
//                                             <View
//                                                 key={colIndex}
//                                                 style={{
//                                                     width: column.width,
//                                                     borderRight: "1px dashed lightgrey",
//                                                     borderLeft:
//                                                         colIndex === 0 ? "1px dashed lightgrey" : "none",
//                                                     borderBottom: "1px dashed lightgrey",
//                                                     display: "flex",
//                                                     justifyContent: "center",
//                                                     alignItems: "center",
//                                                     fontSize: "10px",
//                                                     backgroundColor: "#9e98fb"
//                                                 }}
//                                             >
//                                                 <Text style={fontBold}>{column.title}</Text>
//                                             </View>
//                                         ))}
//                                     </View>

//                                     {Array.from({ length: 5 }).map((_, rowIndex) => {
//                                         const rowdata = fifthTabledata?.rowsData?.[rowIndex] || {};
//                                         return (
//                                             <View
//                                                 key={rowIndex}
//                                                 style={{
//                                                     flexDirection: "row",
//                                                     height: 25,
//                                                     borderBottom:
//                                                         rowIndex === 4
//                                                             ? "1px dashed lightgrey"
//                                                             : "1px dashed lightgrey",
//                                                 }}
//                                             >
//                                                 {fifthTabledata.columns.map((column, colIndex) => (
//                                                     <View
//                                                         key={colIndex}
//                                                         style={{
//                                                             width: column.width,
//                                                             borderLeft:
//                                                                 colIndex === 0 ? "1px dashed lightgrey" : "none",
//                                                             borderRight:
//                                                                 colIndex === 2
//                                                                     ? "1px dashed lightgrey"
//                                                                     : "1px dashed lightgrey",
//                                                             display: "flex",
//                                                             justifyContent: "center",
//                                                             alignItems: "center",
//                                                             fontSize: 10,
//                                                         }}
//                                                     >
//                                                         {colIndex === 2 ? (
//                                                             <View
//                                                                 style={{
//                                                                     width: "100%",
//                                                                     flexDirection: "row",
//                                                                     alignItems: "center",
//                                                                 }}
//                                                             >
//                                                                 <View
//                                                                     style={{
//                                                                         width: "75%",
//                                                                         flexDirection: "row",
//                                                                         justifyContent: "center",
//                                                                     }}
//                                                                 >
//                                                                     {rowdata.drivingScore !== undefined ? (
//                                                                         <View style={{ width: "75%" }}>
//                                                                             <Svg viewBox="0 0 700 20">
//                                                                                 <Rect
//                                                                                     x="0"
//                                                                                     rx="2"
//                                                                                     ry="2"
//                                                                                     width="100%"
//                                                                                     height="25"
//                                                                                     fill="rgb(0, 149, 255)"
//                                                                                 />
//                                                                                 <Rect
//                                                                                     x="0"
//                                                                                     rx="2"
//                                                                                     ry="2"
//                                                                                     width={`${rowdata.drivingScore || 0
//                                                                                         }%`}
//                                                                                     height="25"
//                                                                                     fill="#E4A11B"
//                                                                                 />
//                                                                             </Svg>
//                                                                         </View>
//                                                                     ) : (
//                                                                         <Text
//                                                                             style={{
//                                                                                 textAlign: "center",
//                                                                                 marginRight: "20%",
//                                                                             }}
//                                                                         >
//                                                                             -
//                                                                         </Text>
//                                                                     )}
//                                                                 </View>
//                                                                 <View style={{ width: "25%" }}>
//                                                                     <Text style={{ fontSize: 11 }}>
//                                                                         {rowdata.rideName !== undefined ||
//                                                                             (rowdata.rideName !== null &&
//                                                                                 rowdata.drivingScore)
//                                                                             ? rowdata.drivingScore
//                                                                             : null}
//                                                                     </Text>
//                                                                 </View>
//                                                             </View>
//                                                         ) : (
//                                                             <Text>
//                                                                 {colIndex === 1
//                                                                     ? rowdata.risk !== undefined
//                                                                         ? rowdata.risk
//                                                                         : "-"
//                                                                     : rowdata.rideName !== undefined
//                                                                         ? rowdata.rideName
//                                                                         : "-"}
//                                                             </Text>
//                                                         )}
//                                                     </View>
//                                                 ))}
//                                             </View>
//                                         );
//                                     })}
//                                 </View>
//                             </View>

//                             <View style={styles.viewWithColor}>
//                                 <Text style={styles.heading}>3. Vehicle Last Location</Text>

//                                 <View
//                                     style={{
//                                         borderRight: "1px dashed lightgrey",
//                                         borderTop: "1px dashed lightgrey",
//                                         height: "90px",
//                                         width: "100%",
//                                     }}
//                                 >
//                                     <View
//                                         style={{
//                                             flexDirection: "row",
//                                             height: "35x",
//                                             width: "100%",
//                                             backgroundColor: "#9e98fb"

//                                         }}
//                                     >
//                                         <View
//                                             style={{
//                                                 borderBottom: "1px dashed lightgrey",
//                                                 borderLeft: "1px dashed lightgrey",
//                                                 width: "15%",
//                                                 flexDirection: "row",
//                                                 justifyContent: "center",
//                                                 alignItems: "center",
//                                             }}
//                                         >
//                                             <Text style={styles.sssubheading}>Last Ride</Text>
//                                         </View>
//                                         <View
//                                             style={{
//                                                 borderBottom: "1px dashed lightgrey",
//                                                 borderLeft: "1px dashed lightgrey",
//                                                 width: "25%",
//                                                 flexDirection: "row",
//                                                 justifyContent: "center",
//                                                 alignItems: "center",
//                                             }}
//                                         >
//                                             <Text style={styles.sssubheading}>Driving Score</Text>
//                                         </View>
//                                         <View
//                                             style={{
//                                                 borderBottom: "1px dashed lightgrey",
//                                                 borderLeft: "1px dashed lightgrey",
//                                                 width: "20%",
//                                                 flexDirection: "row",
//                                                 justifyContent: "center",
//                                                 alignItems: "center",
//                                             }}
//                                         >
//                                             <Text style={styles.sssubheading}>Geo-Location</Text>
//                                         </View>
//                                         <View
//                                             style={{
//                                                 borderBottom: "1px dashed lightgrey",
//                                                 borderLeft: "1px dashed lightgrey",
//                                                 width: "40%",
//                                                 flexDirection: "row",
//                                                 justifyContent: "center",
//                                                 alignItems: "center",
//                                             }}
//                                         >
//                                             <Text style={styles.sssubheading}>Location</Text>
//                                         </View>
//                                     </View>
//                                     <View
//                                         style={{
//                                             flexDirection: "row",
//                                             height: "100px",
//                                             width: "100%",
//                                         }}
//                                     >
//                                         <View
//                                             style={{
//                                                 borderBottom: "1px dashed lightgrey",
//                                                 borderLeft: "1px dashed lightgrey",
//                                                 width: "15%",
//                                                 flexDirection: "row",
//                                                 justifyContent: "center",
//                                                 alignItems: "center",
//                                             }}
//                                         >
//                                             <Text style={styles.bbodytext}>
//                                                 {locationDataPdf.deviceName && locationDataPdf.deviceName}
//                                                 {!locationDataPdf.deviceName && "-"}
//                                             </Text>
//                                         </View>
//                                         <View
//                                             style={{
//                                                 borderBottom: "1px dashed lightgrey",
//                                                 borderLeft: "1px dashed lightgrey",
//                                                 width: "25%",
//                                                 flexDirection: "row",
//                                                 justifyContent: "center",
//                                                 alignItems: "center",
//                                             }}
//                                         >
//                                             <View
//                                                 style={{
//                                                     width: "100%",
//                                                     flexDirection: "row",
//                                                     alignItems: "center",
//                                                     justifyContent: "center",
//                                                 }}
//                                             >
//                                                 {locationDataPdf.deviceName !== undefined ||
//                                                     (locationDataPdf.deviceName !== null &&
//                                                         locationDataPdf.drivingScore) ? (
//                                                     <>
//                                                         <View style={{ width: "70%" }}>
//                                                             <Svg viewBox="0 0 700 40">
//                                                                 <Rect
//                                                                     x="0"
//                                                                     rx="2"
//                                                                     ry="2"
//                                                                     width="100%"
//                                                                     height="40"
//                                                                     fill="#D3D3D3"
//                                                                 />
//                                                                 <Rect
//                                                                     x="0"
//                                                                     rx="2"
//                                                                     ry="2"
//                                                                     width={`${locationDataPdf.drivingScore}%`}
//                                                                     height="40"
//                                                                     fill="#6b6fc8"
//                                                                 />
//                                                             </Svg>
//                                                         </View>
//                                                         <View style={{ width: "20%", textAlign: "center" }}>
//                                                             <Text style={styles.bbodytext}>
//                                                                 {`${locationDataPdf.drivingScore}%`}
//                                                             </Text>
//                                                         </View>
//                                                     </>
//                                                 ) : (
//                                                     <Text style={styles.bbodytext}>-</Text>
//                                                 )}
//                                             </View>
//                                         </View>
//                                         <View
//                                             style={{
//                                                 borderBottom: "1px dashed lightgrey",
//                                                 borderLeft: "1px dashed lightgrey",
//                                                 width: "20%",
//                                                 flexDirection: "row",
//                                                 justifyContent: "center",
//                                                 alignItems: "center",
//                                             }}
//                                         >
//                                             {locationDataPdf.latitude !== undefined &&
//                                                 locationDataPdf.longitude !== undefined ? (
//                                                 <View>
//                                                     <Text style={styles.bbodytext}>
//                                                         Lat: {locationDataPdf.latitude}
//                                                     </Text>
//                                                     <Text style={styles.bbodytext}>
//                                                         Lon: {locationDataPdf.longitude}
//                                                     </Text>
//                                                 </View>
//                                             ) : (
//                                                 <Text style={styles.bbodytext}>-</Text>
//                                             )}
//                                         </View>

//                                         <View
//                                             style={{
//                                                 borderBottom: "1px dashed lightgrey",
//                                                 borderLeft: "1px dashed lightgrey",
//                                                 width: "40%",
//                                                 flexDirection: "row",
//                                                 justifyContent: "center",
//                                                 alignItems: "center",
//                                             }}
//                                         >
//                                             <Text style={[styles.bbodytext, { padding: "2px" }]}>
//                                                 {locationDataPdf.location !== undefined &&
//                                                     locationDataPdf.location !== null
//                                                     ? locationDataPdf.location
//                                                     : "-"}
//                                             </Text>
//                                         </View>
//                                     </View>
//                                 </View>
//                             </View>

//                         </View>
//                         <PdfFooter pageNumber={1} totalPages={RenderedPages.length + 1} pathname={pathname} />
//                     </Page>

//                     {RenderedPages}


//                 </Document>
//             </PDFViewer>
//         </React.Fragment>
//     )
// }

// export default ColouredcategorySummaryPdf;

import { Document, Image, Page, PDFViewer, Rect, StyleSheet, Svg, Text, View } from '@react-pdf/renderer';
import React from 'react'
import PdfHeader from '../Components/pdfHeaderV1';
import PdfHeading from '../Components/pdfHeading';
import PdfFooter from '../Components/pdfFooterV1';
import { useLocation } from 'react-router-dom';
import UtilDateTime from '../../../util/UtilDateTime';
import ValueFormat from '../../../util/ValueFormat';
import { globalPDF } from '../../../assets/css/commonCssTextForPdf';

const styles = StyleSheet.create({
    pdfViewer: {
        height: "96%",
        width: "100%",
        marginTop: "20px",
        marginBottom: "5px",
    },
    document: {
        marginTop: "15px",
        marginBottom: "5px",
    },
    page: {
        width: "100%",
    },
    viewWithColor: {
        border: '1px solid lightgrey',
        marginTop: 10,
        marginBottom: 5,
        padding: 15,
        paddingTop: 10,
        borderRadius: 10
    },
    heading: {
        fontSize: 11,
        marginBottom: 5
    },
    table: {
        border: "1px solid lightgrey",
        borderBottom: "none",
        flexDirection: "column",
    },
    headerRow: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: globalPDF.GREY_COLOR
    },
    headerCell: {
        height: 25,
        width: "33.33%",
        justifyContent: "center",
        borderRight: "1px solid lightgrey",
        borderBottom: "1px solid lightgrey",
        alignItems: "center",
    },
    dataRow: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    dataCell: {
        height: 25,
        width: "33.33%",
        borderRight: "1px solid lightgrey",
        borderBottom: "1px solid lightgrey",
        justifyContent: "center",
        alignItems: "center",
    },
    hheading: {
        fontSize: "11px",
        fontFamily: "Helvetica-Bold",
    },
    ssubheading: {
        fontSize: "10px",
        fontFamily: "Helvetica-Bold",
    },
    sssubheading: {
        fontSize: "10px",
        fontFamily: "Helvetica-Bold",
    },
    bbodytext: {
        fontSize: "10px",
    },
});

const fontBold = {
    fontFamily: "Helvetica-Bold",
};

const ColouredcategorySummaryPdf = ({
    safetyPieData,
    leaderBoardProps,
    locationDataPdf,
    rideNamesPerCat,
    filtersUDCS,
}) => {

    const pathname = useLocation();
    var totalPages = 0;
    const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

    let period = "";
    let startDate = "";
    let endDate = "";

    if (filtersUDCS.durationFilter === "CURRENT_YEAR") {
        period = ValueFormat.smallLettersFormat(filtersUDCS.durationFilter);
        startDate = UtilDateTime.getCurrentYear(filtersUDCS.startDateTime);
        endDate = "";
    } else if (filtersUDCS.durationFilter === "CURRENT_MONTH") {
        period = ValueFormat.smallLettersFormat(filtersUDCS.durationFilter);
        startDate = UtilDateTime.getCurrentMonth(filtersUDCS.startDateTime);
        endDate = "";
    } else if (filtersUDCS.durationFilter === "CURRENT_WEEK") {
        period = ValueFormat.smallLettersFormat(filtersUDCS.durationFilter);
        startDate = UtilDateTime.getCurrentWeek1(filtersUDCS.startDateTime);
        endDate = "";
    } else if (filtersUDCS.durationFilter === "CURRENT_DAY") {
        period = ValueFormat.smallLettersFormat(filtersUDCS.durationFilter);
        startDate = UtilDateTime.getCurrentDay(filtersUDCS.startDateTime);
        endDate = "";
    } else {
        period = ValueFormat.smallLettersFormat(filtersUDCS.durationFilter);
        startDate = filtersUDCS.startDateTime.split(" ")[0];
        endDate = filtersUDCS.endDateTime.split(" ")[0];
    }

    const periodFormat = [period, startDate, endDate];
    var currentDate = new Date();
    const heading = "User Dashboard (Category Summary)";
    const reversedDateFormat = currentDate.getDate().toString().padStart(2, "0") + "-" + months[currentDate.getMonth()] + "-" + currentDate.getFullYear();

    const categories = [
        { id: "C1", displayName: "Safe Driver (C1)" },
        { id: "C2", displayName: "Sportive Driver (C2)" },
        { id: "C3", displayName: "Quiet Safe Driver (C3)" },
        { id: "C4", displayName: "Reckless Driver (C4)" },
        { id: "C5", displayName: "Quiet Dangerous Driver (C5)" },
        { id: "C6", displayName: "Dangerous Driver (C6)" },
    ];

    const processSafetyData = (safetyPieData) => {
        if (!Array.isArray(safetyPieData) || safetyPieData.length === 0) {
            return categories.map((category) => ({
                name: category.displayName,
                value: "-",
                percentage: "-",
            }));
        }
        const dataMap = safetyPieData?.reduce((acc, { name, value }) => {
            acc[name] = value;
            return acc;
        }, {});

        const total = safetyPieData?.reduce((acc, { value }) => acc + value, 0);

        return categories.map((category) => ({
            name: category.displayName,
            value: dataMap[category.id] !== undefined ? dataMap[category.id] : "-",
            percentage:
                total > 0 && dataMap[category.id] !== undefined
                    ? ((dataMap[category.id] / total) * 100).toFixed(2) + "%"
                    : "-",
        }));
    };

    const fifthTabledata = {
        columns: [
            { title: "Ride", width: "25%" },
            { title: "Risk", width: "25%" },
            { title: "Driving Score", width: "50%" },
        ],
        rowsData: leaderBoardProps?.data?.map((item) => ({
            rideName: item.rideName,
            risk: item.riskCount,
            drivingScore: item.score,
        })),
    };

    const safetyPie = processSafetyData(safetyPieData);

    const generateRows = (data) => {
        let maxRowCountPerPage = 48;
        const pages = [];
        let currentPageContent = [];
        let currentRowCount = 0;
        let currentPageNumber = 1;

        data.forEach((item, index) => {
            let itemRowCount;
            if (item.rideNameList.length >= 80) {
                itemRowCount = Math.ceil(80 / 16);
            } else {
                itemRowCount = Math.ceil(item.rideNameList.length / 16);
            }

            currentRowCount += itemRowCount + 2;

            if (currentRowCount > maxRowCountPerPage) {
                pages.push({ content: currentPageContent, pageNumber: currentPageNumber });
                currentPageContent = [];
                currentRowCount = 0;
                currentPageNumber++;
            }

            currentPageContent.push(
                <View key={`additional_${index}`} style={styles.viewWithColor}>
                    <View
                        style={{
                            flexDirection: "column",
                            marginTop: 5,
                            paddingBottom: 5,
                        }}
                        key={index}
                    >
                        <View
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Text style={{ fontSize: 10 }}>Category: {item.name}({item.prefix})</Text>
                            <Text style={{ fontSize: 10 }}>Percentage: {item.percentage}%</Text>
                            <Text style={{ fontSize: 10 }}>
                                Rides: Showing (
                                {item.totalRides && item.totalRides > 0 ? "1" : "0"} -{" "}
                                {item.totalRides && item.totalRides >= 80
                                    ? " 80"
                                    : item.totalRides < 80
                                        ? " " + item.totalRides
                                        : " 0"}
                                ) of {item.totalRides}
                            </Text>
                        </View>
                        {item.rideNameList && item.rideNameList.length > 0 && (
                            <View>
                                {[
                                    ...Array(Math.min(Math.ceil(item.rideNameList.length / 16), 5)),
                                ].map((_, rowIndex) => (
                                    <View
                                        key={rowIndex}
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            marginTop: 5,
                                        }}
                                    >
                                        {item.rideNameList
                                            .slice(rowIndex * 16, Math.min((rowIndex + 1) * 16, 80)) // Ensure max 30 items
                                            .map((ride, rideIndex) => (
                                                <Text
                                                    style={{
                                                        fontSize: 9,
                                                        border: "0.9px solid black",
                                                        width: rideIndex === 15 ? "12%" : "11.5%",
                                                        marginRight: rideIndex === 15 ? "0px" : "1%",
                                                        textAlign: "center",
                                                        padding: 1,
                                                        paddingTop: 3,
                                                        borderRadius: 10
                                                    }}
                                                    key={rideIndex}
                                                >
                                                    {ride.rideName}
                                                </Text>
                                            ))}
                                    </View>
                                ))}
                            </View>
                        )}
                    </View>
                </View>
            );
        });

        // Add the remaining content to the last page
        if (currentPageContent.length > 0) {
            pages.push({ content: currentPageContent, pageNumber: currentPageNumber });
        }

        // Wrap each page's content in a Page component
        const formattedPages = pages.map((page, index) => (
            // <Page key={index} style={styles.page}>
            //     <PdfHeader />
            <View key={index}>
                {page.pageNumber === 1 && (
                    <View
                        style={{
                            fontSize: "11px",
                            marginTop: "15px",
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row"
                        }}
                    >
                        <Text style={{ fontSize: 11 }}>4. Driver Category</Text>
                    </View>
                )}
                {page.content}
                </View>
            //     <PdfFooter pageNumber={page.pageNumber + 1} totalPages={pages.length + 1} pathname={pathname} />
            // </Page>
        ));

        return formattedPages;
    };
    const RenderedPages = generateRows(rideNamesPerCat);


    return (
        <React.Fragment>
            <PDFViewer style={styles.pdfViewer}>
                <Document style={styles.document}>

                    <Page size={"A2"} style={styles.page}>

                        <PdfHeader />
                        <View style={{ paddingHorizontal: 30, marginTop: 10, marginBottom: 10 }}>
                            <PdfHeading heading={heading} periodFormat={periodFormat} reversedDateFormat={reversedDateFormat} />
                            <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                            <View style={[styles.viewWithColor,{width: '49.5%'}]}>
                                <Text style={styles.heading}>1. Safety Category</Text>

                                <View style={styles.table}>
                                    <View style={[fontBold, styles.headerRow]}>
                                        <View
                                            style={[
                                                styles.headerCell,
                                                styles.sssubheading,
                                                { alignItems: "flex-start", paddingLeft: "20px" },
                                            ]}
                                        >
                                            <Text>Driver Category</Text>
                                        </View>
                                        <View style={[styles.headerCell, styles.sssubheading]}>
                                            <Text>Value</Text>
                                        </View>
                                        <View style={[styles.headerCell, styles.sssubheading]}>
                                            <Text>Percentage</Text>
                                        </View>
                                    </View>
                                    {safetyPie.map((item, index) => (
                                        <View key={index} style={styles.dataRow}>
                                            <View
                                                style={[
                                                    styles.dataCell,
                                                    styles.bbodytext,
                                                    { alignItems: "flex-start", paddingLeft: "20px" },
                                                ]}
                                            >
                                                <Text>{item.name}</Text>
                                            </View>
                                            <View
                                                style={[
                                                    styles.dataCell,
                                                    styles.bbodytext,
                                                    { alignItems: "flex-end", paddingRight: "73px" },
                                                ]}
                                            >
                                                <Text>{item.value}</Text>
                                            </View>
                                            <View
                                                style={[
                                                    styles.dataCell,
                                                    styles.bbodytext,
                                                    { alignItems: "flex-end", paddingRight: "55px" },
                                                ]}
                                            >
                                                <Text>{item.percentage}</Text>
                                            </View>
                                        </View>
                                    ))}
                                </View>
                            </View>

                            <View style={[styles.viewWithColor,{width: '49.5%'}]}>
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Text style={styles.heading}>2. Leaderboard <Text style={{ fontSize: '9px', marginBottom: 2 }}>(Last 5 Rides)</Text></Text>
                                    <View>
                                        <Text style={[styles.bbodytext, { textAlign: "right" }]}>
                                            Showing for{" "}
                                            {(() => {
                                                switch (
                                                // ValueFormat.capitalizeFirstLetterAndFormat(
                                                leaderBoardProps.filter
                                                ) {
                                                    // )
                                                    case "allLeaderBoard":
                                                        return "All";
                                                    case "workLeaderBoard":
                                                        return "Work";
                                                    case "personalLeaderBoard":
                                                        return "Personal";
                                                }
                                            })()}
                                        </Text>
                                    </View>
                                </View>

                                <View
                                    style={{
                                        borderTop: "1px dashed lightgrey",
                                    }}
                                >
                                    <View style={{ flexDirection: "row", height: "25px" }}>
                                        {fifthTabledata.columns.map((column, colIndex) => (
                                            <View
                                                key={colIndex}
                                                style={{
                                                    width: column.width,
                                                    borderRight: "1px dashed lightgrey",
                                                    borderLeft:
                                                        colIndex === 0 ? "1px dashed lightgrey" : "none",
                                                    borderBottom: "1px dashed lightgrey",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    fontSize: "10px",
                                                    backgroundColor: globalPDF.GREY_COLOR
                                                }}
                                            >
                                                <Text style={fontBold}>{column.title}</Text>
                                            </View>
                                        ))}
                                    </View>

                                    {Array.from({ length: 5 }).map((_, rowIndex) => {
                                        const rowdata = fifthTabledata?.rowsData?.[rowIndex] || {};
                                        return (
                                            <View
                                                key={rowIndex}
                                                style={{
                                                    flexDirection: "row",
                                                    height: 25,
                                                    borderBottom:
                                                        rowIndex === 4
                                                            ? "1px dashed lightgrey"
                                                            : "1px dashed lightgrey",
                                                }}
                                            >
                                                {fifthTabledata.columns.map((column, colIndex) => (
                                                    <View
                                                        key={colIndex}
                                                        style={{
                                                            width: column.width,
                                                            borderLeft:
                                                                colIndex === 0 ? "1px dashed lightgrey" : "none",
                                                            borderRight:
                                                                colIndex === 2
                                                                    ? "1px dashed lightgrey"
                                                                    : "1px dashed lightgrey",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            fontSize: 10,
                                                        }}
                                                    >
                                                        {colIndex === 2 ? (
                                                            <View
                                                                style={{
                                                                    width: "100%",
                                                                    flexDirection: "row",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <View
                                                                    style={{
                                                                        width: "75%",
                                                                        flexDirection: "row",
                                                                        justifyContent: "center",
                                                                    }}
                                                                >
                                                                    {rowdata.drivingScore !== undefined ? (
                                                                        <View style={{ width: "75%" }}>
                                                                            <Svg viewBox="0 0 700 20">
                                                                                <Rect
                                                                                    x="0"
                                                                                    rx="2"
                                                                                    ry="2"
                                                                                    width="100%"
                                                                                    height="25"
                                                                                    fill="rgb(0, 149, 255)"
                                                                                />
                                                                                <Rect
                                                                                    x="0"
                                                                                    rx="2"
                                                                                    ry="2"
                                                                                    width={`${rowdata.drivingScore || 0
                                                                                        }%`}
                                                                                    height="25"
                                                                                    fill="#E4A11B"
                                                                                />
                                                                            </Svg>
                                                                        </View>
                                                                    ) : (
                                                                        <Text
                                                                            style={{
                                                                                textAlign: "center",
                                                                                marginRight: "20%",
                                                                            }}
                                                                        >
                                                                            -
                                                                        </Text>
                                                                    )}
                                                                </View>
                                                                <View style={{ width: "25%" }}>
                                                                    <Text style={{ fontSize: 11 }}>
                                                                        {rowdata.rideName !== undefined ||
                                                                            (rowdata.rideName !== null &&
                                                                                rowdata.drivingScore)
                                                                            ? rowdata.drivingScore
                                                                            : null}
                                                                    </Text>
                                                                </View>
                                                            </View>
                                                        ) : (
                                                            <Text>
                                                                {colIndex === 1
                                                                    ? rowdata.risk !== undefined
                                                                        ? rowdata.risk
                                                                        : "-"
                                                                    : rowdata.rideName !== undefined
                                                                        ? rowdata.rideName
                                                                        : "-"}
                                                            </Text>
                                                        )}
                                                    </View>
                                                ))}
                                            </View>
                                        );
                                    })}
                                </View>
                            </View>
                            </View>

                            <View style={styles.viewWithColor}>
                                <Text style={styles.heading}>3. Vehicle Last Location</Text>

                                <View
                                    style={{
                                        borderRight: "1px dashed lightgrey",
                                        borderTop: "1px dashed lightgrey",
                                        height: "90px",
                                        width: "100%",
                                    }}
                                >
                                    <View
                                        style={{
                                            flexDirection: "row",
                                            height: "35x",
                                            width: "100%",
                                            backgroundColor: globalPDF.GREY_COLOR

                                        }}
                                    >
                                        <View
                                            style={{
                                                borderBottom: "1px dashed lightgrey",
                                                borderLeft: "1px dashed lightgrey",
                                                width: "15%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Text style={styles.sssubheading}>Last Ride</Text>
                                        </View>
                                        <View
                                            style={{
                                                borderBottom: "1px dashed lightgrey",
                                                borderLeft: "1px dashed lightgrey",
                                                width: "25%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Text style={styles.sssubheading}>Driving Score</Text>
                                        </View>
                                        <View
                                            style={{
                                                borderBottom: "1px dashed lightgrey",
                                                borderLeft: "1px dashed lightgrey",
                                                width: "20%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Text style={styles.sssubheading}>Geo-Location</Text>
                                        </View>
                                        <View
                                            style={{
                                                borderBottom: "1px dashed lightgrey",
                                                borderLeft: "1px dashed lightgrey",
                                                width: "40%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Text style={styles.sssubheading}>Location</Text>
                                        </View>
                                    </View>
                                    <View
                                        style={{
                                            flexDirection: "row",
                                            height: "100px",
                                            width: "100%",
                                        }}
                                    >
                                        <View
                                            style={{
                                                borderBottom: "1px dashed lightgrey",
                                                borderLeft: "1px dashed lightgrey",
                                                width: "15%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Text style={styles.bbodytext}>
                                                {locationDataPdf.deviceName && locationDataPdf.deviceName}
                                                {!locationDataPdf.deviceName && "-"}
                                            </Text>
                                        </View>
                                        <View
                                            style={{
                                                borderBottom: "1px dashed lightgrey",
                                                borderLeft: "1px dashed lightgrey",
                                                width: "25%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <View
                                                style={{
                                                    width: "100%",
                                                    flexDirection: "row",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                {locationDataPdf.deviceName !== undefined ||
                                                    (locationDataPdf.deviceName !== null &&
                                                        locationDataPdf.drivingScore) ? (
                                                    <>
                                                        <View style={{ width: "70%" }}>
                                                            <Svg viewBox="0 0 700 40">
                                                                <Rect
                                                                    x="0"
                                                                    rx="2"
                                                                    ry="2"
                                                                    width="100%"
                                                                    height="40"
                                                                    fill="#D3D3D3"
                                                                />
                                                                <Rect
                                                                    x="0"
                                                                    rx="2"
                                                                    ry="2"
                                                                    width={`${locationDataPdf.drivingScore}%`}
                                                                    height="40"
                                                                    fill="#6b6fc8"
                                                                />
                                                            </Svg>
                                                        </View>
                                                        <View style={{ width: "20%", textAlign: "center" }}>
                                                            <Text style={styles.bbodytext}>
                                                                {`${locationDataPdf.drivingScore}%`}
                                                            </Text>
                                                        </View>
                                                    </>
                                                ) : (
                                                    <Text style={styles.bbodytext}>-</Text>
                                                )}
                                            </View>
                                        </View>
                                        <View
                                            style={{
                                                borderBottom: "1px dashed lightgrey",
                                                borderLeft: "1px dashed lightgrey",
                                                width: "20%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            {locationDataPdf.latitude !== undefined &&
                                                locationDataPdf.longitude !== undefined ? (
                                                <View>
                                                    <Text style={styles.bbodytext}>
                                                        Lat: {locationDataPdf.latitude}
                                                    </Text>
                                                    <Text style={styles.bbodytext}>
                                                        Lon: {locationDataPdf.longitude}
                                                    </Text>
                                                </View>
                                            ) : (
                                                <Text style={styles.bbodytext}>-</Text>
                                            )}
                                        </View>

                                        <View
                                            style={{
                                                borderBottom: "1px dashed lightgrey",
                                                borderLeft: "1px dashed lightgrey",
                                                width: "40%",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Text style={[styles.bbodytext, { padding: "2px" }]}>
                                                {locationDataPdf.location !== undefined &&
                                                    locationDataPdf.location !== null
                                                    ? locationDataPdf.location
                                                    : "-"}
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                            </View>

                            {RenderedPages}

                        </View>
                        <PdfFooter pageNumber={1} totalPages={RenderedPages.length + 1} pathname={pathname} />
                    </Page>



                </Document>
            </PDFViewer>
        </React.Fragment>
    )
}

export default ColouredcategorySummaryPdf;

