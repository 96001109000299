import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";
import CountUp from "react-countup";
import {
  SC_LOGIN_USER_FIRST_NAME,
  SC_TOTAL_RIDE_COUNT,
  SC_USER_FIRST_NAME,
  SC_USER_LOGIN_TYPE,
} from "../../../constant/StorageConstant";
import { UDMainDashboardTopRowItems } from "../../../common/data/dashboard";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import ReactEcharts from "echarts-for-react";
import UserDrivingScoreController from "../../../controller/Dashboard/UserDashboardController";
import ValueFormat from "../../../util/ValueFormat";
import YearPopup from "../../ModelPopup/YearPopup";
import DrivingSafetyAreav3 from "./DrivingSafetyAreav3";
import ViewPdf from "../../../components/ExportScreenPdf/indexReact2Pdf";
import UBIController from "../../../controller/UBI Controllers/UBIController";
import Loader from "../../../components/Common/loader";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { UBIPoints } from "./UBIPointsFunctions";
import Mobile from "./UBIDashboardPoints.json";
 
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
 
const UBIDashboardv4 = () => {
  const [showLoader, setShowLoader] = useState(0);
  const currentYear = dayjs().format("YYYY");
  const [startDateTime, setStartDateTime] = useState(
    `${currentYear}-01-01 00:00:00`
  );
  const [endDateTime, setEndDateTime] = useState(
    `${currentYear}-12-31 23:59:59`
  );
  const [drivStyleData, setDrivStyleData] = useState([]);
  const [drivBehavData, setDrivBehavData] = useState([]);
  const [aCatData, setACatData] = useState([]);
  const [sDistData, setSDistData] = useState([]);
  const [sSCData, setSSCData] = useState([]);
  const [mUHData, setMUHData] = useState([]);
  const [mobileUsagePoint, setmobileUsagePoint] = useState("");
  const [topRowStressStrainPoint, setTopRowStressStrainPoint] = useState("");
  const [drivingBehaviorPoint, setDrivingBehaviorPoint] = useState(0);
 
  const [scoreData, setScoreData] = useState([]);
  const [distData, setDistData] = useState([]);
  const [hoursData, setHoursData] = useState([]);
  const [totalkms, setTotalkms] = useState(null);
  const [yearlyDrivingScore, setYearlyDrivingScore] = useState(null);
  console.log(yearlyDrivingScore,"yearlyDrivingScore")
 
  const totalKmSPoint = UBIPoints.calculateDrivingKMSPoint(totalkms,Mobile);
 
  // Helper function to convert month number to text
  const monthToText = (monthNumber) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[parseInt(monthNumber) - 1];
  };
  const filteredScoreData = months.map((month) => {
    const monthData = scoreData.find(
      (item) => monthToText(item.monthNumber) === month
    );
    return monthData ? { value: monthData.overallDrivingScore.toFixed(0) } : null;
  });
 
  const ScoreDistributionData = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    xAxis: [
      {
        type: "category",
        data: months,
        nameLocation: "middle",
        axisLabel: {
          show: true,
        },
        name: "Month",
        nameTextStyle: {
          lineHeight: 40,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        min: 0,
        max: 100,
        interval: 10,
        nameLocation: "middle",
        name: "Score",
        nameTextStyle: {
          lineHeight: 40,
        },
      },
    ],
    grid: { bottom: 40, left: 50, right: 30, top: 30 },
    series: [
      {
        name: "Score",
        type: "bar",
        barWidth: 20,
        data: filteredScoreData,
        label: {
          show: true,
          position: "top",
        },
        emphasis: {
          focus: "series",
        },
        itemStyle: {
          color: function ({ value }) {
            return value >= 0 && value <= 80
              ? "#E21212"
              : value >= 81 && value <= 93
              ? "#DEA602"
              : "#06CD91";
          },
        },
      },
    ],
  };
 
  const filteredDistanceData = months.map((month) => {
    const monthData = distData.find(
      (item) => monthToText(item.monthNumber) === month
    );
    return monthData ? { value: monthData.tKiloMeter } : null;
  });
 
  const KilometerDistributionData = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    yAxis: [
      {
        type: "category",
        data: months,
        nameLocation: "middle",
        name: "Month",
        nameTextStyle: {
          lineHeight: 50,
        },
      },
    ],
    xAxis: [
      {
        type: "value",
        min: 0,
        max: 600,
        interval: 100,
        nameLocation: "middle",
        axisLabel: {
          show: true,
        },
        name: "Kilometer",
        nameTextStyle: {
          lineHeight: 40,
        },
      },
    ],
    grid: { bottom: 40, left: 50, right: 60, top: 30 },
    series: [
      {
        type: "bar",
        barWidth: 20,
        name: "Kilometer",
        data: filteredDistanceData,
        label: {
          show: true,
          position: "right",
        },
        itemStyle: {
          color: "#5b73e8",
        },
        emphasis: {
          focus: "series",
        },
      },
    ],
  };
 
  const filteredHoursData = months.map((month) => {
    const monthData = hoursData.find(
      (item) => monthToText(item.monthNumber) === month
    );
    return monthData ? { value: monthData.totalHours } : null;
  });
 
  const HourDistributionData = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    xAxis: [
      {
        type: "category",
        data: months,
        nameLocation: "middle",
        axisLabel: {
          show: true,
        },
        name: "Month",
        nameTextStyle: {
          lineHeight: 40,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        min: 0,
        max: 100,
        interval: 10,
        nameLocation: "middle",
        name: "Hours",
        nameTextStyle: {
          lineHeight: 40,
        },
      },
    ],
    grid: { bottom: 40, left: 50, right: 30, top: 30 },
    series: [
      {
        type: "bar",
        barWidth: 20,
        name: "Hour",
        data: filteredHoursData,
        label: {
          show: true,
          position: "top",
        },
        itemStyle: {
          color: "#FF6619",
        },
        emphasis: {
          focus: "series",
        },
        tooltip: {
          show: true,
          trigger: "axis",
          formatter: "{c}",
        },
      },
    ],
  };
 
  const getScoreKilometerHour = async () => {
    try {
      setShowLoader((current) => current + 1);
      var result = await UBIController.scoreKilometerHour(
        startDateTime,
        endDateTime
      );
      // console.log("result",result)
      setShowLoader((current) => current - 1);
      var resultJSON = JSON.parse(result);
      // console.log("resultJSON", resultJSON);
      var status = resultJSON.status;
      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        console.log("getScoreKilometerHour", resultJSONData);
 
        let ScoreDist = [];
        let Distance = [];
        let Hours = [];
        let totalKMS = null;
        let realYearlyDrivingScore = null;
        resultJSONData.map((value) => {
          const deviceDetail = ValueFormat.parseAsArray(value.deviceDetail);
          realYearlyDrivingScore = ValueFormat.parseAsString(value.yearlyDrivingScore.overallDrivingScore);
            // console.log(realYearlyDrivingScore);
          deviceDetail.map((data) => {
            const monthNumber = ValueFormat.parseAsString(data.monthNumber);
            const overallDrivingScore = ValueFormat.parseAsString(
              data.overallDrivingScore
            );
            const tKiloMeter = ValueFormat.parseAsString(data.tKiloMeter);
            
            totalKMS += parseFloat(tKiloMeter);
 
            const totalHours = ValueFormat.parseAsString(data.totalHours);
            const roundedTotalHours = parseFloat(totalHours).toFixed(2);
 
            ScoreDist.push({
              monthNumber: monthNumber,
              overallDrivingScore: overallDrivingScore,
            });
            Distance.push({
              monthNumber: monthNumber,
              tKiloMeter: tKiloMeter,
            });
            Hours.push({
              monthNumber: monthNumber,
              totalHours: roundedTotalHours,
            });
          });
        });
        // console.log("totalKMS",totalKMS);
 
        setScoreData(ScoreDist);
        setDistData(Distance);
        setHoursData(Hours);
        setTotalkms(totalKMS);
        setYearlyDrivingScore(realYearlyDrivingScore);
      } else {
        setTotalkms(null);
        setYearlyDrivingScore(null);
        const code = resultJSON.error.code;
        if (code == "E007") {
          console.log("status failed");
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  const colors = [
    "#0AA700",
    "#67C86C",
    "#E9B506",
    "#F6D259",
    "#FF0505",
    "#AF0505",
  ];
  const [sPData, setSPData] = useState([]);
  const SafetyPointData = {
    tooltip: {
      trigger: "item",
      axisPointer: {
        type: "shadow",
      },
      formatter: function (params) {
        if (!sPData || sPData.length === 0) {
          return ""; // Return an empty string if no data
        }
        const data = sPData[params.seriesIndex];
        return `<strong>${data.name}</strong><br/>
                Self Confidence: ${data.selfConfidenceValue}<br/>
                Anticipatory Driving: ${data.anticipateDriveValue}<br/>
                Category: C${data.rideBox}`;
      },
    },
    legend: {
      show: true,
      top: -5,
      data: (sPData || []).map((data) => `C${data.rideBox}`), // Ensure the legend order
    },
    xAxis: [
      {
        type: "value",
        min: 0,
        max: 100,
        interval: 10,
        nameLocation: "middle",
        axisLabel: {
          show: true,
        },
        name: "Anticipatory Driving",
        nameTextStyle: {
          lineHeight: 50,
        },
        splitLine: {
          lineStyle: {
            type: "dashed",
          },
        },
        axisLine: {
          lineStyle: {
            color: "#74788d",
          },
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        min: 0,
        max: 100,
        interval: 10,
        nameLocation: "middle",
        name: "Self Confidence",
        nameTextStyle: {
          lineHeight: 40,
        },
        splitLine: {
          lineStyle: {
            type: "dashed",
          },
        },
        axisLine: {
          lineStyle: {
            color: "#74788d",
          },
        },
      },
    ],
    grid: { bottom: 50, left: 60, right: 40, top: 30 },
    series: [
      ...(sPData || [])
        .sort((a, b) => a.rideBox - b.rideBox) // Sort the data based on rideBox
        .map((data, index) => ({
          type: "scatter",
          symbol: "circle",
          symbolSize: 8,
          data: [[data.anticipateDriveValue, data.selfConfidenceValue]],
          name: `C${data.rideBox}`,
          itemStyle: {
            color: colors[data.rideBox - 1],
          },
        })),
    ],
  };
 
  const [forceRerender, setForceRerender] = useState(false);
  const getSafetyPointData = async () => {
    try {
      var result = await UBIController.getSafetyPoint(
        startDateTime,
        endDateTime
      );
 
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
 
      if (status === "SUCCESS") {
        var resultJSONData = resultJSON.data;
 
        if (resultJSONData && Array.isArray(resultJSONData)) {
          const SPData = resultJSONData.flatMap((value) =>
            value.rideBoxList.map((ride) => ({
              name: ride.name,
              anticipateDriveValue: parseFloat(ride.anticipateDriveValue, 2),
              selfConfidenceValue: parseFloat(ride.selfConfidenceValue, 2),
              rideBox: parseInt(ride.rideBox),
            }))
          );
 
          setSPData(SPData);
        } else {
          console.log("Data is missing or malformed");
          setSPData([]);
        }
      } else {
        console.log("Status Failed");
        setSPData([]);
      }
    } catch (err) {
      console.log(err);
      setSPData([]);
    }
  };
 
  useEffect(() => {
    setForceRerender((prev) => !prev);
  }, [sPData]);
 
  useEffect(() => {
    getScoreKilometerHour();
    getSafetyPointData();
  }, [startDateTime, endDateTime]);
 
  const dynamicData = [
    {
      title: "Driving Score Point",
      overallData: yearlyDrivingScore ? yearlyDrivingScore/10 : 0,
      // row1Title: 'Row 1',
      secondaryData: 567,
      // row2Title: 'Row 2',
      currentMonthGraphData: [50, 40, 30, 20, 10],
      statusColor: "danger",
      currentYearValue: mobileUsagePoint,
      postFix: "units",
      since: "Mobile Usage Point",
    },
    {
      title: "Driving Kms Point",
      overallData: totalKmSPoint,
      // row1Title: 'Row 1',
      secondaryData: 567,
      // row2Title: 'Row 2',
      currentMonthGraphData: [50, 40, 30, 20, 10],
      statusColor: "danger",
      currentYearValue: drivingBehaviorPoint,
      postFix: "units",
      since: "Driving Behavior Point",
    },
    {
      title: "Stress Strain Point",
      // title: "Red Risk Point",
      // overallData: 1,
       overallData: topRowStressStrainPoint,
      // row1Title: 'Row 1',
      secondaryData: 567,
      // row2Title: 'Row 2',
      currentMonthGraphData: [50, 40, 30, 20, 10],
      statusColor: "danger",
      // currentYearValue: totalKmSPoint,
      postFix: "units",
      // since: "Driving Kms Point",
    },
    // {
    //   title: "Stress Strain Point",
    //   overallData: topRowStressStrainPoint,
    //   // row1Title: 'Row 1',
    //   // secondaryData: 567,
    //   // row2Title: 'Row 2',
    //   currentMonthGraphData: [50, 40, 30, 20, 10],
    //   statusColor: "danger",
    //   // currentYearValue: '₹9,111.39',
    //   postFix: "units",
    //   // since:"Your Insurance Amount"
    // },
  ];
 
  const [shouldBreak, setShouldBreak] = useState(
    window.innerWidth >= 1200 && window.innerWidth <= 1375
  );
 
  const [chartWidth, setChartWidth] = useState("98vw");
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 991) {
        setChartWidth("98vw");
      } else {
        setChartWidth("30vw");
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
 
  // Calculate total ride count for each category
  const totalRideCount = ["C1", "C2", "C3", "C4", "C5", "C6"].reduce(
    (acc, categoryKey) => {
      const count = sPData.filter(
        (data) => `C${data.rideBox}` === categoryKey
      ).length;
      acc[categoryKey] = count;
      return acc;
    },
    {}
  );
 
  // Map and display total ride count in the table
  const rideCountRows = Object.keys(totalRideCount).map((categoryKey) => (
    <td key={categoryKey}>{totalRideCount[categoryKey]}</td>
  ));
 
  const getDatafromDrivingSafetyArea = (
    data1,
    data2,
    data3,
    data4,
    data5,
    data6,
    mobileUsagePoint,
    topRowStressStrainPoint,
    drivingBehaviorPoint
  ) => {
    setDrivStyleData(data1);
    setDrivBehavData(data2);
    setACatData(data3);
    setSDistData(data4);
    setSSCData(data5);
    setMUHData(data6);
    setmobileUsagePoint(mobileUsagePoint);
    setTopRowStressStrainPoint(topRowStressStrainPoint);
    setDrivingBehaviorPoint(drivingBehaviorPoint);
  };
  const history = useNavigate();
  const handlePointClick = (params) => {
    // console.log("vvvvv", params);
    if (params.data) {
      history("/ridedetails", {
        state: {
          divisionId: params.data[4],
          userId: params.data[5],
          rideId: params.data[6],
        },
      });
      // localStorage.setItem(SC_LOGIN_USER_FIRST_NAME,params.data[7])
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        {showLoader > 0 && <Loader />}
        <Row className="px-2 d-flex align-items-center">
          <Col xl={4} md={6}>
            <Card>
              <CardBody
                className="d-flex align-items-center justify-content-between"
                style={{ height: "115px" }}
              >
                <div>
                  <h6 className="text-center">Data is showing for the year:</h6>
                </div>
                <div style={{ paddingBottom: "16px" }}>
                  <YearPopup
                    setStartDateTime={setStartDateTime}
                    setEndDateTime={setEndDateTime}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
 
          {/* <Col xl={3} md={6}>
            <Card>
              <CardBody
                className="d-flex align-items-center justify-content-between"
                style={{ height: "115px" }}
              >
                <div>
                  <h6>Usage Based Insurance Score:</h6>
                </div>
                <div
                  data-tooltip-id="ubi"
                  style={{ width: "75px", height: "75px" }}
                >
                  <CircularProgressbar
                    value={90}
                    text="90"
                    styles={buildStyles({
                      width: "20px",
                      textSize: "15px",
                      textColor: "block",
                      textSize: "15px",
                      pathColor:
                        90 >= 0 && 90 <= 80
                          ? "#E21212"
                          : 90 >= 81 && 90 <= 93
                          ? "#DEA602"
                          : "#06CD91",
                      trailColor: "#0095FF",
                    })}
                  />
                </div>
                <ReactTooltip id="ubi" place="top" content="Insurance Score" />
              </CardBody>
            </Card>
          </Col> */}
 
          <Col xl={4} md={6}>
            <Card>
              <CardBody
                className="d-flex align-items-center justify-content-between"
                style={{ height: "115px" }}
              >
                <div>
                  <h6 className="text-center">Yearly Driving Score:</h6>
                </div>
                <div
                  data-tooltip-id="cpb"
                  style={{
                    width: "75px",
                    height: "75px",
                  }}
                >
                  <CircularProgressbar
                    value={yearlyDrivingScore?yearlyDrivingScore:0}
                    text={yearlyDrivingScore?yearlyDrivingScore:"0"}
                    styles={buildStyles({
                      width: "20px",
                      textSize: "15px",
                      textColor: "block",
                      textSize: "15px",
                      pathColor:
                      yearlyDrivingScore >= 0 && yearlyDrivingScore <= 80
                          ? "#E21212"
                          : yearlyDrivingScore >= 81 && yearlyDrivingScore <= 93
                          ? "#DEA602"
                          : "#06CD91",
                      trailColor: "#0095FF",
                    })}
                  />
                </div>
                <ReactTooltip id="cpb" place="top" content="Yearly Score" />
              </CardBody>
            </Card>
          </Col>
 
          <Col xl={4} md={6}>
            <Card>
              <CardBody
                className="d-flex align-items-center justify-content-end pe-2 pe-xl-4"
                style={{ height: "115px" }}
              >
                {/* <div>
                  <ViewPdf
                    filteredScoreData={filteredScoreData}
                    filteredDistanceData={filteredDistanceData}
                    filteredHoursData={filteredHoursData}
                    drivStyleData={drivStyleData}
                    drivBehavData={drivBehavData}
                    aCatData={aCatData}
                    sDistData={sDistData}
                    sSCData={sSCData}
                    mUHData={mUHData}
                    rideCountRows={rideCountRows}
                    sPData={sPData}
                    startDateTime={startDateTime}
                  />
                </div> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
 
        <Row className="mt-3 mb-2">
          {(dynamicData || []).map((widget, key) => (
            <Col xl={4} lg={6} md={6} key={key} className="offset-xl-0.5 mb-2">
              <Card className="card-h-100">
                <CardBody style={{ padding: "10px" }}>
                  <Row className="align-items-center">
                    <Col xs={6}>
                      <div>
                        <span
                          data-tooltip-id={key + 201}
                          className="text-muted mb-3 lh-1 d-block text-truncate"
                        >
                          <ReactTooltip
                            id={key + 201}
                            place="top"
                            content={widget.title}
                          />
                          {widget.title}
                        </span>
                        <h4 className="mb-0">
                          <span className="counter-value">
                            <CountUp
                              start={0}
                              end={widget.overallData}
                              duration={1}
                            />
                          </span>
                          <span
                            style={{
                              fontSize: "10px",
                              fontWeight: "normal",
                            }}
                          >
                            {widget.row1Title}
                          </span>
                        </h4>
                      </div>
                    </Col>
 
                    <Col xs={6}></Col>
                    <ReactTooltip
                      id={key + 1}
                      place="top"
                      content={widget.title}
                      style={{ backgroundColor: "black" }}
                    />
                  </Row>
                  <br />
                  <Row>
                    <Col xl={12} lg={12}>
                      <div className="text-nowrap" style={{ display: "flex" }}>
                        <span
                          className={
                            "badge badge-soft-" +
                            widget.statusColor +
                            " text-" +
                            widget.statusColor +
                            " font-size-14"
                          }
                        >
                          {widget.currentYearValue}
                        </span>
                        {shouldBreak && <br />}
                        <span className="ms-1 text-muted font-size-13">
                          {widget.since}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
 
        <Row className="mt-2">
          {/* <Col lg={4} xs={12}>
            <Card>
              <CardHeader className="cardHeader">
                <h4 className="card-title">Insurance Summary</h4>
              </CardHeader>
              <CardBody
                className="d-flex align-items-center"
                style={{ height: "450px" }}
              >
                <Col xs={6} className="d-flex flex-column align-items-center">
                  <div className="pb-3">
                    <h6>Actual Insurance Amount</h6>
                  </div>
                  <div
                    data-tooltip-id="fd"
                    style={{ width: "150px", height: "150px" }}
                  >
                    <CircularProgressbar
                      value={100}
                      text="₹ 10,00,000"
                      styles={buildStyles({
                        width: "20px",
                        textSize: "12px",
                        textColor: "block",
                        pathColor:
                          90 >= 0 && 90 <= 80
                            ? "#E21212"
                            : 90 >= 81 && 90 <= 93
                            ? "#DEA602"
                            : "#06CD91",
                        trailColor: "#0095FF",
                      })}
                    />
                  </div>
                  <ReactTooltip
                    id="fd"
                    place="top"
                    content="Actual Insurance Amount"
                  />
                </Col>
                <Col xs={6} className="d-flex flex-column align-items-center">
                  <div className="pb-3">
                    <h6>Your Insurance Amount</h6>
                  </div>
                  <div
                    data-tooltip-id="qw"
                    style={{ width: "150px", height: "150px" }}
                  >
                    <CircularProgressbar
                      value={75}
                      text="₹ 7,50,000"
                      styles={buildStyles({
                        width: "20px",
                        textSize: "12px",
                        textColor: "block",
                        pathColor:
                          90 >= 0 && 90 <= 80
                            ? "#E21212"
                            : 90 >= 81 && 90 <= 93
                            ? "#DEA602"
                            : "#06CD91",
                        trailColor: "#0095FF",
                      })}
                    />
                  </div>
                  <ReactTooltip
                    id="qw"
                    place="top"
                    content="Your Insurance Amount"
                  />
                </Col>
              </CardBody>
            </Card>
          </Col> */}
 
          <Col lg={6} xs={12}>
            <Card>
              <CardHeader className="cardHeader">
                <h4 className="card-title">Safety Point</h4>
              </CardHeader>
              <CardBody
                className="d-flex flex-column align-items-center"
                style={{ height: "450px" }}
              >
                <div className="d-flex justify-content-between w-100">
                  <h6 className="ms-4">Unsafe</h6>
                  <h6>Unsafe</h6>
                </div>
                <ReactEcharts
                  key={forceRerender}
                  option={SafetyPointData}
                  style={{ height: "100%", width: chartWidth }}
                  // onEvents={{
                  //   click: handlePointClick,
                  // }}
                />
                <div className="d-flex justify-content-between m-0 p-0 w-100">
                  <h6 className="ms-4">Safe</h6>
                  <h6>Unsafe</h6>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={6} xs={12}>
            <Card>
              <CardHeader className="cardHeader">
                <h4 className="card-title">Safety Point Rides</h4>
              </CardHeader>
              <CardBody style={{ height: "450px" }}>
                <Row className="my-2">
                  <Table responsive bordered>
                    <thead>
                      <tr>
                        <th className="">Category</th>
                        <th className="">C1</th>
                        <th className="">C2</th>
                        <th className="">C3</th>
                        <th className="">C4</th>
                        <th className="">C5</th>
                        <th className="">C6</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Rides</td>
                        {rideCountRows}
                      </tr>
                    </tbody>
                  </Table>
                </Row>
                <Row className="mt-4">
                  <Col xs={1} className="px-0 d-flex justify-content-end">
                    <h6 className="mb-0" style={{ textAlign: "left" }}>
                      C1:
                    </h6>
                  </Col>
                  <Col xs={11} className="d-flex align-items-center px-0">
                    <p
                      className="mb-0 ms-2"
                      style={{
                        textAlign: "justify",
                        fontSize: "12px",
                        marginTop: "-1px",
                      }}
                    >
                      This color indicates the Safe Driver rides in the
                      respective areas.
                    </p>
                  </Col>
                </Row>
                <Row className="my-3">
                  <Col xs={1} className="px-0 d-flex justify-content-end">
                    <h6 className="mb-0" style={{ textAlign: "left" }}>
                      C2:
                    </h6>
                  </Col>
                  <Col xs={11} className="d-flex align-items-center px-0">
                    <p
                      className="mb-0 ms-2"
                      style={{
                        textAlign: "justify",
                        fontSize: "12px",
                        marginTop: "-1px",
                      }}
                    >
                      {" "}
                      This color indicates the Sportive Driver rides in the
                      respective areas.
                    </p>
                  </Col>
                </Row>{" "}
                <Row className="my-3">
                  <Col xs={1} className="px-0 d-flex justify-content-end">
                    <h6 className="mb-0" style={{ textAlign: "left" }}>
                      C3:
                    </h6>
                  </Col>
                  <Col xs={11} className="d-flex align-items-center px-0">
                    <p
                      className="mb-0 ms-2"
                      style={{
                        textAlign: "justify",
                        fontSize: "12px",
                        marginTop: "-1px",
                      }}
                    >
                      {" "}
                      This color indicates the Quite Safe Driver rides in the
                      respective areas.
                    </p>
                  </Col>
                </Row>{" "}
                <Row className="my-3">
                  <Col xs={1} className="px-0 d-flex justify-content-end">
                    <h6 className="mb-0" style={{ textAlign: "left" }}>
                      C4:
                    </h6>
                  </Col>
                  <Col xs={11} className="d-flex align-items-center px-0">
                    <p
                      className="mb-0 ms-2"
                      style={{
                        textAlign: "justify",
                        fontSize: "12px",
                        marginTop: "-1px",
                      }}
                    >
                      {" "}
                      This color indicates the Reckless Driver rides in the
                      respective areas.
                    </p>
                  </Col>
                </Row>{" "}
                <Row className="my-3">
                  <Col xs={1} className="px-0 d-flex justify-content-end">
                    <h6 className="mb-0" style={{ textAlign: "left" }}>
                      C5:
                    </h6>
                  </Col>
                  <Col xs={11} className="d-flex align-items-center px-0">
                    <p
                      className="mb-0 ms-2"
                      style={{
                        textAlign: "justify",
                        fontSize: "12px",
                        marginTop: "-1px",
                      }}
                    >
                      {" "}
                      This color indicates the Quite Dangerous Driver rides in
                      the respective areas.
                    </p>
                  </Col>
                </Row>{" "}
                <Row className="my-3">
                  <Col xs={1} className="px-0 d-flex justify-content-end">
                    <h6 className="mb-0" style={{ textAlign: "left" }}>
                      C6:
                    </h6>
                  </Col>
                  <Col xs={11} className="d-flex align-items-center px-0">
                    <p
                      className="mb-0 ms-2"
                      style={{
                        textAlign: "justify",
                        fontSize: "12px",
                        marginTop: "-1px",
                      }}
                    >
                      {" "}
                      This color indicates the Dangerous Driver rides in the
                      respective areas.
                    </p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
 
        <Row className="mb-4 mt-4">
          <Col lg={4} xs={12}>
            <Card>
              <CardHeader className="cardHeader">
                <h4 className="card-title">Score Distribution</h4>
              </CardHeader>
              <CardBody
                className="d-flex align-items-center"
                style={{ height: "450px" }}
              >
                <ReactEcharts
                  option={ScoreDistributionData}
                  style={{ height: "100%", width: chartWidth }}
                />
              </CardBody>
            </Card>
          </Col>
          <Col lg={4} xs={12}>
            <Card>
              <CardHeader className="cardHeader">
                <h4 className="card-title">Kilometer Distribution</h4>
              </CardHeader>
              <CardBody
                className="d-flex align-items-center"
                style={{ height: "450px" }}
              >
                <ReactEcharts
                  option={KilometerDistributionData}
                  style={{ height: "100%", width: chartWidth }}
                />
              </CardBody>
            </Card>
          </Col>
          <Col lg={4} xs={12}>
            <Card>
              <CardHeader className="cardHeader">
                <h4 className="card-title">Hour Distribution</h4>
              </CardHeader>
              <CardBody
                className="d-flex align-items-center"
                style={{ height: "450px" }}
              >
                <ReactEcharts
                  option={HourDistributionData}
                  style={{ height: "100%", width: chartWidth }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
 
        <DrivingSafetyAreav3
          startYear={startDateTime}
          endYear={endDateTime}
          getDatafromDrivingSafetyArea={getDatafromDrivingSafetyArea}
        />
      </div>
    </React.Fragment>
  );
};
 
export default UBIDashboardv4;
