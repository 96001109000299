import React, { useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ReactApexChart from "react-apexcharts";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table,
  CardHeader,
  CardFooter,
} from "reactstrap";
// import { Row, Col, Card, CardBody, CardHeader, Container } from "reactstrap"

import CountUp from "react-countup";

/** import Mini Widget data */
import { AdminDashboardTopRowItems } from "../../common/data/admin/dashboard";

import RideCountChart from "../Admin/Charts/rideCountChart";
import RiskCountChart from "../Admin/Charts/riskCountChart";
import ValueFormat from "../../util/ValueFormat.js";
import AdminDashboardController from "../../controller/Dashboard/AdminDasahboardController.js";
import DriverAnalysis from "../Admin/Charts/driverAnalysisChart";
import DriverAnalysisSub from "../Admin/Charts/driverAnalysisSubChart";
import LineApexChart from "../Admin/Charts/chartapex";
import OverAllSummary from "../Admin/OverallSummary/overallSummary";
import C1Table from "./Table/c1Table";
import LineBar from "./Charts/c1Chart";
import RadialChart from "./Charts/safetyCategorySummary";
import UsageHistory from "./Charts/usageHistory";
import DrivingBehaviour from "./Charts/multiuserDrivingBehaviour";
import C2Table from "./Table/c2Table";
import C2Chart from "./Charts/c2Chart";
import C3Table from "./Table/c3Table";
import C3Chart from "./Charts/c3Chart";
import C4Table from "./Table/c4Table";
import C4Chart from "./Charts/c4Chart";
import C5Table from "./Table/c5Table";
import C5Chart from "./Charts/c5Chart";
import C6Table from "./Table/c6Table";
import C6Chart from "./Charts/c6Chart";
import C1Chart from "./Charts/c1Chart";
import AdminDailySummeryController from "../../controller/AdminReport/ADDailyReportController.js";
import AdminWeeklySummeryController from "../../controller/AdminReport/ADWeeklyReportController.js";
import { useSelector } from "react-redux";
import { layoutTypes } from "../../constants/layout";
import giftBox from "../../assets/images/giftbox.png";
import Loader from "../../components/Common/loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastService from "../../components/Common/toastService";
import { Link } from "react-router-dom";
import ExportScreenPdf from "../../components/ExportScreenPdf/index.js";
import { displayText } from "../../constant/MessageConstant";
import {
  SC_LOCK_STATUS,
  SC_USER_FIRST_NAME,
} from "../../constant/StorageConstant";
import ViewPdf from "../../components/ExportScreenPdf/indexReact2Pdf.js";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateTimePickerPopup from "../ModelPopup/DateTimePopup.js";
import OverallRiskSummary from "./OverallRiskSummary/overallRiskSummary.js";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import CustomDateFilterMain from "./Charts/customDateFilter.js";
import AdminDrivingCharts from "./AdminDrivingChart/index.js";
import RiskAndRidesAdminDash from "../riskAndRidesAdminPage/riskAndRidesAdminDash.js";
// import RidesKmHrsScoreCBar from "./TrialVersion/RidesKmHrsScoreCBar.js";

const AdminDashboardRideBoxCount = {
  totalRides: 0,
  count: [
    { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 1 },
    { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 2 },
    { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 3 },
    { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 4 },
    { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 5 },
    { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 6 },
  ],
};

const AdminDashboard = () => {
  const {
    layoutType,
    layoutWidth,
    layoutPosition,
    topbarTheme,
    leftSideBarType,
    leftSideBarTheme,
    layoutMode,
  } = useSelector((state) => ({
    layoutType: state.Layout.layoutType,
    layoutWidth: state.Layout.layoutWidth,
    layoutPosition: state.Layout.layoutPosition,
    topbarTheme: state.Layout.topbarTheme,
    leftSideBarType: state.Layout.leftSideBarType,
    leftSideBarTheme: state.Layout.leftSideBarTheme,
    layoutMode: state.Layout.layoutMode,
  }));
  const [ADTopRowItemsTemp, setADTopRowItemsTemp] = useState(
    AdminDashboardTopRowItems
  );
  const [userPercent, setuserPercent] = useState(0);
  
  const [ADRideBoxCount, setADRideBoxCount] = useState(
    AdminDashboardRideBoxCount
  );
  const [rideRiskData, setRideRiskData] = useState({
    rideRiskXaxis: [],
    rideSeries: [],
    riskAmberSeries: [],
    riskRedSeries: [],
    filter: ""
  });
  const [filters, setFilters] = useState({
    durationFilter: "CURRENT_YEAR",
    startDateTime: "",
    endDateTime: ""
})
  const [dailyData, setDailyData] = useState(false);
  const [userUsagePer, setUserUsagePer] = useState([]);
  const [activeDriverData, setActiveDriverData] = useState([]);
  const [activeDriverPie, setActiveDriverPie] = useState([]);
  const [filter, setFilter] = useState("CURRENT_YEAR");
  const [topLineFilter, setTopLineFilter] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [totalSafeRidePer, setTotalSafeRidePer] = useState(0);
  const [totalRidesCount, setTotalRidesCount] = useState(0);
  const [showLoader, setShowLoader] = useState(0);
  const [pdUsageData, setPdUsageData] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [uuStartDateTime, setUUStartDateTime] = useState("");
  const [uuEndDateTime, setUUEndDateTime] = useState("");
  const [uuFilter, setUUFilter] = useState("CURRENT_DAY");
  const [norStartDateTime, setNORStartDateTime] = useState("");
  const [norEndDateTime, setNOREndDateTime] = useState("");
  const [norFilter, setNORFilter] = useState("CURRENT_WEEK");
  const [userFirstName, setUserFirstName] = useState(
    localStorage.getItem(SC_USER_FIRST_NAME)
  );
  const [aggregateData, setAggregateData] = useState({
    totalRides: 0,
    totalKilometer: 0,
    totalHighRiskCount: 0,
    totalAlertDataCount: 0,
    totalMediumRiskCount: 0,
    totalTravelTime: 0
  });
  const [totalDays, setTotalDays] = useState(0);

  useEffect(() => {
    // console.log("loader", showLoader);
  }, [showLoader]);

  const getTotalValues = async () => {
    setShowLoader((current) => current + 1);
    var result = await AdminDashboardController.adminDashboardController(
      filter,
      startDateTime,
      endDateTime
    );
    setShowLoader((current) => current - 1);
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    // console.log("admintopitem:", resultJSON)
    if (status == "SUCCESS") {
      var resultJSONData = resultJSON.data;
      var totalCount = 0;
      var totalRide = 0;
      var safeRide = 0;
      var userCountPercentages = [];
      let overAllRideBoxTotalRidesCount = {
        totalRides: 0,
        count: [
          { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 1 },
          { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 2 },
          { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 3 },
          { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 4 },
          { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 5 },
          { totalRides: 0, rideBox: 0, percentage: 0, rideBox: 6 },
        ],
      };
      let totalDays = 0;
      let totalRides = 0;
      let totalKilometer = 0;
      let totalHighRiskCount = 0;
      let totalAlertDataCount = 0;
      let totalMediumRiskCount = 0;
      let totalTravelTime = 0;
      // console.log("AdminTopRows: " + JSON.stringify(resultJSONData));
      resultJSONData.map((apiResultTemp) => {
        var totalUserCount = parseInt(
          ValueFormat.parseAsInt(apiResultTemp.totalUserCount)
        );
        var activeUserCount = parseInt(
          ValueFormat.parseAsInt(apiResultTemp.activeUserCount)
        );

        var inactiveUserCount = parseInt(
          ValueFormat.parseAsInt(apiResultTemp.inactiveUserCount)
        );
        var unregisteredUserCount = parseInt(
          ValueFormat.parseAsInt(apiResultTemp.unregisteredUserCount)
        );

        var registeredUserCount = parseInt(
          ValueFormat.parseAsInt(apiResultTemp.registeredUserCount)
        );

        totalDays = parseInt(
          ValueFormat.parseAsInt(apiResultTemp.noOfDays)
        );

        const overallRideBoxList = ValueFormat.parseAsArray(
          apiResultTemp.overallRideBoxList
        );
        overallRideBoxList.map((apiData) => {
          var rideBox = ValueFormat.parseAsInt(apiData.rideBox);
          totalRide += ValueFormat.parseAsInt(apiData.totalRides);
          if (rideBox == 1) {
            safeRide = apiData.totalRides;
          }
        });

        const overAllRideBoxCountList = ValueFormat.parseAsArray(
          apiResultTemp.overallRideBoxCountList
        );

        overAllRideBoxCountList.forEach(apiData => {
          totalRides += ValueFormat.parseAsInt(apiData.totalRides);
          totalKilometer += parseFloat(apiData.totalKilometer) == undefined || parseFloat(apiData.totalKilometer) == null ? 0 : parseFloat(apiData.totalKilometer);
          totalHighRiskCount += ValueFormat.parseAsInt(apiData.totalHighRiskCount);
          totalAlertDataCount += ValueFormat.parseAsInt(apiData.totalAlertDataCount);
          totalMediumRiskCount += ValueFormat.parseAsInt(apiData.totalMediumRiskCount);
          totalTravelTime += parseFloat(apiData.totalTravelTime) == undefined || parseFloat(apiData.totalTravelTime) == null ? 0 : parseFloat(apiData.totalTravelTime);
        });

        let overAllTotalRides = 0;
        overAllRideBoxCountList.map((val, i) => {
          overAllTotalRides += ValueFormat.parseAsInt(val.totalRides);
        });
        overAllRideBoxTotalRidesCount.totalRides = overAllTotalRides;
        overAllRideBoxCountList.map((val, i) => {
          overAllRideBoxTotalRidesCount.count[val.rideBox - 1] = {
            ...overAllRideBoxTotalRidesCount.count[val.rideBox],
            totalRides: val.totalRides,
            rideBox: val.rideBox,
            percentage: (
              (ValueFormat.parseAsInt(val.totalRides) /
                overAllRideBoxTotalRidesCount.totalRides) *
              100
            ).toFixed(1),
          };
        });
        setADRideBoxCount(overAllRideBoxTotalRidesCount);
        var totalSafeRideCount = ValueFormat.parseAsInt(safeRide);
        setTotalRidesCount(totalRide);
        setTotalSafeRidePer(
          ValueFormat.parseAsFloat((safeRide / totalRide) * 100),
          0
        );

        userCountPercentages.push(
          activeUserCount,
          inactiveUserCount,
          unregisteredUserCount,
          registeredUserCount
        );


        setADTopRowItemsTemp(
          ADTopRowItemsTemp.map((staticObjectTemp) => {
            var isTypeMatched = false;
            var totalCount = 0;
            let percentage = 0;
            if (staticObjectTemp.type === "TOTAL") {
              isTypeMatched = true;
              totalCount = totalUserCount;
              percentage = 100;
            } else if (staticObjectTemp.type === "ACTIVE") {
              isTypeMatched = true;
              totalCount = activeUserCount;
              percentage = ValueFormat.parseAsFloat(((totalCount/totalUserCount) * 100) , 1);
            } else if (staticObjectTemp.type === "INACTIVE") {
              isTypeMatched = true;
              totalCount = inactiveUserCount;
              percentage = ValueFormat.parseAsFloat(((totalCount/totalUserCount) * 100) , 1);
              // console.log("inac", inactiveUserCount);
            } else if (staticObjectTemp.type === "UNREGISTER") {
              isTypeMatched = true;
              totalCount = unregisteredUserCount;
              percentage = ValueFormat.parseAsFloat(((totalCount/totalUserCount) * 100) , 1);
            } else if (staticObjectTemp.type === "PENDING") {
              isTypeMatched = true;
              totalCount = registeredUserCount;
              percentage = ValueFormat.parseAsFloat(((totalCount/totalUserCount) * 100) , 1);
            } else if (staticObjectTemp.type === "CATEGORY") {
              isTypeMatched = true;
              totalCount = totalSafeRideCount;
              percentage = ValueFormat.parseAsFloat(((totalCount/totalUserCount) * 100) , 1);
            }

            if (isTypeMatched === true) {
              let staticObjectTempReplace = {
                totalCount: totalCount,
                percentage: percentage
              };
              return { ...staticObjectTemp, ...staticObjectTempReplace };
            } else {
              return staticObjectTemp;
            }
          })
        );
        // console.log("rideBoxListLen", ADTopRowItemsTemp)
      });
      setAggregateData({
        totalRides,
        totalKilometer,
        totalHighRiskCount,
        totalAlertDataCount,
        totalMediumRiskCount,
        totalTravelTime
      });
      setTotalDays(totalDays);
    } else {
      // const code = resultJSON.error.code;

      // if (code == "E007") {
      //   ToastService.errormsg(displayText.ER_007);
      //   setTimeout(() => {
      //     window.location.href = "/contacts-profile";
      //   }, 3500);
      // }
      console.log("Status failed");
    }
  };

  const userUsageApi = async () => {
    setShowLoader((current) => current + 1);
    var result = await AdminDashboardController.userUsageController(
      uuStartDateTime,
      uuEndDateTime,
      uuFilter
    );
    setShowLoader((current) => current - 1);
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    // console.log("admintopitem:", JSON.stringify(resultJSON))
    if (status == "SUCCESS") {
      var resultJSONData = resultJSON.data;
      var userCountPercentages = [];
      // console.log("AdminTopRows: " + JSON.stringify(resultJSONData));
      resultJSONData.map((apiResultTemp) => {
        var activeUserCount = parseInt(
          ValueFormat.parseAsInt(apiResultTemp.activeUserCount)
        );

        var inactiveUserCount = parseInt(
          ValueFormat.parseAsInt(apiResultTemp.inactiveUserCount)
        );
        var unregisteredUserCount = parseInt(
          ValueFormat.parseAsInt(apiResultTemp.unregisteredUserCount)
        );

        var registeredUserCount = parseInt(
          ValueFormat.parseAsInt(apiResultTemp.registeredUserCount)
        );

        userCountPercentages.push(
          activeUserCount,
          inactiveUserCount,
          unregisteredUserCount,
          registeredUserCount
        );
      });

      setUserUsagePer(userCountPercentages);
    } else {
      // const code = resultJSON.error.code;

      // if (code == "E007") {
      //   ToastService.errormsg(displayText.ER_007);
      //   setTimeout(() => {
      //     window.location.href = "/contacts-profile";
      //   }, 3500);
      // }
      console.log("Status failed");
    }
  };

  const containerIds = ["container1", "container2", "container3"];

  let driverAnalysis = async () => {
    var result =
      await AdminWeeklySummeryController.adminWeeklyDriverAnalysisController(
        norStartDateTime,
        norEndDateTime,
        norFilter
      );
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    if (status == "SUCCESS") {
      setDailyData(null);
      var resultJSONData = resultJSON.data;
      var usageList = [];
      var usageValue = {};
      var userPerOption = [];
      var driverAnalysis = [{}];
      var dayUserCountList = [{}];
      var activeDriverData = [];
      // console.log("driverAnalysis: " + JSON.stringify(resultJSONData));
      var useageData = 0;
      for (let dr = 0; dr < resultJSONData.length; dr++) {
        var driverAnalysisList = resultJSONData[dr].driverAnalysisList;
        var dayUserCount = resultJSONData[dr].dayUserCount;
        var sunDayUserCount = dayUserCount.day0;
        var monDayUserCount = dayUserCount.day1;
        var tueDayUserCount = dayUserCount.day2;
        var wedDayUserCount = dayUserCount.day3;
        var thuDayUserCount = dayUserCount.day4;
        var friDayUserCount = dayUserCount.day5;
        var satDayUserCount = dayUserCount.day6;

        dayUserCountList = [
          {
            value: sunDayUserCount > 0 ? sunDayUserCount : null,
            name: sunDayUserCount > 0 ? "Sunday" : "",
          },
          {
            value: monDayUserCount > 0 ? monDayUserCount : null,
            name: monDayUserCount > 0 ? "Monday" : "",
          },
          {
            value: tueDayUserCount > 0 ? tueDayUserCount : null,
            name: tueDayUserCount > 0 ? "Tuesday" : "",
          },
          {
            value: wedDayUserCount > 0 ? wedDayUserCount : null,
            name: wedDayUserCount > 0 ? "Wednesday" : "",
          },
          {
            value: thuDayUserCount > 0 ? thuDayUserCount : null,
            name: thuDayUserCount > 0 ? "Thursday" : "",
          },
          {
            value: friDayUserCount > 0 ? friDayUserCount : null,
            name: friDayUserCount > 0 ? "Friday" : "",
          },
          {
            value: satDayUserCount > 0 ? satDayUserCount : null,
            name: satDayUserCount > 0 ? "Saturday" : "",
          },
        ];
        var dayCount =
          sunDayUserCount +
          monDayUserCount +
          tueDayUserCount +
          wedDayUserCount +
          thuDayUserCount +
          friDayUserCount +
          satDayUserCount;
        setDailyData(dayCount > 0 ? true : false);

        activeDriverData.push(...dayUserCountList);
        for (let da = 0; da < driverAnalysisList.length; da++) {
          usageList = [];
          usageValue = {};
          var dayList = driverAnalysisList[da].dayList;
          var firstName = ValueFormat.capitalizeFirstLetterAndFormat(
            driverAnalysisList[da].firstName
          );
          for (let dl = 0; dl < dayList.length; dl++) {
            var usage =
              dayList[dl].usage > 0 ? Math.round(dayList[dl].usage) : 0;
            useageData = useageData + usage;
            usageList.push(usage);
          }
          usageValue = {
            name: firstName,
            type: "line",
            data: usageList,
            markPoint: {
              symbolSize: [35, 40],
              data: [
                { type: "max", name: "Max" },
                { type: "min", name: "Min" },
              ],
            },
          };
          userPerOption.push(usageValue);
        }
      }

      setActiveDriverData(userPerOption);
      setActiveDriverPie(activeDriverData);
      setPdUsageData(useageData > 0 ? true : false);
    } else {
      const code = resultJSON.error.code;
      if (code == "E007") {
        setPdUsageData("failed");
        setDailyData("failed");
      }
    }
  };

  useEffect(() => {
    getTotalValues();
    driverAnalysis();
    userUsageApi();
  }, []);

  useEffect(() => {
    getTotalValues();
  }, [filter, startDateTime, endDateTime]);

  useEffect(() => {
    driverAnalysis();
  }, [norStartDateTime, norEndDateTime, norFilter]);

  useEffect(() => {
    userUsageApi();
  }, [uuEndDateTime, uuStartDateTime, uuFilter]);

  useEffect(() => {
    setPdUsageData();
  }, []);

  const [summaryData, setSummaryData] = useState([]);
  const [usageHistory, setUsageHistory] = useState([]);
  const [safetycategory, setSafetycategory] = useState([]);
  const [safetyTableData, setSafetyTableData] = useState([]);
  const [sportyTableData, setSportyTableData] = useState([]);
  const [quietTableData, setQuietSafeTableData] = useState([]);
  const [dangerousTableData, setDangerousTableData] = useState([]);
  const [quietDangerousTableData, setQuietDangerousTableData] = useState([]);
  const [recklessTableData, setRecklessTableData] = useState([]);

  const handleSummaryData = (data) => {
    setSummaryData(data);
  };
  const getUsageHistory = (data) => {
    setUsageHistory(data);
  };
  const handleSafetyCategory = (data) => {
    setSafetycategory(data);
  };

  const handleSafetyTableData = (data) => {
    setSafetyTableData(data);
  };
  // const handleSportiveTableData = (data) => {
  //   setSportyTableData(data);
  // };
  // const handleQuietSafeTableData = (data) => {
  //   setQuietSafeTableData(data);
  // };
  // const handleRecklessTableData = (data) => {
  //   setRecklessTableData(data);
  // };
  // const handleQuietDangerousTableData = (data) => {
  //   setQuietDangerousTableData(data);
  // };
  // const handleDangerousTableData = (data) => {
  //   setDangerousTableData(data);
  // };
  // document.title = "Motiv AI | Dashboard";

  return (
    <React.Fragment>
      <div className="page-content">
        {/* <div className="ps-5 pe-5"> */}
        <Container fluid id="division">
          <div id="container1">
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title="Admin Dashboard"
              breadcrumbItem="Admin Dashboard"
            />
            {showLoader > 0 && <Loader />}
            <ToastContainer style={{ width: "auto" }} />
            <Row className="mb-2">
              <Col xs={6} className="d-flex justify-content-start text-black">
                <h5 className="d-none d-sm-block">Dashboard</h5>
              </Col>
              <Col xs={6} className="d-flex justify-content-end">
                <div className="p-2 pt-0 pe-0 d-flex">
                  <button
                    type="button"
                    className={
                      filter == ""
                        ? "btn btn-primary btn-sm me-2"
                        : "btn btn-soft-secondary btn-sm me-2"
                    }
                    onClick={() => setFilter("")}
                    data-tooltip-id="allBl"
                  >
                    All
                    <ReactTooltip
                      id="allBl"
                      place="top"
                      content="Showing zone summary for all the time"
                      style={{ background: "black", zIndex: 100 }}
                    />
                  </button>
                  <button
                    type="button"
                    className={
                      filter == "CURRENT_DAY"
                        ? "btn btn-primary btn-sm me-2"
                        : "btn btn-soft-secondary btn-sm me-2"
                    }
                    onClick={() => setFilter("CURRENT_DAY")}
                    data-tooltip-id="cd"
                  >
                    CD
                    <ReactTooltip
                      id="cd"
                      place="top"
                      content="Showing zone summary for current day"
                      style={{ background: "black", zIndex: 100 }}
                    />
                  </button>
                  <button
                    type="button"
                    className={
                      filter == "CURRENT_WEEK"
                        ? "btn btn-primary btn-sm me-2"
                        : "btn btn-soft-secondary btn-sm me-2"
                    }
                    onClick={() => setFilter("CURRENT_WEEK")}
                    data-tooltip-id="cw"
                  >
                    CW
                    <ReactTooltip
                      id="cw"
                      place="top"
                      content="Showing zone summary for current week"
                      style={{ background: "black", zIndex: 100 }}
                    />
                  </button>{" "}
                  <button
                    type="button"
                    className={
                      filter == "CURRENT_MONTH"
                        ? "btn btn-primary btn-sm me-2"
                        : "btn btn-soft-secondary btn-sm me-2"
                    }
                    onClick={() => setFilter("CURRENT_MONTH")}
                    data-tooltip-id="cm"
                  >
                    CM
                    <ReactTooltip
                      id="cm"
                      place="top"
                      content="Showing zone summary for current month"
                      style={{ background: "black", zIndex: 100 }}
                    />
                  </button>{" "}
                  <button
                    type="button"
                    className={
                      filter == "CURRENT_YEAR"
                        ? "btn btn-primary btn-sm me-2"
                        : "btn btn-soft-secondary btn-sm me-2"
                    }
                    onClick={() => setFilter("CURRENT_YEAR")}
                    data-tooltip-id="cy"
                  >
                    CY
                    <ReactTooltip
                      id="cy"
                      place="top"
                      content="Showing zone summary for current year"
                      style={{ background: "black", zIndex: 100 }}
                    />
                  </button>{" "}
                  <button
                    type="button"
                    className={
                      filter == "CUSTOM_DURATION"
                        ? "btn btn-primary btn-sm me-2"
                        : "btn btn-soft-secondary btn-sm me-2"
                    }
                    onClick={() => {
                      setIsOpen(true);
                    }}
                    data-tooltip-id="custom durationn"
                  >
                    <FontAwesomeIcon icon={faClock} />
                    <ReactTooltip
                      id="custom durationn"
                      place="top"
                      content="Showing zone summary for custom duration"
                      style={{ background: "black", zIndex: 100 }}
                    />
                  </button>
                  <ExportScreenPdf
                    containerIds={containerIds}
                    fileName={userFirstName + "'s" + " Dashboard"}
                  />
                </div>

                <div className="p-2 pt-0 d-flex" style={{ marginLeft: "10px" }}>
                  <ViewPdf
                    ADTopRowItemsTemp={ADTopRowItemsTemp}
                    totalSafeRidePer={totalSafeRidePer}
                    userUsagePer={userUsagePer}
                    summaryData={summaryData}
                    usageHistory={usageHistory}
                    safetycategory={safetycategory}
                    rideRiskData={rideRiskData}
                    activeDriverPie={activeDriverPie}
                    activeDriverData={activeDriverData}
                    safetyTableData={safetyTableData}
                    sportyTableData={sportyTableData}
                    quietTableData={quietTableData}
                    recklessTableData={recklessTableData}
                    quietDangerousTableData={quietDangerousTableData}
                    dangerousTableData={dangerousTableData}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xl={3} lg={6} md={6}>
                <Card>
                  <CardHeader
                    style={{
                      backgroundColor: "#74788D1A",
                      padding: "10px 10px",
                      fontWeight: "500",
                    }}
                  >
                    All Users
                    {/* <Link to="/myusertabledata">
                      <button style={{ fontSize: "10px", background: "none", outline: "none", border: "none", float: "right", paddingTop: "3px" }}>
                        View Details
                      </button>
                    </Link> */}
                  </CardHeader>
                  <CardBody style={{}} className="p-0 m-0">
                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <tbody>
                          {ADTopRowItemsTemp.map((item, index) => (
                            <tr
                              key={index}
                              style={{ backgroundColor: "#74788D1A" }}
                            >
                              <td
                                style={{
                                  fontSize: "12px",
                                  padding: "7.8px",
                                }}
                              >
                                {item.title}
                                <AiOutlineExclamationCircle
                                  style={{
                                    marginLeft: "5px",
                                    marginTop: "-1px",
                                  }}
                                  data-tooltip-id={"snpTooltipContentt" + index}
                                />
                                <ReactTooltip
                                  id={"snpTooltipContentt" + index}
                                  place="top"
                                  content={<span>{item.description}</span>}
                                  style={{ backgroundColor: "black" }}
                                />
                              </td>
                              <td
                                style={{
                                  fontSize: "12px",
                                  padding: "7.8px 20px",
                                }}
                              >
                                <span
                                  style={{ float: "right", fontWeight: 60 }}
                                >
                                  {item.totalCount+" "+`(${item.percentage}%)`}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={3} lg={6} md={6}>
                <Card>
                  <CardHeader
                    style={{
                      backgroundColor: "#74788D1A",
                      padding: "10px 10px",
                      fontWeight: "500",
                    }}
                  >
                    Green Zone
                    {/* <Link to="/activeuserspage">
                      <button style={{ fontSize: "10px", background: "none", outline: "none", border: "none", float: "right", paddingTop: "3px" }}>
                        View Details
                      </button>
                    </Link> */}
                  </CardHeader>
                  <CardBody
                    style={{ padding: "10px", backgroundColor: "#74788D1A" }}
                  >
                    <div style={{ display: "flex" }}>
                      <div style={{ height: 70, width: 70 }}>
                        <span
                          style={{
                            position: "absolute",
                            height: 60,
                            width: 70,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            fontWeight: "700",
                            textAlign: "center",
                          }}
                        >
                          <span
                            style={{ fontSize: "12px", textAlign: "center" }}
                          >
                            C1
                          </span>
                          <span style={{ fontSize: "15px" }}>
                            {ADRideBoxCount.count[0].percentage}%
                          </span>
                        </span>
                        <CircularProgressbar
                          value={ADRideBoxCount.count[0].percentage}
                          styles={buildStyles({
                            width: "20px",
                            textColor: "block",
                            textSize: "25px",
                            pathColor: "#19543e",
                            trailColor: "#0095FF",
                          })}
                        ></CircularProgressbar>
                      </div>
                      <span
                        style={{
                          height: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "20px",
                        }}
                      >
                        Safe Category
                        <br />
                        <span style={{ fontWeight: "700", fontSize: "17px" }}>
                          {ADRideBoxCount.count[0].totalRides} /{" "}
                          {ADRideBoxCount.totalRides}
                        </span>
                      </span>
                    </div>

                    <div style={{ display: "flex", marginTop: "12px" }}>
                      <div style={{ height: 70, width: 70 }}>
                        <span
                          style={{
                            position: "absolute",
                            height: 60,
                            width: 70,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            fontWeight: "700",
                            textAlign: "center",
                          }}
                        >
                          <span
                            style={{ fontSize: "12px", textAlign: "center" }}
                          >
                            C2
                          </span>
                          <span style={{ fontSize: "15px" }}>
                            {ADRideBoxCount.count[1].percentage}%
                          </span>
                        </span>
                        <CircularProgressbar
                          value={ADRideBoxCount.count[1].percentage}
                          styles={buildStyles({
                            width: "20px",
                            textColor: "#000",
                            textSize: "40px",
                            pathColor: "#19543e",
                            trailColor: "#0095FF",
                          })}
                        ></CircularProgressbar>
                      </div>
                      <span
                        style={{
                          height: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "20px",
                        }}
                      >
                        Sportive Category
                        <br />
                        <span style={{ fontWeight: "700", fontSize: "17px" }}>
                          {ADRideBoxCount.count[1].totalRides +
                            " / " +
                            ADRideBoxCount.totalRides}
                        </span>
                      </span>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl={3} lg={6} md={6}>
                <Card>
                  <CardHeader
                    style={{
                      backgroundColor: "#74788D1A",
                      padding: "10px 10px",
                      fontWeight: "500",
                    }}
                  >
                    Amber Zone
                    {/* <Link to="/activeuserspage">
                      <button style={{ fontSize: "10px", background: "none", outline: "none", border: "none", float: "right", paddingTop: "3px" }}>
                        View Details
                      </button>
                    </Link> */}
                  </CardHeader>

                  <CardBody
                    style={{ padding: "10px", backgroundColor: "#74788D1A" }}
                  >
                    <div style={{ display: "flex" }}>
                      <div style={{ height: 70, width: 70 }}>
                        <span
                          style={{
                            position: "absolute",
                            height: 60,
                            width: 70,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            fontWeight: "700",
                            textAlign: "center",
                          }}
                        >
                          <span
                            style={{ fontSize: "12px", textAlign: "center" }}
                          >
                            C3
                          </span>
                          <span style={{ fontSize: "15px" }}>
                            {ADRideBoxCount.count[2].percentage}%
                          </span>
                        </span>
                        <CircularProgressbar
                          value={ADRideBoxCount.count[2].percentage}
                          styles={buildStyles({
                            width: "20px",
                            textColor: "block",
                            textSize: "25px",
                            pathColor: "#FFBF00",
                            trailColor: "#0095FF",
                          })}
                        ></CircularProgressbar>
                      </div>
                      <span
                        style={{
                          height: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "20px",
                        }}
                      >
                        Quite Safe Category
                        <br />
                        <span style={{ fontWeight: "700", fontSize: "17px" }}>
                          {ADRideBoxCount.count[2].totalRides +
                            " / " +
                            ADRideBoxCount.totalRides}
                        </span>
                      </span>
                    </div>

                    <div style={{ display: "flex", marginTop: "12px" }}>
                      <div style={{ height: 70, width: 70 }}>
                        <span
                          style={{
                            position: "absolute",
                            height: 60,
                            width: 70,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            fontWeight: "700",
                            textAlign: "center",
                          }}
                        >
                          <span
                            style={{ fontSize: "12px", textAlign: "center" }}
                          >
                            C4
                          </span>
                          <span style={{ fontSize: "15px" }}>
                            {ADRideBoxCount.count[3].percentage}%
                          </span>
                        </span>
                        <CircularProgressbar
                          value={ADRideBoxCount.count[3].percentage}
                          // text={`${15}%`}
                          styles={buildStyles({
                            width: "20px",
                            textColor: "#000",
                            textSize: "40px",
                            pathColor: "#FFBF00",
                            trailColor: "#0095FF",
                          })}
                        ></CircularProgressbar>
                      </div>
                      <span
                        style={{
                          height: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "20px",
                        }}
                      >
                        Reckless Category
                        <br />
                        <span style={{ fontWeight: "700", fontSize: "17px" }}>
                          {ADRideBoxCount.count[3].totalRides +
                            " / " +
                            ADRideBoxCount.totalRides}
                        </span>
                      </span>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl={3} lg={6} md={6}>
                <Card>
                  <CardHeader
                    style={{
                      backgroundColor: "#74788D1A",
                      padding: "10px 10px",
                      fontWeight: "500",
                    }}
                  >
                    Red Zone
                    {/* <Link to="/activeuserspage">
                      <button style={{ fontSize: "10px", background: "none", outline: "none", border: "none", float: "right", paddingTop: "3px" }}>
                        View Details
                      </button>
                    </Link> */}
                  </CardHeader>
                  <CardBody
                    style={{ padding: "10px", backgroundColor: "#74788D1A" }}
                  >
                    <div style={{ display: "flex" }}>
                      <span
                        style={{
                          position: "absolute",
                          height: 60,
                          width: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          fontWeight: "700",
                          textAlign: "center",
                        }}
                      >
                        <span style={{ fontSize: "12px", textAlign: "center" }}>
                          C5
                        </span>
                        <span style={{ fontSize: "15px" }}>
                          {ADRideBoxCount.count[4].percentage}%
                        </span>
                      </span>
                      <div style={{ height: 70, width: 70 }}>
                        <CircularProgressbar
                          value={ADRideBoxCount.count[4].percentage}
                          styles={buildStyles({
                            width: "20px",
                            textColor: "block",
                            textSize: "25px",
                            pathColor: "#FF0000",
                            trailColor: "#0095FF",
                          })}
                        ></CircularProgressbar>
                      </div>
                      <span
                        style={{
                          height: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "20px",
                        }}
                      >
                        Quite Dangerous Category
                        <br />
                        <span style={{ fontWeight: "700", fontSize: "17px" }}>
                          {ADRideBoxCount.count[4].totalRides +
                            " / " +
                            ADRideBoxCount.totalRides}
                        </span>
                      </span>
                    </div>

                    <div style={{ display: "flex", marginTop: "12px" }}>
                      <div style={{ height: 70, width: 70 }}>
                        <span
                          style={{
                            position: "absolute",
                            height: 60,
                            width: 70,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            fontWeight: "700",
                            textAlign: "center",
                          }}
                        >
                          <span
                            style={{ fontSize: "12px", textAlign: "center" }}
                          >
                            C6
                          </span>
                          <span style={{ fontSize: "15px" }}>
                            {ADRideBoxCount.count[5].percentage}%
                          </span>
                        </span>
                        <CircularProgressbar
                          value={ADRideBoxCount.count[5].percentage}
                          // text={`${15}%`}
                          styles={buildStyles({
                            width: "20px",
                            textColor: "#000",
                            textSize: "40px",
                            pathColor: "#FF0000",
                            trailColor: "#0095FF",
                          })}
                        ></CircularProgressbar>
                      </div>
                      <span
                        style={{
                          height: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "20px",
                        }}
                      >
                        Dangerous Category
                        <br />
                        <span style={{ fontWeight: "700", fontSize: "17px" }}>
                          {ADRideBoxCount.count[5].totalRides +
                            " / " +
                            ADRideBoxCount.totalRides}
                        </span>
                      </span>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* <OverAllSummary receiveSummaryData={handleSummaryData} />
            <OverallRiskSummary /> */}

            {/* <RidesKmHrsScoreCBar aggregateData={aggregateData} totalDays={totalDays}/> */}
            <Row>
              <Col xl={12}>
                <AdminDrivingCharts receiveSummaryData={handleSummaryData} loaderCount={setShowLoader}/>
                {/* <DrivingBehaviour loaderCount={setShowLoader} /> */}
              </Col>
            </Row>

            <Row>
              <Col xl={12}>
                <CustomDateFilterMain loaderCount={setShowLoader} handleSafetyTableData={handleSafetyTableData}/>                
              </Col>
            </Row>


            {/* <Row>
              <Col xl={12}>
                <C1Chart filter={filters} loaderCount={setShowLoader} handleSafetyTableData={handleSafetyTableData} />
              </Col>
            </Row>
          </div>
          <div className="html2pdf__page-break" />
          <div id="container2">
            <Row>
              <Col xl={12}>
                <C2Chart filter={filters} loaderCount={setShowLoader} handleSportiveTableData={handleSportiveTableData} />
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <C3Chart filter={filters} handleQuietSafeTableData={handleQuietSafeTableData} loaderCount={setShowLoader} />
              </Col>

              <Col xl={12}>
                <C4Chart filter={filters} handleRecklessTableData={handleRecklessTableData} loaderCount={setShowLoader} />
              </Col>

              <Col xl={12}>
                <C5Chart filter={filters} handleQuietDangerousTableData={handleQuietDangerousTableData} loaderCount={setShowLoader} />
              </Col>
            </Row>
          </div>
          <div className="html2pdf__page-break" />
          <div id="container3">
            <Row>
              <Col xl={12}>
                <C6Chart filter={filters} handleDangerousTableData={handleDangerousTableData} loaderCount={setShowLoader} />
              </Col>
            </Row> */}

            <RiskAndRidesAdminDash />
            <Row>
              <Col xl={6}>
                <DriverAnalysisSub
                  userUsageData={userUsagePer}
                  setUUFilter={setUUFilter}
                  setUUEndDateTime={setUUEndDateTime}
                  setUUStartDateTime={setUUStartDateTime}
                />
              </Col>
              <Col xl={6}>
                <RadialChart
                  loaderCount={setShowLoader}
                  safetycategory={handleSafetyCategory}
                />
              </Col>
            </Row>
            <Row>
              <Col xl={12}>
                <UsageHistory receiveUsageHistory={getUsageHistory} />
              </Col>
            </Row>

            <Row>
              <Col xl={6}>
                <DriverAnalysis
                  activeDriverPie={activeDriverPie}
                  setDailyData={dailyData}
                  setNORFilter={setNORFilter}
                  setNOREndDateTime={setNOREndDateTime}
                  setNORStartDateTime={setNORStartDateTime}
                />
              </Col>

              <Col xl={6}>
                <Card style={{ height: 450 }}>
                  <CardHeader className="cardHeader">
                    <div style={{ display: "flex" }}>
                      <h4 className="card-title me-1">
                        Per day Usage{" "}
                        <span style={{ fontSize: "10px", fontWeight: 400 }}>
                          (All Drivers)
                        </span>
                      </h4>
                    </div>
                  </CardHeader>
                  {pdUsageData === true ? (
                    <CardBody>
                      <LineApexChart activeDriverData={activeDriverData} />
                    </CardBody>
                  ) : pdUsageData === false ? (
                    <CardBody>
                      <p className="data_not_available">
                        {displayText.DATA_NOT_AVAILABLE}
                      </p>

                      <LineApexChart activeDriverData={activeDriverData} />
                    </CardBody>
                  ) : pdUsageData === "failed" ? (
                    <CardBody>
                      <p className="data_not_available">{displayText.ER_007}</p>

                      <LineApexChart activeDriverData={activeDriverData} />
                    </CardBody>
                  ) : (
                    <CardBody>
                      <p className="fetch_data">{displayText.FETCHING_DATA}</p>

                      <LineApexChart activeDriverData={activeDriverData} />
                    </CardBody>
                  )}
                </Card>
              </Col>
            </Row>
            <DateTimePickerPopup
              modalOpen={modalIsOpen}
              modalClose={setIsOpen}
              setStartDateTime={setStartDateTime}
              setEndDateTime={setEndDateTime}
              setFilter={setFilter}
            />
          </div>
        
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AdminDashboard;
