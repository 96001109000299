import { Document, Image, Line, PDFViewer, Page, Rect, StyleSheet, Svg, Text, View } from "@react-pdf/renderer";
import React from "react";
import Logo from "../../../assets/images/logo/motivAiLogoPng.png";
import { SC_COMPANY_NAME, SC_USER_FIRST_NAME } from "../../../constant/StorageConstant";
import UtilDateTime from "../../../util/UtilDateTime";

const styles = StyleSheet.create({
  pdfViewer: {
    height: "96%",
    width: "100%",
    marginTop: "20px",
    marginBottom: "5px",
  },
  document: {
    marginTop: "15px",
    marginBottom: "5px",
  },
  page: {
    paddingLeft: 30,
    paddingRight: 30,
    width: "100%",
  },
  watermark: {
    position: "absolute",
    fontSize: 95,
    // opacity: 0.3,
    color: "#e6e6e6",
    transform: "rotate(-52deg)",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    top: "42%",
    left: "22%",
  },
  footer: {
    position: "absolute", marginLeft: 30, marginRight: 30, bottom: 0, left: 0, right: 0, height: "44.5px"
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottomWidth: 1,
    padding: 5,
  },
  headerText: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    flex: 1,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 5,
  },
  cell: {
    fontSize: 10,
    textAlign: "center",
  },
});

const fontBold = {
  fontFamily: "Helvetica-Bold",
};

const fontItalic = {
  fontFamily: "Times-BoldItalic",
};

const watermarkText = "MotivAI";


const footer = [
  { f: "https://motivai.tech" },
  { f: "https://app.motivai.tech" },
  { f: '"Drive Safe, Arrive Safe"' },
  { f: "A MotivAI Product" },
];

const ActivityUsageSummaryPdf = ({ yearlyActivities, yearlyFilter, time, numberofRidessPdfData, activeDriverData }) => {
  console.log("activeDriverData", activeDriverData);
  const companyName = localStorage.getItem(SC_COMPANY_NAME);
  const firstName = localStorage.getItem(SC_USER_FIRST_NAME);

  const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
  var currentDate = new Date();

  const reversedDateFormat =
    currentDate.getDate().toString().padStart(2, "0") +
    "-" +
    months[currentDate.getMonth()] +
    "-" +
    currentDate.getFullYear();

  const header = [
    { title: "App Name", name: "Motiv AI" },
    { title: "Customer Name", name: companyName },
    { title: "Date", name: reversedDateFormat },
    { title: "First Name", name: firstName },
  ];

  var totalPages = 0;

  let showTime = "";
  if (yearlyFilter === "CURRENT_YEAR") {
    showTime = UtilDateTime.getCurrentYear();
  } else if (yearlyFilter === "CUSTOM_DURATION") {
    showTime = UtilDateTime.getSelectedYear(time);
  }

  const monthData = [
    { label: "Jan" },
    { label: "Feb" },
    { label: "Mar" },
    { label: "Apr" },
    { label: "May" },
    { label: "Jun" },
    { label: "Jul" },
    { label: "Aug" },
    { label: "Sep" },
    { label: "Oct" },
    { label: "Nov" },
    { label: "Dec" },
  ];


  const rideActivity = yearlyActivities.find((activity) => activity.name === "Ride");
  const kmActivity = yearlyActivities.find((activity) => activity.name === "Km");
  const hourActivity = yearlyActivities.find((activity) => activity.name === "Hours");
  const calculateHeight = (value, maxValue) => {
    if (maxValue === 0) {
      return 0;
    }
    const maxHeight = -150;
    return -((parseFloat(value) / parseFloat(maxValue)) * maxHeight);
  };
  const rideHeightValues = rideActivity.data;
  const kmHeightValues = kmActivity.data;
  const hourHeightValues = hourActivity.data;

  const maxRideValue = Math.max(...rideHeightValues);
  const maxKmValue = Math.max(...kmHeightValues);
  const maxHourValue = Math.max(...hourHeightValues);

  return (
    <React.Fragment>
      <PDFViewer style={styles.pdfViewer}>
        <Document style={styles.document}>

          <Page totalPages={totalPages++} style={styles.page}>
            <Text style={styles.watermark}>{watermarkText}</Text>
            <View style={{ height: "4.4%", marginBottom: '15px', }}>
              <View style={{ flexDirection: "row", borderBottom: "1px solid black", backgroundColor: "lightgrey" }} >
                <View style={{ marginTop: "5px", width: "100%", flexDirection: "row" }} >
                  <Image src={Logo} style={{ height: "21px", width: "100px", marginTop: "2px", marginBottom: "5px", marginLeft: "31px" }} />
                  <View style={{ flexDirection: "Row", marginBottom: "5px", marginLeft: "30px", }} >
                    <View style={{ flexDirection: "row" }}>
                      <Text style={[fontBold, { fontSize: 11 }]}> {header[1].title}: </Text>
                      <Text style={{ fontSize: 11, marginLeft: "3px" }}> {header[1].name} </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                      <View style={{ flexDirection: "row", marginTop: "2px", width: "60%" }} >
                        <Text style={[fontBold, { fontSize: 11, width: "85px" }]} > {header[3].title}: </Text>
                        <Text style={{ fontSize: 11, width: "100%" }}> {header[3].name} </Text>
                      </View>
                      <View style={{ flexDirection: "row", marginTop: "2px", marginLeft: "42px" }} >
                        <View style={{ flexDirection: "row" }}>
                          <Text style={[fontBold, { fontSize: 11 }]}> {header[2].title}:{" "} </Text>
                          <Text style={{ fontSize: 11 }}>{header[2].name}</Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>

            <View style={{ width: "100%", flexDirection: "column" }} >
              <Text style={[fontBold, { fontSize: 11, margin: "3px", marginBottom: '17px', textAlign: "center", color: "#003cb3", textDecoration: "underline" }]} > Admin Dashboard (Active & Usage Summary) </Text>
              {/* <Text style={{ color: "black", fontSize: "11px", textDecoration: "none", fontWeight: 600, textAlign: "center", marginTop: "5px" }} >
                This report is generated for the period 2024 2024 2024 2024 2024
            </Text> */}
            </View>


            <View style={{ height: '223px', marginBottom: '15px' }}>
              <View style={{ height: '30px', display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                <View ><Text style={[fontBold, { fontSize: 11 }]}>1. Ride Summary</Text></View>
                <View ><Text style={[fontBold, { fontSize: 11 }]}>Showing for the year{" "}{showTime}</Text></View>
              </View>

              <View style={{ height: '193px' }}>
                <Svg width={"100%"} height={"245px"}>
                  <Line x1=".5" y1="15" x2=".5" y2="165" stroke="black" strokeWidth="1" />
                  <Line x1="0" y1="165" x2="535" y2="165" stroke="black" strokeWidth="1" />
                  {rideHeightValues.map((value, index) => (
                    <React.Fragment key={index}>
                      <Rect x={(index + 1) * 40} y={164.5} fill='#34c38f' width={20} height={calculateHeight(-value, maxRideValue)} />
                      <Text x={(index + 1) * 40 + 10} y={164.5 - calculateHeight(value, maxRideValue) - 5} textAnchor="middle" style={{ fontSize: "8px" }} > {value} </Text>
                      <Text x={(index + 1) * 40 + 10} y={174.5} textAnchor="middle" style={{ fontSize: "8px" }} > {monthData[index].label} </Text>
                    </React.Fragment>
                  ))}
                </Svg>
                <View style={{ height: "20px", width: "100%", flexDirection: "row", alignItems: 'center', justifyContent: "center" }} >
                  <Text style={{ fontSize: "8px", }}>Note: X-axis for Months, Y-axis for Ride</Text>
                </View>
              </View>
            </View>


            <View style={{ height: '223px', marginBottom: '15px' }}>
              <View style={{ height: '30px', display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                <View ><Text style={[fontBold, { fontSize: 11 }]}>2. Distance Summary</Text></View>
                <View ><Text style={[fontBold, { fontSize: 11 }]}>Showing for the year{" "}{showTime}</Text></View>
              </View>

              <View style={{ height: '193px' }}>
                <Svg width={"100%"} height={"245px"}>
                  <Line x1=".5" y1="15" x2=".5" y2="165" stroke="black" strokeWidth="1" />
                  <Line x1="0" y1="165" x2="535" y2="165" stroke="black" strokeWidth="1" />
                  {kmHeightValues.map((value, index) => (
                    <React.Fragment key={index}>
                      <Rect x={(index + 1) * 40} y={164.5} fill='#556ee6' width={20} height={calculateHeight(-value, maxKmValue)} />
                      <Text x={(index + 1) * 40 + 10} y={164.5 - calculateHeight(value, maxKmValue) - 5} textAnchor="middle" style={{ fontSize: "8px" }} > {value} </Text>
                      <Text x={(index + 1) * 40 + 10} y={174.5} textAnchor="middle" style={{ fontSize: "8px" }} > {monthData[index].label} </Text>
                    </React.Fragment>
                  ))}
                </Svg>
                <View style={{ height: "20px", width: "100%", flexDirection: "row", alignItems: 'center', justifyContent: "center" }} >
                  <Text style={{ fontSize: "8px", }}>Note: X-axis for Months, Y-axis for Distance(kms)</Text>
                </View>
              </View>
            </View>

            <View style={{ height: '223px', marginBottom: '15px' }}>
              <View style={{ height: '30px', display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                <View ><Text style={[fontBold, { fontSize: 11 }]}>3. Duration Summary  </Text></View>
                <View ><Text style={[fontBold, { fontSize: 11 }]}>Showing for the year{" "}{showTime}</Text></View>
              </View>

              <View style={{ height: '193px' }}>
                <Svg width={"100%"} height={"245px"}>
                  <Line x1=".5" y1="15" x2=".5" y2="165" stroke="black" strokeWidth="1" />
                  <Line x1="0" y1="165" x2="535" y2="165" stroke="black" strokeWidth="1" />
                  {hourHeightValues.map((value, index) => (
                    <React.Fragment key={index}>
                      <Rect x={(index + 1) * 40} y={164.5} fill='#f46a6a' width={20} height={calculateHeight(-value, maxHourValue)} />
                      <Text x={(index + 1) * 40 + 10} y={164.5 - calculateHeight(value, maxHourValue) - 5} textAnchor="middle" style={{ fontSize: "8px" }} > {value} </Text>
                      <Text x={(index + 1) * 40 + 10} y={174.5} textAnchor="middle" style={{ fontSize: "8px" }} > {monthData[index].label} </Text>
                    </React.Fragment>
                  ))}
                </Svg>
                <View style={{ height: "20px", width: "100%", flexDirection: "row", alignItems: 'center', justifyContent: "center" }} >
                  <Text style={{ fontSize: "8px", }}>Note: X-axis for Months, Y-axis for Hour</Text>
                </View>
              </View>
            </View>

            <View style={styles.footer} >
              <View style={{ borderBottom: "1px solid black" }} />
              <View style={{ flexDirection: "row", bottom: "0px" }} >

                <View style={{ marginTop: "5px", width: "30%", marginBottom: "5px" }} >
                  <Text style={{ fontSize: "11px", marginBottom: "3px" }}> {footer[0].f} </Text>
                  <Text style={[fontItalic, { fontSize: "10px" }]}> {footer[2].f} </Text>
                </View>

                <View style={{ marginTop: "10px", width: "40%", textAlign: "center", }} >
                  <Text style={{ fontSize: "11px" }}> Page {totalPages} of 2 </Text>
                </View>
                <View style={{ marginTop: "5px", width: "30%", flexDirection: "column" }} >
                  <Text style={{ fontSize: "11px", marginBottom: "3px", textAlign: "right" }} > {footer[1].f} </Text>
                  <Text style={{ fontSize: "10px", textAlign: "right" }}> {footer[3].f} </Text>
                </View>

              </View>
            </View>
          </Page>

          <Page totalPages={totalPages++} style={styles.page}>
            <Text style={styles.watermark}>{watermarkText}</Text>
            <View style={{ height: "4.4%", marginBottom: '15px', }}>
              <View style={{ flexDirection: "row", borderBottom: "1px solid black", backgroundColor: "lightgrey" }} >
                <View style={{ marginTop: "5px", width: "100%", flexDirection: "row" }} >
                  <Image src={Logo} style={{ height: "21px", width: "100px", marginTop: "2px", marginBottom: "5px", marginLeft: "31px" }} />
                  <View style={{ flexDirection: "Row", marginBottom: "5px", marginLeft: "30px", }} >
                    <View style={{ flexDirection: "row" }}>
                      <Text style={[fontBold, { fontSize: 11 }]}> {header[1].title}: </Text>
                      <Text style={{ fontSize: 11, marginLeft: "3px" }}> {header[1].name} </Text>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                      <View style={{ flexDirection: "row", marginTop: "2px", width: "60%" }} >
                        <Text style={[fontBold, { fontSize: 11, width: "85px" }]} > {header[3].title}: </Text>
                        <Text style={{ fontSize: 11, width: "100%" }}> {header[3].name} </Text>
                      </View>
                      <View style={{ flexDirection: "row", marginTop: "2px", marginLeft: "42px" }} >
                        <View style={{ flexDirection: "row" }}>
                          <Text style={[fontBold, { fontSize: 11 }]}> {header[2].title}:{" "} </Text>
                          <Text style={{ fontSize: 11 }}>{header[2].name}</Text>
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>

            <View style={{ height: '746px' }}>
              <View style={{ height: '30px', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <View ><Text style={[fontBold, { fontSize: 11 }]}>4. Number of Rides <Text style={[fontBold, { fontSize: 9 }]}>(All drivers)</Text> </Text></View>
                {/* <View ><Text style={[fontBold, { fontSize: 11 }]}>Showing for the year{" "}{showTime}</Text></View> */}
              </View>
              <View style={{ height: '50px', border: '1px solid black', width: '100%', flexDirection: 'column', marginBottom: '10px' }}>
                <View style={{ height: '25px', width: '100%', borderBottom: '1px solid black', flexDirection: 'row', alignItems: 'center' }}>
                  <View style={[fontBold, { fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px solid black', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Days</Text></View>
                  <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px solid black', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Sunday</Text></View>
                  <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px solid black', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Monday</Text></View>
                  <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px solid black', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Tuesday</Text></View>
                  <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px solid black', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Wednesday</Text></View>
                  <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px solid black', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Thursday</Text></View>
                  <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px solid black', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Friday</Text></View>
                  <View style={[{ fontSize: 10, width: '66.75px', height: '25px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Saturday</Text></View>
                </View>
                <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center' }}>
                  <View style={[fontBold, { fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px solid black', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>No of Rides</Text></View>
                  <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px solid black', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{numberofRidessPdfData.day0 || "-"}</Text></View>
                  <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px solid black', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{numberofRidessPdfData.day1 || "-"}</Text></View>
                  <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px solid black', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{numberofRidessPdfData.day2 || "-"}</Text></View>
                  <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px solid black', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{numberofRidessPdfData.day3 || "-"}</Text></View>
                  <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px solid black', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{numberofRidessPdfData.day4 || "-"}</Text></View>
                  <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px solid black', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{numberofRidessPdfData.day5 || "-"}</Text></View>
                  <View style={[{ fontSize: 10, width: '66.75px', height: '25px', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>{numberofRidessPdfData.day6 || "-"}</Text></View>
                </View>
              </View>
              <View style={{ height: '30px', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <View ><Text style={[fontBold, { fontSize: 11 }]}>5. Per day Usage <Text style={[fontBold, { fontSize: 9 }]}>(All drivers)</Text> </Text></View>
                {/* <View ><Text style={[fontBold, { fontSize: 11 }]}>Showing for the year{" "}{showTime}</Text></View> */}
              </View>
              <View style={{ border: '1px solid black', width: '100%', flexDirection: 'column', marginBottom: '10px', borderBottom: 'none', borderRight: 'none' }}>
                <View style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center', borderBottom: '1px solid black' }}>
                  <View style={[fontBold, { fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px solid black', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Username</Text></View>
                  <View style={[fontBold, { fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px solid black', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Sunday</Text></View>
                  <View style={[fontBold, { fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px solid black', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Monday</Text></View>
                  <View style={[fontBold, { fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px solid black', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Tuesday</Text></View>
                  <View style={[fontBold, { fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px solid black', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Wednesday</Text></View>
                  <View style={[fontBold, { fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px solid black', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Thursday</Text></View>
                  <View style={[fontBold, { fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px solid black', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}> <Text>Friday</Text></View>
                  <View style={[fontBold, { fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px solid black', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }]}><Text>Saturday</Text></View>
                </View>
                {activeDriverData.map((userData, index) => (
                  <View key={index} style={{ height: '25px', width: '100%', flexDirection: 'row', alignItems: 'center', borderBottom: '1px solid black' }}>
                    <View style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px solid black', justifyContent: 'center', alignItems: 'center' }]}>
                      <Text>{userData.name || "-"}</Text>
                    </View>
                    {userData.data.map((value, i) => (
                      <View key={i} style={[{ fontSize: 10, width: '66.75px', height: '25px', borderRight: '1px solid black', justifyContent: 'center', alignItems: 'center' }]}>
                        <Text>{value || "-"}</Text>
                      </View>
                    ))}
                  </View>
                ))}
              </View>
            </View>

            <View style={styles.footer} >
              <View style={{ borderBottom: "1px solid black" }} />
              <View style={{ flexDirection: "row", bottom: "0px" }} >

                <View style={{ marginTop: "5px", width: "30%", marginBottom: "5px" }} >
                  <Text style={{ fontSize: "11px", marginBottom: "3px" }}> {footer[0].f} </Text>
                  <Text style={[fontItalic, { fontSize: "10px" }]}> {footer[2].f} </Text>
                </View>

                <View style={{ marginTop: "10px", width: "40%", textAlign: "center", }} >
                  <Text style={{ fontSize: "11px" }}> Page {totalPages} of 2 </Text>
                </View>
                <View style={{ marginTop: "5px", width: "30%", flexDirection: "column" }} >
                  <Text style={{ fontSize: "11px", marginBottom: "3px", textAlign: "right" }} > {footer[1].f} </Text>
                  <Text style={{ fontSize: "10px", textAlign: "right" }}> {footer[3].f} </Text>
                </View>

              </View>
            </View>
          </Page>


        </Document>
      </PDFViewer>
    </React.Fragment>
  );
};

export default ActivityUsageSummaryPdf;
