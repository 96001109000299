import React, { useEffect, useState } from "react";
import Loader from "../../components/Common/loader";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { SC_USER_LOGIN_TYPE } from "../../constant/StorageConstant";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { ToastContainer } from "react-toastify";
import DateTimePickerPopup from "../ModelPopup/DateTimePopup";
import ValueFormat from "../../util/ValueFormat";
import { displayText } from "../../constant/MessageConstant";
import { FaAngleDown } from "react-icons/fa";
import Report from "../../controller/Report/Report";

export default function UserDeviceHealth() {
  const [showLoader, setShowLoader] = useState(false);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const [loginType, setLoginType] = useState("");
  const [dataLength, setDataLength] = useState(0);
  const [apiData, setApiData] = useState([]);
  const [apiDataUser, setApiDataUser] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const [errorFilter, setErrorFilter] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectUsername, setSelectUsername] = useState("");
  const [deviceFilter, setDeviceFilter] = useState("");
  const [indexStart, setIndexStart] = useState(0);
  const [nextShow, setNextShow] = useState(false);
  const [prevShow, setPrevShow] = useState(false);

  const pageSize = 10;

  const previous = () => {
    setIndexStart(indexStart - pageSize);
    setPageNumber(pageNumber - 1);
  };
  const next = () => {
    setIndexStart(indexStart + pageSize);
    setPageNumber(pageNumber + 1);
  };

  const handleSelect = (userIdTemp, firstName) => {
    setSelectedOption(userIdTemp);
    setSelectUsername(firstName);
    // Do something with the selected option
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  const getDeviceHealthReport = async (indexStart) => {
    setShowLoader(true);
    var result = await Report.getUserDeviceHealth(
      indexStart,
      pageSize,
      startDateTime,
      endDateTime,
      selectedOption,
      filter,
      deviceFilter,
      errorFilter
    );
    setShowLoader(false);
    var tableData = [];
    try {
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        // console.log("resultJSONData", resultJSONData);
        setDataLength(resultJSONData.length);
        if (resultJSONData.length > 0) {
          var firstName = "",
            deviceType = "",
            deviceStatus = "",
            deviceModel = "",
            deviceOrderId = "",
            uniqueId = "",
            versionNumber = "",
            totalMobileData = "",
            totalWifiData = "",
            periodicalMobileData = "",
            periodicalWifiData = "",
            createdTime = "",
            appVersion = "",
            remote = "",
            userAgent = "",
            keyvalueTemp = "",
            batteryLevel = "",
            userIdTemp = "";
          for (let i = 0; i < resultJSONData.length; i++) {
            var userApp = resultJSONData[i];
            var userDetail = ValueFormat.parseAsObject(userApp.userDetail);
            var userDeviceDetail = ValueFormat.parseAsObject(
              userApp.userDeviceDetail
            );
            firstName = ValueFormat.parseAsString(ValueFormat.capitalizeAllLetter(userDetail.firstName));
            userIdTemp = userApp.userId || "";
            var totalCounts = ValueFormat.parseAsInt(userApp.totalCount);
            setTotalPageCount(Math.ceil(totalCounts / pageSize));
            setNextShow(indexStart + pageSize < totalCounts);

            if (userDeviceDetail.deviceType === "ANDROID") {
              deviceType = "Android"
            } else if (userDeviceDetail.deviceType === "ANDROID_SDK"){
              deviceType = "Android SDK"
            } else if (userDeviceDetail.deviceType === "IOS") {
              deviceType = "iOS"
            } else if (userDeviceDetail.deviceType === "IOS_SDK"){
              deviceType = "iOS SDK"
            } else  {
              deviceType = "N/A"
            }
            // deviceType = ValueFormat.parseAsString(
            //   ValueFormat.capitalizeFirstLetter(userDeviceDetail.deviceType)
            // );
            deviceModel = ValueFormat.parseAsString(
              userDeviceDetail.deviceModelName
            );
            uniqueId = ValueFormat.parseAsString(
              userDeviceDetail.deviceUniqueId
            );
            deviceStatus = ValueFormat.parseAsString(
              ValueFormat.capitalizeFirstLetterAndFormat(
                userApp.userDeviceActive
              )
            );
            deviceOrderId = ValueFormat.parseAsInt(userDeviceDetail.deviceOrderId);
            versionNumber = ValueFormat.parseAsInt(userDeviceDetail.deviceVersionNumber);
            createdTime = ValueFormat.parseAsString(userApp.createdAtToTimeZone);
            totalMobileData = ValueFormat.parseAsFloat(userApp.totalMobileDataUsage);
            totalWifiData = ValueFormat.parseAsFloat(userApp.totalWifiDataUsage);
            periodicalMobileData = ValueFormat.parseAsFloat(userApp.mobileDataUsage);
            periodicalWifiData = ValueFormat.parseAsFloat(userApp.wifiDataUsage);
            // appVersion = ValueFormat.parseAsFloat(userApp.appVersion);
            // remote = ValueFormat.parseAsFloat(userApp.remoteAddress);
            // userAgent = ValueFormat.parseAsString(userApp.userAgent);
            // errorType = ValueFormat.parseAsString(
            //   ValueFormat.capitalizeFirstLetterAndFormat(userApp.errorType)
            // );
            batteryLevel = ValueFormat.parseAsFloat(userApp.batteryLevel);
            // keyvalueTemp =
            //   userApp.keyValues.map((val) =>
            //     ValueFormat.capitalizeFirstLetterAndFormat(val.key)
            //   ) || [];

            var appExceptiondetails = {
              firstName: firstName,
              deviceType: deviceType,
              uniqueId: uniqueId,
              versionNumber: versionNumber,
              //   appVersion: appVersion,
              //   remote: remote,
              //   userAgent: userAgent,
              //   errorType: errorType,
              batteryLevel: batteryLevel,
              //   keyvalueTemp: keyvalueTemp,
              deviceOrderId: deviceOrderId,
              userIdTemp: userIdTemp,
              deviceModel: deviceModel,
              deviceStatus: deviceStatus,
              createdTime: createdTime,
              totalMobileData: totalMobileData,
              totalWifiData: totalWifiData,
              periodicalMobileData: periodicalMobileData,
              periodicalWifiData: periodicalWifiData,
            };
            tableData.push(appExceptiondetails);
            setApiData(tableData);
          }
        }
      } else {
        var resultJSONCode = resultJSON.error.code;
        if (resultJSONCode == "E007" && userType == "USER") {
          ToastService.errormsg(displayText.ER_007);
          setTimeout(() => {
            window.location.href = "/dashboard";
          }, 3500);
        } else if (resultJSONCode == "E007" && userType == "ADMIN") {
          ToastService.errormsg(displayText.ER_007);
          setTimeout(() => {
            window.location.href = "/adminDashboard";
          }, 3500);
        }
        console.log("Status failed");
      }
    } catch (errMes) { }
    if (indexStart == 0) {
      setPrevShow(false);
    } else if (indexStart > 1) {
      setPrevShow(true);
    }
  };

  const getUserList = async () => {
    // setShowLoader(true);
    var result = await Report.getUserListReport();
    // setShowLoader(false);
    var userData = [];
    try {
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        // console.log("resultJSONData1", resultJSONData);
        // setDataLength(resultJSONData.length);
        if (resultJSONData.length > 0) {
          var firstName = "",
            userIdTemp = "";
          for (let i = 0; i < resultJSONData.length; i++) {
            var userListData = resultJSONData[i];
            firstName = ValueFormat.parseAsString(
              ValueFormat.capitalizeAllLetter(userListData.firstName)
            );
            userIdTemp = userListData.id;

            var userDataListTemp = {
              firstName: firstName,
              userIdTemp: userIdTemp,
            };

            userData.push(userDataListTemp);
            setApiDataUser(userData);
          }
        }
      } else {
        // var resultJSONCode = resultJSON.error.code;
        // if (resultJSONCode == "E007" && userType == "USER") {
        //   ToastService.errormsg(displayText.ER_007);
        //   setTimeout(() => {
        //     window.location.href = "/dashboard";
        //   }, 3500);
        // } else if (resultJSONCode == "E007" && userType == "ADMIN") {
        //   ToastService.errormsg(displayText.ER_007);
        //   setTimeout(() => {
        //     window.location.href = "/adminDashboard";
        //   }, 3500);
        // }
        console.log("Status failed");
      }
    } catch (errMes) { }
  };

  const [count, setCount] = useState(0);
  useEffect(() => {
      setPageNumber(1);
      if (indexStart == 0) setCount((prev) => prev + 1);
      else setIndexStart(0);
  }, [startDateTime,endDateTime,selectedOption,filter,deviceFilter,errorFilter]);

  useEffect(() => {
    if (count > 0) getDeviceHealthReport(indexStart);
  }, [indexStart, count]);

  useEffect(() => {
    getUserList();
  }, []);

  useEffect(() => {
    setLoginType(localStorage.getItem(SC_USER_LOGIN_TYPE));
  }, [loginType]);

  return (
    <div className="page-content">
      {showLoader && <Loader />}
      <Container fluid>
        <Breadcrumbs title="Battery Level" breadcrumbItem="Battery Level" />
        {/* {loginType == "SUPER_ADMIN" ? (
          <span
            style={{
              fontSize: "14px",
              fontWeight: 400,
            }}
          >
            Welcome, You are viewing{" "}
            <span
              style={{
                fontSize: "13px",
                fontWeight: 800,
              }}
            >
              {userFirstName}'s
            </span>{" "}
            App Exception
          </span>
        ) : ( */}
        <Row className="my-2 py-2">
          <Col xs={12} className="d-flex justify-content-start align-items-center text-black">
            <h5 className="mb-0">User Device Health</h5>
          </Col>
        </Row>
        {/* )}  */}

        <Row>
          <Col className="col-12 mt-2">
            <Card>
              <CardHeader style={{ padding: "0px" }}>
                <Row>
                  <Col
                    md={4}
                    style={{
                      borderRight: "1px solid #DFDEDE",
                      paddingRight: 0,
                      paddingLeft: 0,
                    }}
                  >
                    <div
                      style={{
                        marginLeft: "10px",
                        borderBottom: "1px dashed #DFDEDE",
                      }}
                    >
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                      >
                        Status Selection
                      </h4>
                    </div>
                    <div className="mt-2 mb-2" style={{ textAlign: "center" }}>
                      <button
                        type="button"
                        className={
                          errorFilter == ""
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setErrorFilter("")}
                        data-tooltip-id="alle"
                      >
                        All
                        <ReactTooltip
                          id="alle"
                          place="top"
                          content="All"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          errorFilter == "ACTIVE"
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setErrorFilter("ACTIVE")}
                        data-tooltip-id="active"
                      >
                        Active
                        <ReactTooltip
                          id="active"
                          place="top"
                          content="Active"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          errorFilter == "INACTIVE"
                            ? "btn btn-primary btn-sm m-1"
                            : "btn btn-soft-secondary btn-sm m-1"
                        }
                        onClick={() => setErrorFilter("INACTIVE")}
                        data-tooltip-id="inactive"
                      >
                        Inactive
                        <ReactTooltip
                          id="inactive"
                          place="top"
                          content="Inactive"
                          style={{ background: "black" }}
                        />
                      </button>
                    </div>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      borderRight: "1px solid #DFDEDE",
                      paddingRight: 0,
                      paddingLeft: 0,
                    }}
                  >
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                      >
                        Device Selection
                      </h4>
                    </div>
                    <div className="mt-2 mb-2" style={{ textAlign: "center" }}>
                      <button
                        type="button"
                        className={
                          deviceFilter == ""
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setDeviceFilter("")}
                        data-tooltip-id="allBl"
                      >
                        All
                        <ReactTooltip
                          id="allBl"
                          place="top"
                          content="All"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          deviceFilter == "ANDROID"
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setDeviceFilter("ANDROID")}
                        data-tooltip-id="androidBl"
                      >
                        Android
                        <ReactTooltip
                          id="androidBl"
                          place="top"
                          content="Android"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          deviceFilter == "IOS"
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setDeviceFilter("IOS")}
                        data-tooltip-id="iosBl"
                      >
                        iOS
                        <ReactTooltip
                          id="iosBl"
                          place="top"
                          content="iOS"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          deviceFilter == "ANDROID_SDK"
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setDeviceFilter("ANDROID_SDK")}
                        data-tooltip-id="androidBlsdk"
                      >
                        Android SDK
                        <ReactTooltip
                          id="androidBlsdk"
                          place="top"
                          content="Android SDK"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          deviceFilter == "IOS_SDK"
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setDeviceFilter("IOS_SDK")}
                        data-tooltip-id="iosBlsdk"
                      >
                        iOS SDK
                        <ReactTooltip
                          id="iosBlsdk"
                          place="top"
                          content="iOS SDK"
                          style={{ background: "black" }}
                        />
                      </button>
                    </div>
                  </Col>
                  <Col
                    md={3}
                    style={{
                      borderRight: "1px solid #DFDEDE",
                      paddingRight: 0,
                      paddingLeft: 0,
                    }}
                  >
                    <div style={{ borderBottom: "1px dashed #DFDEDE" }}>
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                      >
                        Duration Selection
                      </h4>
                    </div>
                    <div style={{ textAlign: "center", marginTop: "12px" }}>
                      <button
                        type="button"
                        className={
                          filter == "" && !modalIsOpen
                            ? "btn btn-primary btn-sm me-1"
                            : "btn btn-soft-secondary btn-sm me-1"
                        }
                        onClick={() => setFilter("")}
                        data-tooltip-id="allBl"
                      >
                        All
                        <ReactTooltip
                          id="allBl"
                          place="top"
                          content="All"
                          style={{ background: "black" }}
                        />
                      </button>
                      <button
                        type="button"
                        className={
                          filter == "CURRENT_DAY" && !modalIsOpen
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilter("CURRENT_DAY")}
                        data-tooltip-id="cd"
                      >
                        CD
                        <ReactTooltip
                          id="cd"
                          place="top"
                          content="Current Day"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          filter == "CURRENT_WEEK" && !modalIsOpen
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilter("CURRENT_WEEK")}
                        data-tooltip-id="cw"
                      >
                        CW
                        <ReactTooltip
                          id="cw"
                          place="top"
                          content="Current Week"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          filter == "CURRENT_MONTH" && !modalIsOpen
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilter("CURRENT_MONTH")}
                        data-tooltip-id="cm"
                      >
                        CM
                        <ReactTooltip
                          id="cm"
                          place="top"
                          content="Current Month"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          filter == "CURRENT_YEAR" && !modalIsOpen
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => setFilter("CURRENT_YEAR")}
                        data-tooltip-id="cy"
                      >
                        CY
                        <ReactTooltip
                          id="cy"
                          place="top"
                          content="Current Year"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                      <button
                        type="button"
                        className={
                          filter == "CUSTOM_DURATION" || modalIsOpen
                            ? "btn btn-primary btn-sm"
                            : "btn btn-soft-secondary btn-sm"
                        }
                        onClick={() => {
                          setIsOpen(true);
                        }}
                        data-tooltip-id="dfdf"
                      >
                        <FontAwesomeIcon icon={faClock} />
                        <ReactTooltip
                          id="dfdf"
                          place="top"
                          content="Custom Date Selection"
                          style={{ background: "black" }}
                        />
                      </button>{" "}
                    </div>
                  </Col>
                  <Col
                    md={2}
                    style={{
                      paddingRight: 0,
                      paddingLeft: 0,
                    }}
                  >
                    <div
                      style={{
                        marginRight: "10px",
                        borderBottom: "1px dashed #DFDEDE",
                      }}
                    >
                      <h4
                        className="card-title mb-1 mt-1"
                        style={{ textAlign: "center" }}
                      >
                        User Selection
                      </h4>
                    </div>
                    <div style={{ textAlign: "center", marginTop: "12px" }}>
                      <Dropdown
                        isOpen={dropdownOpen}
                        toggle={toggleDropdown}
                        size="sm"
                      >
                        <DropdownToggle caret color="primary">
                          {selectUsername ? selectUsername : "All Users"}
                          <FaAngleDown style={{ marginLeft: "5px" }} />
                        </DropdownToggle>
                        <DropdownMenu
                          style={{ maxHeight: "200px", overflowY: "auto" }}
                        >
                          <DropdownItem onClick={() => handleSelect(" ")}>
                            All Users
                          </DropdownItem>
                          {apiDataUser.map((user) => (
                            <DropdownItem
                              key={user.userIdTemp}
                              onClick={() =>
                                handleSelect(user.userIdTemp, user.firstName)
                              }
                            >
                              {user.firstName}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ padding: "0px" }}>
                <Row>
                  <Col xl={12} md={12} sm={12}>
                    <div className="table-responsive mt-3">
                      <table className="table table-bordered mb-0">
                        <thead className="cardHeader">
                          <tr>
                            <th
                              data-tooltip-id="Fn"
                              style={{ fontSize: "13px", fontWeight: 600, whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 40  }}
                            >
                              First Name
                              <ReactTooltip
                                id="Fn"
                                place="top"
                                content="First Name"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="err"
                              style={{ fontSize: "13px", fontWeight: 600, whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }}
                            >
                              Device Status
                              <ReactTooltip
                                id="err"
                                place="top"
                                content="Device Status"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="deviceType"
                              style={{ fontSize: "13px", fontWeight: 600, whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }}
                            >
                              Device Type
                              <ReactTooltip
                                id="deviceType"
                                place="top"
                                content="Device Type"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="DeviceModel"
                              style={{ fontSize: "13px", fontWeight: 600, whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 30 }}
                            >
                              Device Model
                              <ReactTooltip
                                id="DeviceModel"
                                place="top"
                                content="Device Model"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="DeviceOrderId"
                              style={{ fontSize: "13px", fontWeight: 600, whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }}
                            >
                              Device <br />Order Id
                              <ReactTooltip
                                id="DeviceOrderId"
                                place="top"
                                content="Device Order Id"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="versionNumber"
                              style={{ fontSize: "13px", fontWeight: 600, whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }}
                            >
                              Device <br />Version
                              <ReactTooltip
                                id="versionNumber"
                                place="top"
                                content="Device Version Number"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="uniqueId"
                              style={{ fontSize: "13px", fontWeight: 600, whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }}
                            >
                              Device Unique Id
                              <ReactTooltip
                                id="uniqueId"
                                place="top"
                                content="Device Unique Id"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="createdTime"
                              style={{ fontSize: "13px", fontWeight: 600, whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 30 }}
                            >
                              Created Time
                              <ReactTooltip
                                id="createdTime"
                                place="top"
                                content="Created Time"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="totWifi"
                              style={{ fontSize: "13px", fontWeight: 600, whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }}
                            >
                              Total <br />WIFI Data
                              <ReactTooltip
                                id="totWifi"
                                place="top"
                                content="Total WIFI Data"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="totMobData"
                              style={{ fontSize: "13px", fontWeight: 600, whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }}
                            >
                              Total <br />Mobile Data
                              <ReactTooltip
                                id="totMobData"
                                place="top"
                                content="Total Mobile Data"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="periodWifi"
                              style={{ fontSize: "13px", fontWeight: 600, whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }}
                            >
                              Periodical <br />WIFI Data
                              <ReactTooltip
                                id="periodWifi"
                                place="top"
                                content="Periodical WIFI Data"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="periodMob"
                              style={{ fontSize: "13px", fontWeight: 600, whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }}
                            >
                              Periodical <br />Mobile Data
                              <ReactTooltip
                                id="periodMob"
                                place="top"
                                content="Periodical Mobile Data"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              data-tooltip-id="batterylevel"
                              style={{ fontSize: "13px", fontWeight: 600, whiteSpace: 'nowrap', paddingLeft: 10, paddingRight: 10 }}
                            >
                              Battery <br />Level
                              <ReactTooltip
                                id="batterylevel"
                                place="top"
                                content="Battery Level"
                                style={{ background: "black" }}
                              />
                            </th>
                            {/* <th
                              data-tooltip-id="keyval"
                              style={{fontSize: "13px",fontWeight: 600, padding: "13px" }}
                            >
                              Key Values
                              <ReactTooltip
                                id="keyval"
                                place="top"
                                content="Key Values"
                                style={{ background: "black" }}
                              />
                            </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {dataLength > 0 ? (
                            apiData.map((apiDataTemp, i) => (
                              <tr key={i}>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.firstName}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.deviceStatus}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.deviceType}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.deviceModel}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.deviceOrderId}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.versionNumber}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.uniqueId}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.createdTime}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.totalMobileData}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.totalWifiData}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.periodicalMobileData}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.periodicalWifiData}
                                  </span>
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <span style={{ fontSize: "13px" }}>
                                    {apiDataTemp.batteryLevel}
                                  </span>
                                </td>
                              </tr>
                            ))
                          ) : dataLength === null ? (
                            <tr>
                              {" "}
                              <td colSpan="13">
                                <div
                                  style={{
                                    color: "green",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100px",
                                    width: "100%",
                                  }}
                                >
                                  {displayText.FETCHING_DATA}
                                </div>
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              {" "}
                              <td colSpan="13">
                                <div
                                  style={{
                                    color: "black",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100px",
                                    width: "100%",
                                  }}
                                >
                                  {displayText.NO_RECORDS_FOUND}
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-flex justify-content-between mb-3">
              <button
                type="button"
                className="btn btn-outline-danger btn-sm"
                onClick={previous}
                style={{
                  visibility: prevShow ? "visible" : "hidden",
                  width: "45px",
                  float: "left",
                }}
              >
                Prev
              </button>
              {dataLength > 0 ? (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="pagination">
                    <a>{pageNumber}</a>
                  </div>
                  <p
                    style={{
                      marginTop: "6px",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  >
                    of
                  </p>
                  <div className="pagination ">
                    <a>{totalPageCount}</a>
                  </div>
                </div>
              ) : null}
              {dataLength > 0 ? (
              <button
                type="button"
                className="btn btn-outline-success btn-sm"
                onClick={next}
                style={{
                  visibility: nextShow ? "visible" : "hidden",
                  float: "hidden",
                }}
              >
                Next
              </button>
              ) : null}
            </div>
          </Col>
        </Row>
        <DateTimePickerPopup
          modalOpen={modalIsOpen}
          modalClose={setIsOpen}
          setStartDateTime={setStartDateTime}
          setEndDateTime={setEndDateTime}
          setFilter={setFilter}
          filter={filter}
        />
      </Container>
      <ToastContainer style={{ width: "auto" }} />
    </div>
  );
}
