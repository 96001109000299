import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import AdminDashboardController from "../../../controller/Dashboard/AdminDasahboardController";
import ValueFormat from "../../../util/ValueFormat";
import Loader from "../../../components/Common/loader";
import { Card, CardBody, CardHeader } from "reactstrap";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { displayText } from "../../../constant/MessageConstant";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateTimePickerPopup from "../../ModelPopup/DateTimePopup";

const RadialChart = ({ loaderCount, safetycategory }) => {
  const [series, setSeries] = useState([]);

  const [filter, setFilter] = useState("CURRENT_YEAR");
  const [showLoader, setShowLoader] = useState(false);
  const [safeCategoryData, setSafeCategoryData] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const options = {
    chart: {
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        track: {
          show: true,
          fill: "black",
          strokeWidth: "98%",
          opacity: 0.9,
          margin: 5,
          dropShadow: {
            enabled: true,
            blur: 0.09,
            opacity: 0.3,
          },
        },
        dataLabels: {
          name: {
            fontSize: "22px",
          },
          value: {
            fontSize: "16px",
            formatter: function (val) {
              return val;
            },
          },
          total: {
            show: true,
            label: "Total",
            formatter: function (w) {
              let data = w.globals.seriesTotals.reduce((a, b) => {
                return a + b;
              }, 0);

              data = parseFloat(data).toFixed(0);

              return data;
            },
          },
        },
      },
    },

    labels: [
      "Safe",
      "Sportive",
      "Quite Safe",
      "Reckless",
      "Quite Dangerous",
      "Dangerous",
    ],

    colors: ["#556ee6", "#34c38f", "#f46a6a", "#f1b44c"],
  };

  const getSafetyCategory = async () => {
    // setShowLoader(true)
    // setDataDisplay(null)
    loaderCount((current) => current + 1);

    setSafeCategoryData(null);

    var result = await AdminDashboardController.adminSafetyCategoryController(
      filter, startDateTime, endDateTime
    );
    // setShowLoader(false)
    loaderCount((current) => current - 1);
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    if (status == "SUCCESS") {
      var resultJSONData = resultJSON.data;
      if (resultJSONData.length > 0) {
        setSafeCategoryData(null);
        // console.log("SafetyCategoryTotal: " + JSON.stringify(resultJSONData));
        var totalRideCount = 0;
        var safetyRidePer = 0;
        var sportiveRidePer = 0;
        var quietSafeRidePer = 0;
        var recklessRidePer = 0;
        var quietDangerousRidePer = 0;
        var dangerRidePer = 0;
        var driverPerData = [];
        resultJSONData.map((apiData) => {
          var rideBoxList = ValueFormat.parseAsArray(apiData.rideBoxList);
          rideBoxList.map((rideList) => {
            totalRideCount = rideList.noOfRecords + totalRideCount;
          });

          rideBoxList.map((rideList) => {
            var rideBox = rideList.rideBox;
            var totalRides = rideList.totalRides;
            switch (rideBox) {
              case 1:
                safetyRidePer = parseInt(parseFloat(totalRides).toFixed(2));
                break;
              case 2:
                sportiveRidePer = parseInt(parseFloat(totalRides).toFixed(2));
                break;
              case 3:
                quietSafeRidePer = parseInt(parseFloat(totalRides).toFixed(2));
                break;
              case 4:
                recklessRidePer = parseInt(parseFloat(totalRides).toFixed(2));
                break;
              case 5:
                quietDangerousRidePer = parseInt(
                  parseFloat(totalRides).toFixed(2)
                );
                break;
              case 6:
                dangerRidePer = parseInt(parseFloat(totalRides).toFixed(2));
                break;
              default:
                totalRides;
                break;
            }
          });
          driverPerData.push(
            safetyRidePer,
            sportiveRidePer,
            quietSafeRidePer,
            recklessRidePer,
            quietDangerousRidePer,
            dangerRidePer
          );
          safetycategory({ data: driverPerData, filter: filter })
          setSeries(driverPerData);
        });
        setSafeCategoryData(
          Object.values(driverPerData).some((value) => value !== 0)
        );
      } else {
        setSafeCategoryData(false);
      }

    } else {
      const code = resultJSON.error.code;
      if (code == 'E007') {
        setSafeCategoryData("failed");

      }
    }
  };

  useEffect(() => {
    getSafetyCategory();
  }, [filter, startDateTime, endDateTime]);

  return (
    <React.Fragment>
      {/* {showLoader &&<Loader /> } */}
      <Card style={{ height: 400 }}>
        <CardHeader className="cardHeader">
          <div className="d-flex flex-wrap align-items-center justify-content-center">
            <h5 className="card-title me-2">Safety Category</h5>
            <div className="ms-auto">
              <div>
                <button
                  type="button"
                  data-tooltip-id="cd"
                  className={
                    filter == "CURRENT_DAY"
                      ? "btn btn-primary btn-sm"
                      : "btn btn-soft-secondary btn-sm"
                  }
                  onClick={() => setFilter("CURRENT_DAY")}
                >
                  CD
                  <ReactTooltip
                    id="cd"
                    place="top"
                    content="Current Day"
                    style={{ backgroundColor: "black" }}
                  />
                </button>{" "}
                <button
                  type="button"
                  data-tooltip-id="cw"
                  className={
                    filter == "CURRENT_WEEK"
                      ? "btn btn-primary btn-sm"
                      : "btn btn-soft-secondary btn-sm"
                  }
                  onClick={() => setFilter("CURRENT_WEEK")}
                >
                  CW
                  <ReactTooltip
                    id="cw"
                    place="top"
                    content="Current Week"
                    style={{ backgroundColor: "black" }}
                  />
                </button>{" "}
                <button
                  type="button"
                  data-tooltip-id="cmss"
                  className={
                    filter == "CURRENT_MONTH"
                      ? "btn btn-primary btn-sm"
                      : "btn btn-soft-secondary btn-sm"
                  }
                  onClick={() => setFilter("CURRENT_MONTH")}
                >
                  CM
                  <ReactTooltip
                    id="cmss"
                    place="top"
                    content="Current Month"
                    style={{ backgroundColor: "black" }}
                  />
                </button>{" "}
                <button
                  type="button"
                  data-tooltip-id="cysss"
                  className={
                    filter == "CURRENT_YEAR"
                      ? "btn btn-primary btn-sm"
                      : "btn btn-soft-secondary btn-sm"
                  }
                  onClick={() => setFilter("CURRENT_YEAR")}
                >
                  CY
                  <ReactTooltip
                    id="cysss"
                    place="top"
                    content="Current Year"
                    style={{ backgroundColor: "black" }}
                  />
                </button>{" "}

                <button
                  type="button"
                  className={
                    filter == "CUSTOM_DURATION"
                      ? "btn btn-primary btn-sm"
                      : "btn btn-soft-secondary btn-sm"
                  }
                  onClick={() => { setIsOpen(true); }}
                  data-tooltip-id="dfdf"
                >
                  <FontAwesomeIcon icon={faClock} />
                  <ReactTooltip
                    id="dfdf"
                    place="top"
                    content="Custom Date Selection"
                    style={{ background: "black" }}
                  />
                </button>{" "}
              </div>
            </div>
          </div>
        </CardHeader>
        {safeCategoryData === true ? (
          <CardBody>
            <ReactApexChart
              options={options}
              series={series}
              type="radialBar"
              height="300"
            />
          </CardBody>
        ) : safeCategoryData === false ? (
          <CardBody>
            <p className="data_not_available">
              {displayText.DATA_NOT_AVAILABLE}
            </p>
            <ReactApexChart
              options={options}
              series={series}
              type="radialBar"
              height="300"
            />
          </CardBody>
        ) : safeCategoryData === "failed" ? (
          <CardBody>
            <p className="data_not_available">
              {displayText.ER_007}
            </p>
            <ReactApexChart
              options={options}
              series={series}
              type="radialBar"
              height="300"
            />
          </CardBody>
        ) : (
          <CardBody>
            <p className="fetch_data">{displayText.FETCHING_DATA}</p>
            <ReactApexChart
              options={options}
              series={series}
              type="radialBar"
              height="300"
            />
          </CardBody>
        )}
      </Card>
      <DateTimePickerPopup modalOpen={modalIsOpen} modalClose={setIsOpen} setStartDateTime={setStartDateTime} setEndDateTime={setEndDateTime} setFilter={setFilter}/>
    </React.Fragment>
  );
};

export default RadialChart;
