import React, { Component, useState } from "react"
import ReactEcharts from "echarts-for-react"
import * as echarts from 'echarts';
import { Tooltip as ReactTooltip } from "react-tooltip";

const RedAlertsBubble = ({ redBubblePlot }) => {

  const [data, setData] = useState(redBubblePlot);

  const legendData = ["Over speed", "Curve", "Force acceleration", "Pedestrian crossing", "School zone", "Stop sign", "Priority",
    "Animal crossing", "Caution", "Hill", "Hill downWards", "Hill upwnWards", "Icy conditions", "Intersection",
    "Lane merge", "Low gear area", "Mobile use", "Narrow road", "No OverTaking", "Railway crossing",
    "Risk Of grounding", "Slippery roads", "Traffic light", "Wind", "Winding road", "Yield", "Sudden braking",].filter((cat) => data.some((item) => item[0] === cat));

  const hexColors = {
    'Sudden braking': '#f44336',
    'Over speed': '#2196f3',
    'Curve': '#ffeb3b',
    'Force acceleration': '#7bb57d',
    'Pedestrian crossing': '#8b00ff',
    'School zone': '#db67a6',
    'Stop sign': '#f0b74f',
    'Priority': '#ffc0cb',
    'Animal crossing': '#9bbdac',
    'Caution': '#807c7c',
    'Hill': '#ad8b7b',
    'Hill downWards': '#8c488c',
    'Hill upwnWards': '#91910d',
    'Icy conditions': '#079494',
    'Intersection': '#993f3f',
    'Lane merge': '#ffa07a',
    'Low gear area': '#5e5eb5',
    'Mobile use': '#c9c479',
    'Narrow road': '#cd5c5c',
    'No overTaking': '#b299c4',
    'Railway crossing': '#808080',
    'Risk Of grounding': '#a16ca1',
    'Slippery roads': '#783434',
    'Traffic light': '#e6853c',
    'Wind': '#008080',
    'Winding road': '#714287',
    'Yield': '#adbd37',
  };
  const getOption = () => {
    const singleDataTime = data.length === 1 ? new Date(data[0][1]).getTime() : null;
    return {
      tooltip: {
        height: 200, // set fixed height for tooltip
        show: true,
        formatter: function (params) {
          const date = params.value[1].toLocaleDateString();
          const time = params.value[1].toLocaleTimeString(undefined, { hour12: false, hour: '2-digit', minute: '2-digit' });
          const size = params.value[2];
          // const rstyle = params.value[3];
          // const dstate = params.value[4];
          // const dstyle = params.value[5];

          // Create canvas element
          const canvas = document.createElement("canvas");
          canvas.width = 200;
          canvas.height = 160;

          // Get canvas context
          const ctx = canvas.getContext("2d");

          // Draw speed limit bar graph
          const speedLimitBarWidth = 25;
          const speedLimitBarHeight =
            (params.value[6] * canvas.height) / params.value[7];
          const speedLimitBarX = 60;
          const speedLimitBarY = canvas.height - speedLimitBarHeight;
          ctx.fillStyle = "#DC143C";
          ctx.fillRect(
            speedLimitBarX,
            speedLimitBarY,
            speedLimitBarWidth,
            speedLimitBarHeight
          );

          // Draw speed bar graph
          const speedBarWidth = 25;
          const speedBarHeight =
            (params.value[7] * canvas.height) / params.value[6];
          const speedBarX = 120;
          const speedBarY = canvas.height - speedBarHeight;
          ctx.fillStyle = "#3CB371";
          ctx.fillRect(speedBarX, speedBarY, speedBarWidth, speedBarHeight);

          // Draw x-axis line
          ctx.strokeStyle = "#525354";
          ctx.lineWidth = 1;
          ctx.beginPath();
          ctx.moveTo(8, canvas.height);
          ctx.lineTo(canvas.width, canvas.height);
          ctx.stroke();

          // Draw y-axis line
          ctx.strokeStyle = "#525354";
          ctx.lineWidth = 0.7;
          ctx.beginPath();
          ctx.moveTo(speedLimitBarX - speedLimitBarWidth / 0.49, 0);
          ctx.lineTo(speedLimitBarX - speedLimitBarWidth / 0.49, canvas.height);
          ctx.stroke();

          // Create img element with data URL
          const img = document.createElement("img");
          img.src = canvas.toDataURL();

          // Generate tooltip content
          const tooltipContent =
            '<div style="text-align: left;">' +
            '<span style="display: inline-block; width: 120px;">Date: </span>' +
            date +
            "<br>" +
            '<span style="display: inline-block; width: 120px;">Time: </span>' +
            time +
            "<br>" +
            '<span style="display: inline-block; width: 120px;">Risk Value: </span>' +
            size +
            "<br>" +
            // '<span style="display: inline-block; width: 120px;">Risk Style: </span>' +
            // rstyle +
            // "<br>" +
            // '<span style="display: inline-block; width: 120px;">Driver State: </span>' +
            // dstate +
            // "<br>" +
            // '<span style="display: inline-block; width: 120px;">Driving Style: </span>' +
            // dstyle +
            "<br>" +
            // img.outerHTML +
            "</div>";

          return tooltipContent;
        },
      },
      // legend: {
      //   show: data.length > 0,
      //   data: legendData,
      // },
      // visualMap: {
      //   show: data.length > 0,
      //   top: 85,
      //   right: 25,
      //   seriesIndex: 0,
      //   dimension: 0,
      //   pieces: legendData.map((cat) => {
      //     return {
      //       value: cat,
      //       color: hexColors[cat],
      //       label: "" + data.filter(item => item[0] === cat).length
      //     };
      //   }),
      //   outOfRange: {
      //     color: "#999",
      //   },
      // },
      dataZoom: [
        {
          orient: "vertical",
          startValue: "0",
          show: false,
          left: 32,
          top: "6%",
          height: "62%",
          width: 10,
          fillerColor: "rgba(46, 50, 56, 0.05)",
          borderColor: "rgba(46, 50, 56, 0)",
          textStyle: {
            color: "rgba(28, 31, 35, 0.6)",
            fontFamily: "Inter",
          },
          dataBackground: {
            areaStyle: {
              color: "rgba(46, 50, 56, 0.09)",
            },
          },
          handleIcon:
            "path://M15,30C15,21.71270751953125,21.71270751953125,15,30,15C38.28729248046875,15,45,21.71270751953125,45,30C45,38.28729248046875,38.28729248046875,45,30,45C21.71270751953125,45,15,38.28729248046875,15,30Z",
        },
        {
          type: "inside",
        },
        {
          type: "inside",
          xAxisIndex: [0, 1],
          start: 0,
          end: 100,
          height: 10,
          fillerColor: "rgba(46, 50, 56, 0.05)",
          borderColor: "rgba(46, 50, 56, 0)",
          textStyle: {
            color: "rgba(28, 31, 35, 0.6)",
            fontFamily: "Inter",
          },
          dataBackground: {
            areaStyle: {
              color: "rgba(46, 50, 56, 0.09)",
            },
          },
          handleIcon:
            "path://M15,30C15,21.71270751953125,21.71270751953125,15,30,15C38.28729248046875,15,45,21.71270751953125,45,30C45,38.28729248046875,38.28729248046875,45,30,45C21.71270751953125,45,15,38.28729248046875,15,30Z",
        },
        {
          show: false,
          xAxisIndex: [0, 1],
          type: "slider",
          top: "90%",
          start: 0,
          end: 0,
          height: 10,
          fillerColor: "rgba(46, 50, 56, 0.05)",
          borderColor: "rgba(46, 50, 56, 0)",
          textStyle: {
            color: "rgba(28, 31, 35, 0.6)",
            fontFamily: "Inter",
          },
          dataBackground: {
            areaStyle: {
              color: "rgba(46, 50, 56, 0.09)",
            },
          },
          handleIcon:
            "path://M15,30C15,21.71270751953125,21.71270751953125,15,30,15C38.28729248046875,15,45,21.71270751953125,45,30C45,38.28729248046875,38.28729248046875,45,30,45C21.71270751953125,45,15,38.28729248046875,15,30Z",
        },
      ],

      xAxis: {
        type: 'category',
        name: "Category",
        nameLocation: 'middle',
        // the default nameGap=15 would move the text to the right
        nameGap: 0,    
        nameTextStyle: {
          align: 'center',
          verticalAlign: 'top',
          fontFamily: "IBM Plex Sans, sans-serif",
          fontWeight:200,
          /**
           * the top padding will shift the name down so that it does not overlap with the axis-labels
           * t-l-b-r
           */
          padding: [130, 0, 0, 0],
        },
        boundaryGap: true,
        axisTick: {
          show: true,
          alignWithLabel: true,
          lineStyle: { color: '#6f7275', width: 1 }
        },
        axisLine: {
          show: false,
          lineStyle: { color: "#74788d", width: 1 }
        },
        axisLabel: {
          show: true,
          rotate: 90,
          fontSize: 13,
          align: 'right',
          color: '#6f7275',
        },
        splitLine: {
          alignWithLabel: true,
          show: true,
          lineStyle: { type: 'line', color: '#bfbfbf', offset: 0 },
        },
        axisPointer: {
          type: 'line',
          lineStyle: { color: '#6f7275', width: 1 }
        }
      },

      grid: {
        bottom: '50%',
        left: '16%',
        right: '10%'
      },

      yAxis: {
        type: "time",
        name: "Time",
        nameLocation: 'middle',
        nameGap: 75,
        nameTextStyle:{
          fontFamily: "IBM Plex Sans, sans-serif",
        fontWeight:200,
        },
        axisLine: {
          lineStyle: {
            color: "#74788d",
            width: 1,
          },
        }, textStyle: {
          color: "rgba(28, 31, 35, 0.6)",
          fontFamily: "Inter",
          fontSize: "10px"
        },

        axisLabel: {
          showMinLabel: true,
          showMaxLabel: true,
          textStyle: {
            color: "rgba(28, 31, 35, 0.6)",
            fontFamily: "Inter",
            fontSize: "10px",

          },
          formatter: function (value, i) {
            const date = new Date(value);
            let hours = date.getHours();
            hours = hours < 10 ? "0" + hours : hours;
            let minutes = date.getMinutes();
            minutes = minutes < 10 ? "0" + minutes : minutes;
            let seconds = date.getSeconds();
            seconds = seconds < 10 ? "0" + seconds : seconds;
            let milliseconds = date.getMilliseconds();
            milliseconds = milliseconds < 10 ? "00" + milliseconds : (milliseconds < 100 ? "0" + milliseconds : milliseconds);
            
            const time = hours + ":" + minutes + ":" + seconds 
            // + "." + milliseconds
            ;
        
            return time;
        },
        },
      min: singleDataTime ? singleDataTime - 1000 * 60 * 60 : null,
      max: singleDataTime ? singleDataTime + 2000 * 60 * 60 : null,
      },
      series: [
        {
          label: { show: true },
          type: "scatter",
          data: redBubblePlot,
          symbolSize: function (data) {
            let symbol = 0;

            if (data[2] == 91) {
              symbol = 5;
            } else if (data[2] == 92) {
              symbol = 5;
            } else if (data[2] == 93) {
              symbol = 5;
            } else if (data[2] == 94) {
              symbol = 5;
            } else if (data[2] == 95) {
              symbol = 10;
            } else if (data[2] == 96) {
              symbol = 10;
            } else if (data[2] == 97) {
              symbol = 10;
            } else if (data[2] == 98) {
              symbol = 15;
            } else if (data[2] == 99) {
              symbol = 15;
            } else if (data[2] == 100) {
              symbol = 15;
            } else {
              symbol = 0;
            }
            return symbol;
          },
          itemStyle: {
            color: function (params) {
              const categories = [
                "",
                "braking",
                "Over speed",
                "Curve",
                "Force acceleration",
                "Pedestrian crossing",
                "School zone",
                "Stop sign",
                "Priority",
                "Animal crossing",
                "Caution",
                "Hill",
                "Hill downWards",
                "Hill upwnWards",
                "Icy conditions",
                "Intersection",
                "Lane merge",
                "Low gear area",
                "Mobile use",
                "Narrow Road",
                "No overTaking",
                "Railway crossing",
                "Risk of grounding",
                "Slippery roads",
                "Traffic light",
                "Wind",
                "Winding road",
                "Yield",
                "cornering",
              ];

              const colors = [
                "#5c5c5c",
                "#f44336",
                "#2196f3",
                "#ffeb3b",
                "#7bb57d",
                "#8b00ff",
                "#db67a6",
                "#f0b74f",
                "#ffc0cb",
                "#9bbdac",
                "#807c7c",
                "#ad8b7b",
                "#8c488c",
                "#91910d",
                "#079494",
                "#993f3f",
                "#ffa07a",
                "#5e5eb5",
                "#c9c479",
                "#cd5c5c",
                "#b299c4",
                "#808080",
                "#a16ca1",
                "#783434",
                "#e6853c",
                "#008080",
                "#714287",
                "#adbd37",
                "#607D8B",
              ];
              const categoryIndex = categories.indexOf(params.data[0]);
              return colors[categoryIndex];
            },
            borderColor: '#6e706f', borderWidth: 0.5
          },
        },
      ],

    }
  };

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between">
        <ReactEcharts style={{ height: "430px", width: "95vw" }} option={getOption()} />
        <div className="p-12" style={{ width: "6vw", textAlign:"center" }}>
          <p>Counts</p>
          {legendData.map((cat, i) => (

            <div className="w-100" key={i}>

              <button data-tooltip-id={cat} className="w-80  btn btn-sm mb-2 " style={{
                height: "30px", background: hexColors[cat],
                color: "white",
                fontSize: "12px", fontWeight: "bold"
              }}>
                {data.filter(item => item[0] === cat).length}
              </button>
              <ReactTooltip
                id={cat}
                place="top"
                content={cat + " - " + data.filter(item => item[0] === cat).length}
                style={{ backgroundColor: "black", fontSize: "9px" }}
              />
            </div>
          ))}

        </div>
      </div>
    </React.Fragment>
  )
}

export default RedAlertsBubble