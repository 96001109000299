import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Card, CardBody, Row, Table, CardHeader } from "reactstrap";
import { getInvestedOverview } from "../../store/actions";
import ApexRadial from "./ApexRadial";
import UserDrivingScoreController from "../../controller/Dashboard/UserDashboardController";
import ValueFormat from "../../util/ValueFormat";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { displayText } from "../../constant/MessageConstant";
import ProgressBar from "react-bootstrap/ProgressBar";
import UserReactToPdf from "../../components/ExportScreenPdf/userReactToPdf";

const LeaderBoardDataList = [{ rideName: "-", riskCount: "-", score: "-" }];

const InvestedOverview = ({ loaderCount , getLeaderboardProps}) => {
  const dispatch = useDispatch();
  const [state, setState] = useState("AP");
  const [leaderBoardDataTemp, setLeaderBoardDataTemp] =
    useState(LeaderBoardDataList);
  const [deviceMode, setDeviceMode] = useState("");
  const [leaderBoard, setLeaderBoard] = useState("");
  const [lBoard, setLBoard] = useState(false);
  const [dataDisplay, setDataDisplay] = useState(false);
  const onSelectData = (data) => {
    setState(data);
    dispatch(getInvestedOverview(data));
  };

  const getLeaderBoardData = async (deviceMode, leaderBoard) => {

    try{
      setDataDisplay(null)
    setLeaderBoard(leaderBoard);
    loaderCount((current) => current + 1);
    var result = await UserDrivingScoreController.userLeaderBoard(deviceMode);
    loaderCount((current) => current - 1);
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    if(status == "SUCCESS"){
    var resultJSONData = resultJSON.data;
    // console.log("getLeaderBoardData: " + JSON.stringify(resultJSONData));
    var resultData = [];
    var disPlay = 0;

    resultJSONData.map((value) => {
      var name = ValueFormat.parseAsString(value.name);
      var drivingScore = Math.round(
        ValueFormat.parseAsFloat(value.drivingScore, 2)
      );
      disPlay = disPlay + 1;
      var riskCount = ValueFormat.parseAsInt(value.alertDataCount);
      var drivingScoreFullValue = ValueFormat.parseAsInt(value.drivingScore);


      // console.log("drivingScoreTemp: " + drivingScore);
      let upDateValue = {
        rideName: name,
        score: drivingScore,
        riskCount: riskCount,
        driveScoreValue: drivingScoreFullValue,
      };
      resultData.push(upDateValue);
getLeaderboardProps({data:resultData,filter:leaderBoard})
      setLeaderBoardDataTemp(resultData);
    });
    setDataDisplay(disPlay > 0 ? true : false);
  }else{
    const code = resultJSON.error.code;
    if(code == "E007"){
      setDataDisplay("failed")
    }
  }

}catch(e){
  // console.log("leaderboard err: " + e)
}
  };
  useEffect(() => {
    getLeaderBoardData("", "allLeaderBoard");
    dispatch(getInvestedOverview("AP"));
  }, []);

  useEffect(() => {
    setLBoard();
  }, [leaderBoard])

  const { InvestedData } = useSelector((state) => ({
    InvestedData: state.dashboard.InvestedData,
  }));

  return (
    <React.Fragment>
      <Col xl={8}>
        <Card style={{ height: "490px" }}>
          <CardHeader
            className="d-flex flex-wrap align-items-center cardHeader"
          >
            <h4 className="sub_heading me-2">
              Leaderboard <span style={{ fontSize: "11px", fontWeight: 400 }}>(Last 5 Rides)</span>
            </h4>
            <div className="ms-auto ">
              <div>
                <button
                  data-tooltip-id="al"
                  type="button"
                  className={
                    leaderBoard == "allLeaderBoard"
                      ? "btn btn-primary btn-sm"
                      : "btn btn-soft-secondary btn-sm"
                  }
                  onClick={() => getLeaderBoardData("", "allLeaderBoard")}
                >
                  All
                  <ReactTooltip
                    id="al"
                    place="top"
                    content="All"
                    style={{ backgroundColor: "black" }}
                  />
                </button>{" "}
                <button
                  data-tooltip-id="wk"
                  type="button"
                  className={
                    leaderBoard == "workLeaderBoard"
                      ? "btn btn-secondary btn-sm"
                      : "btn btn-soft-secondary btn-sm"
                  }
                  onClick={() => getLeaderBoardData("WORK", "workLeaderBoard")}
                >
                  Work
                  <ReactTooltip
                    id="wk"
                    place="top"
                    content="Work"
                    style={{ backgroundColor: "black" }}
                  />
                </button>{" "}
                <button
                  data-tooltip-id="pnl"
                  type="button"
                  className={
                    leaderBoard == "personalLeaderBoard"
                      ? "btn btn-secondary btn-sm"
                      : "btn btn-soft-secondary btn-sm"
                  }
                  onClick={() => {
                    if (leaderBoard === "personalLeaderBoard" && !dataDisplay) {
                        setDataDisplay("failed");
                    } else {
                        getLeaderBoardData("PERSONAL", "personalLeaderBoard");
                    }
                }}
                >
                  Personal
                  <ReactTooltip
                    id="pnl"
                    place="top"
                    content="Personal"
                    style={{ backgroundColor: "black" }}
                  />
                </button>{" "}
              </div>
            </div>
          </CardHeader>
         
          {dataDisplay === false ? (
                  <CardBody style={{ textAlign: "center", alignContent:"center" }}>
                      <p className="data_not_available">{displayText.DATA_NOT_AVAILABLE}</p>
                  </CardBody>
          ) : dataDisplay === null ? (
              <p className="fetch_data">{displayText.FETCHING_DATA}</p>
          ) : (
              <CardBody>
                  <Row className="align-items-center">
                      <div className="col-sm align-self-center">
                          <div className="table-responsive">
                              <Table className="table mb-0">
                                  <thead>
                                      <tr>
                                          <th style={{ fontSize: "13px" }}>Ride</th>
                                          <th style={{ fontSize: "13px" }}>Risk</th>
                                          <th style={{ fontSize: "13px" }}>Driving Score</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      {(leaderBoardDataTemp || []).map((leaderBoard, key) => (
                                          <tr key={key}>
                                              <td style={{ fontSize: "12px" }}>
                                                  {leaderBoard.rideName.charAt(0).toUpperCase() +
                                                      leaderBoard.rideName.slice(1).toLowerCase()}
                                              </td>
                                              <td style={{ fontSize: "12px" }}>
                                                  {leaderBoard.riskCount}
                                              </td>
                                              <td style={{ display: "flex", alignItems: 'center' }}>
                                                  <div data-tooltip-id={key + 1} style={{ fontSize: "12px", width: "20%", textAlign: "center" }}>
                                                      {leaderBoard.score}
                                                      <ReactTooltip
                                                          id={key + 1}
                                                          place="top"
                                                          content={leaderBoard.driveScoreValue}
                                                          style={{ backgroundColor: "black" }}
                                                      />
                                                  </div>
                                                  <ProgressBar
                                                      className="ms-2"
                                                      style={{ height: "5px", width: "60%", backgroundColor: "#0095FF", marginTop: '5px' }}
                                                      variant="warning"
                                                      now={leaderBoard.score}
                                                  />
                                              </td>
                                          </tr>
                                      ))}
                                  </tbody>
                              </Table>
                          </div>
                      </div>
                  </Row>
              </CardBody>
          )}

            <div style={{display:'none'}}><UserReactToPdf leaderBoardDataTemp={leaderBoardDataTemp}/></div>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default InvestedOverview;
