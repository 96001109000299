import React, { Component } from "react"
import cloneDeep from "lodash.clonedeep"
import ReactEcharts from "echarts-for-react"
import { color } from "echarts"

const  GuagesNumber = ({drivingStatus}) => {

  const getOption = () => {
    return {
    //   tooltip: {
    //     formatter: "{a} <br/>{b} : {c}%",
    //   },
    //   toolbox: {
    //     feature: {
    //       restore: {title: "Refresh"},
    //         saveAsImage: {title: "Download Image"}
    //     },
    //   },
      series:[
        {
          type: 'gauge',
          startAngle:"none",
          endAngle:"none",
          min:"none",
          max: "none",
          splitNumber:"none",
        //   itemStyle: {
        //     height:"10px",
        //     width:"10px",
        //     color: '#4B4D4E',
        //     shadowColor: '#4B4D4E',
        //     shadowBlur: 10,
        //     shadowOffsetX: 2,
        //     shadowOffsetY: 2
        //   },
        //   progress: {
        //     show: true,
        //     roundCap: true,
        //     width: 13
        //   },
          pointer:"none",
        //   axisLine: {
        //     roundCap: true,
        //     lineStyle: {
        //         color: [
        //             [0.2, "#AF40C3"],
        //             [0.8, "#AF40C3"],
        //             [1, "#AF40C3"],                    
        //           ],
        //       width: 13              
              
        //     }
        //   },
        //   axisTick: {
        //     splitNumber: 2,
        //     lineStyle: {
        //       width: 2,
        //       color: '#999'
        //     }
        //   },
        //   splitLine: {
        //     length: 12,
        //     lineStyle: {
        //       width: 3,
        //       color: '#999'
        //     }
        //   },
        //   axisLabel: {
        //     distance: 30,
        //     color: '#999',
        //     fontSize: 20
        //   },
        //   title: {
        //     show: false
        //   },
          detail: {
            backgroundColor: '#fff',
            borderColor: '#999',
            borderWidth: 0,
            width: '60%',
            lineHeight: 40,
            height: 60,
            borderRadius: 0,
            offsetCenter: [0, '35%'],
            valueAnimation: true,
            formatter: function (value) {
              return '{value|' + value.toFixed(0) + '}';
            },
            rich: {
              value: {
                fontSize: 80,
                fontWeight: 'bolder',
                color: '#6D716F'
              },
              unit: {
                fontSize: 50,
                color: '#999',
                padding: [0, 0, -10, 10]
              }
            }
          },
         
          data: [
            {
              value: drivingStatus.totalKilometer
            }
          ]
        }
      ]
    };
  };


    return (
      <React.Fragment>
        <ReactEcharts style={{ height: "150px" }} option={getOption()} />
      </React.Fragment>
    )
  }

export default GuagesNumber
