import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap";
import { getWalletBalance } from "../../store/actions";

import WalletPieChart from "./WalletPieChart";

import UserDrivingScoreController from "../../controller/Dashboard/UserDashboardController";
import ValueFormat from "../../util/ValueFormat";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { displayText } from "../../constant/MessageConstant";
import UserReactToPdf from "../../components/ExportScreenPdf/userReactToPdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import DateTimePickerPopup from "../ModelPopup/DateTimePopup";

const WalletBalance = ({ filter, loaderCount, getSafetyDataProps }) => {
  const safetyDataTemp = [
    { name: " Safe", bottomText: "Driver", name2: "-", value: 0, percentage: 0, class: "success" },
    { name: " Sportive", bottomText: "Driver", name2: "-", value: 0, percentage: 0, class: "primary" },
    { name: " Quite Safe", bottomText: "Driver", name2: "-", value: 0, percentage: 0, class: "info" },
    { name: "Reckless", bottomText: "Driver", name2: "-", value: 0, percentage: 0, class: "warning" },
    {
      name: " Quite Dangerous",
      bottomText: "Driver",
      name2: "-",
      value: 0,
      percentage: 0,
      class: "danger",
    },
    { name: "Dangerous", bottomText: "Driver", name2: "-", value: 0, percentage: 0, class: "secondary" },
  ];

  const [state, setState] = useState("ALL");
  // const [filter, setFilter] = useState("CURRENT_YEAR");
  const [safetyData, setSafetyData] = useState(safetyDataTemp);
  const [safetyPieData, setSafetyPieData] = useState([
    { value: 0, name: 'C1' },
    { value: 0, name: 'C2' },
    { value: 0, name: 'C3' },
    { value: 0, name: 'C4' },
    { value: 0, name: 'C5' },
    { value: 0, name: 'C6' }]);

  const [display, setDisplay] = useState(null)
  const [pieColors, setPieColors] = useState([]);
  const [dataDisplay, setDataDisplay] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");

  const dispatch = useDispatch();

  const onChangehandle = (data) => {
    setState(data);
    dispatch(getWalletBalance(data));
  };

  const { WallentBalanceData } = useSelector((state) => ({
    WallentBalanceData: state.dashboard.WallentBalanceData,
  }));

  const getSafetyCategory = async () => {
    setDataDisplay(null)
    loaderCount((current) => current + 1);
    var result = await UserDrivingScoreController.safetyCategoryController(
      filter.durationFilter,
      filter.startDateTime,
      filter.endDateTime,
    );
    loaderCount((current) => current - 1);
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    if (status == "SUCCESS") {
      setDataDisplay(null)
      var resultJSONData = resultJSON.data;
      // console.log("getSafetyCategory: " + JSON.stringify(resultJSONData));
      var totalRideDataList = [];
      var categoryName = [];
      var safetyList = [];
      var safetyCategoryData = [];
      var totalRides = null;
      var safetyName = "";
      var safetyName1 = '';
      var bottomValue = "Driver";

      var className = "";
      var piChartColor = [];
      var totalRideDataTempPerList = 0;
      resultJSONData.map((value) => {
        var piColor = "";

        var rideBoxList = ValueFormat.parseAsArray(value.rideBoxList);
        rideBoxList.map((rides) => {
          var totalRideDataTemp = ValueFormat.parseAsInt(rides.totalRides);
          totalRides = totalRideDataTemp + totalRides;
        });

        rideBoxList.map((rides) => {
          var rideBox = rides.rideBox;
          var totalRideDataTemp = ValueFormat.parseAsInt(rides.totalRides);
          var totalRideDataTempPer = parseInt(
            (ValueFormat.parseAsInt(rides.totalRides) / totalRides) * 100
          );

          totalRideDataTempPerList = parseFloat(
            (ValueFormat.parseAsInt(rides.totalRides) / totalRides) * 100
          ).toFixed(2);

          if (rideBox == 1) {
            safetyName = "Safe";
            safetyName1 = 'C1';
            className = "success";
            piColor = "#08BD3F";
          } else if (rideBox == 2) {
            safetyName = "Sportive";
            safetyName1 = 'C2';
            className = "primary";
            piColor = "#5156be";
          } else if (rideBox == 3) {
            safetyName = "Quite Safe";
            safetyName1 = 'C3';
            className = "info";
            piColor = "#11BFD0";
          } else if (rideBox == 4) {
            safetyName = "Reckless";
            safetyName1 = 'C4';
            className = "warning";
            piColor = "#ffbf53";
          } else if (rideBox == 5) {
            safetyName = "Quite Dangerous";
            safetyName1 = 'C5';
            className = "danger";
            piColor = "#FF0000";
          } else if (rideBox == 6) {
            safetyName = "Dangerous";
            safetyName1 = 'C6';
            className = "secondary";
            piColor = "#585F5F";
          }

          piChartColor.push(piColor);
          totalRideDataList.push(totalRideDataTempPer);
          categoryName.push(safetyName);

          var result = {
            name: safetyName,
            bottomText: bottomValue,
            name2: safetyName1,
            value: totalRideDataTemp,
            percentage: totalRideDataTempPerList,
            class: className,
          };
          var safetyPieChart = { value: totalRideDataTemp, name: safetyName1 };
          safetyCategoryData.push(safetyPieChart);

          safetyList.push(result);
        });
        const pieChartResult = {
          name: categoryName,
          data: totalRideDataList,
          colorName: piChartColor,
        };
        setDataDisplay(totalRides > 0 ? true : totalRides <= 0 ? false : null)
        setPieColors(piChartColor);
        setSafetyPieData(safetyCategoryData);
        if (safetyCategoryData.length > 0){
        getSafetyDataProps(safetyCategoryData);
        } else {
          getSafetyDataProps([
            { value: "-", name: 'C1' },
            { value: "-", name: 'C2' },
            { value: "-", name: 'C3' },
            { value: "-", name: 'C4' },
            { value: "-", name: 'C5' },
            { value: "-", name: 'C6' }])
        }
        // setSafetyPieData(pieChartResult);
        setSafetyData(safetyList);
        // setSafetyPieData(totalRideDataList);
      });
    } else {
      getSafetyDataProps(
        { value: 0, name: 'C1' },
        { value: 0, name: 'C2' },
        { value: 0, name: 'C3' },
        { value: 0, name: 'C4' },
        { value: 0, name: 'C5' },
        { value: 0, name: 'C6' })
      const code = resultJSON.error.code;
      if (code == "E007") {
        setDataDisplay("failed");
      } else {
        setDataDisplay(false);
      }
    }
  };

  useEffect(() => {
    dispatch(getWalletBalance(state));
  }, [state]);

  useEffect(() => {
    dispatch(getWalletBalance(state));
  }, [dispatch]);

  useEffect(() => {
    getSafetyCategory();
  }, [filter.durationFilter, filter.startDateTime, filter.endDateTime]);



  return (
    <React.Fragment>
      <Col xl={7}>
        <Card style={{ height: "490px" }}>
          <CardHeader
            className="align-items-center d-flex cardHeader"
          >
            <h4 className="sub_heading flex-grow-1">Safety Category</h4>
            {/* <div className="ms-auto">
              <div>
                <button
                  data-tooltip-id="cur"
                  type="button"
                  className={
                    filter == "CURRENT_DAY"
                      ? "btn btn-secondary btn-sm"
                      : "btn btn-soft-secondary btn-sm"
                  }
                  onClick={() => setFilter("CURRENT_DAY")}
                >
                  CD
                  <ReactTooltip
                    id="cur"
                    place="top"
                    content="Current Day"
                    style={{ backgroundColor: "black" }}
                  />
                </button>{" "}
                <button
                  data-tooltip-id="curw"
                  type="button"
                  className={
                    filter == "CURRENT_WEEK"
                      ? "btn btn-secondary btn-sm"
                      : "btn btn-soft-secondary btn-sm"
                  }
                  onClick={() => setFilter("CURRENT_WEEK")}
                >
                  CW
                  <ReactTooltip
                    id="curw"
                    place="top"
                    content="Current Week"
                    style={{ backgroundColor: "black" }}
                  />
                </button>{" "}
                <button
                  data-tooltip-id="curm"
                  type="button"
                  className={
                    filter == "CURRENT_MONTH"
                      ? "btn btn-secondary btn-sm"
                      : "btn btn-soft-secondary btn-sm"
                  }
                  onClick={() => setFilter("CURRENT_MONTH")}
                >
                  CM
                  <ReactTooltip
                    id="curm"
                    place="top"
                    content="Current Month"
                    style={{ backgroundColor: "black" }}
                  />
                </button>{" "}
                <button
                  data-tooltip-id="cury"
                  type="button"
                  className={
                    filter == "CURRENT_YEAR"
                      ? "btn btn-primary btn-sm"
                      : "btn btn-soft-secondary btn-sm"
                  }
                  onClick={() => setFilter("CURRENT_YEAR")}
                >
                  CY
                  <ReactTooltip
                    id="cury"
                    place="top"
                    content="Current Year"
                    style={{ backgroundColor: "black" }}
                  />
                </button>{" "}
                <button
                          type="button"
                          className={
                            filter == "CUSTOM_DURATION"
                              ? "btn btn-secondary btn-sm"
                              : "btn btn-soft-secondary btn-sm"
                          }
                          onClick={() =>{
                            setIsOpen(true);
                            // setFilter("CUSTOM_DURATION")
                          
                          }}
                          data-tooltip-id="dfdf"
                        >
                          <FontAwesomeIcon icon={faClock}/>
                          <ReactTooltip
                            id="dfdf"
                            place="top"
                            content="Custom Date Selection"
                            style={{ backgroundColor: "black" }}
                          />
                        </button>{" "}
              </div>
            </div> */}
          </CardHeader>

          <CardBody
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {dataDisplay == "failed" ? (
              <p className="data_not_available">{displayText.ER_007}</p>
            ) : dataDisplay == false ? (<p className="data_not_available">{displayText.DATA_NOT_AVAILABLE}</p>
            ) : dataDisplay == null ? (<p className="fetch_data text-center">{displayText.FETCHING_DATA}</p>) : ""}
            <Row className="align-items-center">
              <Col xs={7} >
                <div id="wallet-balance" className="apex-charts">
                  <WalletPieChart
                    WallentBalanceData={safetyPieData}
                    pieColors={pieColors}
                    safetyPieData={safetyPieData}
                  />

                </div>
              </Col>
              <Col className="align-self-center" xs={5} >
                <Table
                  className="table mb-0"
                  style={{ borderBottom: "0px solid white" }}
                >
                  <tbody>
                    {(safetyData || []).map((safety, key) => (
                      <tr key={key}>
                        <td>
                          <span
                            className="mb-2"
                            style={{ fontSize: "12px", verticalAlign: "top", whiteSpace: "nowrap" }}
                          >
                            <i
                              className={
                                `mdi mdi-circle align-middle font-size-9 me-1 text-` +
                                safety.class
                              }
                            ></i>
                            {safety.name} {safety.bottomText} - {safety.name2}
                          </span>
                          <br />
                          {/* <span
                              className="mb-2"
                              style={{ fontSize: "11px",marginLeft:"15px",marginTop:"-20px"}}
                            >
                              
                              
                            </span> */}
                        </td>
                        <td>
                          <span
                            style={{
                              float: "right",
                              fontWeight: 600,
                              fontSize: "12px",
                            }}
                            className="align-middle"
                          >
                            {safety.value}
                          </span>
                        </td>
                        <td>
                          <span
                            style={{ float: "right", fontSize: "12px" }}
                            className="text-muted font-size-10 fw-normal"
                          >
                            {safety.percentage}%
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </CardBody>

        </Card>
      </Col>
      <DateTimePickerPopup modalOpen={modalIsOpen} modalClose={setIsOpen} setStartDateTime={setStartDateTime} setEndDateTime={setEndDateTime} />

    </React.Fragment>
  );
};

export default WalletBalance;
