import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";
import 'react-circular-progressbar/dist/styles.css';
import '../src/assets/css/general.css';
import store from "./store";
import ScrollToTop from "./routes/scrollToTop";

navigator.serviceWorker.register('firebase-messaging-sw.js',{scope:"firsebase-cloud-messaging-push-scope"})
navigator.serviceWorker.ready
  .then(registration => registration.pushManager.subscribe({ /* options */ }))
  .then(subscription => {
    // Handle the successful subscription
    console.log('Subscription successful:', subscription);
  })
  .catch(error => {
    // Handle subscription error
    console.error('Subscription failed:', error);
  });
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.Fragment>
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </React.Fragment>
  </Provider>
);
